import useApis from '@/api'
import { ApiResponse } from '@/models/api'
import store from '@/store'
import { NavigationInformationInterface, NavigationItemWithChildrenInterface } from '@/store/modules/navigation/types'
import { LoginType } from '@/store/modules/user-session/types'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { AlertMessagesActions } from '../alert-messages/actions'
import { AlertMessageSource } from '../alert-messages/types'
import { NavigationCalloutMutations } from '../navigation-callouts/mutations'
import { UserSessionGetters } from '../user-session/getters'
import { NavigationPopulationApiResponse } from './types'

const { standard: api } = useApis()

export class NavigationCallouts {
  public static PopulateNavigationCallout = 'navigationCallouts/populateNavigation'
}

export async function getNavigationData (loginType: LoginType, agentId: number, isDaStore: boolean): Promise<NavigationPopulationApiResponse | false>
{
  const route = 'agent_portal/navigation/data_object'
  return api.post<ApiResponse<NavigationPopulationApiResponse>>(route, { agentId, isDaStore, loginType })
    .then<NavigationPopulationApiResponse>((response) => response.data.value)
    .catch<false>(async () => Promise.resolve(false))
}

export const actions: ActionTree<NavigationInformationInterface, RootState> = {
    async populateNavigation ({ commit }): Promise<boolean> {
      try {
        const loginType: LoginType = Number(store.getters[UserSessionGetters.CalculatedLoginType])
        const agentId = store.getters[UserSessionGetters.AgentId]
        const isDaStore = store.getters[UserSessionGetters.IsDaStore]

        const result = await getNavigationData(loginType, agentId, isDaStore)
        const navigationBar = result  as NavigationPopulationApiResponse
        const navigationElements = navigationBar.navigationElements as NavigationItemWithChildrenInterface[]

        const items: Array<NavigationItemWithChildrenInterface> =
          navigationElements
            .map((p: NavigationItemWithChildrenInterface) => ({
              active: p.active,
              children: p.children,
              disabled: p.disabled,
              external: p.external,
              loginType: p.loginType,
              name: p.name,
              newWindow: p.newWindow,
              order: p.order,
              path: p.path,
              permission: p.permission,
              text: p.text
            } as NavigationItemWithChildrenInterface))

            commit(NavigationCalloutMutations.SET_MAIN_NAVIGATION_ITEMS, items)
        return true
      }
      catch (reason) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText:
            "There was a problem loading the navigation bar.",
          source: AlertMessageSource.General
        })

        const message = 'navigation.populateNavigation :: failed:'
        console.log(message)
        return false
      }
    }
  }

  export default actions
