<template>
  <div class="my-auto">
    <div class="mx-auto mb-4 gateway">
      <Logos />
      <v-progress-linear
        :indeterminate="!hasError"
        :striped="hasError"
        :color="hasError ? 'error' : 'secondary'"
        :value="hasError ? 100 : 0"
      />
    </div>
  </div>
</template>

<script lang='ts'>
import Logos from '@/components/common/Logos.vue'
import enums from '@/constants/enumerations'
import router from '@/router'
import store from '@/store'
import { BusinessSearchActions } from '@/store/modules/business-search/actions'
import { ClientActions } from '@/store/modules/client/actions'
import { ClientGetters } from '@/store/modules/client/getters'
import { Client, ClientInterface } from '@/store/modules/client/types'
import { ConfigActions } from '@/store/modules/config-toggle/actions'
import { GatewayCalloutActions } from '@/store/modules/gateway-callouts/actions'
import { RoutingActionResult } from '@/store/modules/gateway-callouts/types'
import { GetExistingFirmographicsData } from '@/utils/ClientViewShared'
import { EventBus, Events } from '@/utils/EventBus'
import Register from '@/utils/ProcessingMaskHelpers'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'Gateway',
  components: {
    Logos,
  },
  setup() {
    const route = useRoute()
    const hasError = ref(false)
    EventBus.emit(Events.hideLayout)

    /**
     * Handles the bridging.
     */

    if (!route.query?.iid) window.location.href = process.env.VUE_APP_PL_URL + '/MainMenu.aspx'

    store
      .dispatch(GatewayCalloutActions.LoadAccessToken, { instanceId: route.query.iid })
      .then(async () => {
        return await store.dispatch(GatewayCalloutActions.LoadAmtInstance, { instanceId: route.query.iid })
      })
      .then(async (response: RoutingActionResult) => {
        if (!response) throw new Error('GatewayCalloutActions.LoadAmtInstance returned false')

        // Get config keys and set in session
        await store.dispatch(ConfigActions.GetConfigValues)

        const mode = response.mode

        if (mode === enums.appMode.ManageAgencyAccountSettings) {
          router.replace({ name: enums.appName.AgencySelfService })
        } else if (mode === enums.appMode.ClientSearch) {
          const entryPoint = response.entryPoint
          if (entryPoint === enums.entryPoint.Client) {
            router.replace({ name: enums.pageName.ClientSearch })
          } else if (entryPoint === enums.entryPoint.ClientView) {
            let client: ClientInterface = store.getters[ClientGetters.NewClient]
            await store.dispatch(ClientActions.LoadClient, client.accountHolderId)
            client = store.getters[ClientGetters.NewClient]
            if (client.clientDetail?.isUsdotVerified) store.dispatch(ClientActions.DisablePrefilledFields, true)
            await GetExistingFirmographicsData(client.accountHolderId)
            router.replace({ name: enums.pageName.ClientView })
          } else if (entryPoint === enums.entryPoint.NeedHelp) {
            await store.dispatch(BusinessSearchActions.SetReadOnly, true)
            const client: ClientInterface = store.getters[ClientGetters.NewClient]
            await store.dispatch(ClientActions.LoadClient, client.accountHolderId)
            router.replace({ name: enums.pageName.BusinessSearchInfo })
          } else if (entryPoint === enums.entryPoint.ClientEntry) {
            let client: ClientInterface = store.getters[ClientGetters.NewClient]
            await store.dispatch(ClientActions.LoadClient, client.accountHolderId)
            client = store.getters[ClientGetters.NewClient]
            client.isRewrite = true
            if (client.clientDetail?.isUsdotVerified) store.dispatch(ClientActions.DisablePrefilledFields, true)
            await GetExistingFirmographicsData(client.accountHolderId)
            router.replace({ name: enums.pageName.ClientEntry })
          } else if (
            entryPoint === enums.entryPoint.ClientEntryRewrite ||
            entryPoint === enums.entryPoint.ClientEntryCrossSell
          ) {
            const client: Client = store.getters[ClientGetters.NewClient]
            client.clientMode = enums.clientMode.NewClient
            if (entryPoint === enums.entryPoint.ClientEntryRewrite) client.isRewrite = true
            if (entryPoint === enums.entryPoint.ClientEntryCrossSell) client.isCrossSell = true
            Register(store.dispatch(ClientActions.SetNewClient, client))
            Register(store.dispatch(ClientActions.SetSelectedBusinessName, ''))
            router.replace({ name: enums.pageName.ClientEntry })
          }
        }
      })
      .catch((reason) => {
        console.error('The bridging process failed:', reason)
        hasError.value = true
      })

    return {
      hasError,
    }
  },
})
</script>

<style lang="scss" scoped>
.gateway {
  width: 133px;
}
</style>
