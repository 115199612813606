import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { ImageMutations } from './mutations'
import { Image, ImageInterface, ImageStoreInterface } from './types'

/**
 * The image actions class.
 */
export class ImageActions {
  public static GetLogos = 'image/getLogos'
}

/**
 * The image action tree.
 */
export const actions: ActionTree<ImageStoreInterface, RootState> = {
  getLogos ({ commit }) {
    const logos = new Array<ImageInterface>(
      new Image({
        path: require('@/assets/logos/National-General-Insurance.min.svg'),
        alt: 'National General Insurance Logo',
        displayOrder: 1,
        width: 133,
        height: 75
      })
    )
    commit(ImageMutations.SET_IMAGES, logos)
  }
}

export default actions
