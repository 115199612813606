import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AlertBannerInterface, AdminAlert } from './types'

export class AlertBannerGetters {
  public static AlertBanner = 'alertBanner/adminAlerts'
}

export const getters: GetterTree<AlertBannerInterface, RootState> = {
  adminAlerts: (state: AlertBannerInterface): Array<AdminAlert> => {
    return state.adminAlerts
  },
}

export default getters
