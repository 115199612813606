<template>
  <v-container>
    <alert-messages />
    <v-card flat>
      <v-card-title> Client View </v-card-title>
      <v-row class="pb-1">
        <v-col cols="8" class="pr-1">
          <v-card class="px-3 fill-height">
            <v-card-title class="font-weight-bold">{{ getFullName() }}</v-card-title>
            <v-row class="mx-3 mt-n6 mb-3">
              <v-col cols="6" class="pl-1">
                <v-row>
                  <v-col cols="5" class="pt-2 pb-0" id="account-id"> <b>Account ID:</b> </v-col>
                  <v-col class="pt-2 pb-0" for="account-id"> {{ accountHolder.accountHolderId }} </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="py-0" id="entity"> <b>Entity:</b> </v-col>
                  <v-col class="py-0" for="entity"> {{ accountHolder.clientDetail.businessEntityType }} </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="py-0 pb-2" id="occupation"> <b>Occupation:</b> </v-col>
                  <v-col class="pt-0 pb-2" for="occupation"> {{ accountHolder.clientDetail.occupation }} </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="pr-1">
                <v-row>
                  <v-col cols="4" class="pt-2 pb-0" id="address"><b>Address: </b></v-col>
                  <v-col class="pt-2 pb-0" for="address">
                    <div>{{ accountHolder.businessAddress.streetAddress }}</div>
                    <div v-if="hasSecondaryAddress">{{ accountHolder.businessAddress.secondaryAddress }}</div>
                    <div>{{ getCityStateZip() }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="py-0" id="phone"> <b>Phone:</b> </v-col>
                  <v-col class="py-0" for="phone"> {{ getFormattedPhoneInfo() }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="pt-0 pb-2" id="email"> <b>Email:</b> </v-col>
                  <v-col class="pt-0 pb-2" for="email"> {{ getEmailAddress() }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="px-1">
                  <simple-button color="primary" @click="editInfo()" class="mx-2" for="edit">Edit</simple-button>
                  <p class="font-small" id="edit">
                    Edits to a client's information will NOT apply to existing quotes or policies.<br />You must make
                    those changes directly in the quote or policy.
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="px-3 fill-height">
            <v-card-title>Add Products</v-card-title>
            <div class="mx-3 pl-1 mb-3">
              <v-row no-gutters class="my-0 flex-nowrap">
                <v-col cols="1" class="mr-1 flex-grow-1 flex-shrink-0 new-quote-label" id="state">
                  <div class="legacy-label">State</div>
                </v-col>
                <v-col cols="10" class="flex-grow-1 flex-shrink-0 new-quote-select">
                  <simple-select
                    class="pl-3"
                    :items="stateOptions"
                    v-model="selectedState"
                    @change="getSubProductOptions()"
                    for="state"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="my-0 flex-nowrap">
                <v-col cols="2" class="mr-1 flex-grow-1 flex-shrink-0 new-quote-label" id="product">
                  <div class="legacy-label">Product</div>
                </v-col>
                <v-col cols="10" class="flex-grow-1 flex-shrink-0 new-quote-select">
                  <simple-select class="pl-3" :items="subProductOptions" v-model="selectedSubProduct" for="product" />
                </v-col>
              </v-row>
              <v-row dense class="justify-center">
                <simple-button color="primary" @click="createQuote()" class="my-3">
                  New Quote From This Client
                </simple-button>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card v-if="showPolicies" class="my-2 px-3 py-2">
        <v-card-title>Policies</v-card-title>
        <client-view-policies :policies="accountHolder.policies" />
      </v-card>
      <v-card v-if="showQuotes" class="my-3 px-3 py-2">
        <v-card-title>Quotes</v-card-title>
        <client-view-quotes :quotes="accountHolder.quotes" />
      </v-card>
    </v-card>
  </v-container>
</template>

<style scoped>
.new-quote-label {
  min-width: 52px;
  max-width: 100%;
}
.new-quote-select {
  min-width: 430px;
  max-width: 100%;
}
</style>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { CommonSelectOptions, SelectOption } from '@/models/select-option'
import enums from '@/constants/enumerations'
import store from '@/store'
import { ClientGetters } from '@/store/modules/client/getters'
import { ClientInterface } from '@/store/modules/client/types'
import { FormatPhone } from '@/utils/Formatter'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import SimpleSelect from '@/components/common/input/AppSelect.vue'
import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import ClientViewPolicies from '@/components/client/ClientViewPolicies.vue'
import ClientViewQuotes from '@/components/client/ClientViewQuotes.vue'
import { BridgeToNamedInsured, GetExistingFirmographicsData, RedirectToClientEntry } from '@/utils/ClientViewShared'
import useFetchStateProducts from '@/components/client/composables/useFetchStateProducts'

export default defineComponent({
  name: 'ClientView',
  components: {
    AlertMessages,
    ClientViewPolicies,
    ClientViewQuotes,
    SimpleSelect,
    SimpleButton,
  },
  setup() {
    const accountHolder = computed<ClientInterface>(() => {
      return store.getters[ClientGetters.NewClient]
    })

    const selectedState = ref<string>(accountHolder.value.initialState)

    const stateOptions = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetAgentStateOptions()
    })

    async function getSubProductOptions() {
      return await useFetchStateProducts(selectedState.value)
    }

    getSubProductOptions().then(() => {
      CommonSelectOptions.GetAgentStateOptions()
    })

    const subProductOptions = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetAgentProductOptions()
    })

    const selectedSubProduct = ref<string>(accountHolder.value.subProduct)

    const showPolicies = computed<boolean>(() => {
      return accountHolder.value.policies?.length > 0
    })

    const showQuotes = computed<boolean>(() => {
      return accountHolder.value.quotes?.length > 0
    })

    function getFullName() {
      if (accountHolder.value.clientDetail?.businessEntityType === enums.entityType.Individual) {
        return `${accountHolder.value.person.firstName} ${accountHolder.value.person.lastName}`
      }
      return accountHolder.value.business?.businessName ?? ''
    }

    function formatZipCode() {
      return accountHolder.value.businessAddress.zipPlusFour
        ? `${accountHolder.value.businessAddress.zipCode}-${accountHolder.value.businessAddress.zipPlusFour}`
        : accountHolder.value.businessAddress.zipCode
    }

    function getCityStateZip() {
      return `${accountHolder.value.businessAddress.city}, ${
        accountHolder.value.businessAddress.state
      } ${formatZipCode()}`
    }

    function getFormattedPhoneInfo() {
      return accountHolder.value.phoneNumbers.length && accountHolder.value.phoneNumbers[0].phoneNumber !== ''
        ? `${FormatPhone(accountHolder.value.phoneNumbers[0].phoneNumber)} |
           ${accountHolder.value.phoneNumbers[0].phoneType}`
        : ''
    }

    function getEmailAddress() {
      return accountHolder.value.emailAddresses.length ? accountHolder.value.workEmailAddress.emailAddress : ''
    }

    const hasSecondaryAddress = computed<boolean>(() => {
      return accountHolder.value.businessAddress.secondaryAddress?.length > 0
    })

    async function editInfo() {
      await GetExistingFirmographicsData(accountHolder.value.accountHolderId)
      RedirectToClientEntry()
    }

    function createQuote() {
      store.dispatch(AlertMessagesActions.ClearMessages)
      const payload = {
        state: selectedState.value,
        subproduct: selectedSubProduct.value,
        generatedFrom: '0',
        isNewQuote: true,
      }

      if (selectedState.value !== '' && selectedSubProduct.value !== '') {
        let prefillType = enums.prefillType.CVSameState
        if (selectedSubProduct.value !== enums.product.CV) {
          if (selectedState.value !== accountHolder.value.initialState) {
            prefillType =
              accountHolder.value.clientDetail?.businessEntityType === enums.entityType.Individual
                ? enums.prefillType.NonCVDifferentState
                : enums.prefillType.None
          } else if (selectedSubProduct.value !== enums.product.CV) {
            prefillType =
              accountHolder.value.clientDetail?.businessEntityType === enums.entityType.Individual
                ? enums.prefillType.NonCVSameState
                : enums.prefillType.None
          }
        } else {
          if (selectedState.value !== accountHolder.value.initialState) {
            prefillType = enums.prefillType.CVDifferentState
          }
        }

        BridgeToNamedInsured(prefillType, payload)
      } else {
        if (accountHolder.value.initialState === '') {
          store.dispatch(AlertMessagesActions.AddErrorMessage, {
            messageText: 'Please select a State.',
            source: AlertMessageSource.General,
          })
        }

        if (accountHolder.value.product === '') {
          store.dispatch(AlertMessagesActions.AddErrorMessage, {
            messageText: 'Please select a Product.',
            source: AlertMessageSource.General,
          })
        }
      }
    }

    return {
      accountHolder,
      createQuote,
      editInfo,
      enums,
      getCityStateZip,
      getEmailAddress,
      getFormattedPhoneInfo,
      getFullName,
      getSubProductOptions,
      hasSecondaryAddress,
      subProductOptions,
      selectedSubProduct,
      selectedState,
      showPolicies,
      showQuotes,
      stateOptions,
    }
  },
})
</script>
