<template>
  <header>
    <section>
      <Logos />
    </section>
    <section v-if="!isReadOnly">
      <div class="d-flex flex flex-row-reverse">
        <a class="px-3" @click="logOut($event)">Logout</a>
        <p v-if="!$route.fullPath.includes(internalRoutes.agencySelfService)">Hello, {{ fullName }}</p>
      </div>
    </section>
  </header>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import store from '@/store'
import Logos from '@/components/common/Logos.vue'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import internalRoutes from '@/constants/internalRoutes'
import { BusinessSearchGetters } from '@/store/modules/business-search/getters'
import { SystemTransferCalloutActions } from '@/store/modules/system-transfer-callouts/actions'

export default defineComponent({
  name: 'PageHeader',
  components: {
    Logos,
  },
  setup() {
    const fullName = computed<string>(
      () => `${store.getters[UserSessionGetters.FirstName]} ${store.getters[UserSessionGetters.LastName]}`
    )
    const isReadOnly = computed<boolean>(() => {
      return store.getters[BusinessSearchGetters.ReadOnly]
    })

    async function logOut() {
      await store.dispatch(SystemTransferCalloutActions.DeleteCookies)
      await store.dispatch(SystemTransferCalloutActions.LogoutUser)
    }

    return {
      fullName,
      internalRoutes,
      isReadOnly,
      logOut,
    }
  },
})
</script>
