import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AgencyInterface } from './types'

export class AgencyGetters {
  public static Agency = 'agency/agency'
}

export const getters: GetterTree<AgencyInterface, RootState> = {
  agency: (state: AgencyInterface) => state,
}

export default getters
