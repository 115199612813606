import useApis from '@/api'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'

const { standard: api } = useApis()

export default async function (agentId: number): Promise<Array<string>> {
  try {
    const result = await api.get(`agent_portal/producers/agent/${agentId}/states`)

    if (!result.data?.isSuccessful) {
      store.dispatch(AlertMessagesActions.AddErrorMessage, {
        messageText: 'There was a problem getting list of states.',
        source: AlertMessageSource.General,
      })
      return []
    }

    return result.data.value.states
  } catch (reason) {
    console.error('producers :: agentStatesCallout :: failed', reason)
    return []
  }
}
