import store from "@/store"
import { OccupationSearchGetters } from "@/store/modules/occupation-search/getters"
import { Occupation, OccupationInterface } from "@/store/modules/occupation/types"
import useFetchOccupationList from "./useFetchOccupationList"

const noAvailableOccupations: Array<OccupationInterface> =
    [new Occupation({
        category: '',
        checked: false,
        description: '',
        naic: '',
        occupation: 'No available occupations returned',
        rnOnly: false,
        sic: '',
        subCategoryList: [],
        subCategory: null,
        unacceptable: true
    })]

/**
 *
 * @returns A prefiled list of occupations to display
 */
export default async function (prefilledOccupation: string): Promise<Array<OccupationInterface>> {
    await useFetchOccupationList()

    const occupations = store.getters[OccupationSearchGetters.OccupationResults]

    const prefiledOccupation = occupations.find((occupation: OccupationInterface) =>
        occupation.occupation === prefilledOccupation
    )

    return prefiledOccupation ? [prefiledOccupation] : noAvailableOccupations
}