import enumerations from "@/constants/enumerations"

export interface EmailInterface {
  contactId: number
  contactEmail: string
  contactName: string
}

export class Email implements EmailInterface {
  contactId: number
  contactEmail: string
  contactName: string

  constructor(options?: {
    contactId: number
    contactEmail: string
    contactName: string
  }) {
    this.contactId = options?.contactId ?? 0
    this.contactEmail = options?.contactEmail ?? ''
    this.contactName = options?.contactName ?? ''
  }
}

export interface AgencyContactPreferencesEmailsInterface {
  category: string
  primary: Email
  secondary: Email
  helpText: string
}

export class AgencyContactPreferencesEmails implements AgencyContactPreferencesEmailsInterface {
  category: string
  primary: Email
  secondary: Email
  helpText: string

  constructor(options?: {
    category: string
    primary: Email
    secondary: Email
    helpText: string
  }) {
    this.category = options?.category ?? ''
    this.primary = new Email({
      contactId: options?.primary.contactId ?? 0,
      contactEmail: options?.primary.contactEmail ?? '',
      contactName: options?.primary.contactName ?? ''
    })
    this.secondary = new Email({
      contactId: options?.secondary.contactId ?? 0,
      contactEmail: options?.secondary.contactEmail ?? '',
      contactName: options?.secondary.contactName ?? ''
    })
    this.helpText = enumerations.emailNotificationsHelpText[this.category as keyof typeof enumerations.emailNotificationsHelpText]
  }
}

export interface EmailListInterface {
  emails: Array<Email>;
}

export interface AgencyContactEmailListInformationInterface {
  listOfAgencyContactEmails: Array<Email>
  totalNumberOfAgencyContactEmails: number
}

export class AgencyContactEmailListInformation implements AgencyContactEmailListInformationInterface {
  listOfAgencyContactEmails: Array<Email>
  totalNumberOfAgencyContactEmails: number

  constructor(options?: {
    listOfAgencyContactEmails: Array<Email>
    totalNumberOfAgencyContactEmails: number
  }) {
    this.listOfAgencyContactEmails = options?.listOfAgencyContactEmails ?? []
    this.totalNumberOfAgencyContactEmails = options?.totalNumberOfAgencyContactEmails ?? 0
  }
}
export interface EmailNotificationsInterface {
  agencyContactPreferencesEmails: Array<AgencyContactPreferencesEmails>
  agencyContactEmailListInformation: AgencyContactEmailListInformationInterface
}

export interface AgencyEmailNotificationContactIDsInterface {
  category: string
  primaryContactId: number
  secondaryContactId: number
}

export class AgencyEmailNotificationContactIDs implements AgencyEmailNotificationContactIDsInterface {
  category: string
  primaryContactId: number
  secondaryContactId: number

  constructor(options?: {
    category: string
    primaryContactId: number
    secondaryContactId: number
  }) {
    this.category = options?.category ?? ''
    this.primaryContactId = options?.primaryContactId ?? 0
    this.secondaryContactId = options?.secondaryContactId ?? 0
  }
}
export interface AgencyEmailNotificationInformationRequestInterface {
  agentId: number
  agencyEmailNotificationContactIDs: Array<AgencyEmailNotificationContactIDsInterface>
}

export interface NewContactEmailPreferencesInformationInterface {
  email: string
  confirmEmail: string
  preferences: Array<string>
}

export class NewContactEmailPreferencesInformation implements NewContactEmailPreferencesInformationInterface {
  agentId: number
  email: string
  confirmEmail: string
  preferences: Array<string>

  constructor(options?: {
    agentId: number
    email: string
    confirmEmail:string
    preferences: Array<string>
  }) {
    this.agentId = options?.agentId ?? 0
    this.email = options?.email ?? ''
    this.confirmEmail = options?.confirmEmail ?? ''
    this.preferences = options?.preferences ?? []
  }
}