<template>
  <div class="add-email">
    <div class="pb-4 text-field">
      <simple-text-field
        placeholder="Enter Email"
        class="rounded-0 px-6 py-1"
        v-model="textField"
        @input="input"
        :rules="[rules.email]"
        dense
        hide-details
        outlined
      />
      <simple-text-field
        placeholder="Verify Email"
        class="rounded-0 px-6 py-1"
        v-model="confirmTextField"
        @input="input"
        :rules="[rules.matchText.value(textField, confirmTextField), rules.email]"
        dense
        hide-details
        outlined
      />
    </div>

    <v-card-text class="px-6">{{ message }}</v-card-text>

    <div class="email-notifications d-flex flex-wrap px-3">
      <v-col cols="6" v-for="(label, i) in checkBoxLabels" :key="label">
        <div v-if="showCheckboxes(label)">
          <span class="legacy-label bold">{{ label }}</span>
          <v-checkbox
            hide-details
            class="pa-0 ma-0"
            v-model="selected"
            :value="checkBoxLabels[i] + '|Primary'"
            @change="input"
          >
            <span slot="label" class="black--text">Primary</span>
          </v-checkbox>
          <v-checkbox
            hide-details
            class="pa-0 ma-0"
            v-model="selected"
            :value="checkBoxLabels[i] + '|Secondary'"
            @change="input"
          >
            <span slot="label" class="black--text">Secondary</span>
          </v-checkbox>
        </div>
      </v-col>
    </div>

    <v-card-text class="px-6"
      >Do you need to correct an email address? Click Cancel, then correct it in the Contacts section.</v-card-text
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { NewContactEmailPreferencesInformationInterface } from '@/store/modules/agency-contact-preferences-emails/types'
import ShowAgencyEmailNotification from '@/utils/ShowAgencyEmailNotification'
import Inputrestrictionrules from '@/constants/inputrestrictionrules'
import SimpleTextField from '@/components/common/input/SimpleTextField.vue'

export default defineComponent({
  name: 'ModalAddEmailSection',
  props: {
    message: {
      required: false,
      type: String,
      default: '',
    },
    checkBoxLabels: {
      required: false,
      type: Array as PropType<Array<string>>,
    },
  },
  components: { SimpleTextField },
  setup(props, context) {
    const selected = ref<Array<string>>([])
    const textField = ref<string>('')
    const confirmTextField = ref<string>('')

    const rules = Inputrestrictionrules.rules

    function showCheckboxes(category: string) {
      return ShowAgencyEmailNotification(category)
    }

    function input() {
      const newEmailInformation: NewContactEmailPreferencesInformationInterface = {
        email: textField.value.trim(),
        confirmEmail: confirmTextField.value.trim(),
        preferences: selected.value,
      }

      context.emit('input', newEmailInformation)
    }

    return {
      confirmTextField,
      input,
      rules,
      selected,
      showCheckboxes,
      textField,
    }
  },
})
</script>

<style lang="scss" scoped>
.email-notifications:deep(.legacy-label) {
  height: 1em;
}

.text-field:deep(input::placeholder) {
  font-style: italic;
  text-align: center;
}
</style>
