import store from '@/store'
import { OccupationSearchGetters } from '@/store/modules/occupation-search/getters'
import { OccupationInterface } from '@/store/modules/occupation/types'
import { ref } from 'vue'

/**
 *
 * @returns An array of Occupations from state with unique categories.
 */
export default function (): Array<OccupationInterface> {
  const allOccupations = ref<Array<OccupationInterface>>(store.getters[OccupationSearchGetters.OccupationResults] ?? [])

  return allOccupations.value
    .sort((x, y) => (x.category.toLowerCase() > y.category.toLowerCase() ? 1 : -1))
    .filter((value, index, self) => {
      return index === self.findIndex((t) => t.category === value.category)
    })
}
