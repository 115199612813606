import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "./actions"
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { OccupationFilterInterface } from "./types";

export const occupationFilter: Module<OccupationFilterInterface, RootState> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
}

export default occupationFilter