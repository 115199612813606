import { Client, ClientInterface, ClientListInterface } from './types'

export const state: ClientListInterface = {
  clientList: new Array<ClientInterface>(),
  clientMode: '',
  disablePrefilledFields: false,
  newClient: new Client(),
  selectedBusinessName: '',
}

export default state
