import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { NavigationInformationInterface, NavigationItemWithChildrenInterface } from './types'

export class NavigationGetters {
  public static GetMainNavigationItems = 'navigation/getMainNavigationItems'
}

export const getters: GetterTree<NavigationInformationInterface, RootState> = {
  getMainNavigationItems: (state): Array<NavigationItemWithChildrenInterface> => state.mainNavigationItems
}

export default getters
