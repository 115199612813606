import { DefaultDateFormat } from '@/constants/Strings'
import dayjs, { Dayjs } from 'dayjs'

// Automatically add forward-slash ("/") when typing
export function autoGenerateForwardSlash(textFieldInput: string) {
  const stringToBeMatched = textFieldInput

  // use this block if adding slash as typing mm and dd
  if (stringToBeMatched.match('^[0-9]{2}$') !== null || stringToBeMatched.match('^[0-9]{2}/[0-9]{2}$') !== null) {
    textFieldInput = stringToBeMatched + '/'
  }
  // only add slashes in when eight digits are typed in (MMDDYYYY)
  if (stringToBeMatched.match('^[0-9]{8}$') !== null) {
    const mm = textFieldInput.slice(0, 2)
    const dd = textFieldInput.slice(2, 4)
    const yyyy = textFieldInput.slice(4)

    textFieldInput = `${mm}/${dd}/${yyyy}`
  }
  return textFieldInput
}

// Automatically add dash ("-") when typing
export function autoGenerateDash(textFieldInput: string) {
  const stringToBeMatched = textFieldInput

  // add slashes in when ten digits are typed in (XXXXXXXXXX)
  if (stringToBeMatched.match('^[0-9]{10}$') !== null) {
    const areaCode = textFieldInput.slice(0, 3)
    const prefix = textFieldInput.slice(3, 6)
    const line = textFieldInput.slice(6)

    textFieldInput = `${areaCode}-${prefix}-${line}`
  }

  return textFieldInput
}

export function FormatDateForDatePicker(date: Date | Dayjs | string | undefined) {
  if (typeof date === 'undefined' || date === '') return ''
  if (typeof date === 'string' || date instanceof Date) return dayjs(date).format(DefaultDateFormat)
  // By process of elimination, we know date is a Dayjs instance
  return date.format(DefaultDateFormat)
}

export const FormatDate = (dateValue: string) => {
  let result: { [key: string]: string } = {}
  if (new Date(dateValue).toString() !== 'Invalid Date') {
    const time = new Date(dateValue.slice(0, -6))
    const meridiem = Number(time.getHours()) >= 12
    let hours = Number(time.getHours()) > 12 ? String(time.getHours() - 12) : String(time.getHours())
    hours = ('0' + hours).slice(-2)
    hours = hours === '00' ? '12' : hours
    result = {
      hh: hours,
      mm: ('0' + time.getMinutes()).slice(-2),
      A: meridiem ? 'PM' : 'AM',
    }
  }
  return result
}

export const FormatPhone = (phone: string) => {
  const digitsOnly = phone.replace(/\D/g, '')

  if (digitsOnly.length < 10) {
    return ''
  } else {
    const areaCode = digitsOnly.substr(0, 3)
    const prefix = digitsOnly.substr(3, 3)
    const line = digitsOnly.substr(6, 4)
    return areaCode + '-' + prefix + '-' + line
  }
}

export const ConvertToTitleCase = (src: string) => {
  if (src?.length) {
    const converter = function (matches: string) {
      return matches[0].toUpperCase()
    }

    let result = src.toLowerCase().replace(/\b[a-z](?!\s)/g, converter)
    result = result.charAt(0).toUpperCase() + result.slice(1)

    return result
  }
  return src
}

export const ConvertToUpperCase = (src: string) => {
  if (src?.length) {
    return src.toUpperCase()
  }

  return src
}

export const FormatAddress = (src: string) => {
  if (src.length) {
    let address = ''
    const parts = src.split(' ')

    for (let i = 0; i <= parts.length - 1; i++) {
      if (parts[i] === '') {
        continue
      } else if ((i === 0 && parts[i].match(/^p\.?o\.?$/gi)) || parts[i].match(/^(n|s)\.?(e|w)\.?$/gi)) {
        address += parts[i].replace(/\./g, '').toUpperCase() + ' '
      } else if (parts[i].match(/^\d+[a-z]*$/gi) && !parts[i].match(/^\d+(th)|(rd)|(st)|(nd)$/gi)) {
        address += parts[i].toUpperCase() + ' '
      } else {
        address += parts[i].toUpperCase().charAt(0) + parts[i].substring(1).toLowerCase() + ' '
      }
    }

    const reg = /^p(ost)?\s*\.?\s*o(ffice)?\s*\.?\s*b(ox)?\s*/gi
    if (address.replace(/\s*/g, '').match(reg)) {
      address = address.replace(reg, 'PO Box ')
    }

    address = address.trim()

    return address
  }

  return src
}

export const FormatCurrency = (src: number) => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

  return formatter.format(src)
}
// This trims extra whitespace within the string as well as from start/end
export const TrimExtraWhitespace = (src: string) => {
  if (src?.length) {
    return src.replace(/\s+/g, ' ').trim()
  }

  return ''
}

export function htmlDecode(value: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(value, 'text/html')
  return doc.documentElement?.textContent ?? value
}
