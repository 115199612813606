import useApis from '@/api'
import useAgentDisplay from '@/components/client/composables/useAgentDisplay'
import enums from '@/constants/enumerations'
import { ApiResponse } from '@/models/api'
import store from '@/store'
import {
  AccountHolderSaveRequest,
  AccountHolderSaveResponse,
  AccountHolderSaveResult,
  AccountHolderSearchResponse,
} from '@/store/modules/account-holder-callouts/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import Register from '@/utils/ProcessingMaskHelpers'
import { reactive } from 'vue'
import { ActionTree } from 'vuex'
import { ClientGetters } from './getters'
import { ClientMutations } from './mutations'
import { AddressInterface, Client, ClientInterface, ClientListInterface, EmailInterface, Phone } from './types'

export class ClientActions {
  public static DisablePrefilledFields = 'client/disablePrefilledFields'
  public static LoadClient = 'client/loadClient'
  public static SaveClient = 'client/saveClient'
  public static SearchClient = 'client/searchClient'
  public static SetClients = 'client/setClients'
  public static SetInitialState = 'client/setInitialState'
  public static SetNewClient = 'client/setNewClient'
  public static SetOccupation = 'client/setOccupation'
  public static SetProduct = 'client/setProduct'
  public static SetSelectedBusinessName = 'client/setSelectedBusinessName'
  public static SetSubProduct = 'client/setSubProduct'
  public static UpdateAddress = 'client/updateAddress'
  public static UpdateEmailAddress = 'client/updateEmailAddress'
}

const { standard: api } = useApis()

export const actions: ActionTree<ClientListInterface, RootState> = {
  async saveClient(context, payload: ClientInterface) {
    const request: AccountHolderSaveRequest = {
      agentId: payload.agentId !== 0 ? payload.agentId : store.getters[UserSessionGetters.AgentId],
      client: {
        accountHolderId: payload.accountHolderId,
        addresses: payload.addresses,
        business:
          payload.clientDetail.businessEntityType === enums.entityType.Corporation ||
          payload.clientDetail.businessEntityType === enums.entityType.Partnership
            ? payload.business
            : undefined,
        clientDetail: payload.clientDetail,
        emailAddresses: payload.workEmailAddress.emailAddress !== '' ? payload.emailAddresses : undefined,
        person: payload.clientDetail.businessEntityType === enums.entityType.Individual ? payload.person : undefined,
        phoneNumbers: payload.phoneNumbers[0].phoneNumber !== '' ? payload.phoneNumbers : undefined,
      },
      product: payload.product,
    }

    return await api
      .put<ApiResponse<AccountHolderSaveResponse>>('agent_portal/account_holder', request)
      .then<AccountHolderSaveResult>((response) => {
        if (response.data?.isSuccessful) {
          if (response.data?.value && payload.accountHolderId === 0) {
            payload.accountHolderId = response.data.value.accountHolderId ?? 0
            context.commit(ClientMutations.SET_NEW_CLIENT, payload)
          }
        }
        return {
          isSuccessful: response.data.isSuccessful,
          accountHolderId: payload.accountHolderId,
        } as AccountHolderSaveResult
      })
      .catch<AccountHolderSaveResult>((reason) => {
        console.error('account-holder-callouts :: accountHolderSave :: failed:', reason)
        return {
          isSuccessful: false,
          accountHolderId: 0,
          messages: new Array<string>(),
        }
      })
  },
  async searchClient(context, payload: ClientInterface) {
    // Clear any prior results
    context.commit(ClientMutations.SET_CLIENTS, [])

    const isInternalSearch = !useAgentDisplay()

    const addressToUse: AddressInterface =
      store.getters[UserSessionGetters.Product] === enums.product.CV
        ? payload.businessAddress
        : payload.residentialAddress

    return await api
      .post<ApiResponse<AccountHolderSearchResponse>>('agent_portal/account_holder/search', {
        agentId: store.getters[UserSessionGetters.AgentId],
        businessName: payload?.business.businessName ?? '',
        firstName: payload?.person.firstName ?? '',
        lastName: payload?.person.lastName ?? '',
        zipCode: addressToUse?.zipCode ?? '',
        internalSearch: isInternalSearch,
      })
      .then<boolean>((response) => {
        if (!response.data?.value) return false

        const clientList: Array<ClientInterface> =
          response.data.value.accountHolders?.map((accountHolder: ClientInterface) => new Client(accountHolder)) ?? []
        context.commit(ClientMutations.SET_CLIENTS, clientList)

        return true
      })
      .catch<false>((reason) => {
        console.error('searchClient failed:', reason)

        return false
      })
    return true
  },
  async loadClient(context, accountHolderId: number) {
    const isInternalSearch = !useAgentDisplay()

    return await api
      .post(`agent_portal/account_holder/information`, {
        accountHolderId,
        agentId: store.getters[UserSessionGetters.AgentId],
        internalSearch: isInternalSearch,
      })
      .then<boolean>((response) => {
        if (!response.data?.value) return false
        const clientToLoad: ClientInterface = new Client(response.data.value)

        // TODO: Fix this once the split phone# component is used
        if (!clientToLoad.phoneNumbers.length) clientToLoad.phoneNumbers = [new Phone()]

        // This needs to actually be false since the DDL recognizes 'null' as not selected
        if (clientToLoad.clientDetail?.businessEntityType && clientToLoad.clientDetail.motorCarrierForHire === null) {
          clientToLoad.clientDetail.motorCarrierForHire = false
        }

        clientToLoad.initialState = store.getters[UserSessionGetters.InitialState]
        clientToLoad.product = store.getters[UserSessionGetters.Product]
        clientToLoad.subProduct = store.getters[UserSessionGetters.SubProduct]

        context.commit(ClientMutations.SET_NEW_CLIENT, clientToLoad)

        return true
      })
      .catch<false>((reason) => {
        console.error('account-holder-callouts :: accountHolderLoad :: failed:', reason)
        const NewClient = reactive(new Client())
        const client: Client = store.getters[ClientGetters.NewClient]
        client.clientMode = enums.clientMode.NewClient
        NewClient.initialState = client.initialState
        NewClient.businessAddress.state = client.initialState
        NewClient.product = client.product
        NewClient.subProduct = client.subProduct
        Register(store.dispatch(ClientActions.SetNewClient, NewClient))
        Register(store.dispatch(ClientActions.SetSelectedBusinessName, ''))

        return false
      })
  },
  setClients(context, payload: ClientInterface) {
    context.commit(ClientMutations.SET_CLIENTS, payload)
  },
  setNewClient(context, payload: ClientInterface) {
    context.commit(ClientMutations.SET_NEW_CLIENT, payload)
  },
  setInitialState({ commit }, initialState: string) {
    commit(ClientMutations.SET_INITIAL_STATE, initialState)
  },
  setOccupation({ commit }, occupation: string) {
    commit(ClientMutations.SET_OCCUPATION, occupation)
  },
  setProduct({ commit }, product: string) {
    commit(ClientMutations.SET_PRODUCT, product)
  },
  setSelectedBusinessName({ commit }, businessName: string) {
    commit(ClientMutations.SET_SELECTEDBUSINESSNAME, businessName)
  },
  setSubProduct({ commit }, subProduct: string) {
    commit(ClientMutations.SET_SUBPRODUCT, subProduct)
  },
  updateAddress({ commit }, payload: AddressInterface) {
    commit(ClientMutations.SET_ADDRESS, payload)
  },
  updateEmailAddress({ commit }, payload: EmailInterface) {
    commit(ClientMutations.SET_EMAILADDRESS, payload)
  },
  disablePrefilledFields({ commit }, shouldDisable: boolean) {
    commit(ClientMutations.SET_DISABLEPREFILLEDFIELDS, shouldDisable)
  },
}
