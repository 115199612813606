import useCategoriesToDisplay from '@/components/client/composables/useCategoriesToDisplay'
import useFetchOccupationList from '@/components/client/composables/useFetchOccupationList'
import useOccupationsToDisplay from '@/components/client/composables/useOccupationsToDisplay'
import usePrefilledOccupationList from '@/components/client/composables/usePrefilledOccupationList'
import useSubCategoriesToDisplay from '@/components/client/composables/useSubCategoriesToDisplay'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { OccupationInterface, SubCategory } from '../occupation/types'
import { OccupationFilterMutations } from './mutations'
import { OccupationFilterInterface } from './types'

export class OccupationFilterActions {
  public static FilterOccupations = 'occupationFilter/filterOccupations'
  public static ResetModule = 'occupationFilter/resetModule'
  public static SetListBasedOnPrefilledOccupation = 'occupationFilter/setPrefilledOccupation'
  public static SetBusinessTypeAfterSelection = 'occupationFilter/setBusinessTypeAfterSelection'
  public static UpdateSelectedCategories = 'occupationFilter/updateCategories'
  public static UpdateSelectedSubCategories = 'occupationFilter/updateSubCategories'
  public static UpdateSearchString = 'occupationFilter/updateSearchString'
}

export const actions: ActionTree<OccupationFilterInterface, RootState> = {
  filterOccupations({ commit }) {
    commit(OccupationFilterMutations.SET_FILTERED_OCCUPATION_LIST, useOccupationsToDisplay())
  },
  resetModule: async function ({ commit }) {
    await useFetchOccupationList()
    commit(OccupationFilterMutations.SET_SEARCH_STRING, '')
    commit(OccupationFilterMutations.SET_SELECTED_OCCUPATION_CATEGORIES, useCategoriesToDisplay())
    commit(OccupationFilterMutations.SET_SELECTED_SUBCATEGORIES, useSubCategoriesToDisplay())
    commit(OccupationFilterMutations.SET_FILTERED_OCCUPATION_LIST, useOccupationsToDisplay())
  },
  updateCategories({ commit }, categories: Array<OccupationInterface>) {
    commit(OccupationFilterMutations.SET_SELECTED_OCCUPATION_CATEGORIES, categories)
  },
  updateSearchString({ commit }, searchString: string) {
    commit(OccupationFilterMutations.SET_SEARCH_STRING, searchString)
  },
  updateSubCategories({ commit }, subCategories: Array<SubCategory>) {
    commit(OccupationFilterMutations.SET_SELECTED_SUBCATEGORIES, subCategories)
  },
  // For the prefill
  setPrefilledOccupation: async function ({ commit }, prefilledOccupation: string) {
    commit(
      OccupationFilterMutations.SET_FILTERED_OCCUPATION_LIST,
      await usePrefilledOccupationList(prefilledOccupation)
    )
    commit(OccupationFilterMutations.SET_SEARCH_STRING, '')
    commit(OccupationFilterMutations.SET_SELECTED_OCCUPATION_CATEGORIES, useCategoriesToDisplay())
    commit(OccupationFilterMutations.SET_SELECTED_SUBCATEGORIES, useSubCategoriesToDisplay())
  },
  // Set to the selected occupation
  setBusinessTypeAfterSelection({ commit }, newOccupation: OccupationInterface) {
    commit(OccupationFilterMutations.SET_FILTERED_OCCUPATION_LIST, [newOccupation])
    commit(OccupationFilterMutations.SET_SEARCH_STRING, '')
  },
}
