import useApis from '@/api'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { AlertBannerMutations } from './mutations'
import { AdminAlert, AdminAlertInterface, AlertBannerInterface } from './types'

export class AlertBannerActions {
  /**
   * gets the alert banner information that is associated with the Princeton Code
   */
  public static GetAdminAlertsByPrincetonCode = 'alertBanner/getAdminAlertsByPrincetonCode'
  /**
   * dismisses the alert banner
   */
  public static DismissAdminAlert = 'alertBanner/dismissAdminAlert'
}

const { standard: api } = useApis()

export const actions: ActionTree<AlertBannerInterface, RootState> = {
  async getAdminAlertsByPrincetonCode({ commit }, payload: { princetonCode: string }) {
    try {
      const request = {
        princetonCode: payload.princetonCode,
      }
      return await api.post('lookup/admin_alerts/princeton_code', request).then(async (response) => {
        if (response.data.value) {
          const adminAlerts: Array<AdminAlertInterface> = response.data.value.map(
            (alert: AdminAlert) => new AdminAlert(alert)
          )
          commit(AlertBannerMutations.SET_ALERTBANNER, adminAlerts)
        } else {
          commit(AlertBannerMutations.SET_ALERTBANNER, [])
        }
      })
    } catch (reason) {
      console.error('agencyhome :: getAdminAlertByPrincetonCode :: failed:', reason)
      return null
    }
  },

  async dismissAdminAlert({ dispatch }, payload: { alertDismissed: AdminAlert }) {
    try {
      return await api.post('agent_portal/admin_alerts/dismiss', payload.alertDismissed).then(async (response) => {
        if (response.data?.isSuccessful) {
          dispatch(
            AlertBannerActions.GetAdminAlertsByPrincetonCode,
            { princetonCode: payload.alertDismissed.princetonCode },
            { root: true }
          )
        }
      })
    } catch (reason) {
      console.error('agencyhome :: dismissAdminAlert :: failed:', reason)
    }
  },
}
