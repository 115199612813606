<template>
  <div class="agency-self-service">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { EventBus, Events } from '@/utils/EventBus'

export default defineComponent({
  name: 'AgencySelfService',
  setup() {
    EventBus.emit(Events.showLayout)
    return {}
  },
})
</script>

<style scoped>
.agency-self-service {
  max-width: 1300px;
  margin: 0 auto;
}
</style>
