import { MutationTree } from 'vuex'
import { ConfigInformationInterface, ConfigKeyValueInterface, } from './types'

export class ConfigMutations {
  public static SET_CONFIG = 'SET_CONFIG'
}

export const mutations: MutationTree<ConfigInformationInterface> = {
  SET_CONFIG(state: ConfigInformationInterface, configKeyValueInformation: ConfigKeyValueInterface) {
    if (!state.configInformation.find((config) => config.key === configKeyValueInformation.key)) {
      state.configInformation.push(configKeyValueInformation)
    }
  }
}

export default mutations
