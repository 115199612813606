<template>
  <v-row v-if="!hidden" no-gutters class="py-1">
    <v-col v-if="label" :cols="hasTooltip ? 3 : 4">
      <div class="legacy-label align-start required">{{ label }}</div>
    </v-col>
    <v-col v-if="hasTooltip" cols="1">
      <simple-tooltip :persistOnClick="true">
        <slot name="tooltip" />
      </simple-tooltip>
    </v-col>
    <v-col :cols="label ? 5 : 7">
      <v-radio-group dense class="my-n1" v-model="selected">
        <v-radio class="black--text" v-for="(option, index) in options" :key="index" :value="option.value">
          <span slot="label" class="black--text">
            {{ option.text }}
          </span>
        </v-radio>
      </v-radio-group>
      <span v-if="hasFooter" class="mt-2 black--text">
        <slot name="footer" />
      </span>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { SelectOption } from '@/models/select-option'
import SimpleTooltip from './SimpleTooltip.vue'

export default defineComponent({
  name: 'AppRadio',
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hidden: {
      required: false,
      type: Boolean,
      default: false,
    },
    options: {
      required: true,
      type: Array as PropType<Array<SelectOption<string>>>,
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    value: {
      required: false,
      type: String,
      default: '',
    },
    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    'simple-tooltip': SimpleTooltip,
  },
  setup(props, context) {
    const selected = computed<string>({
      get: () => props.value ?? '',
      set: (newValue) => context.emit('input', newValue.toString()),
    })

    // Reads the text contents of what's being passed into the tooltips slot to determine if the tooltip should render.
    const hasFooter = computed(() => context.slots.footer)
    const hasTooltip = computed(() => context.slots.tooltip && context.slots.tooltip()[0]?.text?.trim())

    const attrs = computed(() => context.attrs)
    const listeners = computed(() => context.listeners)

    return {
      attrs,
      hasFooter,
      hasTooltip,
      listeners,
      selected,
    }
  },
})
</script>
