<template>
    <div>
      <v-row>
        <v-col class="d-flex justify-space-between">
          <h2>Agency Information</h2>
          <router-link class="edit-btn" :to="{ name: 'EditAgency' }">
           <simple-button color="primary" >Edit</simple-button>
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-3">
          <p class="bold black--text">Agency Name</p>
        </v-col>
        <v-col class="col-9">
          <p class="black--text">{{ agency.name }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-3">
          <p class="bold black--text">Agency AKA Name</p>
        </v-col>
        <v-col class="col-9">
          <p class="black--text">{{ agency.akaName }}</p>
        </v-col>
      </v-row>
       <v-row>
        <PhysicalAddress label="Agency Mailing Address" :address="agency.mailingAddress" />
        <PhysicalAddress label="Agency Street Address" :address="agency.streetAddress" />
      </v-row>
     <v-row>
        <v-col class="col col-6">
          <p class="bold black--text">Agency Phone Number</p>
          <p class="black--text">{{ phone }}</p>
        </v-col>
        <v-col class="col col-6">
          <p class="bold black--text">Agency Fax Number</p>
          <p class="black--text">{{ fax }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-3">
          <p class="bold black--text">Agency Email</p>
        </v-col>
        <v-col class="col-5">
          <p class="black--text">{{ agency.email }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-3">
          <p class="bold black--text">Agency Website</p>
        </v-col>
        <v-col class="col-5">
          <p class="black--text">{{ agency.website }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-3">
          <p class="bold black--text">Comp Rater</p>
        </v-col>
        <v-col class="col-5">
          <p class="black--text">{{ agencyComprater }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col col-3">
          <p class="bold black--text">Agency Hours</p>
        </v-col>
        <v-col class="col-5">
          <p class="black--text">{{ openTime }}{{openTime && closeTime ? '&ndash;' : ''}}{{ closeTime }}</p>
        </v-col>
      </v-row>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import PhysicalAddress from '@/components/common/Address.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'

import { Agency } from '@/store/modules/agency/types'
import { Comprater } from '@/store/modules/comprater/types'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'AgencyInfo',
  components: {
    PhysicalAddress,
    SimpleButton
  },
  props: {
    agency: {
      type: Object as PropType<Agency>,
      required: true,
    },
    comprater: {
      type: Object as PropType<Comprater>,
      required: true,
    },
  },
  setup(props) {

    const formatDateString = (dateValue : string) => new Date(dateValue).toString()!=="Invalid Date"
      ? dayjs(dateValue.slice(0, -6)).format('h:mm A')
      :''

    const phone = computed( () => props.agency.phone.formattedPhoneNumber)
    const fax = computed( () => props.agency.fax.formattedPhoneNumber)
    const openTime = computed( () => formatDateString(props.agency.hours?.monday?.openTime))
    const closeTime = computed( () => formatDateString(props.agency.hours?.monday?.closeTime))
    const agencyComprater = computed( () => { return props.comprater.id ? String(props.comprater.data[props.comprater.id]) : "" })

    return {
      phone,
      fax,
      openTime,
      closeTime,
      agencyComprater
    }
  },
})
</script>

<style lang="scss" scoped>
.col {
  padding-top: 0;
  padding-bottom: 3px;
}

p {
  white-space: nowrap;
}

.ngic-button {
  width: 125px
}
</style>
