<template>
  <div class="pa-8 container">
    <alert-messages />
    <h2>Agency Information</h2>
    <div>
      <div class="actions">
        <simple-button color="primary" @click="updateAgency" class="mx-2">Save</simple-button>
        <modal-cancel />
      </div>
      <app-text-field :disabled="true" label="Agency Name" :maxlength="50" isBold v-model="agency.name" />
      <app-text-field :disabled="true" label="Agency Code" isBold v-model="agency.princetonAgtCode" />
      <app-text-field :disabled="true" label="Agency AKA Name" :maxlength="60" isBold v-model="agency.akaName" />
      <div class="address">
        <span class="col-md-3 bold pa-0 required">Agency Mailing Address</span>
        <app-text-field
          label="Street"
          @keydown="StreetAddressRestrictor"
          :maxlength="40"
          v-model="agency.mailingAddress.street"
        />
        <app-text-field label="City" @keydown="CityRestrictor" :maxlength="20" v-model="agency.mailingAddress.city" />
        <app-select label="State" :items="stateOptions" v-model="agency.mailingAddress.state" />
        <app-text-field label="Zip" @keydown="IntegersOnly" :maxlength="5" v-model="agency.mailingAddress.zip" />
      </div>
      <div class="address">
        <span class="col-md-3 bold pa-0 required">Agency Street Address</span>
        <app-text-field
          label="Street"
          @keydown="StreetAddressRestrictor"
          :maxlength="40"
          v-model="agency.streetAddress.street"
        />
        <app-text-field label="City" @keydown="CityRestrictor" :maxlength="20" v-model="agency.streetAddress.city" />
        <app-select label="State" :items="stateOptions" v-model="agency.streetAddress.state" />
        <app-text-field label="Zip" @keydown="IntegersOnly" :maxlength="5" v-model="agency.streetAddress.zip" />
      </div>
      <phone-field label="Agency Phone Number" isBold isRequired v-model="phone" />
      <phone-field label="Agency Fax Number" isBold v-model="fax" />
      <app-text-field
        label="Agency Email"
        :rules="[rules.email]"
        :maxlength="100"
        isBold
        isRequired
        v-model="agency.email"
      >
        <template v-slot:helpText> This email address will appear on customer applications. </template>
      </app-text-field>
      <app-text-field label="Agency Website" :rules="[rules.website]" :maxlength="100" isBold v-model="agency.website">
        <template v-slot:helpText> Web addresses must include either www., https://, or http:// prefix. </template>
      </app-text-field>
      <v-row no-gutters class="py-1">
        <v-col class="col col-4">
          <p class="bold black--text">Agency Hours</p>
        </v-col>
        <v-col class="col-5 timePicker">
          <span class="pr-1">Open</span>
          <VueTimepicker format="hh:mm A" :minute-interval="30" v-model="open" />
          <span class="pl-4 pr-1">Close</span>
          <VueTimepicker format="hh:mm A" :minute-interval="30" v-model="close" />
        </v-col>
      </v-row>
      <app-select label="Comp Rater" isBold :items="pickList" v-model="selected" />

      <v-alert type="info" dense outlined class="py-1 my-6 infoBackground">
        <span>{{ processTimeInfoMessage }} </span>
      </v-alert>

      <div class="actions">
        <simple-button color="primary" @click="updateAgency" class="mx-2">Save</simple-button>
        <modal-cancel />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import store from '@/store'

import ModalCancel from '@/components/common/modals/ModalCancel.vue'
import AppSelect from '@/components/common/input/AppSelect.vue'
import AppTextField from '@/components/common/input/AppTextField.vue'
import PhoneField from '@/components/common/input/PhoneField.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import inputrestrictionrules from '@/constants/inputrestrictionrules'
import verbiage from '@/constants/verbiage'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'

import { Phone } from '@/models/phone-number'
import { IntegersOnly, CityRestrictor, NoNumberRestrictor, StreetAddressRestrictor } from '@/utils/InputFilters'

import { CommonSelectOptions, SelectOption } from '@/models/select-option'
import { CompraterInterface, SaveCompraterRequest } from '@/store/modules/comprater/types'

import { AgencyActions } from '@/store/modules/agency/actions'
import { AgencyGetters } from '@/store/modules/agency/getters'
import { CompraterActions } from '@/store/modules/comprater/actions'
import { CompraterGetters } from '@/store/modules/comprater/getters'
import {
  Address,
  AgencyInformationRequest,
  AgencyInterface,
  DayRequest,
  HoursOfOperationRequest,
} from '@/store/modules/agency/types'

import Register from '@/utils/ProcessingMaskHelpers'
import { FormatDate } from '@/utils/Formatter'
import ReturnToHomePage from '@/utils/ReturnToHomePage'
import ClearAlertMessages from '@/utils/ClearAlertMessages'

export default defineComponent({
  name: 'EditAgency',

  components: {
    AlertMessages,
    AppSelect,
    AppTextField,
    ModalCancel,
    PhoneField,
    SimpleButton,
    VueTimepicker,
  },

  setup() {
    const rules = inputrestrictionrules.rules
    const processTimeInfoMessage = verbiage.processTimeInfo.editAgency
    const stateOptions = computed(() => CommonSelectOptions.GetProducerStateOptions())

    const agency = computed<AgencyInterface>(() => {
      store.dispatch(AlertMessagesActions.ClearMessages)
      store.dispatch(AgencyActions.GetAgencyFromSession)
      return store.getters[AgencyGetters.Agency]
    })

    const phone = ref<Phone>(agency.value.phone)
    const fax = ref<Phone>(agency.value.fax)
    const open = ref<{ [key: string]: string }>({})
    const close = ref<{ [key: string]: string }>({})

    onMounted(() => {
      open.value = FormatDate(agency.value.hours?.monday?.openTime)
      close.value = FormatDate(agency.value.hours?.monday?.closeTime)

      // Must set the format as "hh:mm A" in the time picker elements
      // But update the placeholder for the timer pickers to "hh:mm" here
      Array.from(document.getElementsByClassName('vue__time-picker')).forEach((field) => {
        field.children[0].setAttribute('placeholder', 'hh:mm')
      })
    })

    const comprater = computed<CompraterInterface>(() => {
      store.dispatch(CompraterActions.GetCompraterFromSession)
      return store.getters[CompraterGetters.Comprater]
    })

    const pickList = computed<Array<SelectOption<string>>>(() => {
      const items: Array<SelectOption<string>> = []
      let noneOption: SelectOption<string> = new SelectOption<string>('', '')

      for (const [key, value] of Object.entries(comprater.value.data)) {
        if (key === '93')
          // 93 == None
          noneOption = new SelectOption<string>(value, key)
        else items.push(new SelectOption<string>(value, key))
      }

      items.sort((a, b) => a.text.localeCompare(b.text))

      if (noneOption.text !== '') items.push(noneOption)

      return items
    })

    const selected = ref<string>(comprater.value.id ? String(comprater.value.id) : '')

    const updateAgency = async () => {
      store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })

      const mondayWithRequestedHours = new DayRequest(open.value, close.value)

      const agencyInformation: AgencyInformationRequest = {
        id: agency.value.id,
        name: agency.value.name,
        akaName: agency.value.akaName,
        mailingAddress: new Address({
          street: agency.value.mailingAddress.street,
          city: agency.value.mailingAddress.city,
          state: agency.value.mailingAddress.state,
          zip: agency.value.mailingAddress.zip,
        }),
        streetAddress: new Address({
          street: agency.value.streetAddress.street,
          city: agency.value.streetAddress.city,
          state: agency.value.streetAddress.state,
          zip: agency.value.streetAddress.zip,
        }),
        princetonAgtCode: agency.value.princetonAgtCode,
        phone: phone.value.phoneNumber,
        fax: fax.value.phoneNumber,
        email: agency.value.email,
        website: agency.value.website.toLowerCase(),
        status: agency.value.status,
        hours: new HoursOfOperationRequest(mondayWithRequestedHours),
      }

      const compraterInformation: SaveCompraterRequest = {
        agentId: agency.value.id,
        pickListId: Number(selected.value),
      }

      await Register(
        store.dispatch(AgencyActions.UpdateAgency, {
          agencyInformation,
          compraterInformation,
        })
      ).then((response) => {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById('app')?.scrollIntoView()
        if (response) ReturnToHomePage()
      })
    }

    const clearAlertMessages = () => ClearAlertMessages()

    return {
      agency,
      CityRestrictor,
      clearAlertMessages,
      close,
      fax,
      IntegersOnly,
      NoNumberRestrictor,
      open,
      phone,
      pickList,
      processTimeInfoMessage,
      rules,
      selected,
      stateOptions,
      StreetAddressRestrictor,
      updateAgency,
    }
  },
})
</script>

<style lang="scss" scoped>
.container:deep() {
  @import '@/styles/_inputField.scss';
}
</style>
