import { isConfigEnabled } from '@/components/common/composables/isConfigEnabled'
import { NavigationItemType } from '@/components/layout/navigation/navigationItem'
import configKeys from '@/constants/configKeys'
import navigationData from '@/data/navigationData.json'
import navigationDataV2 from '@/data/navigationDataV2.json'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { LoginType } from '@/store/modules/user-session/types'
import { RootState } from '@/store/types'
import { computed } from 'vue'
import { ActionTree } from 'vuex'
import { AlertMessagesActions } from '../alert-messages/actions'
import { NavigationMutations } from './mutations'
import { NavigationItemWithChildrenInterface, NavigationInformationInterface } from './types'
import { NavigationCallouts } from '../navigation-callouts/actions'
import { NavigationCalloutGetters } from '../navigation-callouts/getters'

export class NavigationActions {
  /**
   * populates the Navigation items
   */
  public static PopulateNavigation = 'navigation/populateNavigation'
}

function allowItem(item: NavigationItemType, loginType: LoginType): boolean {
  return item.active && item.loginType.includes(loginType)
}

export const actions: ActionTree<NavigationInformationInterface, RootState> = {
  async populateNavigation({ commit }): Promise<boolean> {

    try {
      const isNavigationCalledOut = computed<boolean>(() => isConfigEnabled(configKeys.ado639297_SwitchToNavigationApiLoading))
      if(isNavigationCalledOut.value){
        await store.dispatch( NavigationCallouts.PopulateNavigationCallout)
        const result = store.getters[NavigationCalloutGetters.GetMainNavigationItems]
        commit(NavigationMutations.SET_MAIN_NAVIGATION_ITEMS, result)

        return true
      }

      const loginType: LoginType = Number(store.getters[UserSessionGetters.CalculatedLoginType])
      const isEnabled = computed<boolean>(() => isConfigEnabled(configKeys.ADO_538732_NavigationBarUpdates))

      const data = computed(() => {
        if (isEnabled.value) {
          return navigationDataV2
        }
        return navigationData
      })

      const items: Array<NavigationItemWithChildrenInterface> =
        data.value
          .filter((x: NavigationItemType) => allowItem(x, loginType))
          .map((p: NavigationItemWithChildrenInterface) => ({
            active: p.active,
            children: p.children?.filter((c: NavigationItemType) => allowItem(c, loginType)),
            disabled: p.disabled,
            external: p.external,
            loginType: p.loginType,
            name: p.name,
            newWindow: p.newWindow,
            order: p.order,
            path: p.path,
            permission: p.permission,
            text: p.text
          } as NavigationItemWithChildrenInterface))

      commit(NavigationMutations.SET_MAIN_NAVIGATION_ITEMS, items)
      return true
    }
    catch (reason) {
      store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'There was a problem loading the navigation bar.',
        })

      const message = 'navigation.populateNavigation :: failed:'
      // eslint-disable-next-line no-console
      console.log(message)
      return false
    }

  }
}
