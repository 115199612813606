<template>
  <div class="pt-4">
    <div class="d-flex my-3">
      <h2>Agency Code</h2>
      <DropDown
        :options="codeOptions"
        :selected="agentId"
        :disabled="!agentIsHomeOffice"
        @change="changeAgencyCode($event.target[$event.target.selectedIndex])"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import DropDown from '@/components/common/input/DropDown.vue'
import { AgencyGroupListInterface } from '@/store/modules/agency-code/type'
import { SelectOption } from '@/models/select-option'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import store from '@/store'
import { UserSessionActions } from '@/store/modules/user-session/actions'
import { AgencyCodeActions } from '@/store/modules/agency-code/actions'
import { AgencyActions } from '@/store/modules/agency/actions'
import { ContactActions } from '@/store/modules/contacts/actions'
import { AlertBannerActions } from '@/store/modules/admin-alert/actions'
import { EventBus, Events } from '@/utils/EventBus'
import Register from '@/utils/ProcessingMaskHelpers'
import { AgencyContactPreferencesEmailsActions } from '@/store/modules/agency-contact-preferences-emails/actions'

export default defineComponent({
  name: 'AgencyCode',
  components: {
    DropDown,
  },
  props: {
    agentIsHomeOffice: {
      type: Boolean,
      required: true,
    },
    agencyGroups: {
      type: Object as PropType<AgencyGroupListInterface>,
      required: true,
    },
  },
  setup(props) {
    const codeOptions = computed(() =>
      props.agencyGroups.agencyGroupList.map(
        (agencyGroup) => new SelectOption(`${agencyGroup.princetonCode} - ${agencyGroup.status}`, agencyGroup.id)
      )
    )
    const agentId = ref<number>(store.getters[UserSessionGetters.AgentId])
    const contactListCurrentItemsPerPage = ref<number>(50)
    const contactListCurrentSortMethod = ref<number>(1)
    const contactListCurrentTab = ref<number>(2)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EventBus.on(Events.updateContactListGridInformation, (info: any) => {
      contactListCurrentItemsPerPage.value = info.itemsPerPage
      contactListCurrentSortMethod.value = info.sortMethod
      contactListCurrentTab.value = info.contactType
    })

    const changeAgencyCode = (selectedOption: HTMLInputElement) => {
      const agentInfo = {
        agentId: Number(selectedOption.value),
        agentGroup: store.getters[UserSessionGetters.AgentGroup],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        princetonAgentCode: selectedOption.innerText
          .split('-')
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .find((_) => true)
          ?.trim(),
      }

      store.dispatch(AgencyActions.GetAgencyById, { agentId: agentInfo.agentId })
      Register(
        store.dispatch(ContactActions.GetContactsById, {
          agentId: agentInfo.agentId,
          itemsPerPage: contactListCurrentItemsPerPage.value,
          sortMethod: contactListCurrentSortMethod.value,
          contactType: contactListCurrentTab.value,
        })
      )

      store.dispatch(AgencyCodeActions.GetAgencyGroup)

      store.dispatch(AgencyContactPreferencesEmailsActions.GetAgencyContactPreferencesEmailsById, {
        agentId: agentInfo.agentId,
      })
      store.dispatch(UserSessionActions.UpdateUserInformationFromAgencyCodeDropdown, agentInfo)
      store.dispatch(AlertBannerActions.GetAdminAlertsByPrincetonCode, { princetonCode: agentInfo.princetonAgentCode })
    }

    return {
      codeOptions,
      agentId,
      changeAgencyCode,
    }
  },
})
</script>
