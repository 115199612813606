var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-0 black--text dataTable",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":_vm.itemKey,"items-per-page":5,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-skip-previous',
    lastIcon: 'mdi-skip-next',
    prevIcon: 'mdi-menu-left',
    nextIcon: 'mdi-menu-right',
    'items-per-page-text': 'Rows',
    'items-per-page-options': [5, 10, 25, 50, -1],
  },"show-select":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:`header.data-table-select`,fn:function({ props, on }){return [_c('v-theme-provider',{attrs:{"dark":""}},[(props.indeterminate)?_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"py-1",attrs:{"value":props.indeterminate}},'v-simple-checkbox',props,false),on)):_vm._e(),(!props.indeterminate)?_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"py-1"},'v-simple-checkbox',props,false),on)):_vm._e()],1)]}},{key:`item.data-table-select`,fn:function({ item, isSelected, select }){return [_c('v-simple-checkbox',{staticClass:"py-1",attrs:{"color":"primary","value":_vm.shouldBeChecked(item, isSelected),"disabled":_vm.shouldDisable(item)},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }