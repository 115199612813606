import useApis from '@/api'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { AgencyContactPreferencesEmailsMutations } from './mutations'
import {
  AgencyContactEmailListInformation,
  AgencyContactPreferencesEmails,
  AgencyEmailNotificationInformationRequestInterface,
  Email,
  EmailNotificationsInterface,
  NewContactEmailPreferencesInformation,
} from './types'
export class AgencyContactPreferencesEmailsActions {
  /**
   * gets the agency contact preferences emails using the agent id in session
   */
  public static GetAgencyContactPreferencesEmailsFromSession =
    'emailNotifications/getAgencyContactPreferencesEmailsFromSession'

  /**
   * gets the agency contact preferences emails when agency code is changed
   */
  public static GetAgencyContactPreferencesEmailsById = 'emailNotifications/getAgencyContactPreferencesEmailsById'

  /**
   * gets the list of available contact emails using the agent id passed in
   */
  public static GetEmailListFromSession = 'emailNotifications/getEmailListFromSession'
  /**
   * filters the agency contact preferences emails using the agent id and email address value passed in
   */
  public static FilterEmailListFromSession = 'emailNotifications/filterEmailListFromSession'
  /**
   * inserts a single agency contact preferences email
   */
  public static InsertEmailListFromSession = 'emailNotifications/insertEmailListFromSession'
  /**
   * clears the agency contact preferences emails
   */
  public static ClearEmailListFromSession = 'emailNotifications/clearEmailListFromSession'
  /**
   * updates the agency contact preferences emails
   */
  public static UpdateEmailNotifications = 'emailNotifications/updateEmailNotifications'
  /**
   * adds the agency contact preferences emails
   */
  public static AddEmailNotifications = 'emailNotifications/addEmailNotifications'
}

const { standard: api } = useApis()

export const actions: ActionTree<EmailNotificationsInterface, RootState> = {
  async getAgencyContactPreferencesEmailsFromSession({ commit }) {
    try {
      const agentId = store.getters[UserSessionGetters.AgentId]

      const response = await api.get(`agent_portal/producers/contacts/agent/${agentId}/preferences_emails`)
      const preferencesEmails: Array<AgencyContactPreferencesEmails> =
        response.data.value?.agencyContactPreferencesEmails.map(
          (preference: AgencyContactPreferencesEmails) => new AgencyContactPreferencesEmails(preference)
        ) ?? []

      commit(AgencyContactPreferencesEmailsMutations.SET_PREFERENCESEMAILS, preferencesEmails)
    } catch (reason) {
      console.error('emailNotifications :: getAgencyContactPreferencesEmailsFromSession :: failed:', reason)
      return false
    }
  },
  async getAgencyContactPreferencesEmailsById({ commit }, payload: { agentId: number }) {
    try {
      const response = await api.get(`agent_portal/producers/contacts/agent/${payload.agentId}/preferences_emails`)
      const preferencesEmails: Array<AgencyContactPreferencesEmails> =
        response.data.value.agencyContactPreferencesEmails.map(
          (preference: AgencyContactPreferencesEmails) => new AgencyContactPreferencesEmails(preference)
        )

      commit(AgencyContactPreferencesEmailsMutations.SET_PREFERENCESEMAILS, preferencesEmails)
    } catch (reason) {
      console.error('emailNotifications :: getAgencyContactPreferencesEmailsById :: failed:', reason)
      return null
    }
  },
  async getEmailListFromSession({ commit }, payload: { pagination: number }) {
    try {
      const agentId = store.getters[UserSessionGetters.AgentId]
      const paginationForCallout = payload.pagination - 1

      const response = await api.get(`agent_portal/producers/contacts/email_search/
          ${agentId}
          ?itemsPerPage=10
          &pagination=${paginationForCallout}`)

      const totalNumberOfEmailsFromResponse = response.data.value.totalNumberOfEmails
      const emailListFromResponse: Array<Email> = response.data.value.agencyContactEmails
        ? response.data.value.agencyContactEmails.map((email: Email) => {
            return new Email(email)
          })
        : [] // There could be an empty list from the successful response

      const agencyContactEmailListResult: AgencyContactEmailListInformation = {
        listOfAgencyContactEmails: emailListFromResponse,
        totalNumberOfAgencyContactEmails: totalNumberOfEmailsFromResponse,
      }

      commit(AgencyContactPreferencesEmailsMutations.SET_EMAILLIST, agencyContactEmailListResult)
    } catch (reason) {
      console.error('emailNotifications :: getEmailListFromSession :: failed:', reason)
      return false
    }
  },
  async updateEmailNotifications(_context, emailNotifications: AgencyEmailNotificationInformationRequestInterface) {
    try {
      const response = await api.put('agent_portal/producers/email_notifications', emailNotifications)
      return response.data.isSuccessful
    } catch (reason) {
      console.error('emailNotifications :: updateEmailNotifications :: failed:', reason)
      return null
    }
  },
  async addEmailNotifications(_context, newEmailNotificationRequest: NewContactEmailPreferencesInformation) {
    try {
      newEmailNotificationRequest.agentId = store.getters[UserSessionGetters.AgentId]

      const updateResponse = await api.post('agent_portal/producers/email_notifications', newEmailNotificationRequest)
      return updateResponse.data.isSuccessful
    } catch (reason) {
      console.error('emailNotifications :: addEmailNotifications :: failed:', reason)
      return null
    }
  },
  async insertEmailListFromSession({ commit }, existingEmails: Array<Email>) {
    try {
      const agencyContactEmailListResult: AgencyContactEmailListInformation = {
        listOfAgencyContactEmails: existingEmails,
        totalNumberOfAgencyContactEmails: NaN,
      }

      commit(AgencyContactPreferencesEmailsMutations.SET_EMAILLIST, agencyContactEmailListResult)
    } catch (reason) {
      console.error('emailNotifications :: insertEmailListFromSession :: failed:', reason)
      return false
    }
  },
  async clearEmailListFromSession({ commit }) {
    try {
      commit(AgencyContactPreferencesEmailsMutations.CLEAR_EMAILLIST)
    } catch (reason) {
      console.error('emailNotifications :: clearEmailListFromSession :: failed:', reason)
      return false
    }
  },
}
