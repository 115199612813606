<template>
  <div>
    <div class="pa-8 container">
      <alert-messages />
      <p class="edit-screen-message pt-2">Select an email address from the drop-down to edit information.</p>
      <p class="edit-screen-message pb-6">Or, to add an email, click the "Add" button.</p>
      <h2>Email Alerts/Notification Settings</h2>

      <div class="actions">
        <simple-button color="primary" @click="saveContactPreferencesEmail" class="mx-2">Save</simple-button>
        <modal-cancel />
        <app-modal
          name="Add"
          activatorButtonColor="tertiary"
          title="Add Email"
          :deactivateOnReject="false"
          :maxWidth="modalMaxWidth"
          confirmButtonLabel="Add"
          rejectButtonLabel="Cancel"
          @clearAlertMessages="ClearAlertMessages"
          @confirm="addEmailNotification"
        >
          <add-email-section
            title="Add Email"
            :message="modalAddMessage"
            :checkBoxLabels="modalAddCheckboxLabels"
            @input="newEmail"
          />
        </app-modal>
      </div>

      <v-row
        no-gutters
        class="py-2"
        v-for="(emailNotification, i) in agencyContactPreferencesEmails"
        :key="emailNotification.category"
      >
        <app-email-notification-dropdowns
          :emailListResult="emailListResult"
          :totalNumberOfEmails="totalNumberOfEmails"
          v-model="agencyContactPreferencesEmails[i]"
        />
      </v-row>

      <div class="actions pt-4">
        <simple-button color="primary" @click="saveContactPreferencesEmail" class="mx-2">Save</simple-button>
        <modal-cancel />
        <app-modal
          name="Add"
          activatorButtonColor="tertiary"
          title="Add Email"
          :deactivateOnReject="false"
          :maxWidth="modalMaxWidth"
          confirmButtonLabel="Add"
          rejectButtonLabel="Cancel"
          @clearAlertMessages="ClearAlertMessages"
          @confirm="addEmailNotification"
        >
          <add-email-section :message="modalAddMessage" :checkBoxLabels="modalAddCheckboxLabels" @input="newEmail" />
        </app-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import store from '@/store'

import SimpleButton from '@/components/common/input/SimpleButton.vue'
import AppEmailNotificationDropdowns from '@/components/common/input/AppEmailNotificationDropdowns.vue'
import AppModal from '@/components/common/modals/AppModal.vue'
import AddEmail from '@/components/agency-self-service/modals/ModalAddEmailSection.vue'
import ModalCancel from '@/components/common/modals/ModalCancel.vue'

import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import {
  AgencyContactPreferencesEmails,
  AgencyEmailNotificationContactIDs,
  AgencyEmailNotificationInformationRequestInterface,
  Email,
  NewContactEmailPreferencesInformation,
  NewContactEmailPreferencesInformationInterface,
} from '@/store/modules/agency-contact-preferences-emails/types'
import { AgencyContactPreferencesEmailsActions } from '@/store/modules/agency-contact-preferences-emails/actions'
import { AgencyContactPreferencesEmailsGetters } from '@/store/modules/agency-contact-preferences-emails/getters'
import { UserSessionGetters } from '@/store/modules/user-session/getters'

import Register from '@/utils/ProcessingMaskHelpers'
import ClearAlertMessages from '@/utils/ClearAlertMessages'
import ReturnToHomePage from '@/utils/ReturnToHomePage'
import enumerations from '@/constants/enumerations'
import verbiage from '@/constants/verbiage'

export default defineComponent({
  name: 'EditEmailNotifications',
  components: {
    AlertMessages,
    AppModal,
    'add-email-section': AddEmail,
    ModalCancel,
    SimpleButton,
    AppEmailNotificationDropdowns,
  },
  setup() {
    store.dispatch(AlertMessagesActions.ClearMessages)

    const emailAsNoneOption: Array<Email> = [
      new Email({
        contactId: 0,
        contactEmail: 'None',
        contactName: '',
      }),
    ]

    store.dispatch(AgencyContactPreferencesEmailsActions.InsertEmailListFromSession, emailAsNoneOption)

    const page = ref<number>(1)
    Register(store.dispatch(AgencyContactPreferencesEmailsActions.GetEmailListFromSession, { pagination: page.value }))

    const agencyContactPreferencesEmails = computed<Array<AgencyContactPreferencesEmails>>(
      () => store.getters[AgencyContactPreferencesEmailsGetters.EmailNotifications].agencyContactPreferencesEmails
    )

    // Populate the existing email information to the store in order for them to be shown in the dropdowns
    agencyContactPreferencesEmails.value.forEach((emailNotification: AgencyContactPreferencesEmails) => {
      const existingPrimaryAndSecondaryEmails: Array<Email> = [
        emailNotification.primary,
        emailNotification.secondary,
      ].filter((email: Email) => email.contactEmail.length !== 0)

      if (existingPrimaryAndSecondaryEmails.length > 0) {
        store.dispatch(
          AgencyContactPreferencesEmailsActions.InsertEmailListFromSession,
          existingPrimaryAndSecondaryEmails
        )
      }
    })

    const emailListResult = computed<Array<Email>>(
      () =>
        store.getters[AgencyContactPreferencesEmailsGetters.EmailNotifications].agencyContactEmailListInformation
          .listOfAgencyContactEmails
    )

    const totalNumberOfEmails = computed<number>(
      () =>
        store.getters[AgencyContactPreferencesEmailsGetters.EmailNotifications].agencyContactEmailListInformation
          .totalNumberOfAgencyContactEmails
    )

    const loadMoreEmails = computed<boolean>(() => emailListResult.value.length < totalNumberOfEmails.value)

    async function saveContactPreferencesEmail() {
      ClearAlertMessages()

      const currentAgentId: number = store.getters[UserSessionGetters.AgentId]

      const selectedContactIDs: Array<AgencyEmailNotificationContactIDs> = agencyContactPreferencesEmails.value.map(
        (email) => {
          return new AgencyEmailNotificationContactIDs({
            category: email.category,
            primaryContactId: email.primary.contactId,
            secondaryContactId: email.secondary.contactId,
          })
        }
      )

      const emailNotificationRequest: AgencyEmailNotificationInformationRequestInterface = {
        agentId: currentAgentId,
        agencyEmailNotificationContactIDs: selectedContactIDs,
      }

      await Register(
        store.dispatch(AgencyContactPreferencesEmailsActions.UpdateEmailNotifications, emailNotificationRequest)
      ).then((response) => {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById('app')?.scrollIntoView()
        if (response) ReturnToHomePage()
      })
    }

    const modalMaxWidth = enumerations.modalWidths.Medium

    const modalAddMessage: string = verbiage.addEmailNotification

    const modalAddCheckboxLabels: Array<string> = [
      enumerations.emailNotificationCategory.PolicyActivity,
      enumerations.emailNotificationCategory.EligibleToReinstate,
      enumerations.emailNotificationCategory.Claims,
      enumerations.emailNotificationCategory.NatGenPremierRenewalReport,
    ]

    const newEmailInformation = ref<NewContactEmailPreferencesInformationInterface>({
      email: '',
      confirmEmail: '',
      preferences: [],
    })

    function newEmail(currentValue: NewContactEmailPreferencesInformationInterface) {
      newEmailInformation.value.email = currentValue.email
      newEmailInformation.value.confirmEmail = currentValue.confirmEmail
      newEmailInformation.value.preferences = currentValue.preferences
    }

    async function addEmailNotification() {
      ClearAlertMessages()
      const newEmailNotificationInformationRequest: NewContactEmailPreferencesInformation = JSON.parse(
        JSON.stringify(newEmailInformation.value)
      )

      await Register(
        store.dispatch(
          AgencyContactPreferencesEmailsActions.AddEmailNotifications,
          newEmailNotificationInformationRequest
        )
      ).then((response) => {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById('app')?.scrollIntoView()
        if (response) ReturnToHomePage()
      })
    }

    return {
      addEmailNotification,
      newEmail,
      emailListResult,
      agencyContactPreferencesEmails,
      ClearAlertMessages,
      loadMoreEmails,
      modalAddCheckboxLabels,
      modalAddMessage,
      modalMaxWidth,
      saveContactPreferencesEmail,
      totalNumberOfEmails,
    }
  },
})
</script>

<style lang="scss" scoped>
.container:deep() {
  @import '@/styles/_inputField.scss';
}
</style>
