<template>
  <v-row v-if="!hidden" no-gutters class="py-1">
    <v-col v-if="label" :cols="hasTooltip ? 3 : 4">
      <div class="legacy-label" :class="{ required: isRequired, bold: isBold }">{{ label }}</div>
    </v-col>
    <v-col v-if="hasTooltip" cols="1" class="py-2">
      <app-tooltip>
        <slot name="tooltip"></slot>
      </app-tooltip>
    </v-col>
    <v-col :cols="label ? 5 : 7">
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        :disabled="disabled"
        transition="scale-transition"
        offset-x
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on">
            <v-text-field
              v-model="textModel"
              :label="textModel === '' ? DefaultDateFormat : ''"
              @keyup="textFieldKeyUpHandler($event.target.value)"
              @keydown="IntegersAndForwardSlashOnly"
              :maxlength="10"
              :disabled="disabled"
              :filled="disabled"
              v-bind="attrs"
              v-on="on"
              class="rounded-0"
              dense
              hide-details
              outlined
            >
              <v-icon slot="append" v-on="on">mdi-calendar-today</v-icon>
            </v-text-field>
          </div>
        </template>
        <v-date-picker
          v-model="pickerModel"
          @input="pickerInputHandler"
          :max="maxDate"
          :min="minDate"
          :disabled="disabled"
          reactive
          scrollable
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col v-if="hasRightColumn" :cols="3" class="px-5">
      <div>
        <slot name="rightColumn"></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { DefaultDateFormat } from '@/constants/Strings'
import { autoGenerateForwardSlash, FormatDateForDatePicker } from '@/utils/Formatter'
import { IntegersAndForwardSlashOnly } from '@/utils/InputFilters'
import { computed, defineComponent, ref } from 'vue'
import SimpleTooltip from './SimpleTooltip.vue'

export default defineComponent({
  name: 'AppDateField',
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hidden: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    value: {
      required: false,
      type: String,
      default: '',
    },
    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
    isBold: {
      required: false,
      type: Boolean,
      default: false,
    },
    showColumn: {
      required: false,
      type: Boolean,
      default: false,
    },
    originalDate: {
      required: false,
      type: String,
      default: '',
    },
    min: {
      required: false,
      type: String,
      default: '',
    },
    max: {
      required: false,
      type: String,
      default: '',
    },
  },
  components: {
    'app-tooltip': SimpleTooltip,
  },
  setup(props, context) {
    // Reads the text contents of what's being passed into the tooltips slot to determine if the tooltip should render.
    const hasTooltip = computed(() => context.slots.tooltip && context.slots.tooltip()[0]?.text?.trim())
    const hasRightColumn = computed(() => context.slots.rightColumn && props.showColumn)
    const maxDate = computed(() => { return props.max })
    const minDate = computed(() => { return props.min })

    const originalDate = props.originalDate.substring(0, 10)

    const model = computed<string>({
      get: () => originalDate,
      set: (newValue) => context.emit('set', newValue),
    })

    /** Create separate models for the v-date-picker and text box */
    const pickerModel = ref(originalDate !== '' ? originalDate : props.max)
    const textModel = ref<string>(FormatDateForDatePicker(originalDate))

    const attrs = computed(() => context.attrs)
    const listeners = computed(() => context.listeners)

    function textFieldKeyUpHandler(textFieldInput: string) {
      const digitsOnly = textFieldInput.replaceAll('/', '')

      // Only add slashes and update the text box when there are eight digits
      if (digitsOnly.length === 8) {
        const dateWithSlashes = autoGenerateForwardSlash(digitsOnly)

        textModel.value = dateWithSlashes

        // only update the time picker and model when the input date is valid: e.g. 04/31/2021 or 99/99/9999
        if (FormatDateForDatePicker(new Date(dateWithSlashes)) === dateWithSlashes) {
          model.value = dateWithSlashes

          const dateInput = dateWithSlashes.split('/')

          // Date picker only takes YYYY-MM-DD format
          if (dateInput.length === 3) {
            const formattedDateString = dateInput[2] + '-' + dateInput[0] + '-' + dateInput[1]
            pickerModel.value = formattedDateString
          }
        }

        // send empty value to validator when date is invalid
      } else {
        model.value = ''
        pickerModel.value = ''
      }
    }

    function pickerInputHandler(pickerSelection: string) {
      textModel.value = FormatDateForDatePicker(pickerSelection)
      model.value = FormatDateForDatePicker(pickerSelection)
    }

    return {
      attrs,
      DefaultDateFormat,
      hasRightColumn,
      hasTooltip,
      IntegersAndForwardSlashOnly,
      listeners,
      maxDate,
      minDate,
      model,
      textFieldKeyUpHandler,
      pickerInputHandler,
      pickerModel,
      textModel,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep() {
  @import '@/styles/_inputField.scss';
}
</style>
