import { MutationTree } from 'vuex'
import { BusinessSearchInterface } from './types'

export class BusinessSearchMutations {
  public static SET_MCFH_READ_ONLY = 'SET_MCFH_READ_ONLY'
  public static SET_SHOW_CANCEL_SAVE = 'SET_SHOW_CANCEL_SAVE'
  public static SET_READ_ONLY = 'SET_READ_ONLY'
  public static SET_SHOW_HELP = 'SET_SHOW_HELP'
}

export const mutations: MutationTree<BusinessSearchInterface> = {
  SET_MCFH_READ_ONLY(state: BusinessSearchInterface, value: boolean) {
    state.mcfhReadOnly = value
  },
  SET_SHOW_CANCEL_SAVE(state: BusinessSearchInterface, value: boolean) {
    state.showCancelSave = value
  },
  SET_READ_ONLY(state: BusinessSearchInterface, value: boolean) {
    state.readOnly = value
  },
  SET_SHOW_HELP(state: BusinessSearchInterface, value: boolean) {
    state.showHelp = value
  },
}

export default mutations
