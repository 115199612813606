import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { OccupationFilterInterface } from './types'

export class OccupationFilterGetters {
  public static DisplaySubCategories = 'occupationFilter/displaySubCategories'
  public static Occupations = 'occupationFilter/filteredOccupationList'
  public static SearchString = 'occupationFilter/searchString'
  public static SelectedCategories = 'occupationFilter/selectedOccupationCategories'
  public static SelectedSubCategories = 'occupationFilter/selectedSubCategories'
}

export const getters: GetterTree<OccupationFilterInterface, RootState> = {
  filteredOccupationList: (state: OccupationFilterInterface) => state.filteredOccupationList ?? [],
  searchString: (state: OccupationFilterInterface) => state.searchString,
  selectedOccupationCategories: (state: OccupationFilterInterface) => state.selectedOccupationCategories ?? [],
  selectedSubCategories: (state: OccupationFilterInterface) => state.selectedSubCategories ?? [],
}

export default getters
