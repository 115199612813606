<template>
  <v-container>
    <v-card class="ma-3 px-3 pb-2">
      <v-card-title> Client Information </v-card-title>
      <cv-client-entry v-if="isCV" />
      <base-client-entry v-else />
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import enums from '@/constants/enumerations'
import store from '@/store'
import { ClientGetters } from '@/store/modules/client/getters'

import BaseClientEntry from '@/components/client/BaseClientEntry.vue'
import CVClientEntry from '@/components/client/CVClientEntry.vue'

export default defineComponent({
  components: {
    'base-client-entry': BaseClientEntry,
    'cv-client-entry': CVClientEntry,
  },
  setup() {
    const isCV = computed<boolean>(() => {
      return store.getters[ClientGetters.Product] === enums.product.CV
    })
    return {
      isCV,
    }
  },
})
</script>
