import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ConfigInformationInterface } from './types'

export class ConfigGetters {
  public static Configs = 'configs/configInformation'
}

export const getters: GetterTree<ConfigInformationInterface, RootState> = {
  configInformation: (state: ConfigInformationInterface) => state.configInformation
}

export default getters
