import { Phone } from "@/models/phone-number"
import { ProviderProfile} from "@/models/provider-profile"

export interface AddressInterface {
  street: string
  city: string
  state: string
  zip: string
}

export class Address implements AddressInterface {
  street: string
  city: string
  state: string
  zip: string

  constructor(options?: {
    street: string
    city: string
    state: string
    zip: string
  }) {
    this.street = options?.street ?? ''
    this.city = options?.city ?? ''
    this.state = options?.state ?? ''
    this.zip = options?.zip ?? ''
  }
}

export interface DayInterface {
  openTime: string
  closeTime: string
}

export class Day implements DayInterface {
  openTime: string
  closeTime: string

  constructor(options?: {
    openTime: string
    closeTime: string
  }) {
    if ((options?.openTime === '0001-01-01T00:00:00+00:00' && options?.closeTime === '0001-01-01T00:00:00+00:00')
      || (options?.openTime === '0001-01-01T00:00:00-05:00' && options?.closeTime === '0001-01-01T00:00:00-05:00')) {
      options.openTime = ''
      options.closeTime = ''
    }
    this.openTime = options?.openTime ?? ''
    this.closeTime = options?.closeTime ?? ''
  }
}

export interface HoursOfOperationInterface {
  monday: DayInterface
}

export class HoursOfOperation implements HoursOfOperationInterface {
  monday: Day

  constructor(options?: {
    monday: Day
  }) {
    this.monday = new Day(options?.monday ?? {
      openTime: '',
      closeTime: ''
    })
  }
}

export interface DayRequestInterface {
  openTime: string
  closeTime: string
}

export class DayRequest implements DayRequestInterface {
  openTime: string
  closeTime: string

  constructor(
    open: { [key: string]: string },
    close: { [key: string]: string },
  ) {
    const openHours =
      open.A === 'PM' && open.hh !== '12'
        ? Number(open.hh) + 12
        : open.hh === '12' && open.A !== 'PM'
          ? 24
          : Number(open.hh)
    const closeHours =
      close.A === 'PM' && close.hh !== '12'
        ? Number(close.hh) + 12
        : close.hh === '12' && close.A !== 'PM'
          ? 24
          : Number(close.hh)

    const isEmptyOpenSelection = Number.isNaN(openHours) || (open.hh === '' && open.mm === '' && open.A === '')
    const isIncompleteOpenSelection = open.hh === '' || open.mm === '' || open.A === '' || openHours === 0
    const isEmptyCloseSelection = Number.isNaN(closeHours) || (close.hh === '' && close.mm === '' && close.A === '')
    const isIncompleteCloseSelection = close.hh === '' || close.mm === '' || close.A === '' || closeHours === 0

    const hoursSelected = new HoursOfOperation({
      monday: new Day({
        openTime:
        isEmptyOpenSelection
          ? new Date(0, 0, 0, 0, 0, 0).toISOString()
          : isIncompleteOpenSelection
            ? new Date(1800, 0, 0, 0, 0, 0).toISOString()
            : new Date(1900, 1, 1, openHours, Number(open.mm), 0).toISOString(),
        closeTime:
        isEmptyCloseSelection
          ? new Date(0, 0, 0, 0, 0, 0).toISOString()
          : isIncompleteCloseSelection
            ? new Date(1800, 0, 0, 0, 0, 0).toISOString()
            : new Date(1900, 1, 2, closeHours, Number(close.mm), 0).toISOString(),
      }),
    })

    this.openTime = hoursSelected.monday.openTime
    this.closeTime = hoursSelected.monday.closeTime
  }
}

export interface HoursOfOperationRequestInterface {
  monday: DayRequestInterface
}

export class HoursOfOperationRequest implements HoursOfOperationRequestInterface {
  monday: DayRequest

  constructor(monday: DayRequest) {
    this.monday = monday
  }
}

export enum MigrationStatus {
  NotMigrated = "NotMigrated",
  AllowNISMigration = "AllowNISMigration",
  PartiallyMigrated = "PartiallyMigrated",
  Migrated = "Migrated"
}

export interface AgencyInterface {
  id: number
  name: string
  akaName: string
  mailingAddress: AddressInterface
  streetAddress: AddressInterface
  princetonAgtCode: string
  phone: Phone
  fax: Phone
  email: string
  website: string
  hours: HoursOfOperationInterface
  status: string
  indicator: string
  migrationStatus: MigrationStatus
  singleSignOnProviderInformation: Array<ProviderProfile>
  isSingleSignOn: boolean
}

export interface AgencyInformationRequest {
  id: number
  name: string
  akaName: string
  mailingAddress: AddressInterface
  streetAddress: AddressInterface
  princetonAgtCode: string
  phone: string
  fax: string
  email: string
  website: string
  hours: HoursOfOperationRequestInterface
  status: string
}

export class Agency implements AgencyInterface {
  id: number
  name: string
  akaName: string
  mailingAddress: AddressInterface
  streetAddress: AddressInterface
  princetonAgtCode: string
  phone: Phone
  fax: Phone
  email: string
  website: string
  hours: HoursOfOperationInterface
  status: string
  indicator: string
  migrationStatus: MigrationStatus
  singleSignOnProviderInformation: Array<ProviderProfile>
  isSingleSignOn: boolean

  constructor(options?: {
    id: number
    name: string
    akaName: string
    mailingAddress: AddressInterface
    streetAddress: AddressInterface
    phone: string
    fax: string
    email: string
    website: string
    hours: HoursOfOperationInterface
    princetonAgtCode: string
    status: string
    indicator: string
    migrationStatus: MigrationStatus
    singleSignOnProviderInformation: Array<ProviderProfile>
    isSingleSignOn: boolean
  }) {
    this.id = options?.id ?? 0
    this.name = options?.name ?? ''
    this.akaName = options?.akaName ?? ''
    this.mailingAddress = new Address({
      street: options?.mailingAddress.street ?? '',
      city: options?.mailingAddress.city ?? '',
      state: options?.mailingAddress.state ?? '',
      zip: options?.mailingAddress.zip ?? '',
    })
    this.streetAddress = new Address({
      street: options?.streetAddress.street ?? '',
      city: options?.streetAddress.city ?? '',
      state: options?.streetAddress.state ?? '',
      zip: options?.streetAddress.zip ?? '',
    })
    this.phone = new Phone(options?.phone ?? '')
    this.fax = new Phone(options?.fax ?? '')
    this.email = options?.email ?? ''
    this.website = options?.website ?? ''
    this.hours = new HoursOfOperation(options?.hours ?? { monday: new Day })
    this.princetonAgtCode = options?.princetonAgtCode ?? ''
    this.status = options?.status ?? ''
    this.indicator = options?.indicator ?? ''
    this.migrationStatus = options?.migrationStatus ?? MigrationStatus.NotMigrated
    this.singleSignOnProviderInformation = options?.singleSignOnProviderInformation ?? []
    this.isSingleSignOn = false
  }
}