import { PhoneNumber } from '@/models/phone-number'
import { Address as AgencyAddress, AddressInterface as AgencyAddressInterface } from '../agency/types'

export interface AddressInterface {
  addressType: string
  city: string
  country: string
  secondaryAddress: string
  state: string
  streetAddress: string
  zipCode: string
  zipPlusFour: string
}

export class Address implements AddressInterface {
  addressType: string
  city: string
  country: string
  secondaryAddress: string
  state: string
  streetAddress: string
  zipCode: string
  zipPlusFour: string

  constructor(options?: {
    addressType: string
    city: string
    country: string
    secondaryAddress: string
    state: string
    streetAddress: string
    zipCode: string
    zipPlusFour: string
  }) {
    this.addressType = options?.addressType ?? ''
    this.city = options?.city ?? ''
    this.country = options?.country ?? ''
    this.secondaryAddress = options?.secondaryAddress ?? ''
    this.state = options?.state ?? ''
    this.streetAddress = options?.streetAddress ?? ''
    this.zipCode = options?.zipCode ?? ''
    this.zipPlusFour = options?.zipPlusFour ?? ''
  }
}

export interface PersonInterface {
  birthDate: string
  firstName: string
  gender: string | undefined
  lastName: string
  middleName: string | undefined
  socialSecurityLastFour: string | undefined
  socialSecurityToken: string | undefined
  suffix: string | undefined
}

export class Person implements PersonInterface {
  birthDate: string
  firstName: string
  gender: string | undefined
  lastName: string
  middleName: string | undefined
  socialSecurityLastFour: string | undefined
  socialSecurityToken: string | undefined
  suffix: string | undefined

  constructor(options?: {
    birthDate: string
    firstName: string
    gender: string | undefined
    lastName: string
    middleName: string | undefined
    socialSecurityLastFour: string | undefined
    socialSecurityToken: string | undefined
    suffix: string | undefined
  }) {
    this.birthDate = options?.birthDate ?? ''
    this.firstName = options?.firstName ?? ''
    this.gender = options?.gender
    this.lastName = options?.lastName ?? ''
    this.middleName = options?.middleName
    this.socialSecurityLastFour = options?.socialSecurityLastFour
    this.socialSecurityToken = options?.socialSecurityToken
    this.suffix = options?.suffix
  }
}

export interface BusinessInterface {
  businessName: string
}

export class Business implements BusinessInterface {
  businessName: string

  constructor(options?: { businessName: string }) {
    this.businessName = options?.businessName ?? ''
  }
}

export interface PhoneInterface {
  countryCode: string
  extension: string
  phoneNumber: string
  phoneType: string
  priority: number
  splitPhoneNumber: PhoneNumber
}

export class Phone implements PhoneInterface {
  countryCode: string
  extension: string
  phoneNumber: string
  phoneType: string
  priority: number
  splitPhoneNumber: PhoneNumber

  constructor(options?: {
    countryCode: string
    extension: string
    phoneNumber: string
    phoneType: string
    priority: number
    splitPhoneNumber: PhoneNumber
  }) {
    this.countryCode = options?.countryCode ?? ''
    this.extension = options?.extension ?? ''
    this.phoneNumber = options?.phoneNumber ?? ''
    this.phoneType = options?.phoneType ?? 'Cell' // Use default of 'Cell' from _refPhoneType
    this.priority = options?.priority ?? 0
    this.splitPhoneNumber = options?.splitPhoneNumber ?? new PhoneNumber('')
  }
}

export interface EmailInterface {
  emailAddress: string
  emailType: string
  priority: number
}

export class Email implements EmailInterface {
  emailAddress: string
  emailType: string
  priority: number

  constructor(options?: { emailAddress: string; emailType: string; priority: number }) {
    this.emailAddress = options?.emailAddress ?? ''
    this.emailType = options?.emailType ?? ''
    this.priority = options?.priority ?? 0
  }
}

export interface AgencyInterface {
  id: number
  name: string
  mailingAddress: AgencyAddressInterface
  streetAddress: AgencyAddressInterface
  princetonAgtCode: string
  phone: string
  fax: string
  email: string
  website: string
  indicator: string
}

export class Agency implements AgencyInterface {
  id: number
  name: string
  mailingAddress: AgencyAddressInterface
  streetAddress: AgencyAddressInterface
  princetonAgtCode: string
  phone: string
  fax: string
  email: string
  website: string
  indicator: string

  constructor(options?: {
    id: number
    name: string
    mailingAddress: AgencyAddressInterface
    streetAddress: AgencyAddressInterface
    phone: string
    fax: string
    email: string
    website: string
    princetonAgtCode: string
    indicator: string
  }) {
    this.id = options?.id ?? 0
    this.name = options?.name ?? ''
    this.mailingAddress = new AgencyAddress({
      street: options?.mailingAddress.street ?? '',
      city: options?.mailingAddress.city ?? '',
      state: options?.mailingAddress.state ?? '',
      zip: options?.mailingAddress.zip ?? '',
    })
    this.streetAddress = new AgencyAddress({
      street: options?.streetAddress.street ?? '',
      city: options?.streetAddress.city ?? '',
      state: options?.streetAddress.state ?? '',
      zip: options?.streetAddress.zip ?? '',
    })
    this.phone = options?.phone ?? ''
    this.fax = options?.fax ?? ''
    this.email = options?.email ?? ''
    this.website = options?.website ?? ''
    this.princetonAgtCode = options?.princetonAgtCode ?? ''
    this.indicator = options?.indicator ?? ''
  }
}

export interface PolicyInterface {
  agentId: number
  additionalProducts: Array<string>
  agencyResult: AgencyInterface
  canceledDate: string
  cost: number
  detailedPolicyStatus: string
  effectiveDate: string
  expirationDate: string
  govState: string
  nextAmountDue: number
  nextAmountDueDate: string
  paymentMethod: string
  policyId: number
  policyNumber: string
  policyStatus: string
  policySuffix: string
  policyTerm: string
  product: string
  state: string
  subProduct: string
  totalPremium: number
}

export class Policy implements PolicyInterface {
  agentId: number
  additionalProducts: Array<string>
  agencyResult: AgencyInterface
  canceledDate: string
  cost: number
  detailedPolicyStatus: string
  effectiveDate: string
  expirationDate: string
  govState: string
  nextAmountDue: number
  nextAmountDueDate: string
  paymentMethod: string
  policyId: number
  policyNumber: string
  policyStatus: string
  policySuffix: string
  policyTerm: string
  product: string
  state: string
  subProduct: string
  totalPremium: number

  constructor(options?: {
    agentId: number
    additionalProducts: Array<string>
    agencyResult: AgencyInterface
    canceledDate: string
    cost: number
    detailedPolicyStatus: string
    effectiveDate: string
    expirationDate: string
    govState: string
    nextAmountDue: number
    nextAmountDueDate: string
    paymentMethod: string
    policyId: number
    policyNumber: string
    policyStatus: string
    policySuffix: string
    policyTerm: string
    product: string
    state: string
    subProduct: string
    totalPremium: number
  }) {
    this.agentId = options?.agentId ?? 0
    this.additionalProducts = options?.additionalProducts ?? []
    this.agencyResult = new Agency({
      id: options?.agencyResult.id ?? 0,
      name: options?.agencyResult.name ?? '',
      mailingAddress: new AgencyAddress({
        street: options?.agencyResult.mailingAddress.street ?? '',
        city: options?.agencyResult.mailingAddress.city ?? '',
        state: options?.agencyResult.mailingAddress.state ?? '',
        zip: options?.agencyResult.mailingAddress.zip ?? '',
      }),
      streetAddress: new AgencyAddress({
        street: options?.agencyResult.streetAddress.street ?? '',
        city: options?.agencyResult.streetAddress.city ?? '',
        state: options?.agencyResult.streetAddress.state ?? '',
        zip: options?.agencyResult.streetAddress.zip ?? '',
      }),
      phone: options?.agencyResult.phone ?? '',
      fax: options?.agencyResult.phone ?? '',
      email: options?.agencyResult.email ?? '',
      website: options?.agencyResult.website ?? '',
      princetonAgtCode: options?.agencyResult.princetonAgtCode ?? '',
      indicator: options?.agencyResult.indicator ?? '',
    })
    this.canceledDate = options?.canceledDate ?? ''
    this.cost = options?.cost ?? 0
    this.detailedPolicyStatus = options?.detailedPolicyStatus ?? ''
    this.effectiveDate = options?.effectiveDate ?? ''
    this.expirationDate = options?.expirationDate ?? ''
    this.govState = options?.govState ?? ''
    this.nextAmountDue = options?.nextAmountDue ?? 0
    this.nextAmountDueDate = options?.nextAmountDueDate ?? ''
    this.paymentMethod = options?.paymentMethod ?? ''
    this.policyId = options?.policyId ?? 0
    this.policyNumber = options?.policyNumber ?? ''
    this.policyStatus = options?.policyStatus ?? ''
    this.policySuffix = options?.policySuffix ?? ''
    this.policyTerm = options?.policyTerm ?? ''
    this.product = options?.product ?? ''
    this.state = options?.state ?? ''
    this.subProduct = options?.subProduct ?? ''
    this.totalPremium = options?.totalPremium ?? 0
  }
}

export interface QuoteInterface {
  agentId: number
  agencyResult: AgencyInterface
  createdDate: string
  effectiveDate: string
  isClaimsHistoryOrdered: boolean
  isMotorVehicleRecordOrdered: boolean
  product: string
  quoteId: string
  source: string
  subproduct: string
  totalPremium: number
  govState: string
  subProductDisplayName: string
}

export class Quote implements QuoteInterface {
  agentId: number
  agencyResult: AgencyInterface
  createdDate: string
  effectiveDate: string
  isClaimsHistoryOrdered: boolean
  isMotorVehicleRecordOrdered: boolean
  product: string
  quoteId: string
  source: string
  subproduct: string
  totalPremium: number
  govState: string
  subProductDisplayName: string

  constructor(options?: {
    agentId: number
    agencyResult: AgencyInterface
    createdDate: string
    effectiveDate: string
    isClaimsHistoryOrdered: boolean
    isMotorVehicleRecordOrdered: boolean
    product: string
    quoteId: string
    source: string
    subproduct: string
    totalPremium: number
    govState: string
    subProductDisplayName: string
  }) {
    this.agentId = options?.agentId ?? 0
    this.agencyResult = new Agency({
      id: options?.agencyResult.id ?? 0,
      name: options?.agencyResult.name ?? '',
      mailingAddress: new AgencyAddress({
        street: options?.agencyResult.mailingAddress.street ?? '',
        city: options?.agencyResult.mailingAddress.city ?? '',
        state: options?.agencyResult.mailingAddress.state ?? '',
        zip: options?.agencyResult.mailingAddress.zip ?? '',
      }),
      streetAddress: new AgencyAddress({
        street: options?.agencyResult.streetAddress.street ?? '',
        city: options?.agencyResult.streetAddress.city ?? '',
        state: options?.agencyResult.streetAddress.state ?? '',
        zip: options?.agencyResult.streetAddress.zip ?? '',
      }),
      phone: options?.agencyResult.phone ?? '',
      fax: options?.agencyResult.phone ?? '',
      email: options?.agencyResult.email ?? '',
      website: options?.agencyResult.website ?? '',
      princetonAgtCode: options?.agencyResult.princetonAgtCode ?? '',
      indicator: options?.agencyResult.indicator ?? '',
    })
    this.createdDate = options?.createdDate ?? ''
    this.effectiveDate = options?.effectiveDate ?? ''
    this.isClaimsHistoryOrdered = options?.isClaimsHistoryOrdered ?? false
    this.isMotorVehicleRecordOrdered = options?.isMotorVehicleRecordOrdered ?? false
    this.product = options?.product ?? ''
    this.quoteId = options?.quoteId ?? ''
    this.source = options?.source ?? ''
    this.subproduct = options?.subproduct ?? ''
    this.totalPremium = options?.totalPremium ?? 0
    this.govState = options?.govState ?? ''
    this.subProductDisplayName = options?.subProductDisplayName ?? ''
  }
}

export interface ClientDetailInterface {
  doingBusinessAs: string | undefined
  businessEntityType: string
  clientHasUsdotType: string | undefined
  isPossibleUsdot: boolean | undefined
  isUsdotVerified: boolean | undefined
  motorCarrierForHire: boolean | undefined
  occupation: string
  usdotNumber: number | undefined
}

export class ClientDetail implements ClientDetailInterface {
  doingBusinessAs: string | undefined
  businessEntityType: string
  clientHasUsdotType: string | undefined
  isPossibleUsdot: boolean | undefined
  isUsdotVerified: boolean | undefined
  motorCarrierForHire: boolean | undefined
  occupation: string
  usdotNumber: number | undefined

  constructor(options?: {
    doingBusinessAs: string | undefined
    businessEntityType: string
    clientHasUsdotType: string | undefined
    isPossibleUsdot: boolean | undefined
    isUsdotVerified: boolean | undefined
    motorCarrierForHire: boolean | undefined
    occupation: string
    usdotNumber: number | undefined
  }) {
    this.doingBusinessAs = options?.doingBusinessAs
    this.businessEntityType = options?.businessEntityType ?? ''
    this.clientHasUsdotType = options?.clientHasUsdotType ?? ''
    this.isPossibleUsdot = options?.isPossibleUsdot
    this.isUsdotVerified = options?.isUsdotVerified
    this.motorCarrierForHire = options?.motorCarrierForHire
    this.occupation = options?.occupation ?? ''
    this.usdotNumber = options?.usdotNumber
  }
}

export interface ClientInterface {
  accountHolderId: number
  agencyResult: AgencyInterface | undefined
  agentId: number
  addresses: Array<AddressInterface>
  business: BusinessInterface
  businessAddress: AddressInterface
  clientDetail: ClientDetailInterface
  clientMode: string
  emailAddresses: Array<EmailInterface>
  initialState: string
  isCrossSell: boolean
  isRewrite: boolean
  person: PersonInterface
  personalEmailAddress: EmailInterface
  phoneNumbers: Array<PhoneInterface>
  policies: Array<PolicyInterface>
  product: string
  quotes: Array<QuoteInterface>
  residentialAddress: AddressInterface
  subProduct: string
  workEmailAddress: EmailInterface
}

export interface ClientListInterface {
  clientList: Array<ClientInterface>
  clientMode: string
  disablePrefilledFields: boolean
  newClient: ClientInterface
  selectedBusinessName: string
}

export class Client implements ClientInterface {
  accountHolderId: number
  agencyResult: AgencyInterface | undefined
  agentId: number
  addresses: Array<AddressInterface>
  business: BusinessInterface
  businessAddress: AddressInterface
  clientDetail: ClientDetailInterface
  clientMode: string
  emailAddresses: Array<EmailInterface>
  initialState: string
  isCrossSell: boolean
  isRewrite: boolean
  person: PersonInterface
  personalEmailAddress: EmailInterface
  phoneNumbers: Array<PhoneInterface>
  policies: Array<PolicyInterface>
  product: string
  quotes: Array<QuoteInterface>
  residentialAddress: AddressInterface
  subProduct: string
  workEmailAddress: EmailInterface

  constructor(options?: {
    accountHolderId: number
    agencyResult: AgencyInterface | undefined
    agentId: number
    addresses: Array<AddressInterface>
    business: {
      businessName: string
    }
    businessAddress: AddressInterface
    clientDetail: ClientDetailInterface
    clientMode: string
    emailAddresses: Array<EmailInterface>
    initialState: string
    isRewrite: boolean
    isCrossSell: boolean
    person: PersonInterface
    personalEmailAddress: EmailInterface
    phoneNumbers: Array<PhoneInterface>
    policies: Array<PolicyInterface>
    product: string
    quotes: Array<QuoteInterface>
    residentialAddress: AddressInterface
    subProduct: string
    workEmailAddress: EmailInterface
  }) {
    this.accountHolderId = options?.accountHolderId ?? 0
    this.agencyResult = new Agency({
      id: options?.agencyResult?.id ?? 0,
      name: options?.agencyResult?.name ?? '',
      mailingAddress: new AgencyAddress({
        street: options?.agencyResult?.mailingAddress.street ?? '',
        city: options?.agencyResult?.mailingAddress.city ?? '',
        state: options?.agencyResult?.mailingAddress.state ?? '',
        zip: options?.agencyResult?.mailingAddress.zip ?? '',
      }),
      streetAddress: new AgencyAddress({
        street: options?.agencyResult?.streetAddress.street ?? '',
        city: options?.agencyResult?.streetAddress.city ?? '',
        state: options?.agencyResult?.streetAddress.state ?? '',
        zip: options?.agencyResult?.streetAddress.zip ?? '',
      }),
      phone: options?.agencyResult?.phone ?? '',
      fax: options?.agencyResult?.phone ?? '',
      email: options?.agencyResult?.email ?? '',
      website: options?.agencyResult?.website ?? '',
      princetonAgtCode: options?.agencyResult?.princetonAgtCode ?? '',
      indicator: options?.agencyResult?.indicator ?? '',
    })
    this.agentId = options?.agentId ?? 0
    this.addresses = options?.addresses.map((address: AddressInterface) => new Address(address)) ?? [new Address()]
    this.business = options?.business ?? new Business()
    this.businessAddress = options?.businessAddress ?? new Address()
    this.clientDetail = options?.clientDetail ?? new ClientDetail()
    this.clientMode = options?.clientMode ?? ''
    this.emailAddresses = options?.emailAddresses.map((email: EmailInterface) => new Email(email)) ?? [new Email()]
    this.initialState = options?.initialState ?? ''
    this.isCrossSell = options?.isCrossSell ?? false
    this.isRewrite = options?.isRewrite ?? false
    this.person = options?.person ?? new Person()
    this.personalEmailAddress = options?.personalEmailAddress ?? new Email()
    this.phoneNumbers = options?.phoneNumbers.map((phone: PhoneInterface) => new Phone(phone)) ?? [new Phone()]
    this.policies = options?.policies?.map((policy: PolicyInterface) => new Policy(policy)) ?? [new Policy()]
    this.product = options?.product ?? ''
    this.quotes = options?.quotes?.map((quote: QuoteInterface) => new Quote(quote)) ?? [new Quote()]
    this.residentialAddress = options?.residentialAddress ?? new Address()
    this.subProduct = options?.subProduct ?? ''
    this.workEmailAddress = options?.workEmailAddress ?? new Email()
  }
}

export interface BusinessNameInterface {
  selectedName: string
  systemMatchedName: string
  userEnteredName: string
}
