import store from '@/store'
import { OccupationSearchGetters } from '@/store/modules/occupation-search/getters'
import { Occupation, OccupationInterface, SubCategory } from '@/store/modules/occupation/types'
import { ref } from 'vue'

const services = 'Services'

/**
 *
 * @returns An array of subcategories
 */
export default function (): Array<SubCategory> {
  const allOccupations = ref<Array<OccupationInterface>>(store.getters[OccupationSearchGetters.OccupationResults] ?? [])

  const serviceOccupation =
    allOccupations.value.find((item) => {
      return item.category === services
    }) ?? new Occupation()

  return serviceOccupation.subCategoryList.map((subCategory) => {
    return new SubCategory({
      name: subCategory.name,
      checked: subCategory.checked,
    })
  })
}
