export class SubCategory {
  name: string
  checked: boolean

  constructor(options: { name: string; checked: boolean }) {
    this.name = options?.name
    this.checked = options?.checked
  }
}

export interface OccupationInterface {
  category: string // SICCategoryDesc or NAICCategoryDesc
  checked: boolean | undefined // Derived from whether category is selected in Section 2
  description: string // BusinessTypeDefinitionDesc
  naic: string // NAIC
  occupation: string // Occupation
  rnOnly: boolean // RN Only
  sic: string // SIC
  subCategoryList: Array<SubCategory>
  subCategory: SubCategory | null // BusinessType
  unacceptable: boolean // Unacceptable Occupation
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setNaic(options: any) {
  return options?.naic && options?.naic.includes('-1') ? 'Non-Business' : options?.naic ?? ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setSic(naic: string, options: any) {
  return naic === 'Non-Business' ? naic : options?.sic ?? ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setUnacceptable(options: any) {
  return options?.occupation.toLowerCase().includes('unacceptable') ?? false
}

export class Occupation {
  category: string
  checked: boolean | undefined
  description: string
  naic: string
  occupation: string
  rnOnly: boolean
  sic: string
  subCategoryList: Array<SubCategory>
  subCategory: SubCategory | null
  unacceptable: boolean

  constructor(options?: {
    category: string
    checked: boolean | undefined
    description: string
    naic: string
    occupation: string
    rnOnly: boolean | undefined
    sic: string
    subCategoryList: Array<SubCategory>
    subCategory: SubCategory | null
    unacceptable: boolean | undefined
  }) {
    const naic = setNaic(options)
    const sic = setSic(naic, options)
    const unacceptable = setUnacceptable(options)

    this.category = options?.category ?? ''
    this.checked = options?.checked ?? true
    this.description = options?.description ?? ''
    this.naic = naic
    this.occupation = options?.occupation ?? ''
    this.rnOnly = options?.rnOnly ?? false
    this.sic = sic
    this.subCategoryList = options?.subCategoryList ?? []
    this.subCategory = options?.subCategory ?? null
    this.unacceptable = unacceptable
  }
}

export const OccupationGridHeader = [
  { text: 'Business Type', value: 'occupation' },
  { text: 'Description', value: 'description' },
  { text: 'SIC', value: 'sic' },
  { text: 'NAICS', value: 'naic' },
]
