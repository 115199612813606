import useApis from '@/api'
import configKeys from '@/constants/configKeys'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { ConfigMutations } from './mutations'
import { ConfigInformationInterface, ConfigKeyValueInterface } from './types'

export class ConfigActions {
  /**
   * gets the config value
   */
  public static GetConfigValues = 'configs/getConfigValues'
}

const { standard: api } = useApis()

export const actions: ActionTree<ConfigInformationInterface, RootState> = {
  async getConfigValues({ commit }): Promise<boolean> {
    try {
      for (const key in configKeys) {
      const response = await api.get(`agent_portal/config/value?configKey=${key}`)

      if (response.data.value.configKeyValue !== '') {
        const configInformation: ConfigKeyValueInterface = {
          key,
          value: response.data.value.configKeyValue,
        }

        commit(ConfigMutations.SET_CONFIG, configInformation)
      }
    }
      return true
    } catch (reason) {
      console.error('agencyHome :: getConfig :: failed:', reason)
      return false
    }
  }
}
