<template>
  <div v-if="messages.length" class="mx-4 mt-4" style="white-space: break-spaces; word-break: break-word">
    <template v-for="(message, i) in messages">
      <v-alert :key="i" :type="message.type" dense outlined class="py-1 my-1" :class="getBackgroundColor(message.type)">
        <span v-html="message.text" />
      </v-alert>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import store from '@/store'
import { AlertMessageInterface, AlertMessageType } from '@/store/modules/alert-messages/types'
import { AlertMessagesGetters } from '@/store/modules/alert-messages/getters'

export default defineComponent({
  name: 'AlertMessages',
  props: {
    sources: {
      required: false,
      type: Array as PropType<Array<string>>,
      default: undefined,
    },
  },
  setup(props) {
    const getOrder = (message: AlertMessageInterface): number => {
      let order = 0
      // First, group/order by Type
      switch (message.type) {
        // If type = Error, then order = 0
        case AlertMessageType.Warning:
          order += 10
          break
        case AlertMessageType.Info:
          order += 20
          break
        case AlertMessageType.Success:
          order += 30
          break
      }

      // Next, group/order by Source
      switch (message.source.toLowerCase()) {
        case 'validation':
          order += 1
          break
        case 'rule':
          order += 2
          break
        default:
          order += 9
          break
      }
      return order
    }

    /**
     * @returns A sorted list of alert message interface
     * filtered by a list of sources if they are provided.
     * Otherwise, returns the unfiltered list.
     */
    const messages = computed<Array<AlertMessageInterface>>(() => {
      const messages: Array<AlertMessageInterface> = store.getters[AlertMessagesGetters.AlertMessages]
      return messages
        .filter((x) => !props.sources || props.sources?.includes(x.source))
        .sort((a: AlertMessageInterface, b: AlertMessageInterface) => getOrder(a) - getOrder(b))
    })

    const getBackgroundColor = (messageType: string) => {
      if (messageType === AlertMessageType.Error ) return 'errorBackground'
      if (messageType === AlertMessageType.Warning ) return 'warningBackground'
      if (messageType === AlertMessageType.Success ) return 'successBackground'
      if (messageType === AlertMessageType.Info ) return 'infoBackground'
    }

    return { messages, getBackgroundColor }
  },
})
</script>
