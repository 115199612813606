import store from "@/store"
import { ContactListFields } from "@/models/data-tables/agency-contacts"
import { AgencyGetters } from "@/store/modules/agency/getters"
import { ContactActions } from "@/store/modules/contacts/actions"
import { LicenseTerminationRequest } from "@/store/modules/contacts/types"
import Register from "./ProcessingMaskHelpers"

// May add more functions in the future
export default async function DeleteContacts(selected: Array<ContactListFields>) {
    const agency = store.getters[AgencyGetters.Agency]
    const terminationInformation: LicenseTerminationRequest = {
        removedContacts: selected.map((contact) => {
            return {
                contactId: contact.id,
                contactFirstName: contact.firstName,
                contactMiddleName: contact.middleName,
                contactLastName: contact.lastName,
                terminationEffectiveDate: String(new Date().toISOString().slice(0, 10)),
                removeFromAllLocations: false,
                hasActiveLicense: contact.hasActiveLicense
            }
        }),
        agentId: agency.id,
        princetonAgentCode: agency.princetonAgtCode,
        agencyName: agency.name,
    }

    return await Register(store.dispatch(ContactActions.DeleteContact, terminationInformation))
}
