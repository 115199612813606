export interface AgencyGroupInterface {
  id: number
  name: string
  princetonCode: string
  status: string
  streetAddress: string
  isAssociatedWithCurrentContact: boolean | null
  associatedContactId: number | null
}

export class AgencyGroup implements AgencyGroupInterface {
  id: number
  name: string
  princetonCode: string
  status: string
  streetAddress: string
  isAssociatedWithCurrentContact: boolean | null
  associatedContactId: number | null
  userId: string
  userName: string

  constructor(options?: {
    id: number
    name: string
    princetonCode: string
    status: string
    streetAddress: string
    isAssociatedWithCurrentContact: boolean | null
    associatedContactId: number | null
    userId: string
    userName: string
  }) {
    this.id = options?.id ?? 0
    this.name = options?.name ?? ''
    this.princetonCode = options?.princetonCode ?? ''
    this.status = options?.status ?? ''
    this.isAssociatedWithCurrentContact = options?.isAssociatedWithCurrentContact ?? null
    this.streetAddress = options?.streetAddress ?? ''
    this.associatedContactId = options?.associatedContactId ?? null
    this.userId = options?.userId ?? ''
    this.userName = options?.userName ?? ''
  }
}

export interface AgencyGroupResponseInterface {
  id: number
  name: string
  princetonCode: string
  status: string
  streetAddress: string
  isAssociatedWithCurrentContact: boolean | null
  associatedContactId: number | null
  userId: string
  userName: string
}

export interface AgencyGroupListInterface {
  agencyGroupList: Array<AgencyGroupInterface>
  agencyLocationList: Array<AgencyGroupInterface>
}

export interface AssociatedAgencyInformationInterface {
  associatedContactId: number
  associatedAgentId: number
  nisUserId: string
  userName: string
}

export interface NisUserIdRequestInterface {
  userId: string
}

export class NisUserIdRequest implements NisUserIdRequestInterface {
  userId: string

  constructor(nisUserId: string) {
    this.userId = nisUserId
  }
}
