import { MutationTree } from 'vuex'
import { AgencyContactEmailListInformation, AgencyContactPreferencesEmailsInterface, Email, EmailNotificationsInterface } from './types'

const filterEmptyEmailAddress = (emailObject: Email) => {
  return emailObject.contactEmail !== ""
}

const filterDuplicateEmail = (emailObject: Email, index: number, emailList: Array<Email>) => {
  return index === emailList.findIndex((email) => (
    email.contactId === emailObject.contactId && email.contactEmail === emailObject.contactEmail
  ))
}

export class AgencyContactPreferencesEmailsMutations {
  public static SET_PREFERENCESEMAILS = 'SET_PREFERENCESEMAILS'
  public static SET_EMAILLIST = 'SET_EMAILLIST'
  public static CLEAR_EMAILLIST = 'CLEAR_EMAILLIST'
}

export const mutations: MutationTree<EmailNotificationsInterface> = {
  SET_PREFERENCESEMAILS(state: EmailNotificationsInterface, contactPreferenceData: Array<AgencyContactPreferencesEmailsInterface>) {
    state.agencyContactPreferencesEmails = contactPreferenceData.map(preference => {
      return {
        category: preference.category,
        primary: preference.primary,
        secondary: preference.secondary,
        helpText: preference.helpText
      }
    })
  },
  SET_EMAILLIST(state: EmailNotificationsInterface, agencyContactEmailsData: AgencyContactEmailListInformation) {
    if (state.agencyContactEmailListInformation.listOfAgencyContactEmails.length > 0) {
      state.agencyContactEmailListInformation.listOfAgencyContactEmails =
        state.agencyContactEmailListInformation.listOfAgencyContactEmails
          .concat(agencyContactEmailsData.listOfAgencyContactEmails)
    } else {
      state.agencyContactEmailListInformation.listOfAgencyContactEmails =
        agencyContactEmailsData.listOfAgencyContactEmails
    }

    state.agencyContactEmailListInformation.listOfAgencyContactEmails =
      state.agencyContactEmailListInformation.listOfAgencyContactEmails
        .filter((emailObject: Email, index: number, emailList: Array<Email>) => {
          return filterDuplicateEmail(emailObject, index, emailList) &&
            filterEmptyEmailAddress(emailObject)
        })

    state.agencyContactEmailListInformation.totalNumberOfAgencyContactEmails =
      agencyContactEmailsData.totalNumberOfAgencyContactEmails
  },
  CLEAR_EMAILLIST(state: EmailNotificationsInterface) {
    state.agencyContactEmailListInformation.listOfAgencyContactEmails = []
    state.agencyContactEmailListInformation.totalNumberOfAgencyContactEmails = 0
  }
}

export default mutations
