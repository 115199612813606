<template>
  <div class="pt-12">
    <div class="gray-background py-9">
      <v-row>
        <v-col class="d-flex justify-space-between">
          <h2>Email Alerts/Notification Settings</h2>
          <router-link class="edit-btn" :to="{ name: 'EditEmailNotifications' }">
            <simple-button color="primary">Edit</simple-button>
          </router-link>
        </v-col>
      </v-row>
      <h2 class="email-explanation">{{ emailNotificationExplanation }}</h2>

      <v-row no-gutters class="pt-2" v-for="email in agencyEmailNotifications" :key="email.category">
        <v-col class="col col-3" v-if="showEmails(email.category)">
          <p class="bold black--text d-inline">{{ email.category }}</p>
          <app-tooltip class="px-1 d-inline" :persistOnClick="true">
            <slot> {{ email.helpText }} </slot>
          </app-tooltip>
          <p class="black--text"><em>Primary:</em> {{ email.primary.contactEmail }}</p>
          <p class="black--text"><em>Secondary:</em> {{ email.secondary.contactEmail }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

import SimpleTooltip from '@/components/common/input/SimpleTooltip.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'

import verbiage from '@/constants/verbiage'

import { EmailNotificationsInterface } from '@/store/modules/agency-contact-preferences-emails/types'

import ShowAgencyEmailNotification from '@/utils/ShowAgencyEmailNotification'

export default defineComponent({
  name: 'EmailNotifications',
  components: {
    'app-tooltip': SimpleTooltip,
    SimpleButton,
  },
  props: {
    emailNotifications: {
      type: Object as PropType<EmailNotificationsInterface>,
      required: true,
    },
  },
  setup(props) {
    const agencyEmailNotifications = computed(() => props.emailNotifications.agencyContactPreferencesEmails)
    function showEmails(category: string) {
      return ShowAgencyEmailNotification(category)
    }

    const emailNotificationExplanation = verbiage.emailNotificationExplanation

    return {
      agencyEmailNotifications,
      showEmails,
      emailNotificationExplanation,
    }
  },
})
</script>

<style lang="scss" scoped>
.col {
  padding-top: 0;
  padding-bottom: 3px;
}

p {
  white-space: nowrap;
}

.ngic-button {
  width: 125px;
}

.email-explanation {
  font-weight: normal;
  padding-top: 10px;
}
</style>
