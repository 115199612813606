import { MutationTree } from 'vuex'
import { OccupationInterface } from '../occupation/types'
import { OccupationSearchResponseInterface } from './types'

export class OccupationMutations {
  public static SET_OCCUPATIONS = 'SET_OCCUPATIONS'
}

export const mutations: MutationTree<OccupationSearchResponseInterface> = {
    SET_OCCUPATIONS(state: OccupationSearchResponseInterface, searchResults: Array<OccupationInterface>) {
    state.results = searchResults ?? []
  }
}

export default mutations