<template>
  <v-row>
    <v-col cols="2" class="ml-1 mt-3 pt-4"> {{ numberOfRecords }} total records </v-col>
    <v-col>
      <v-pagination
        cols="8"
        v-model="pages"
        :length="pageCount"
        :total-visible="8"
        @input="$emit('updatePage', $event)"
      >
      </v-pagination>
    </v-col>
    <v-col cols="3" align="right" class="mt-2">
      <v-row>
        <v-col cols="7" align="right" class="mt-2"> Rows per page: </v-col>
        <v-col cols="5">
          <v-select :items="perPageChoices" :value="itemsPerPage" @change="$emit('input', $event)" solo dense />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SimplePagination',
  props: {
    perPageChoices: {
      required: false,
      type: Array,
      default() {
        return [15, 30, 60, 100]
      },
    },
    itemsPerPage: {
      required: false,
      type: Number,
      default: 15,
    },
    pageCount: {
      required: true,
      type: Number,
    },
    numberOfRecords: {
      required: true,
      type: Number,
    },
  },
  setup() {
    const pages = ref<number>(1)

    return {
      pages,
    }
  },
})
</script>
