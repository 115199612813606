<template>
  <v-container fluid class="send-registration-link">
    <div class="px-6 medium-text">
      <p v-if="isResend" class="mb-5">{{ resendMessageString }}</p>
      <p v-html="message"></p>
    </div>

    <div>
      <div v-if="isSingleSignOn" class="px-6 pt-4 text-field">
        <v-row>
          <v-col cols="2">
            <p>User ID</p>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="singleSignOnUsername"
              outlined
              dense
              class="rounded-0 px-2"
              @input="setRegistrationInformation"
              @blur="singleSignOnUsername = TrimExtraWhitespace(singleSignOnUsername)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <p>Email address for registration</p>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.trim="emailString"
              class="rounded-0 px-2"
              dense
              hide-details
              outlined
              @input="setRegistrationInformation"
              @blur="emailString = TrimExtraWhitespace(emailString)"
              @keyup="markEmailAsChanged()"
              :rules="[rules.email]"
            />
          </v-col>
        </v-row>
      </div>
      <v-radio-group
        v-else
        v-model="registrationMethod"
        @change="showLocationGridAndSetRegistrationInfo(registrationMethod)"
        class="px-5 text-field"
      >
        <v-row no-gutters class="">
          <v-col cols="3">
            <v-radio label="Mobile Phone" :value="enums.registrationMethod.Mobile" />
          </v-col>
          <v-col col="7">
            <v-text-field
              v-if="isMobilePhoneSelected"
              placeholder="XXX-XXX-XXXX"
              v-model.trim="mobilePhoneString"
              class="rounded-0 px-2"
              dense
              hide-details
              outlined
              :maxlength="12"
              @keyup="
                phoneFieldKeyUpHandler($event.target.value)
                markPhoneAsChanged()
              "
              @keydown="IntegersAndDashOnly"
              @input="setRegistrationInformation"
              @blur="mobilePhoneString = TrimExtraWhitespace(mobilePhoneString)"
              :rules="[rules.phone]"
            />
          </v-col>
          <v-col col="2" />
        </v-row>
        <v-row no-gutters class="p-1">
          <v-col cols="3">
            <v-radio label="Email" :value="enums.registrationMethod.Email" />
          </v-col>
          <v-col col="7">
            <v-row no-gutters class="mt-1">
              <v-text-field
                v-if="isEmailSelected"
                v-model.trim="emailString"
                class="rounded-0 px-2"
                dense
                hide-details
                outlined
                @input="setRegistrationInformation"
                @blur="emailString = TrimExtraWhitespace(emailString)"
                @keyup="markEmailAsChanged()"
                :rules="[rules.email]"
              />
            </v-row>
            <v-row no-gutters class="mt-n3 pl-1">
              <div class="update-info">
                <v-checkbox
                  v-if="(isEmailSelected && isEmailChanged) || (isMobilePhoneSelected && isPhoneChanged)"
                  hide-details
                  class="update-information"
                  v-model="isUpdate"
                  @change="showLocationGridAndSetRegistrationInfo"
                >
                  <span bold slot="label" class="black--text">Update this contact's information.</span>
                </v-checkbox>
              </div>
            </v-row>
          </v-col>
          <v-col col="2" />
        </v-row>
      </v-radio-group>
    </div>

    <div class="agency-location-grid ma-auto py-2" id="select_associated_agencies" v-if="showAgencyLocationGrid">
      <p class="medium-text pt-4 pb-1">{{ contactListed }}</p>
      <p class="medium-text pt-1 pb-4">{{ contactListed2 }}</p>
      <location-selection-grid
        v-model="selectedLocations"
        item-key="agentId"
        :items="associatedLocations"
        @input="setRegistrationInformation"
      />
    </div>

    <div class="agency-location-grid ma-auto py-2" id="select_additional_agencies" v-if="showAgencyLocationGrid">
      <p class="medium-text pt-4 pb-1">{{ contactNotListed }}</p>
      <p class="medium-text pt-1 pb-4">{{ contactNotListed2 }}</p>
      <location-selection-grid
        v-model="selectedLocations"
        item-key="agentId"
        :items="unassociatedLocations"
        @input="setRegistrationInformation"
      />
    </div>

    <div v-if="isMobilePhoneSelected" v-html="textConsent" class="medium-text px-6 py-4"></div>

    <p class="medium-text px-6 py-2">
      {{ textRegistrationExpirationMessage }}
    </p>
  </v-container>
</template>

<script lang="ts">
import router from '@/router'
import store from '@/store'
import { computed, defineComponent, PropType, ref, onBeforeMount } from 'vue'

import LocationSelectionGrid from '@/components/agency-self-service/LocationSelectionGrid.vue'
import enums from '@/constants/enumerations'
import Inputrestrictionrules from '@/constants/inputrestrictionrules'
import verbiage from '@/constants/verbiage'
import { AgencyLocationFields, AgencyLocationGridHeader, ContactListFields } from '@/models/data-tables/agency-contacts'
import { AgencyCodeActions } from '@/store/modules/agency-code/actions'
import { AgencyCodeGetters } from '@/store/modules/agency-code/getters'
import { AgencyGroup, AssociatedAgencyInformationInterface } from '@/store/modules/agency-code/type'
import { ContactActions } from '@/store/modules/contacts/actions'
import { NisAgencyInformation, RegisterUserInformation } from '@/store/modules/contacts/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { EventBus, Events } from '@/utils/EventBus'
import { autoGenerateDash, TrimExtraWhitespace } from '@/utils/Formatter'
import { IntegersAndDashOnly } from '@/utils/InputFilters'
import Register from '@/utils/ProcessingMaskHelpers'

export default defineComponent({
  name: 'ModalSendRegistrationLink',
  props: {
    message: {
      required: false,
      type: String,
      default: '',
    },
    checkBoxLabels: {
      required: false,
      type: Array as PropType<Array<string>>,
    },
    contact: {
      required: true,
      type: ContactListFields,
    },
    isResendLink: {
      required: true,
      type: Boolean,
    },
    resendMessage: {
      required: false,
      type: String,
      default: '',
    },
    isSSO: {
      required: true,
      type: Boolean,
    },
  },
  components: { LocationSelectionGrid },
  setup(props, context) {
    // Modal related
    const registrationMethod = ref<string>('')
    const isUpdate = ref<boolean>(false)
    const isEmailChanged = ref<boolean>(false)
    const isPhoneChanged = ref<boolean>(false)
    const isEmailSelected = computed(() => registrationMethod.value === 'email')
    const isMobilePhoneSelected = computed(() => registrationMethod.value === 'mobile')

    const mobilePhoneString = ref<string>(props.contact.mobilePhone)
    const emailString = ref<string>(props.contact.email)
    const isResend = computed(() => props.isResendLink)
    const resendMessageString = computed(() => props.resendMessage)
    const textRegistrationExpirationMessage = verbiage.nisRegistrationExpirationMessage
    const textConsent = verbiage.textConsent
    const isSingleSignOn = computed(() => props.isSSO)
    const singleSignOnUsername = ref<string>('')

    const rules = Inputrestrictionrules.rules

    function isLoggedInAgency(item: AgencyLocationFields) {
      return item.agencyCode === store.getters[UserSessionGetters.PrincetonAgentCode]
    }

    function phoneFieldKeyUpHandler(textFieldInput: string) {
      const digitsOnly = textFieldInput.replaceAll('-', '')

      // Only add dashes and update the text box when there are ten digits
      if (digitsOnly.length === 10) {
        const phoneNumberWithDashes = autoGenerateDash(digitsOnly)
        mobilePhoneString.value = phoneNumberWithDashes
      }
    }

    // Location grid related
    const headers = AgencyLocationGridHeader

    const agencyLocationData: Array<AgencyLocationFields> = store.getters[
      AgencyCodeGetters.AgencyGroups
    ].agencyLocationList.map((agency: AgencyGroup) => new AgencyLocationFields(agency))

    const selectedLocations = ref<Array<AgencyLocationFields>>([])
    const showAgencyLocationGrid = ref<boolean>(false)
    const contactAssociatedAgenciesSet = ref<boolean>(false)

    const setContactAssociatedAgencies = async () => {
      if (!contactAssociatedAgenciesSet.value) {
        await Register(store.dispatch(AgencyCodeActions.GetAssociatedAgencies, props.contact.id)).then((response) => {
          if (response && response.length > 0) {
            const adminAgencyById = new Map<number, AgencyLocationFields>(
              agencyLocationData.map(
                (agency: AgencyLocationFields) => [agency.agentId, agency] as [number, AgencyLocationFields]
              )
            )

            response.forEach((associatedAgency: AssociatedAgencyInformationInterface) => {
              if (adminAgencyById.has(associatedAgency.associatedAgentId)) {
                const agencyAdmin = adminAgencyById.get(associatedAgency.associatedAgentId) as AgencyLocationFields
                agencyAdmin.isAssociatedWithCurrentContact = true
                agencyAdmin.associatedContactId = associatedAgency.associatedContactId
              }
            })

            selectedLocations.value = agencyLocationData.filter((location) => location.isAssociatedWithCurrentContact)
          }
        })

        contactAssociatedAgenciesSet.value = true
      }

      return true
    }

    const associatedLocations = computed(() => {
      const filteredList = agencyLocationData.filter((location) => location.isAssociatedWithCurrentContact)
      return filteredList
    })

    const unassociatedLocations = computed(() => {
      const filteredList = agencyLocationData.filter((location) => !location.isAssociatedWithCurrentContact)
      return filteredList
    })

    const contactListed = verbiage.nisRegistrationAdditionalAgencyGrid1
    const contactListed2 = verbiage.nisRegistrationAdditionalAgencyGrid1b
    const contactNotListed = verbiage.nisRegistrationAdditionalAgencyGrid2
    const contactNotListed2 = verbiage.nisRegistrationAdditionalAgencyGrid2b

    const enableConfirmButton = computed(
      () =>
        (isEmailSelected.value && emailString.value) ||
        (isMobilePhoneSelected.value && mobilePhoneString.value) ||
        (isSingleSignOn.value && emailString.value && singleSignOnUsername.value)
    )

    // Action
    const showLocationGridAndSetRegistrationInfo = async (value: string) => {
      await setContactAssociatedAgencies().then(() => {
        setRegistrationInformation()
        showAgencyLocationGrid.value = value !== enums.registrationMethod.None && agencyLocationData.length > 1
      })
    }

    function setRegistrationInformation() {
      if (contactAssociatedAgenciesSet.value) {
        const registerUserInformation: RegisterUserInformation = {
          currentContactId: props.contact.id,
          firstName: props.contact.firstName,
          lastName: props.contact.lastName,
          dob: props.contact.dob,
          registrationEmail: isEmailSelected.value || isSingleSignOn.value ? emailString.value : '',
          registrationMobilePhone:
            // only send the mobile phone number string if
            // 1. checkbox for the mobile phone is selected
            // and 2. there are ten digits
            isMobilePhoneSelected.value && mobilePhoneString.value.replaceAll('-', '').length === 10
              ? mobilePhoneString.value
              : '',
          isUpdate: isUpdate.value,
          nisLoginAgencies: selectedLocations.value.map((agency: AgencyLocationFields) => {
            const additionalAgency: NisAgencyInformation = {
              agtId: agency.agentId,
              agencyName: agency.agencyName,
              agencyPrincetonCode: agency.agencyCode,
              agencyStreetAddress: agency.agencyLocation,
              associatedContactId: agency.associatedContactId === null ? 0 : agency.associatedContactId,
            }
            return additionalAgency
          }),
          identityProviderInformation: null,
          singleSignOnUsername: singleSignOnUsername.value,
        }
        context.emit('input', registerUserInformation)
        if (enableConfirmButton.value) {
          EventBus.emit(Events.showModalConfirm)
        } else {
          EventBus.emit(Events.disableModalConfirm)
        }
      }
    }

    onBeforeMount(() => {
      EventBus.emit(Events.disableModalConfirm)
      if (isSingleSignOn.value) {
        showLocationGridAndSetRegistrationInfo(enums.registrationMethod.Email)
      }
    })
    function markEmailAsChanged() {
      isEmailChanged.value = true
    }

    function markPhoneAsChanged() {
      isPhoneChanged.value = true
    }

    // Close modal and stay on homepage
    EventBus.on(Events.closeModalAndStay, () => {
      registrationMethod.value = enums.registrationMethod.None
      isUpdate.value = false
      selectedLocations.value = []
      contactAssociatedAgenciesSet.value = false
    })

    // Refresh contact phone and email info
    EventBus.on(Events.refreshContacts, () => {
      mobilePhoneString.value = props.contact.mobilePhone
      emailString.value = props.contact.email
    })

    return {
      agencyLocationData,
      associatedLocations,
      contactListed,
      contactListed2,
      contactNotListed,
      contactNotListed2,
      emailString,
      enums,
      headers,
      IntegersAndDashOnly,
      isEmailSelected,
      isLoggedInAgency,
      isMobilePhoneSelected,
      isEmailChanged,
      isPhoneChanged,
      isResend,
      isUpdate,
      markEmailAsChanged,
      markPhoneAsChanged,
      mobilePhoneString,
      phoneFieldKeyUpHandler,
      registrationMethod,
      resendMessageString,
      rules,
      selectedLocations,
      setRegistrationInformation,
      showAgencyLocationGrid,
      showLocationGridAndSetRegistrationInfo,
      textConsent,
      textRegistrationExpirationMessage,
      TrimExtraWhitespace,
      unassociatedLocations,
      isSingleSignOn,
      singleSignOnUsername,
    }
  },
})
</script>

<style lang="scss" scoped>
.medium-text {
  font-size: 14px;
}

.update-info:deep(.v-input__control) {
  margin: auto;
  flex-grow: 0;
  width: auto;
}

.agency-location-grid {
  max-width: 90%;
}

.theme--light.v-icon {
  color: black;
}

.redirect {
  cursor: pointer;
}
</style>
