import { Phone } from '@/models/phone-number'
import { AgencyInterface, MigrationStatus } from './types'

export const state: AgencyInterface = {
  id: 0,
  name: '',
  akaName: '',
  mailingAddress: {
    street: '',
    city: '',
    state: '',
    zip: '',
  },
  streetAddress: {
    street: '',
    city: '',
    state: '',
    zip: '',
  },
  phone: new Phone(''),
  fax: new Phone(''),
  email: '',
  website: '',
  princetonAgtCode: '',
  hours: {
    monday : {
      openTime: '',
      closeTime: ''
    }
  },
  status: '',
  indicator: '',
  migrationStatus: MigrationStatus.NotMigrated,
  singleSignOnProviderInformation: [],
  isSingleSignOn: false,
}

export default state
