import store from "@/store"
import { AgencyGetters } from "@/store/modules/agency/getters"
import { Agency } from "@/store/modules/agency/types"
import enumerations from "@/constants/enumerations"

export default function ShowAgencyEmailNotification (category: string) {
    const agency: Agency = store.getters[AgencyGetters.Agency]
    const isBlendOrPreferred: boolean =
        agency.indicator.toLowerCase() === enumerations.agencyIndicator.Blended || agency.indicator.toString() === enumerations.agencyIndicator.Preferred

    return isBlendOrPreferred || category !== enumerations.emailNotificationCategory.NatGenPremierRenewalReport
}