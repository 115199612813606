import { MutationTree } from 'vuex'
import { AgencyGroupListInterface } from './type'

export class AgencyCodeMutations {
  public static SET_AGENCYGROUP = 'SET_AGENCYGROUP'
}

export const mutations: MutationTree<AgencyGroupListInterface> = {
  SET_AGENCYGROUP(state: AgencyGroupListInterface, agencyGroupInformation: AgencyGroupListInterface) {
    state.agencyGroupList = agencyGroupInformation.agencyGroupList.map(agencyGroup => {
      return {
        id: agencyGroup.id,
        name: agencyGroup.name,
        princetonCode: agencyGroup.princetonCode,
        status: agencyGroup.status,
        streetAddress: agencyGroup.streetAddress,
        isAssociatedWithCurrentContact: null,
        associatedContactId: null
      }
    })

    state.agencyLocationList = agencyGroupInformation.agencyLocationList.map(agencyLocation => {
      return {
        id: agencyLocation.id,
        name: agencyLocation.name,
        princetonCode: agencyLocation.princetonCode,
        status: agencyLocation.status,
        streetAddress: agencyLocation.streetAddress,
        isAssociatedWithCurrentContact: null,
        associatedContactId: null
      }
    })
  }
}

export default mutations
