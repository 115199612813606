import useApis from '@/api'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'
import { AgencyGetters } from '../agency/getters'
import { LoginProviderType } from '../user-session/types'
import { AgencyCodeMutations } from './mutations'
import {
  AgencyGroup,
  AgencyGroupInterface,
  AgencyGroupListInterface,
  AgencyGroupResponseInterface,
  AssociatedAgencyInformationInterface,
  NisUserIdRequest,
} from './type'

export class AgencyCodeActions {
  /**
   * gets the agency code options
   */
  public static GetAgencyGroup = 'agencyCode/getAgencyGroup'
  /**
   * gets the associated agencies
   */
  public static GetAssociatedAgencies = 'agencyCode/getAssociatedAgencies'
}

const { standard: api } = useApis()

export const actions: ActionTree<AgencyGroupListInterface, RootState> = {
  async getAgencyGroup(context) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let agencyGroupResponse: AxiosResponse<any, any>
      let agencyGroupResult: Array<AgencyGroupInterface>

      const loginProviderType = store.getters[UserSessionGetters.LoginProviderType]

      if (loginProviderType === LoginProviderType.Nis) {
        const nisUserId = store.getters[UserSessionGetters.NisUserId]
        const nisUserIdRequest = new NisUserIdRequest(nisUserId)
        agencyGroupResponse = await api.post(
          'agent_portal/producers/agent_group/nis_user/administrator',
          nisUserIdRequest
        )
      } else {
        const agentGroup = store.getters[UserSessionGetters.AgentGroup]
        agencyGroupResponse = await api.get('agent_portal/producers/agency/agent_group/' + agentGroup)
      }

      const agencyLocationResult = agencyGroupResponse.data.value.map(
        (agencyGroup: AgencyGroupResponseInterface) => new AgencyGroup(agencyGroup)
      )

      if (store.getters[UserSessionGetters.IsHomeOffice]) {
        agencyGroupResult = agencyLocationResult
      } else {
        agencyGroupResult = [
          new AgencyGroup({
            id: store.getters[UserSessionGetters.AgentId],
            name: store.getters[AgencyGetters.Agency].name,
            princetonCode: store.getters[UserSessionGetters.PrincetonAgentCode],
            status: store.getters[AgencyGetters.Agency].status,
            streetAddress: store.getters[AgencyGetters.Agency].streetAddress,
            isAssociatedWithCurrentContact: true,
            associatedContactId: null,
            userId: '',
            userName: '',
          }),
        ]
      }

      const agencyGroupInformation: AgencyGroupListInterface = {
        agencyGroupList: agencyGroupResult.sort(
          (x, y) => y.status.localeCompare(x.status) || x.princetonCode.localeCompare(y.princetonCode)
        ),
        agencyLocationList: agencyLocationResult.sort(
          (x: AgencyGroupResponseInterface, y: AgencyGroupResponseInterface) =>
            y.status.localeCompare(x.status) || x.princetonCode.localeCompare(y.princetonCode)
        ),
      }

      context.commit(AgencyCodeMutations.SET_AGENCYGROUP, agencyGroupInformation)
    } catch (reason) {
      console.error('agencyhome :: getAgencyGroup :: failed:', reason)
      return false
    }
  },

  async getAssociatedAgencies(
    context,
    contactId: number
  ): Promise<Array<AssociatedAgencyInformationInterface> | false> {
    try {
      let route = 'agent_portal/producers/agency/agent_group/contact/' + contactId + '?'

      const loginProviderType = store.getters[UserSessionGetters.LoginProviderType]
      if (loginProviderType === LoginProviderType.Nis) {
        const nisUserId = store.getters[UserSessionGetters.NisUserId]
        route = route + 'nisUserId=' + nisUserId
      } else {
        const agentGroup = store.getters[UserSessionGetters.AgentGroup]
        route = route + 'agentGroup=' + agentGroup
      }

      const response = await api.get(route)
      const associatedLocations: Array<AssociatedAgencyInformationInterface> = response.data.value
      return associatedLocations
    } catch (reason) {
      console.error('agencyhome :: getAssociatedLocations :: failed:', reason)
      return false
    }
  },
}
