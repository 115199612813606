import { MutationTree } from 'vuex'
import { AlertBannerInterface, AdminAlert } from './types'

export class AlertBannerMutations {
  public static SET_ALERTBANNER = 'SET_ALERTBANNER'
}

export const mutations: MutationTree<AlertBannerInterface> = {
  SET_ALERTBANNER (state: AlertBannerInterface, newAlerts: Array<AdminAlert>) {
    state.adminAlerts = newAlerts.map(alert => {
      return {
        alertId: alert.alertId,
        alertName: alert.alertName,
        alertAction: alert.alertAction,
        rowEffective: alert.rowEffective,
        location: alert.location,
        princetonCode: alert.princetonCode,
        bannerMessage: alert.bannerMessage
      }
    })
  }
}

export default mutations

