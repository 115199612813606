import enums from '@/constants/enumerations'
import useFetchAgentStates from '@/components/client/composables/useFetchAgentStates'
import useFetchInternalSalesStatus from '@/components/client/composables/useFetchInternalSalesStatus'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { UserSessionMutations } from './mutations'
import { LoginProviderType, UserSessionInterface, UserStateProductsInterface } from './types'


/**
 * The user session actions.
 */
export class UserSessionActions {

  /** Sets the initial user state when Bridging */
  public static SetUserInformation = 'userSession/setUserInformation'

  /** Updates the user information. */
  public static UpdateUserInformation = 'userSession/updateUserInformation'

  /** Updates the user product options. */
  public static UpdateUserProductOptions = 'userSession/updateUserProductOptions'

  /** Updates the user information when agency dropdown information is changed. */
  public static UpdateUserInformationFromAgencyCodeDropdown = 'userSession/updateUserInformationFromAgencyCodeDropdown'

}

/**
 * The user session action tree.
 */
export const actions: ActionTree<UserSessionInterface, RootState> = {
  async setUserInformation({ commit }, payload: { userInfo: UserSessionInterface }) {
    payload.userInfo.isInternalSales = await getInternalSalesStatusAsync(payload.userInfo.username, payload.userInfo.mode, payload.userInfo.loginProviderType)
    const options = await useFetchAgentStates(payload.userInfo.agentId)
    payload.userInfo.userStateOptions = options
    commit(UserSessionMutations.SET_USER, payload.userInfo)
  },

  async updateUserInformation({ commit }, payload: UserSessionInterface) {
    payload.isInternalSales = await getInternalSalesStatusAsync(payload.username, payload.mode, payload.loginProviderType)
    commit(UserSessionMutations.UPDATE_USER, payload)
  },

  updateUserInformationFromAgencyCodeDropdown({ commit }, payload: UserSessionInterface) {
    commit(UserSessionMutations.UPDATE_USER_FROM_AGENCY_CODE_DROPDOWN, payload)
  },

  async updateUserProductOptions({ commit }, payload: Array<UserStateProductsInterface>) {
    commit(UserSessionMutations.UPDATE_USER_PRODUCT_OPTIONS, payload)
  }
}

async function getInternalSalesStatusAsync( userName: string, appMode: string, loginProviderType: LoginProviderType): Promise<boolean> {
  return userName === 'NGInternal' || appMode !== enums.appMode.ClientSearch
    ? false 
    : await useFetchInternalSalesStatus(userName)
}

export default actions
