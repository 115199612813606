import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ImageStoreInterface } from './types'

/**
 * The image getters class.
 */
export class ImageGetters {
  public static Images = 'image/images'
}

/**
 * The image getter tree.
 */
export const getters: GetterTree<ImageStoreInterface, RootState> = {
  images: (state: ImageStoreInterface) => { return state.images }
}

export default getters
