import enums from '@/constants/enumerations'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import useComparer from '@/utils/useComparer'

const { includesIgnoreCase } = useComparer()

/**
 *
 * @returns A boolean indicating if the user is an agent or internalSales
 */
export default function (): boolean {
  const environment: string = process.env.VUE_APP_SYSTEM_ENV
  const isProduction: boolean = includesIgnoreCase(
    [enums.environment.Production, enums.environment.LiveDebug, 'PROD'],
    environment
  )
  return (
    (isProduction && store.getters[UserSessionGetters.AgentId] === 47) ||
    store.getters[UserSessionGetters.IsAgent] ||
    store.getters[UserSessionGetters.IsInternalSales]
  )
}
