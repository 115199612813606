import tokenService from "./tokenService"

// Token timer kicks in every 45 mins

// Need to call the token fresh code directly for IE
const isIE: boolean = window.navigator.userAgent.indexOf('Trident/') !== -1

if (isIE) {
    setInterval(async () => { await tokenService.getToken() }, 1000 * 60 * 45)
}

// For browsers other than IE
const tokenTimer = () => { setInterval(() => { postMessage('') }, 1000 * 60 * 45) }

export default tokenTimer