import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import alertBanner from './modules/admin-alert'
import agency from './modules/agency'
import agencyCode from './modules/agency-code'
import emailNotifications from './modules/agency-contact-preferences-emails'
import alertMessages from './modules/alert-messages'
import businessSearch from './modules/business-search'
import client from './modules/client'
import comprater from './modules/comprater'
import configs from './modules/config-toggle'
import contacts from './modules/contacts'
import gatewayCallouts from './modules/gateway-callouts'
import image from './modules/image'
import internalSalesCallouts from './modules/internal-sales-callouts'
import navigation from './modules/navigation'
import navigationCallouts from './modules/navigation-callouts'
import occupationFilter from './modules/occupation-filter'
import occupationSearch from './modules/occupation-search'
import processingMask from './modules/processing-mask'
import reportsCallouts from './modules/reports-callouts'
import systemTransferCallouts from './modules/system-transfer-callouts'
import userSession from './modules/user-session'
import userSessionCallouts from './modules/user-session-callouts'
import { RootState } from './types'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['businessSearch', 'client', 'configs', 'navigation', 'occupationFilter', 'occupationSearch', 'reportsCallouts', 'userSession'],
    }),
  ],
  modules: {
    agency,
    alertBanner,
    agencyCode,
    alertMessages,
    businessSearch,
    client,
    comprater,
    configs,
    contacts,
    emailNotifications,
    gatewayCallouts,
    image,
    internalSalesCallouts,
    navigation,
    navigationCallouts,
    occupationFilter,
    occupationSearch,
    processingMask,
    reportsCallouts,
    systemTransferCallouts,
    userSession,
    userSessionCallouts,
  },
})
