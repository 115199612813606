import { MutationTree } from 'vuex'
import { UserSessionInterface } from './types'

/**
 * The user session mutations.
 */
export class UserSessionMutations {
  /** Sets all necessary user information. */
  public static SET_USER = 'SET_USER'

  /** Updates all necessary user information. */
  public static UPDATE_USER = 'UPDATE_USER'

  public static UPDATE_USER_PRODUCT_OPTIONS = 'UPDATE_USER_PRODUCT_OPTIONS'

  /** Updates all necessary user information. */
  public static UPDATE_USER_FROM_AGENCY_CODE_DROPDOWN = 'UPDATE_USER_FROM_AGENCY_CODE_DROPDOWN'

  /** Sets NIS config. */
  public static SET_NIS_CONFIG = 'SET_NIS_CONFIG'
}

/**
 * The user session mutation tree.
 */
export const mutations: MutationTree<UserSessionInterface> = {
  SET_USER(state: UserSessionInterface, value: UserSessionInterface) {
    state.agentGroup = value.agentGroup
    state.agentId = value.agentId
    state.canViewCommissionStatement = value.canViewCommissionStatement
    state.emailAddress = value.emailAddress
    state.employeeNum = value.employeeNum
    state.environment = value.environment
    state.firstName = value.firstName
    state.generatedFrom = value.generatedFrom
    state.homeOfficeId = value.homeOfficeId
    state.initialState = value.initialState
    state.isAgencyAdmin = value.isAgencyAdmin
    state.isAgencyPrimaryLogin = value.isAgencyPrimaryLogin
    state.isAgent = value.isAgent
    state.isDaStore = value.isDaStore
    state.isHomeOffice = value.isHomeOffice
    state.isInternalSales = value.isInternalSales
    state.isMGA = value.isMGA
    state.isSuperUser = value.isSuperUser
    state.lastName = value.lastName
    state.loginProviderType = value.loginProviderType
    state.loginType = value.loginType
    state.masterAccountId = value.masterAccountId
    state.masterUserId = value.masterUserId
    state.nisUserId = value.nisUserId
    state.officeAccountCode = value.officeAccountCode
    state.originUrl = value.originUrl
    state.permissions = value.permissions
    state.policyId = value.policyId
    state.princetonAgentCode = value.princetonAgentCode
    state.product = value.product
    state.quoteId = value.quoteId
    state.role = value.role
    state.subProduct = value.subProduct
    state.username = value.username
    state.userProductOptions = value.userProductOptions
    state.userStateOptions = value.userStateOptions
  },

  UPDATE_USER(state: UserSessionInterface, value: UserSessionInterface) {
    state.agentGroup = value.agentGroup
    state.agentId = value.agentId
    state.canViewCommissionStatement = value.canViewCommissionStatement
    state.emailAddress = value.emailAddress
    state.employeeNum = value.employeeNum
    state.environment = value.environment
    state.firstName = value.firstName
    state.generatedFrom = value.generatedFrom
    state.initialState = value.initialState
    state.isAgencyAdmin = value.isAgencyAdmin
    state.isAgencyPrimaryLogin = value.isAgencyPrimaryLogin
    state.isAgent = value.isAgent
    state.isDaStore = value.isDaStore
    state.isInternalSales = value.isInternalSales
    state.isMGA = value.isMGA
    state.isSuperUser = value.isSuperUser
    state.lastName = value.lastName
    state.loginProviderType = value.loginProviderType
    state.loginType = value.loginType
    state.nisUserId = value.nisUserId
    state.masterAccountId = value.masterAccountId
    state.masterUserId = value.masterUserId
    state.officeAccountCode = value.officeAccountCode
    state.originUrl = value.originUrl
    state.permissions = value.permissions
    state.policyId = value.policyId
    state.princetonAgentCode = value.princetonAgentCode
    state.product = value.product
    state.quoteId = value.quoteId
    state.subProduct = value.subProduct
    state.role = value.role
    state.username = value.username
    state.userStateOptions = value.userStateOptions
  },

  UPDATE_USER_PRODUCT_OPTIONS(state: UserSessionInterface, value: UserSessionInterface) {
    state.userProductOptions = value.userProductOptions
  },

  UPDATE_USER_FROM_AGENCY_CODE_DROPDOWN(state: UserSessionInterface, value: UserSessionInterface) {
    state.agentGroup = value.agentGroup
    state.agentId = value.agentId
    state.princetonAgentCode = value.princetonAgentCode
  },
}

export default mutations
