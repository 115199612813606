<template>
  <v-text-field
    v-model.trim="model"
    v-bind="attrs"
    v-on="listeners"
    class="rounded-0"
    dense
    hide-details
    outlined
    :disabled="disabled"
    :filled="disabled"
    :required="isRequired"
    @blur="model = TrimExtraWhitespace(model)"
  >
    <template v-slot:append>
      <slot name="append" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { TrimExtraWhitespace } from '@/utils/Formatter'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'SimpleTextField',
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hidden: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
    isBold: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // Reads the text contents of what's being passed into the tooltips slot to determine if the tooltip should render.

    const model = computed<string | number>({
      get: () => props.value,
      set: (newValue) => context.emit('input', newValue),
    })

    const attrs = computed(() => context.attrs)
    const listeners = computed(() => context.listeners)

    return {
      attrs,
      listeners,
      model,
      TrimExtraWhitespace,
    }
  },
})
</script>
