import userActivityTimerWorker from "./userActivityTimerWorker"

const trackUserActivity = () => {
    const activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart']

    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, function () {
        userActivityTimerWorker.stopuserTimerWorker()
        userActivityTimerWorker.startUserTimerWorker()
      })
    })
}

export default trackUserActivity
