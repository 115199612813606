import startWorker from './startWorker'
import userActivityTimer from './userActivityTimer'

export interface UserActivityTimerInterface {
  worker: Worker | undefined
  isRunning: boolean
}

/**
 * The UserActivityTimer model.
 */
export class UserActivityTimer implements UserActivityTimerInterface {
  worker: Worker | undefined
  isRunning: boolean

  /**
   * Creates a new instance of the UserActivityTimer model.
   */
  constructor(options: { worker: Worker | undefined; isRunning: boolean }) {
    this.worker = options?.worker
    this.isRunning = options?.isRunning
  }
}

class UserTimerActivityService {
  private userTimerWorker: UserActivityTimerInterface | undefined

  startUserTimerWorker() {
    if (window.Worker) {
      if (this.userTimerWorker === undefined) {
        this.userTimerWorker = new UserActivityTimer({
          worker: startWorker(userActivityTimer),
          isRunning: true,
        })

        if (this.userTimerWorker.worker !== undefined) {
          this.userTimerWorker.worker.onmessage = async () => {
            this.logOut()
          }
        }
      } else {
        console.error('There is already an user activity tracking timer running.')
      }
    } else {
      console.error('Web worker is not supported on this browser.')
    }
  }

  stopuserTimerWorker() {
    if (this.userTimerWorker?.worker !== undefined && this.userTimerWorker.isRunning === true) {
      this.userTimerWorker.isRunning = false
      this.userTimerWorker.worker.terminate()
      this.userTimerWorker.worker = undefined
      this.userTimerWorker = undefined
    } else {
      console.error('No web worker for the user activity timer has started yet.')
    }
  }

  private logOut() {
    const transferUrl = `${process.env.VUE_APP_PL_URL}/Logout.aspx`
    window.open(transferUrl, '_self')
  }
}

const userActivityTimerWorker = new UserTimerActivityService()

export default userActivityTimerWorker
