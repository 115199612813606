<template>
  <div>
    <div class="pa-8 container">
      <alert-messages />
      <h2>Contact Information</h2>
      <div class="actions">
        <simple-button color="primary" @click="saveContact" class="mx-2">Save</simple-button>
        <modal-cancel />
        <app-modal
          v-if="allowDelete"
          name="Delete"
          title="Delete Contact"
          :deactivateOnReject="false"
          :maxWidth="modalMaxWidth"
          confirmButtonLabel="Yes"
          rejectButtonLabel="No"
          activatorButtonColor="tertiary"
          @clearAlertMessages="ClearAlertMessages"
          @confirm="deleteContact"
        >
          <v-card-text class="px-6">{{ deleteContactConfirmationMessage }}</v-card-text>
        </app-modal>
      </div>

      <div class="form-fields">
        <app-text-field
          label="First Name"
          @keydown="NoNumberRestrictor"
          @paste="NoNumberPasteRestrictor"
          :maxlength="50"
          :disabled="isContactTypeOwner"
          isRequired
          isBold
          v-model="contact.firstName"
        />
        <app-text-field
          label="Middle Name"
          @keydown="NoNumberRestrictor"
          @paste="NoNumberPasteRestrictor"
          :maxlength="50"
          :disabled="isContactTypeOwner"
          isBold
          v-model="contact.middleName"
        />
        <app-text-field
          label="Last Name"
          @keydown="NoNumberRestrictor"
          @paste="NoNumberPasteRestrictor"
          :maxlength="50"
          :disabled="isContactTypeOwner"
          isRequired
          isBold
          v-model="contact.lastName"
        />
        <app-select label="Suffix" isBold :items="suffixOptions" v-model="contact.suffix" />

        <div class="address-section" v-show="showAddressSection">
          <div class="address">
            <span class="col-md-3 pa-0 bold required">Mailing Address</span>
            <app-text-field
              label="Street"
              @keydown="StreetAddressRestrictor"
              @paste="StreetAddressPasteRestrictor"
              :maxlength="40"
              v-model="contact.mailingAddress.street"
            />
            <app-text-field
              label="City"
              @keydown="CityRestrictor"
              @paste="CityPasteRestrictor"
              :maxlength="20"
              v-model="contact.mailingAddress.city"
            />
            <app-select label="State" :items="stateOptions" v-model="contact.mailingAddress.state" />
            <app-text-field label="Zip" @keydown="IntegersOnly" :maxlength="5" v-model="contact.mailingAddress.zip" />
          </div>

          <v-checkbox class="pa-0" @click="togglePhysicalAddress">
            <span slot="label" class="black--text">Mailing Address same as Physical Address</span>
          </v-checkbox>

          <div class="address">
            <span class="col-md-3 pa-0 bold required">Physical Address</span>
            <app-text-field
              label="Street"
              @keydown="StreetAddressRestrictor"
              @paste="StreetAddressPasteRestrictor"
              :maxlength="40"
              :disabled="isContactPhysicalSame"
              v-model="contact.physicalAddress.street"
            />
            <app-text-field
              label="City"
              @keydown="CityRestrictor"
              @paste="CityPasteRestrictor"
              :maxlength="20"
              :disabled="isContactPhysicalSame"
              v-model="contact.physicalAddress.city"
            />
            <app-select
              label="State"
              :items="stateOptions"
              :disabled="isContactPhysicalSame"
              v-model="contact.physicalAddress.state"
            />
            <app-text-field
              label="Zip"
              @keydown="IntegersOnly"
              :maxlength="5"
              :disabled="isContactPhysicalSame"
              v-model="contact.physicalAddress.zip"
            />
          </div>
        </div>

        <app-date-field
          label="Date of Birth"
          isRequired
          isBold
          min="1900-01-01"
          :max="maxDate"
          :disabled="isContactTypeOwner"
          @set="setValueFromEmit($event)"
          :originalDate="contact.dob"
        />
        <app-text-field
          label="Email"
          isBold
          isRequired
          :rules="[rules.email]"
          v-model="contact.email"
        />
        <phone-field label="Work Phone" isBold v-model="workPhone" />
        <phone-field label="Mobile Phone" isBold v-model="cellPhone" />
        <app-text-field
          label="Social Security Number/Tax ID"
          isBold
          @keydown="IntegersOnly"
          :maxlength="9"
          :disabled="enableSSN"
          v-model="ssn"
        />
        <app-select
          label="Contact Type"
          isBold
          isRequired
          :disabled="isContactTypeOwner"
          :items="contactTypeOptions"
          v-model="contact.type"
        />
        <app-select
          label="Do you want to appoint this contact?"
          isBold
          isRequired
          :disabled="isAppointedActive"
          :items="yesOrNoOptions"
          placeholder="Select an item"
          v-model="contact.appointed"
        />

        <!-- NIS config -->
        <div class="agency-location-grid ma-auto py-2" v-if="enableNisFeatures">
          <p class="legacy-label py-4 bold">Select Locations</p>
          <location-selection-grid
            :v-model="selectedLocations"
            itemKey="agentId"
            :items="locationsWithAssociationData"
            @input="updateSelectedLocations"
            disableAssociated
          />
        </div>

        <div class="admin-privileges" v-if="enableNisFeatures">
          <v-checkbox class="pa-0" v-model="isAdmin">
            <span style="font-weight: bold" slot="label" class="black--text">Admin Privileges</span>
          </v-checkbox>
          <p class="pa-0 mt-n6 ml-8 font-small">Admin users have the ability to change important agency information:</p>
          <p class="pa-0 ml-8 font-small">
            add/remove admin users, change contact information, view reports, and more.
          </p>
        </div>
      </div>

      <v-alert v-if="isEdit" type="info" dense outlined class="py-1 my-6 infoBackground">
        <span>{{ processTimeInfoMessage }} </span>
      </v-alert>

      <div class="actions">
        <simple-button color="primary" @click="saveContact" class="mx-2">Save</simple-button>
        <modal-cancel />
        <app-modal
          v-if="isEdit && !isContactTypeOwner"
          name="Delete"
          title="Delete Contact"
          :deactivateOnReject="false"
          :maxWidth="modalMaxWidth"
          confirmButtonLabel="Yes"
          rejectButtonLabel="No"
          activatorButtonColor="tertiary"
          @clearAlertMessages="ClearAlertMessages"
          @confirm="deleteContact"
        >
          <v-card-text class="px-6">{{ deleteContactConfirmationMessage }}</v-card-text>
        </app-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LocationSelectionGrid from '@/components/agency-self-service/LocationSelectionGrid.vue'
import AppDateField from '@/components/common/input/AppDateField.vue'
import AppSelect from '@/components/common/input/AppSelect.vue'
import AppTextField from '@/components/common/input/AppTextField.vue'
import PhoneField from '@/components/common/input/PhoneField.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import AppModal from '@/components/common/modals/AppModal.vue'
import ModalCancel from '@/components/common/modals/ModalCancel.vue'

import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'

import inputrestrictionrules from '@/constants/inputrestrictionrules'
import store from '@/store'
import { CityPasteRestrictor, CityRestrictor, IntegersOnly, NoNumberPasteRestrictor, NoNumberRestrictor, StreetAddressPasteRestrictor, StreetAddressRestrictor } from '@/utils/InputFilters'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router/composables'

import internalRoutes from '@/constants/internalRoutes'
import verbiage from '@/constants/verbiage'
import { Phone } from '@/models/phone-number'
import { CommonSelectOptions } from '@/models/select-option'
import { ContactGetters } from '@/store/modules/contacts/getters'
import {
  AgentContactRole,
  Contact,
  ContactInformationRequest,
  LicenseTerminationRequest,
  SocialSecurityNumber
} from '@/store/modules/contacts/types'

import { AgencyGetters } from '@/store/modules/agency/getters'
import { ContactActions } from '@/store/modules/contacts/actions'

import enumerations from '@/constants/enumerations'
import { AgencyLocationFields, AgencyLocationGridHeader } from '@/models/data-tables/agency-contacts'
import { AgencyCodeActions } from '@/store/modules/agency-code/actions'
import { AgencyCodeGetters } from '@/store/modules/agency-code/getters'
import { AgencyGroup, AssociatedAgencyInformationInterface } from '@/store/modules/agency-code/type'
import { MigrationStatus } from '@/store/modules/agency/types'
import ClearAlertMessages from '@/utils/ClearAlertMessages'
import Register from '@/utils/ProcessingMaskHelpers'
import ReturnToHomePage from '@/utils/ReturnToHomePage'
import { orderBy } from 'lodash'

export default defineComponent({
  name: 'SaveContact',

  components: {
    AlertMessages,
    AppTextField,
    AppSelect,
    ModalCancel,
    PhoneField,
    SimpleButton,
    AppDateField,
    AppModal,
    LocationSelectionGrid
  },

  setup() {
    const route = useRoute()
    const isContactPhysicalSame = ref<boolean>(false)
    const today = new Date()
    const maxDate = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate()).toISOString().slice(0, 10)
    const rules = inputrestrictionrules.rules
    const agency = store.getters[AgencyGetters.Agency]
    const deleteContactConfirmationMessage = verbiage.deleteSingleContact

    // Set Enable NIS features to true when Agency is partially or fully migrated
    const enableNisFeatures = agency.migrationStatus !== MigrationStatus.NotMigrated

    const stateOptions = computed(() => CommonSelectOptions.GetProducerStateOptions())
    const yesOrNoOptions = computed(() => CommonSelectOptions.GetYesNoOptionsStringValues())
    const suffixOptions = computed(() => CommonSelectOptions.GetSuffixes())
    const contactTypeOptions = computed(() => CommonSelectOptions.GetContactTypesOptions())

    const isEdit = computed<boolean>(
      () => route.meta?.title === internalRoutes.agentSelfServicePortalPageNames.editContact
    )

    const agencyLocationData: Array<AgencyLocationFields> = store.getters[
      AgencyCodeGetters.AgencyGroups
    ].agencyLocationList.map((agency: AgencyGroup) => new AgencyLocationFields(agency))

    const locationsWithAssociationData = ref<Array<AgencyLocationFields>>([])
    const selectedLocations = ref<Array<AgencyLocationFields>>([])

    function updateSelectedLocations(locations: Array<AgencyLocationFields>) {
      selectedLocations.value = locations
    }

    function getSortedAgencyLocationData() {
      if (isEdit.value) return orderBy(agencyLocationData, ['isAssociatedWithCurrentContact'], 'desc')

      return agencyLocationData
    }

    const showAddressSection = !isEdit.value

    const appointedSelection = {
      active: 'true',
      'non-appointed': 'false',
      nonappointed: 'false',
    }

    const contact = computed<Contact>(() => {
      store.dispatch(AlertMessagesActions.ClearMessages)

      if (isEdit.value) {
        const result = computed(() => store.getters[ContactGetters.Contacts].contactList)
        const contactResult = result.value[0]

        for (const [key, value] of Object.entries(appointedSelection)) {
          if (key === contactResult.appointed.toLowerCase()) {
            contactResult.appointed = value
          }
        }
        if (
          new Date(contactResult.dob).valueOf() === new Date('1900-01-01T00:00:00-05:00').valueOf() &&
          contactResult.dob !== '01/01/1900' // avoid turning to empty string when user inputs 01/01/1900 as DOB
        ) {
          contactResult.dob = ''
        }

        return contactResult
      } else {
        store.dispatch(ContactActions.NewContact)
        return store.getters[ContactGetters.Contacts].contactList[0]
      }
    })

    const originalActiveStatus = contact.value.appointed.toLowerCase() === 'true'

    const cellPhone = ref<Phone>(contact.value.cellPhone)
    const workPhone = ref<Phone>(contact.value.workPhone)

    const enableSSN = computed<boolean>(() => isEdit.value && contact.value.ssn.lastFour !== '')
    const ssn = ref<string>(enableSSN.value ? `***-**-${contact.value.ssn.lastFour}` : '')

    const isContactTypeOwner = contact.value.type.toLowerCase() === 'owner'

    const processTimeInfoMessage = isContactTypeOwner ? verbiage.processTimeInfo.editOwnerContact : verbiage.processTimeInfo.editContact

    if (isEdit.value && !isContactTypeOwner) {
      contactTypeOptions.value.pop()
    }

    const isAppointedActive = contact.value.appointed.toLowerCase() === appointedSelection.active
    const emailRequired = computed<boolean>(() => contact.value.appointed.toLowerCase() === appointedSelection.active)
    const mobilePhoneRequired = computed<boolean>(() => !emailRequired.value && contact.value.email.length < 1)

    const allowDelete = isEdit.value && !isContactTypeOwner
    const checkBoxLabels = ['Remove from all locations']

    const returnToHomePage = () => ReturnToHomePage()

    const modalMaxWidth = enumerations.modalWidths.Small

    const hasAdminRole = ((contact.value.role === AgentContactRole.Admin) || (contact.value.role === AgentContactRole.AdminNoCommission))
    const isAdmin = ref<boolean>(hasAdminRole)

    function togglePhysicalAddress() {
      isContactPhysicalSame.value = !isContactPhysicalSame.value
      contact.value.physicalAddress.street = contact.value.mailingAddress.street
      contact.value.physicalAddress.city = contact.value.mailingAddress.city
      contact.value.physicalAddress.state = contact.value.mailingAddress.state
      contact.value.physicalAddress.zip = contact.value.mailingAddress.zip
    }
    const deleteContact = async () => {
      const agency = store.getters[AgencyGetters.Agency]

      const terminationInformation: LicenseTerminationRequest = {
        removedContacts: [
          {
            contactId: contact.value.id,
            contactFirstName: contact.value.firstName,
            contactMiddleName: contact.value.middleName,
            contactLastName: contact.value.lastName,
            terminationEffectiveDate: String(new Date().toISOString().slice(0, 10)),
            removeFromAllLocations: false,
            hasActiveLicense: contact.value.hasActiveLicense,
          },
        ],

        agentId: agency.id,
        princetonAgentCode: agency.princetonAgtCode,
        agencyName: agency.name,
      }

      await Register(store.dispatch(ContactActions.DeleteContact, terminationInformation)).then((deleteResponse) => {
        if (deleteResponse) {
            returnToHomePage()
        }
      })
    }

    const saveContact = async () => {
      store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })

      const social = new SocialSecurityNumber({
        id: !enableSSN.value ? ssn.value : '',
        lastFour: '',
      })

      const agency = store.getters[AgencyGetters.Agency]
      const contactRole = isAdmin.value ? AgentContactRole.Admin : AgentContactRole.NonAdmin // For now if chcekbox is checked the role will be "Admin". Waiting for future reqs for how business would like to set "AdminNoCommission"
      const contactInformation: ContactInformationRequest = {
        additionalAgencyRoles: Object.fromEntries(
          selectedLocations.value.map((agency: AgencyLocationFields) => [agency.agentId, contactRole])
        ),
        agencyName: agency.name,
        agencyStatus: agency.status,
        agentId: agency.id,
        appointed:
          contact.value.appointed === appointedSelection.active
            ? 'Active'
            : contact.value.appointed === appointedSelection.nonappointed
            ? 'Non-Appointed'
            : '',
        cellPhone: cellPhone.value.phoneNumber,
        dob: contact.value.dob,
        email: contact.value.email,
        firstName: contact.value.firstName,
        id: contact.value.id,
        role: contactRole,
        isUpdate: isEdit.value,
        lastName: contact.value.lastName,
        mailingAddress: contact.value.mailingAddress,
        middleName: contact.value.middleName,
        nisUserId: contact.value.nisUserId,
        originalAppointedStatus: originalActiveStatus,
        physicalAddress: contact.value.physicalAddress,
        princetonAgentCode: agency.princetonAgtCode,
        ssn: social,
        suffix: contact.value.suffix,
        type: contact.value.type,
        workPhone: workPhone.value.phoneNumber,
      }

      await Register(store.dispatch(ContactActions.SaveContact, contactInformation)).then((response) => {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById('app')?.scrollIntoView()
        if (response) returnToHomePage()
      })
    }

    const setValueFromEmit = (value: string) => {
      contact.value.dob = value
    }

    const headers = AgencyLocationGridHeader

    async function setContactAssociatedAgencies() {

      if(isEdit.value) {
        await Register(store.dispatch(AgencyCodeActions.GetAssociatedAgencies, contact.value.id)).then((response) => {
          if((response)&&(response.length>0)) {
            const adminAgencyById=new Map<number, AgencyLocationFields>(
              agencyLocationData.map((agency: AgencyLocationFields) => [agency.agentId, agency] as [number, AgencyLocationFields])
            )
            const nisOnly = response.filter((agency: AssociatedAgencyInformationInterface) => agency.userName.length > 0 && contact.value.nisUserId === agency.nisUserId)
            if (nisOnly.length>0) { response = nisOnly }
            response.forEach((associatedAgency: AssociatedAgencyInformationInterface) => {
              if(adminAgencyById.has(associatedAgency.associatedAgentId)) {
                const agencyAdmin=adminAgencyById.get(associatedAgency.associatedAgentId) as AgencyLocationFields
                agencyAdmin.isAssociatedWithCurrentContact=true
                agencyAdmin.associatedContactId=associatedAgency.associatedContactId
              }
            })
          }
        })
      }

      locationsWithAssociationData.value = getSortedAgencyLocationData()

      return true
    }

    onBeforeMount(async () => {
      await setContactAssociatedAgencies()
    })

    return {
      agencyLocationData,
      allowDelete,
      cellPhone,
      checkBoxLabels,
      CityPasteRestrictor,
      CityRestrictor,
      ClearAlertMessages,
      contact,
      contactTypeOptions,
      deleteContact,
      enableNisFeatures,
      enableSSN,
      emailRequired,
      getSortedAgencyLocationData,
      hasAdminRole,
      headers,
      IntegersOnly,
      isAdmin,
      isAppointedActive,
      isEdit,
      isContactPhysicalSame,
      isContactTypeOwner,
      locationsWithAssociationData,
      maxDate,
      mobilePhoneRequired,
      modalMaxWidth,
      NoNumberPasteRestrictor,
      NoNumberRestrictor,
      processTimeInfoMessage,
      rules,
      saveContact,
      showAddressSection,
      setValueFromEmit,
      ssn,
      stateOptions,
      StreetAddressPasteRestrictor,
      StreetAddressRestrictor,
      suffixOptions,
      selectedLocations,
      togglePhysicalAddress,
      updateSelectedLocations,
      workPhone,
      yesOrNoOptions,
      deleteContactConfirmationMessage,
    }
  },
})
</script>

<style lang="scss" scoped>
.container:deep() {
  @import '@/styles/_inputField.scss';
}
</style>
