<template>
  <v-container fluid>
    <v-data-table
      dense
      class="v-card v-sheet ngic-color-override mb-3 text-justify"
      :headers="headers"
      :items="quoteData"
      no-data-text="No results found."
      disable-sort
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :class="itemsPerPage > 15 ? 'scrollable' : ''"
    >
      <template v-slot:[`item.quoteId`]="{ item }">
        <simple-button plain link @click="goToQuoteStatus(item)">
          {{ item.quoteId }}
        </simple-button>
      </template>
      <template v-slot:[`item.princetonAgtCode`]="{ item }">
        {{ item.princetonAgtCode }}
        <simple-tooltip>
          <agent :agency="item.agencyResult"></agent>
        </simple-tooltip>
      </template>
    </v-data-table>
    <simple-pagination
      :itemsPerPage="itemsPerPage"
      :numberOfRecords="quoteData.length"
      :pageCount="pageCount"
      @input="updateItemsPerPage"
      @updatePage="updatePage"
    >
    </simple-pagination>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import store from '@/store'
import Register from '@/utils/ProcessingMaskHelpers'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import SimpleTooltip from '@/components/common/input/SimpleTooltip.vue'
import SimplePagination from '@/components/common/input/SimplePagination.vue'
import Agent from '@/components/common/Agent.vue'
import { Quote, QuoteInterface } from '@/store/modules/client/types'
import { QuoteFields, InternalQuoteGridHeader, ExternalQuoteGridHeader } from '@/models/data-tables/clients'
import { SystemTransferCalloutActions } from '@/store/modules/system-transfer-callouts/actions'
import { PredefinedPath, SystemDomain } from '@/store/modules/system-transfer-callouts/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'

export default defineComponent({
  name: 'ClientViewQuotes',
  components: { SimpleButton, SimpleTooltip, Agent, SimplePagination },
  props: {
    quotes: {
      type: Array as PropType<Array<QuoteInterface>>,
      required: true,
    },
  },
  setup(props) {
    const isAgent = store.getters[UserSessionGetters.IsAgent]
    const headers = isAgent ? ExternalQuoteGridHeader : InternalQuoteGridHeader
    const itemsPerPage = ref<number>(15)
    const page = ref<number>(1)
    const pageCount = ref<number>(0)

    const quoteData = computed(() => {
      return props.quotes.map((quote: Quote) => {
        return new QuoteFields(quote)
      })
    })

    async function goToQuoteStatus(item: Quote) {
      const result: boolean = await Register(
        store.dispatch(SystemTransferCalloutActions.Bridge, {
          destination: PredefinedPath.PLQuoteSummary,
          domain: SystemDomain.PL,
          quoteId: item.quoteId,
          state: item.govState,
          subproduct: item.subproduct,
        })
      )

      return result
    }

    function updateItemsPerPage(currentNumber: number) {
      itemsPerPage.value = currentNumber
    }

    function updatePage(currentPage: number) {
      page.value = currentPage
    }

    return {
      goToQuoteStatus,
      headers,
      quoteData,
      page,
      pageCount,
      itemsPerPage,
      updateItemsPerPage,
      updatePage,
    }
  },
})
</script>
