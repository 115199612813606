import { SelectOption } from '@/models/select-option'
import { FirmographicsInterface } from '@/store/modules/reports/firmographics/types'
import { MutationTree } from 'vuex'
import { ReportsCalloutsInterface } from './types'

/**
 * Mutations for Reports
 */
export class ReportsCalloutsMutations {
  public static SET_CAB_IS_DOWN = 'SET_CAB_IS_DOWN'
  public static SET_FIRMOGRAPHICS_INFO = 'SET_FIRMOGRAPHICS_INFO'
  public static SET_TITLESTATUS = 'SET_TITLESTATUS'
}

/**
 * exporting titleStatus action
 */
export const mutations: MutationTree<ReportsCalloutsInterface> = {
  SET_CAB_IS_DOWN(state: ReportsCalloutsInterface, cabIsDown: boolean) {
    state.cabIsDown = cabIsDown
  },

  SET_FIRMOGRAPHICS_INFO(state: ReportsCalloutsInterface, firmographics: FirmographicsInterface) {
    state.firmographics = firmographics
  },

  SET_TITLESTATUS(state: ReportsCalloutsInterface, titleStatus: Array<SelectOption<string>>) {
    state.titleStatus = titleStatus
  },
}

export default mutations
