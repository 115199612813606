<template>
  <div>
    <v-row no-gutters class="py-3">
      <v-col :cols="labelColumns">
        <div class="legacy-label">{{ label }}</div>
      </v-col>
      <v-col :cols="fieldColumns">
        <input
          class="autocomplete-search"
          type="text"
          v-if="shouldDisplayTextBox"
          id="autocomplete"
          v-model="searchString"
          placeholder="Search by Business Type or SIC/NAICS here..."
          autocomplete="off"
        />
        <span v-else>
          <v-btn
            plain
            class="primary--text pa-0 ma-0 text-capitalize link-style justify-start"
            @mouseover.native="isHovering = true"
            @mouseout.native="isHovering = false"
            :class="{ 'text-decoration-underline': isHovering }"
          >
            {{ currentClient.clientDetail.occupation }}
          </v-btn>
          <v-btn plain x-small text class="pa-0 ma-0">
            <v-icon class="tertiary--text" @click="useSetOccupation('')">{{ iconOptions.close }}</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-row v-if="shouldDisplayDropDownList" class="mt-n5 mb-5">
      <v-col :cols="labelColumns" />
      <v-col :cols="fieldColumns">
        <v-card class="search-list">
          <ul class="search-list-items">
            <li v-for="item in occupationList" :key="item.occupation">
              <simple-button plain link left @click="() => useSetOccupation(item)" :disabled="item.unacceptable">
                {{ item.occupation }}
              </simple-button>
            </li>
          </ul>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import IconOptions from '@/constants/icon-options'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import { OccupationInterface } from '@/store/modules/occupation/types'
import { OccupationFilterGetters } from '@/store/modules/occupation-filter/getters'
import store from '@/store'
import { ClientGetters } from '@/store/modules/client/getters'
import { ClientInterface } from '@/store/modules/client/types'
import { OccupationFilterActions } from '@/store/modules/occupation-filter/actions'
import useSetOccupation from './composables/useSetOccupation'

export default defineComponent({
  name: 'SearchAutocomplete',
  props: {
    label: {
      required: false,
      type: String,
      default: '',
    },
    labelColumns: {
      required: false,
      type: String,
      default: '3',
    },
    fieldColumns: {
      required: false,
      type: String,
      default: '8',
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    'simple-button': SimpleButton,
  },
  setup(props) {
    const currentClient = computed<ClientInterface>(() => store.getters[ClientGetters.NewClient])

    if (props.readOnly) currentClient.value.clientDetail.occupation = ''

    const occupationList = computed<Array<OccupationInterface>>(() => {
      return store.getters[OccupationFilterGetters.Occupations] ?? []
    })

    const searchString = computed<string>({
      get: () => store.getters[OccupationFilterGetters.SearchString],
      set: (value) => {
        store.dispatch(OccupationFilterActions.UpdateSearchString, value)
        store.dispatch(OccupationFilterActions.FilterOccupations)
      },
    })

    const iconOptions = IconOptions
    const buttonText = ref('')
    const isHovering = ref(false)
    const shouldDisplayDropDownList = computed(() => searchString.value.length > 2)
    const shouldDisplayTextBox = computed(
      () =>
        currentClient.value.clientDetail.occupation === '' ||
        currentClient.value.clientDetail.occupation === null ||
        currentClient.value.clientDetail.occupation === undefined
    )

    return {
      buttonText,
      currentClient,
      iconOptions,
      isHovering,
      occupationList,
      searchString,
      shouldDisplayDropDownList,
      shouldDisplayTextBox,
      useSetOccupation,
    }
  },
})
</script>

<style lang="scss" scoped>
.autocomplete-search {
  border: 1px solid grey;
  background: white;
  padding: 6px;
  width: 90%;
}
input[type='text']:focus {
  // This color is the same as the light theme for Vue
  border: 1px solid #005fcc;
  outline: none;
}
.search-list {
  position: absolute;
  z-index: 1000;
  width: fit-content;
  padding: 5px 0px;
  overflow-y: auto;
  max-height: 250px;
}
.search-list-items {
  list-style-type: none;
  margin-right: 30px;
}
</style>
