export default {
  entityType: {
    Corporation: 'Corporation',
    Individual: 'Individual',
    Partnership: 'Partnership',
  },
  product: {
    AH: 'AH',
    AProt: 'AProt',
    CV: 'CV',
    Life: 'Life',
    MC: 'MC',
    PPA: 'PPA',
    Road: 'Road',
    RV: 'RV',
    TProt: 'TProt',
    Trav: 'Trav',
  },
  productVersion: {
    RAD6: 'RAD6',
    RAD6Value: 'RAD6Value',
  },
  subproduct: {
    Golf: 'ATV/Scooters/Golf Cart',
    CV: 'Commercial Auto',
    ILife: 'Individual Life',
    MC: 'Motorcycle',
    PPA: 'Personal Auto',
    LowCost: 'PPA Value',
    RV: 'RV/Motorhome/Trailer',
    Value: 'UniVista Value',
  },
  ancillaryProduct: {
    Life: 'Life',
    Road: 'Road',
    AProt: 'AProt',
    TMED: 'TMED',
    TProt: 'TProt',
    Trav: 'Trav',
    AME: 'AME',
  },
  usdotSelectedOption: {
    No: 'NoValue',
    None: 'None',
    NotRequired: 'NotRequired',
    Yes: 'HasValue',
  },
  appMode: {
    AgencySelfService: 'AgencySelfService',
    ClientSearch: 'ClientSearch',
    ManageAgencyAccountSettings: 'ManageAgencyAccountSettings',
  },
  appName: {
    AgencySelfService: 'Agency Self Service',
    ClientSearch: 'Client Search',
    ClientView: 'Client View',
  },
  clientMode: {
    NewClient: 'New Client',
    ExistingClient: 'Existing Client',
  },
  pageName: {
    ClientEntry: 'Client Entry',
    ClientSearch: 'Client Search',
    ClientView: 'Client View',
    BusinessSearchInfo: 'Business Search Info',
  },
  entryPoint: {
    Client: 'client',
    ClientView: 'client-view',
    ClientEntry: 'client-entry',
    NeedHelp: 'need-help',
    ClientEntryRewrite: 'client-entry-rewrite',
    ClientEntryCrossSell: 'client-entry-crosssell',
  },
  addressType: {
    Business: 'Business',
    Residential: 'Residential',
  },
  emailType: {
    Personal: 'Personal',
    Work: 'Work',
  },
  sortMethodEnum: {
    1: ['firstName', false],
    2: ['firstName', true],
    3: ['lastName', false],
    4: ['lastName', true],
    5: ['appointed', false],
    6: ['appointed', true],
    7: ['email', false],
    8: ['email', true],
    9: ['mobilePhone', false],
    10: ['mobilePhone', true],
  },
  contactTypeEnum: {
    1: 'All',
    2: 'Agent',
    3: 'CSR',
    4: 'Manager & Owner',
  },
  agencyIndicator: {
    Blended: 'blended',
    Preferred: 'preferred',
  },
  emailNotificationCategory: {
    PolicyActivity: 'Policy Activity',
    EligibleToReinstate: 'Eligible To Reinstate',
    Claims: 'Claims',
    NatGenPremierRenewalReport: 'NatGen Premier Renewal Report',
  },
  emailNotificationsHelpText: {
    'Policy Activity': 'Receive automated reports for cancellations, non-renewals, past due payments and more.',
    'Eligible To Reinstate': 'Receive a daily report for policies which are eligible for reinstatement.',
    Claims: 'Receive an alert when a new claim is reported.',
    'NatGen Premier Renewal Report': 'Receive a daily report for renewals that have not been paid.',
  },
  prefillType: {
    CVSameState: 'cvSameState',
    CVDifferentState: 'cvDifferentState',
    NonCVSameState: 'nonCvSameState',
    NonCVDifferentState: 'nonCvDifferentState',
    None: 'none',
  },
  occupationCategories: {
    services: 'Services',
  },
  modalWidths: {
    Small: '35%',
    Medium: '55%',
    Large: '75%',
    Max: '90%',
  },

  userIdColumnStyle: {
    Register: 'teal-text',
    Resend: 'allstate-blue',
  },

  userIdColumnOptions: {
    Register: 'Register',
    Resend: 'Resend',
  },

  environment: {
    Development: 'Dev',
    LiveDebug: 'LiveDebug',
    Production: 'Production',
    ReleaseCandidate: 'RC',
    Staging: 'Staging',
    UserTest: 'UserTest',
  },

  registrationMethod: {
    Email: 'email',
    Mobile: 'mobile',
    None: '',
  },
}
