/* eslint-disable no-console */
import enums from '@/constants/enumerations'
import router from '@/router'
import store from '@/store'
import { ClientActions } from '@/store/modules/client/actions'
import { ClientGetters } from '@/store/modules/client/getters'
import { ClientInterface } from '@/store/modules/client/types'
import { ReportsCalloutsActions } from '@/store/modules/reports-callouts/actions'
import { ReportsCalloutsGetters } from '@/store/modules/reports-callouts/getters'
import {
  FirmographicsCalloutActionResult,
  ReportTransactionCalloutActionResult,
} from '@/store/modules/reports-callouts/types'
import { SystemTransferCalloutActions } from '@/store/modules/system-transfer-callouts/actions'
import { PredefinedPath, SystemDomain } from '@/store/modules/system-transfer-callouts/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import Register from '@/utils/ProcessingMaskHelpers'

export async function GetTransaction(accountHolderId: number, service: string) {
  const result: ReportTransactionCalloutActionResult = await Register(
    store.dispatch(ReportsCalloutsActions.GetReportTransaction, {
      accountHolderId,
      serviceName: service,
    })
  )
  if (result?.isSuccessful) {
    return result
  }
  return null
}

export async function ClientSetupCheck(client: ClientInterface, showWarnings = false) {
  // If there's no AccountHolderID, setup has not been completed
  if (client.accountHolderId === 0) {
    if (showWarnings) console.warn('Commercial Client Setup - No AccountHolderID')
    return false
  }

  if (!client.clientDetail || client.clientDetail.businessEntityType === '') {
    // If there's no ClientDetail or no Entity, setup has not been completed
    if (showWarnings) console.warn('Commercial Client Setup - No ClientDetail')
    return false
  }

  // If the 'Has USDOT#' question has not been answered, setup has not been completed

  if (
    client.clientDetail.clientHasUsdotType === undefined ||
    client.clientDetail.clientHasUsdotType === enums.usdotSelectedOption.None
  ) {
    if (showWarnings) console.warn('Commercial Client Setup - Has USDOT# question not answered')
    return false
  }

  // If 'Has USDOT#' has been answered 'Yes' and the CAB order date is greater than 30 days ago, reorder
  if (
    client.clientDetail.clientHasUsdotType === enums.usdotSelectedOption.Yes &&
    !store.getters[ReportsCalloutsGetters.CabIsDown]
  ) {
    const cabTransaction = await GetTransaction(client.accountHolderId, 'TruckingRisk')
    if (cabTransaction && !cabTransaction.orderInPast30Days) {
      console.warn('Commercial Client Setup - CAB ReOrdered')
      await Register(store.dispatch(ReportsCalloutsActions.OrderCAB, { client, shouldReorder: true }))
    }
  }

  if (router.currentRoute.name !== enums.pageName.ClientEntry && !client.clientDetail.occupation) {
    console.warn('Commercial Client Setup - Business Type not selected')
    return false
  }

  const isFirmographicsOrdered: boolean = store.getters[ReportsCalloutsGetters.FirmographicsTransactionId]?.length
  if (isFirmographicsOrdered) {
    // If Firmographics has been ordered but Business Type selection has not been made and client is an existing being edited,
    // setup has not been completed. UI on BSI page should handle new client case.
    const companyName: string = store.getters[ReportsCalloutsGetters.FirmographicsCompanyName]
    if (companyName?.length && client.clientDetail?.occupation === undefined) {
      if (!client.clientMode) {
        if (showWarnings) console.warn('Commercial Client Setup - Business Type selection has not been made')
        return false
      }
    }
  } else {
    // If conditions are met and Firmographics has not been ordered, setup has not been completed
    if (
      !(
        client.clientDetail.clientHasUsdotType === enums.usdotSelectedOption.Yes &&
        client.clientDetail.motorCarrierForHire
      )
    ) {
      if (showWarnings) console.warn('Commercial Client Setup - Firmographics was not ordered when conditions met')
      return false
    }
  }

  return true
}

export async function GetExistingFirmographicsData(accountHolderId: number) {
  if (!store.getters[ReportsCalloutsGetters.FirmographicsCompanyName]?.length) {
    const item = await GetTransaction(accountHolderId, 'Firmographics')

    if (item) {
      const result: FirmographicsCalloutActionResult = await store.dispatch(
        ReportsCalloutsActions.LoadFirmographics,
        item.transactionId
      )

      if (result) {
        store.dispatch(ReportsCalloutsActions.SetFirmographicsInfo, {
          transactionId: item.transactionId,
          companyName: result.companyName,
          sicCodes: result.sicCodes,
          naicCodes: result.naicCodes,
        })
      }
    }
  }
}

export function ClearFirmographicsData() {
  store.dispatch(ReportsCalloutsActions.SetFirmographicsInfo, {
    transactionId: '',
    companyName: '',
    sicCodes: [],
    naicCodes: [],
  })
  store.dispatch(ClientActions.SetSelectedBusinessName, '')
}

/* REDIRECTS */

export const BridgeToNamedInsured = (
  prefillType: string = enums.prefillType.CVSameState,
  payload: { state: string; subproduct: string; generatedFrom: string; isNewQuote: boolean } | undefined = undefined
) => {
  const quoteNum = payload?.isNewQuote ? 0 : store.getters[UserSessionGetters.QuoteId]
  const policyNum = payload?.isNewQuote ? 0 : store.getters[UserSessionGetters.PolicyId]
  let finalDest = null
  if (quoteNum > 0 || policyNum > 0) {
    finalDest = !store.getters[ClientGetters.NewClient].isCrossSell
      ? PredefinedPath.PLQuoteMain
      : PredefinedPath.PLCrossSell
  } else {
    finalDest = PredefinedPath.PLQuoteNamedInsured
  }
  const isDaStore: boolean = store.getters[UserSessionGetters.IsDaStore]
  Register(
    store.dispatch(SystemTransferCalloutActions.Bridge, {
      destination: finalDest,
      domain: SystemDomain.PL,
      generatedFrom: payload?.generatedFrom,
      prefillType,
      state: payload?.state,
      subproduct: payload?.subproduct,
      quoteId: quoteNum,
      policyId: policyNum,
      isDaStore,
      agentId: store.getters[UserSessionGetters.AgentId],
    })
  )
}

export const RedirectToBusinessSearchInfo = () => {
  router.push({ name: enums.pageName.BusinessSearchInfo, query: router.currentRoute.query })
}

export const RedirectToClientEntry = () => {
  router.push({ name: enums.pageName.ClientEntry, query: router.currentRoute.query })
}

export const RedirectToClientView = () => {
  router.push({ name: enums.pageName.ClientView, query: router.currentRoute.query })
}
