<template>
  <v-row no-gutters class="pb-1">
    <v-autocomplete
      v-model="model"
      :items="items"
      :item-text="textName"
      :item-value="valueName"
      filled
      dense
      outlined
      return-object
      hide-details
      placeholder="Search..."
    >
      <template v-if="loadMore" v-slot:append-item>
        <div v-if="loadMore" v-intersect="onIntersect" @click="onIntersect" class="info--text">
          {{ isIE ? 'Click to load more...' : '' }}
        </div>
      </template>
    </v-autocomplete>
  </v-row>
</template>

<script lang="ts">
import { Email } from '@/store/modules/agency-contact-preferences-emails/types'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'SimpleAutoComplete',
  props: {
    items: {
      required: true,
      type: Array as PropType<Array<Email>>,
    },
    value: {
      required: false,
      type: Object as PropType<Email>,
      default: () => new Email(),
    },
    totalNumberOfItems: {
      required: false,
      type: Number,
      default: 0,
    },
    itemTextName: {
      required: false,
      type: String,
      default: '',
    },
    itemValueName: {
      required: false,
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const model = computed<Email>({
      get: () => props.value ?? '',
      set: (newValue) => context.emit('input', newValue),
    })

    const attrs = computed(() => context.attrs)
    const listeners = computed(() => context.listeners)

    const loadMore = computed<boolean>(() => props.items.length < props.totalNumberOfItems)
    const isIE: boolean = window.navigator.userAgent.indexOf('Trident/') !== -1

    // First page is already populated
    const page = ref<number>(2)
    const textName = computed(() => { return props.itemTextName })
    const valueName = computed(() => { return props.itemValueName })

    function onIntersect() {
      if (loadMore.value) {
        // Call "load more" function in the parent
        context.emit('loadMore', page.value)
        page.value += 1
      }
    }

    return {
      attrs,
      isIE,
      listeners,
      loadMore,
      model,
      onIntersect,
      textName,
      valueName,
    }
  },
})
</script>
