<template>
  <v-container fluid>
    <v-data-table
      v-if="useAgentDisplay()"
      class="v-card v-sheet ngic-color-override my-3"
      :headers="externalHeader"
      :items="clientData"
      no-data-text="No results found."
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :class="itemsPerPage > 15 ? 'scrollable' : ''"
    >
      <template v-slot:[`item.accountHolderId`]="{ item }">
        <simple-button plain link @click="loadSelectedClient(item.accountHolderId)">
          {{ item.accountHolderId }}
        </simple-button>
      </template>
    </v-data-table>
    <v-data-table
      v-else
      class="v-card v-sheet ngic-color-override my-3"
      :headers="internalHeader"
      :items="clientData"
      no-data-text="No results found."
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :class="itemsPerPage > 15 ? 'scrollable' : ''"
    >
      <template v-slot:[`item.accountHolderId`]="{ item }">
        <simple-button plain link @click="loadSelectedClient(item.accountHolderId)">
          {{ item.accountHolderId }}
        </simple-button>
      </template>
    </v-data-table>
    <simple-pagination
      :itemsPerPage="itemsPerPage"
      :numberOfRecords="clientData.length"
      :pageCount="pageCount"
      @input="updateItemsPerPage"
      @updatePage="updatePage"
    >
    </simple-pagination>
  </v-container>
</template>

<script lang="ts">
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import SimplePagination from '@/components/common/input/SimplePagination.vue'
import enums from '@/constants/enumerations'
import { ExternalSearchGridHeader, InternalSearchGridHeader, SearchFields } from '@/models/data-tables/clients'
import store from '@/store'
import { ClientActions } from '@/store/modules/client/actions'
import { ClientGetters } from '@/store/modules/client/getters'
import { Business, Client, ClientDetail, ClientInterface, Email, Person, Phone } from '@/store/modules/client/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import useAgentDisplay from './composables/useAgentDisplay'
import {
  BridgeToNamedInsured,
  ClientSetupCheck,
  GetExistingFirmographicsData,
  RedirectToClientEntry,
} from '@/utils/ClientViewShared'
import Register from '@/utils/ProcessingMaskHelpers'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  components: { SimpleButton, SimplePagination },
  name: 'ClientSearchResults',
  props: {
    clients: {
      type: Array as PropType<Array<ClientInterface>>,
      required: true,
    },
  },
  setup(props) {
    const externalHeader = ExternalSearchGridHeader
    const internalHeader = InternalSearchGridHeader
    const itemsPerPage = ref<number>(15)
    const page = ref<number>(1)
    const pageCount = ref<number>(0)

    const clientData = computed(() => {
      const clientList: Array<ClientInterface> = props.clients
      return clientList.map((client: Client) => {
        return new SearchFields(client)
      })
    })

    function updateItemsPerPage(currentNumber: number) {
      itemsPerPage.value = currentNumber
    }

    function updatePage(currentPage: number) {
      page.value = currentPage
    }

    async function loadSelectedClient(accountHolderId: number) {
      let shouldEdit = false

      await Register(store.dispatch(ClientActions.LoadClient, accountHolderId))

      const client: Client = store.getters[ClientGetters.NewClient]
      const product: string = store.getters[UserSessionGetters.Product]

      if (product === enums.product.CV) {
        await GetExistingFirmographicsData(client.accountHolderId)

        if (!(await ClientSetupCheck(client, true))) {
          if (!client.clientDetail) client.clientDetail = new ClientDetail()
          if (!client.business) client.business = new Business()
          if (!client.person) client.person = new Person()
          if (!client.phoneNumbers) client.phoneNumbers = [new Phone()]
          if (client.phoneNumbers.length === 0) client.phoneNumbers.push(new Phone())
          if (!client.emailAddresses) client.emailAddresses = [new Email()]
          if (client.emailAddresses.length === 0) client.emailAddresses.push(new Email())

          shouldEdit = true
        }
      } else {
        // TODO: This should be what non-CV products should do when loading a client
      }

      await Register(store.dispatch(ClientActions.SetNewClient, client))
      store.dispatch(ClientActions.SetInitialState, store.getters[UserSessionGetters.InitialState])
      store.dispatch(ClientActions.SetProduct, store.getters[UserSessionGetters.Product])
      store.dispatch(ClientActions.SetSubProduct, store.getters[UserSessionGetters.SubProduct])

      if (shouldEdit) {
        RedirectToClientEntry()
      } else {
        BridgeToNamedInsured()
      }
    }

    return {
      clientData,
      externalHeader,
      internalHeader,
      loadSelectedClient,
      useAgentDisplay,
      itemsPerPage,
      page,
      pageCount,
      updateItemsPerPage,
      updatePage,
    }
  },
})
</script>
