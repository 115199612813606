import useApis from '@/api'
import enums from '@/constants/enumerations'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import { UserSessionActions } from '@/store/modules/user-session/actions'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { SubProductInterface, UserStateProductsInterface } from '@/store/modules/user-session/types'

const { standard: api } = useApis()

export default async function (govState: string): Promise<boolean> {
  try {
    const request = {
      agentId: store.getters[UserSessionGetters.AgentId],
      state: govState,
      site: 'PL',
    }
    const result = await api.post('agent_portal/producers/agent/subproducts', request).then()

    if (!result.data?.isSuccessful) {
      store.dispatch(AlertMessagesActions.AddErrorMessage, {
        messageText: 'There was a problem getting list of products.',
        source: AlertMessageSource.General,
      })
      return false
    }

    const ancillaryProducts = Object.values(enums.ancillaryProduct)
    const userStateProducts: UserStateProductsInterface = result.data.value
    store.dispatch(UserSessionActions.UpdateUserProductOptions, {
      userProductOptions: userStateProducts.subProducts.filter(
        (subProduct: SubProductInterface) => !ancillaryProducts.includes(subProduct.subProduct)
      ),
    })

    return true
  } catch (reason) {
    console.error('producers :: stateProductsCallout :: failed', reason)
    return false
  }
}
