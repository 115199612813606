import enumerations from '@/constants/enumerations'
import { MutationTree } from 'vuex'
import { AddressInterface, ClientInterface, ClientListInterface, EmailInterface } from './types'

export class ClientMutations {
  public static SET_CLIENTS = 'SET_CLIENTS'
  public static SET_NEW_CLIENT = 'SET_NEW_CLIENT'
  public static SET_ADDRESS = 'SET_ADDRESS'
  public static SET_DISABLEPREFILLEDFIELDS = 'SET_DISABLEPREFILLEDFIELDS'
  public static SET_EMAILADDRESS = 'SET_EMAILADDRESS'
  public static SET_INITIAL_STATE = 'SET_INITIAL_STATE'
  public static SET_OCCUPATION = 'SET_OCCUPATION'
  public static SET_PRODUCT = 'SET_PRODUCT'
  public static SET_SELECTEDBUSINESSNAME = 'SET_SELECTEDBUSINESSNAME'
  public static SET_SUBPRODUCT = 'SET_SUBPRODUCT'
}

export const mutations: MutationTree<ClientListInterface> = {
  SET_CLIENTS(state: ClientListInterface, clients: Array<ClientInterface>) {
    state.clientList = clients
  },
  SET_NEW_CLIENT(state: ClientListInterface, client: ClientInterface) {
    client.clientMode = enumerations.clientMode.NewClient
    state.newClient = client
    state.clientMode = enumerations.clientMode.NewClient
  },
  SET_DISABLEPREFILLEDFIELDS(state: ClientListInterface, shouldDisable: boolean) {
    state.disablePrefilledFields = shouldDisable
  },
  SET_ADDRESS(state: ClientListInterface, address: AddressInterface) {
    let index: number = state.newClient.addresses.findIndex((a) => a.addressType === address.addressType)
    if (index >= 0) {
      state.newClient.addresses[index] = address
    } else {
      index = state.newClient.addresses.findIndex((a) => a.addressType === '')
      if (index === -1) {
        state.newClient.addresses.push(address)
      } else {
        state.newClient.addresses[index] = address
      }
    }
  },
  SET_EMAILADDRESS(state: ClientListInterface, email: EmailInterface) {
    let index: number = state.newClient.emailAddresses.findIndex((ea) => ea.emailType === email.emailType)
    if (index >= 0) {
      state.newClient.emailAddresses[index] = email
    } else {
      index = state.newClient.emailAddresses.findIndex((ea) => ea.emailType === '')
      if (index === -1) {
        state.newClient.emailAddresses.push(email)
      } else {
        state.newClient.emailAddresses[index] = email
      }
    }
  },
  SET_INITIAL_STATE(state: ClientListInterface, initialState: string) {
    state.newClient.initialState = initialState
  },
  SET_OCCUPATION(state: ClientListInterface, occupation: string) {
    state.newClient.clientDetail.occupation = occupation
  },
  SET_PRODUCT(state: ClientListInterface, product: string) {
    state.newClient.product = product
  },
  SET_SELECTEDBUSINESSNAME(state: ClientListInterface, businessName: string) {
    state.selectedBusinessName = businessName
  },
  SET_SUBPRODUCT(state: ClientListInterface, subProduct: string) {
    state.newClient.subProduct = subProduct
  },
}

export default mutations
