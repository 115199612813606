/** The Alert Message Type */
export enum AlertMessageType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success'
}

/** The Alert Message source. */
export enum AlertMessageSource {
  AddressValidation = 'address-validation',
  ApiValidation = 'api-validation',
  CLUE = 'clue',
  CommercialClientSetup = 'ccs',
  Credit = 'credit',
  General = 'general',
  DHI = 'dhi',
  Lookup = 'lookup',
  MVR = 'mvr',
  Prefill = 'prefill',
  Rules = 'rules',
  Sherlock = 'sherlock',
  USDOTValidation = 'usdot-validation',
  USDOTInfo = 'usdot-info',
  VHR = 'vhr'
}

/** The Alert Message interface */
export interface AlertMessageInterface {
  text: string;
  type: AlertMessageType;
  source: AlertMessageSource;
}

/** The Alert Message model */
export class AlertMessage implements AlertMessageInterface {
  text: string
  type: AlertMessageType
  source: AlertMessageSource

  /** Creates a new instance of an Alert Message */
  constructor (options: { text: string; type: AlertMessageType; source: AlertMessageSource }) {
    this.text = options?.text ?? ''
    this.type = options?.type ?? AlertMessageType.Info
    this.source = options?.source ?? AlertMessageSource.General
  }
}

/** The Alert Messages state */
export interface AlertMessagesInterface {
  alertMessages: Array<AlertMessageInterface>;
}
