import useApis from '@/api'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import { EventBus, Events } from '@/utils/EventBus'
import { ActionTree } from 'vuex'
import { ContactsMutations } from './mutations'
import {
  Contact,
  ContactInformationRequest,
  ContactInterface,
  ContactListByBatch,
  ContactListInformationInterface,
  LicenseTerminationRequest,
  RegisterUserInformation,
  RegisterUserRequest,
} from './types'
export class ContactActions {
  /**
   * gets the contacts information using the agent id in session
   */
  public static GetContactsFromSession = 'contacts/getContactsFromSession'
  /**
   * gets the contacts information using the agent id passed in
   */
  public static GetContactsById = 'contacts/getContactsById'
  /**
   * filters contact list using the contact id passed in
   */
  public static FilterContact = 'contacts/filterContact'
  /**
   * clear contact list
   */
  public static NewContact = 'contacts/newContact'
  /**
   * saves the contact information passed in
   */
  public static SaveContact = 'contacts/saveContact'
  /**
   * deletes the contact
   */
  public static DeleteContact = 'contacts/deleteContact'
  /**
   * sends the registration link request for the contact
   */
  public static RegisterContact = 'contacts/registerContact'
  /**
   * sends the enable NIS account request
   */
  public static EnableNisAccount = 'contacts/enableNisAccount'
}

const { standard: api } = useApis()

export const actions: ActionTree<ContactListInformationInterface, RootState> = {
  async getContactsFromSession(
    { commit },
    payload: { itemsPerPage: number; pagination: number; sortMethod: number; contactType: number }
  ): Promise<boolean> {
    try {
      const agentId = store.getters[UserSessionGetters.AgentId]
      const paginationForCallout = payload.pagination - 1
      const baseUrl = 'agent_portal/producers/v2/'
      const response = await api.get(
        `${baseUrl}contacts/${agentId}?itemsPerPage=${payload.itemsPerPage}&pagination=${paginationForCallout}&sortMethod=${payload.sortMethod}&contactType=${payload.contactType}`
      )

      const totalNumberOfContactsFromResponse = response.data.value.totalNumberOfContacts
      const contactListFromResponse: Array<ContactInterface> = response.data.value.contacts.map((contact: Contact) => {
        return new Contact(contact)
      })
      const contactListInformation: ContactListByBatch = {
        totalNumberOfContacts: totalNumberOfContactsFromResponse,
        contactList: contactListFromResponse,
      }

      commit(ContactsMutations.SET_CONTACTS, contactListInformation)
      return true
    } catch (reason) {
      console.error('agencyHome :: getContactsFromSession :: failed:', reason)

      const contactListInformation: ContactListByBatch = {
        totalNumberOfContacts: 0,
        contactList: [],
      }

      commit(ContactsMutations.SET_CONTACTS, contactListInformation)

      return false
    }
  },
  async getContactsById(
    { commit },
    payload: { agentId: number; itemsPerPage: number; sortMethod: number; contactType: number }
  ) {
    try {
      const baseUrl = 'agent_portal/producers/v2/'
      const response = await api.get(
        `${baseUrl}contacts/${payload.agentId}?itemsPerPage=${payload.itemsPerPage}&pagination=0&sortMethod=${payload.sortMethod}&contactType=${payload.contactType}`
      )

      const totalNumberOfContactsFromResponse = response.data.value.totalNumberOfContacts
      const contactListFromResponse: Array<ContactInterface> = response.data.value.contacts.map((contact: Contact) => {
        return new Contact(contact)
      })

      const contactListInformation: ContactListByBatch = {
        totalNumberOfContacts: totalNumberOfContactsFromResponse,
        contactList: contactListFromResponse,
      }

      commit(ContactsMutations.SET_CONTACTS, contactListInformation)
      EventBus.emit(Events.updateTotalNumberOfContacts, contactListInformation.totalNumberOfContacts)
    } catch (reason) {
      console.error('agencyHome :: getContactsById :: failed:', reason)

      const contactListInformation: ContactListByBatch = {
        totalNumberOfContacts: 0,
        contactList: [],
      }

      commit(ContactsMutations.SET_CONTACTS, contactListInformation)
      EventBus.emit(Events.updateTotalNumberOfContacts, contactListInformation.totalNumberOfContacts)

      return false
    }
  },
  async saveContact(_context, contactInformation: ContactInformationRequest) {
    try {
      const response = await api.put('agent_portal/producers/contact_information', contactInformation)
      return response.data.isSuccessful
    } catch (reason) {
      console.error('saveContact :: saveContact :: failed:', reason)
      return false
    }
  },
  async deleteContact(_context, terminationInformation: LicenseTerminationRequest) {
    try {
      const response = await api.delete('agent_portal/producers/contact_license', { data: terminationInformation })
      return response.data.isSuccessful
    } catch (reason) {
      console.error('saveContact :: deleteContact :: failed:', reason)
      return false
    }
  },
  async registerContact(_context, registerUserInformation: RegisterUserInformation) {
    try {
      const registerUserRequest = new RegisterUserRequest(registerUserInformation)
      const response = await api.post('agent_portal/nis/registration', registerUserRequest)
      return response.data.isSuccessful
    } catch (reason) {
      console.error('agencyHome : registerContact :: failed:', reason)
      return false
    }
  },
  filterContact({ commit }, contactId: number) {
    commit(ContactsMutations.FILTER_CONTACT, contactId)
  },
  newContact({ commit }) {
    commit(ContactsMutations.NEW_CONTACT)
  },
  async enableNisAccount(_context, userId: string) {
    try {
      const response = await api.put(`agent_portal/nis/enable/${userId}`)
      return response.data.isSuccessful
    } catch (reason) {
      console.error('contact : enableNisUser :: failed:', reason)
      return false
    }
  },
}
