const userActivityTimer = () => {
  // Making the conversion to milliseconds more readable
  const hours = 3
  const minutes = 5
  const seconds = 0
  const milliseconds = ((hours * 60 + minutes) * 60 + seconds) * 1000
  setInterval(() => {
    postMessage('')
  }, milliseconds)
}

export default userActivityTimer
