import useApis from '@/api'
import { InternalSalesApiResponse } from '@/store/modules/internal-sales-callouts/types'

const { standard: api } = useApis()

export default async function (userName: string): Promise<boolean> {
  try {
    const internalSalesCallout = await api.post('lookup/cost_center', { UserSamAccount: userName })

    const internalSalesResponse: InternalSalesApiResponse = internalSalesCallout.data
    return internalSalesResponse.value?.toLowerCase() !== 'service'
  } catch (reason) {
    console.error('lookup :: internalSalesCallout :: failed', reason)
    return true // A value of 'true' restricts visibility and should be the default.
  }
}
