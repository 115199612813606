import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import { BusinessSearchActions } from '@/store/modules/business-search/actions'
import { BusinessSearchGetters } from '@/store/modules/business-search/getters'
import { ClientGetters } from '@/store/modules/client/getters'
import { ClientInterface } from '@/store/modules/client/types'
import { OccupationSearchActions } from '@/store/modules/occupation-search/actions'
import { OccupationSearchGetters } from '@/store/modules/occupation-search/getters'
import { OccupationInterface } from '@/store/modules/occupation/types'
import { ReportsCalloutsGetters } from '@/store/modules/reports-callouts/getters'
import { NaicInterface, SicInterface } from '@/store/modules/reports/firmographics/types'
import Register from '@/utils/ProcessingMaskHelpers'
import { computed } from 'vue'

async function GetOccupations(currentClient: ClientInterface, forceUnfiltered: boolean): Promise<boolean> {
  const isReadOnly: boolean = store.getters[BusinessSearchGetters.ReadOnly]
  const mcfhReadOnly: boolean = store.getters[BusinessSearchGetters.MCFHReadOnly]
  let naicCodes = []

  let sicCodes = []

  if (!forceUnfiltered && !store.getters[BusinessSearchGetters.ShowHelp]) {
    naicCodes =
      store.getters[ReportsCalloutsGetters.FirmographicsNaicCodes]?.map((code: NaicInterface) => code.naic) ?? []

    sicCodes = store.getters[ReportsCalloutsGetters.FirmographicsSicCodes]?.map((code: SicInterface) => code.sic) ?? []
  }

  const result: Array<OccupationInterface> = await Register(
    store.dispatch(OccupationSearchActions.SearchOccupations, {
      naicFilters: naicCodes,
      sicFilters: sicCodes,
      motorCarrier: isReadOnly ? mcfhReadOnly : Boolean(currentClient.clientDetail.motorCarrierForHire),
      state: currentClient.initialState,
      product: currentClient.product
    })
  )

  if (!result) {
    store.dispatch(AlertMessagesActions.AddErrorMessage, {
      messageText: 'There was a problem getting list of business types.',
      source: AlertMessageSource.Lookup,
    })
    return false
  }
  return true
}

/**
 * Dispatches the action to fetch occupations which updates state.
 * Dispatches the action to update error
 * @returns a boolean indicating success of the fetch request
 */
export default async function (): Promise<boolean> {
  const currentClient = computed<ClientInterface>(() => {
    return store.getters[ClientGetters.NewClient]
  })

  const result = await GetOccupations(currentClient.value, false)

  if (!result) {
    return false
  }

  const occupations = store.getters[OccupationSearchGetters.OccupationResults]

  if (!occupations.length) {
    store.dispatch(BusinessSearchActions.SetShowHelp, true)
    const result = await GetOccupations(currentClient.value, true)

    if (!result) {
      return false
    }
  }

  return true
}
