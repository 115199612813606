import { EmptyGuid, InactiveUserWorker } from '@/constants/Strings'
import verbiage from '@/constants/verbiage'
import { AgencyGroup } from '@/store/modules/agency-code/type'
import { AgentContactRole, Contact } from '@/store/modules/contacts/types'

export const ContactListGridHeader = [
  { text: 'Edit', value: 'actions', sortable: false, width: '3.75%' },
  { text: 'First Name', align: 'start', value: 'firstName', width: '9%' },
  { text: 'Last Name', align: 'start', value: 'lastName', width: '9%' },
  { text: 'Appt.', value: 'appointed', width: '7%' },
  { text: 'Admin', value: 'isAdmin', width: '7%' },
  { text: 'Email', value: 'email', width: '12%' },
  { text: 'Mobile', value: 'mobilePhone', width: '10%'  },
  { text: 'User ID', value: 'userIdStatus', width: '12%' },
  { text: 'Login Status', value: 'loginStatus', width: '10%'},
  { text: '', value: 'enabled', sortable: false, width: '5%' },
  { text: 'Delete', value: 'data-table-select', width: '4.75%' },
]

export const AgencyLocationGridHeader = [
  { text: '', value: 'data-table-select' },
  { text: 'Agency Code', align: 'start', value: 'agencyCode', width: '12%' },
  { text: 'Agency Status', align: 'start', value: 'agencyStatus', width: '12%' },
  { text: 'Agency Name', align: 'start', value: 'agencyName', width: '30%' },
  { text: 'Agency Location', value: 'agencyLocation' },
]

export const TabHeader = {
  agent: 'Agent',
  csr: 'CSR',
  managerAndOwner: 'Manager & Owner',
}

export interface ContactListFieldsInterface {
  id: number
  dob: string
  appointed: string
  firstName: string
  middleName: string
  lastName: string
  email: string
  mobilePhone: string
  type: string
  hasActiveLicense: boolean
  userIdStatus: string
  registrationRequestMedium: string
  isAdmin: string
  loginStatus: string
  enabled: boolean
  disabledMessage: string
}

export class ContactListFields implements ContactListFieldsInterface {
  id: number
  dob: string
  appointed: string
  firstName: string
  middleName: string
  lastName: string
  email: string
  mobilePhone: string
  type: string
  hasActiveLicense: boolean
  userIdStatus: string
  registrationRequestMedium: string
  nisUserId: string
  isAdmin: string
  loginStatus: string
  enabled: boolean
  disabledMessage: string

  constructor(contact: Contact) {
    this.id = contact.id ?? ''
    this.dob = contact.dob ?? ''
    this.appointed = contact.appointed.toLowerCase() === 'active' ? 'Yes' : 'No'
    this.firstName = contact.firstName ?? ''
    this.middleName = contact.middleName ?? ''
    this.lastName = contact.lastName ?? ''
    this.email = contact.email ?? ''
    this.mobilePhone = contact.cellPhone.formattedPhoneNumber ?? ''
    this.type = contact.type ?? ''
    this.hasActiveLicense = contact.hasActiveLicense ?? false
    this.userIdStatus = contact.userIdStatus ?? ''
    this.registrationRequestMedium = contact.registrationRequestMedium ?? ''
    this.nisUserId = contact.nisUserId ?? EmptyGuid
    this.isAdmin =
      contact.role === AgentContactRole.Admin || contact.role === AgentContactRole.AdminNoCommission ? 'Yes' : 'No'
    this.loginStatus = contact.nisUserId === EmptyGuid ? '' : (contact.enabled ? 'Active' : 'Disabled') 
    this.enabled = contact.enabled
    this.disabledMessage =
      contact.lastStatusChangedBy === InactiveUserWorker ? `${verbiage.disabledForInactivity} ${contact.lastStatusChangeDate}` : `${verbiage.disabledManually} ${contact.lastStatusChangeDate}`;
  }
}

export interface AgencyLocationFieldsInterface {
  agencyCode: string
  agentId: number
  agencyStatus: string
  agencyName: string
  agencyLocation: string
  isAssociatedWithCurrentContact: boolean
  associatedContactId: number | null
  userId: string
  userName: string
}

export class AgencyLocationFields implements AgencyLocationFieldsInterface {
  agencyCode: string
  agentId: number
  agencyStatus: string
  agencyName: string
  agencyLocation: string
  isAssociatedWithCurrentContact: boolean
  associatedContactId: number | null
  userId: string
  userName: string

  constructor(agencyLocation: AgencyGroup) {
    this.agencyCode = agencyLocation.princetonCode ?? ''
    this.agentId = agencyLocation.id ?? 0
    this.agencyStatus = agencyLocation.status ?? ''
    this.agencyName = agencyLocation.name ?? ''
    this.agencyLocation = agencyLocation.streetAddress ?? ''
    this.isAssociatedWithCurrentContact = agencyLocation.isAssociatedWithCurrentContact ?? false
    this.associatedContactId = agencyLocation.associatedContactId ?? null
    this.userId = agencyLocation.userId ?? ''
    this.userName = agencyLocation.userName ?? ''
  }
}
