/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <v-container>
    <h4>{{ title }}</h4>
    <v-card-text class="px-0 py-2"> {{ text }} </v-card-text>

    <search-autocomplete ref="autocompleteRef" label="Enter Business Type selection:" :readOnly="isReadOnly" />

    <div v-if="!isReadOnly">
      <v-row v-if="showHelp" class="mb-3">
        <v-col cols="10">
          <v-row dense class="font-weight-bold mt-3 pb-2">
            <v-col cols="1"> </v-col>
            <v-col cols="9"> {{ categoryTitle }} </v-col>
            <v-col cols="2"> Include </v-col>
          </v-row>
          <v-row no-gutters class="ma-0 pa-0 plain" v-for="(item, index) in categoriesToDisplay" :key="index">
            <v-col cols="1">
              <v-icon
                v-if="item.category === services && !showSubCategories"
                class="material-icons get-click"
                align="right"
                @click="toggleSubCategories"
              >
                {{ iconOptions.menuRight }}
              </v-icon>
              <v-icon
                v-if="item.category === services && showSubCategories"
                class="material-icons get-click"
                align="right"
                @click="toggleSubCategories"
              >
                {{ iconOptions.menuDown }}
              </v-icon>
            </v-col>
            <v-col cols="9" class="pl-0">
              <span class="uppercase">{{ item.category }}</span>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                hide-details
                :input-value="item.checked"
                @change="updateSelectedCategories($event, item)"
                class="pl-6 mr-0 row--dense"
              />
            </v-col>
            <ul class="pb-2" v-if="item.category === services && showSubCategories">
              <v-col cols="12" class="py-0">
                <v-row
                  no-gutters
                  class="ma-0 pa-0 plain"
                  v-for="(subCategory, index) in subCategoriesToDisplay"
                  :key="index"
                >
                  <v-col cols="1" class="px-0"> </v-col>
                  <v-col cols="9" class="pa-0 ml-8">
                    <span>{{ subCategory.name }}</span>
                  </v-col>
                  <v-col cols="1" class="pl-3">
                    <v-checkbox
                      hide-details
                      :input-value="subCategory.checked"
                      @change="updateSelectedSubCategories($event, subCategory)"
                      class="pl-16 row--dense"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </ul>
          </v-row>
        </v-col>
        <v-col cols="1" align="right">
          <simple-button class="mt-8" color="secondary" @click="selectAllCategories">Select All</simple-button>
          <simple-button class="mt-4" color="secondary" @click="() => selectAllCategories(false)">
            Clear All
          </simple-button>
        </v-col>
      </v-row>

      <div v-else>
        <v-row dense class="font-weight-bold mt-3">
          <v-col cols="8"> {{ categoryTitle }} </v-col>
          <v-col cols="2"> Include </v-col>
        </v-row>
        <v-row no-gutters class="ma-0 pa-0 plain" v-for="(item, index) in categoriesToDisplay" :key="index">
          <v-col cols="8" class="pl-0">
            <span class="uppercase">{{ item.category }}</span>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              hide-details
              :input-value="item.checked"
              @change="updateSelectedCategories($event, item)"
              class="pl-8 row--dense"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="8">
            If none of the above Categories apply, please click <b>Need Help</b> for other options.
          </v-col>
          <v-col align="right">
            <simple-button color="secondary" @click="showBusinessTypeHelp">Need Help</simple-button>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import SearchAutocomplete from '@/components/client/SearchAutocomplete.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import enums from '@/constants/enumerations'
import IconOptions from '@/constants/icon-options'
import verbiage from '@/constants/verbiage'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { BusinessSearchActions } from '@/store/modules/business-search/actions'
import { BusinessSearchGetters } from '@/store/modules/business-search/getters'
import { ClientActions } from '@/store/modules/client/actions'
import { OccupationFilterActions } from '@/store/modules/occupation-filter/actions'
import { OccupationFilterGetters } from '@/store/modules/occupation-filter/getters'
import { OccupationInterface, SubCategory } from '@/store/modules/occupation/types'
import { computed, defineComponent, ref } from 'vue'
import { selectAllCategories, selectAllSubCategories } from './composables/useToggleSelectedCategories'

export default defineComponent({
  name: 'BusinessTypeSelection',
  components: { SimpleButton, SearchAutocomplete },
  setup() {
    const iconOptions = IconOptions
    const services = enums.occupationCategories.services
    const showSubCategories = ref<boolean>(false)
    const autocompleteRef = ref()
    const sectionText = verbiage.bsiText
    const showHelp = computed<boolean>(() => {
      return store.getters[BusinessSearchGetters.ShowHelp]
    })

    const isReadOnly = Boolean(store.getters[BusinessSearchGetters.ReadOnly])

    const categoryTitle = computed<string>(() => {
      return showHelp.value ? sectionText.section4CategoryTitle : sectionText.section2CategoryTitle
    })

    const title = computed<string>(() => {
      const section4Title = isReadOnly ? sectionText.needHelpReadOnlyTitle : sectionText.section4Title
      return showHelp.value ? section4Title : sectionText.section2Title
    })

    const text = computed<string>(() => {
      const section4Text = isReadOnly ? sectionText.needHelpReadOnlyText : ''
      return showHelp.value ? section4Text : sectionText.section2Text
    })

    const toggleSubCategories = () => {
      showSubCategories.value = !showSubCategories.value
    }

    const categoriesToDisplay = computed<Array<OccupationInterface>>(() => {
      return store.getters[OccupationFilterGetters.SelectedCategories]
    })

    const subCategoriesToDisplay = computed<Array<SubCategory>>(() => {
      return store.getters[OccupationFilterGetters.SelectedSubCategories]
    })

    const occupationsToDisplay = computed<Array<OccupationInterface>>(() => {
      return store.getters[OccupationFilterGetters.Occupations]
    })

    function updateSelectedSubCategories(value: boolean, subCategory: SubCategory) {
      subCategory.checked = value

      let isNoneChecked = true
      subCategoriesToDisplay.value.forEach((item) => {
        if(isNoneChecked && item.checked)
        {
          isNoneChecked = false
        }
      })

      // Since it seems like the only category with sub-categories is services...
      categoriesToDisplay.value.forEach((item) => {
        if(item.category === services)
        {
          item.checked = !isNoneChecked;
        }
      })

      store.dispatch(OccupationFilterActions.UpdateSelectedCategories, categoriesToDisplay.value)
      store.dispatch(OccupationFilterActions.UpdateSelectedSubCategories, subCategoriesToDisplay.value)
      store.dispatch(OccupationFilterActions.FilterOccupations)
    }

    function updateSelectedCategories(value: boolean, category: OccupationInterface) {
      category.checked = value
      if (category.category === services) selectAllSubCategories(value)
      store.dispatch(OccupationFilterActions.UpdateSelectedCategories, categoriesToDisplay.value)
      store.dispatch(OccupationFilterActions.FilterOccupations)
    }

    function showBusinessTypeHelp() {
      store.dispatch(AlertMessagesActions.ClearMessages)
      store.dispatch(ClientActions.SetOccupation, '')
      store.dispatch(BusinessSearchActions.SetShowHelp, true)
      store.dispatch(BusinessSearchActions.SetShowCancelSave, true)
      store.dispatch(OccupationFilterActions.ResetModule)
    }

    return {
      autocompleteRef,
      categoriesToDisplay,
      categoryTitle,
      showBusinessTypeHelp,
      iconOptions,
      isReadOnly,
      occupationsToDisplay,
      selectAllCategories,
      selectAllSubCategories,
      services,
      showHelp,
      showSubCategories,
      subCategoriesToDisplay,
      text,
      title,
      toggleSubCategories,
      updateSelectedCategories,
      updateSelectedSubCategories,
    }
  },
})
</script>
