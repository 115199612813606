<template>
  <div v-if="loading" class="mx-4">
    <alert-messages />
    <AgencyCode :agencyGroups="dataSet.agencyGroups" :agentIsHomeOffice="agentIsHomeOffice" />
    <AgencyInfo :agency="dataSet.agency" :comprater="dataSet.comprater" />
    <EmailNotifications :emailNotifications="dataSet.emailNotifications" />
    <Contacts :contacts="dataSet.contacts" />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import store from '@/store'

import AgencyCode from '@/components/agency-self-service/AgencyCode.vue'
import AgencyInfo from '@/components/agency-self-service/AgencyInfo.vue'
import Contacts from '@/components/agency-self-service/Contacts.vue'
import EmailNotifications from '@/components/agency-self-service/EmailNotifications.vue'

import { AgencyCodeActions } from '@/store/modules/agency-code/actions'
import { AgencyActions } from '@/store/modules/agency/actions'
import { AgencyContactPreferencesEmailsActions } from '@/store/modules/agency-contact-preferences-emails/actions'
import { CompraterActions } from '@/store/modules/comprater/actions'
import { ContactActions } from '@/store/modules/contacts/actions'

import { AgencyCodeGetters } from '@/store/modules/agency-code/getters'
import { AgencyGetters } from '@/store/modules/agency/getters'
import { AgencyContactPreferencesEmailsGetters } from '@/store/modules/agency-contact-preferences-emails/getters'
import { CompraterGetters } from '@/store/modules/comprater/getters'
import { ContactGetters } from '@/store/modules/contacts/getters'
import { UserSessionGetters } from '@/store/modules/user-session/getters'

import AlertMessages from '@/components/common/AlertMessages.vue'

import Register from '@/utils/ProcessingMaskHelpers'
import userActivityTimerWorker from '@/utils/userActivityTimerWorker'
import trackUserActivity from '@/utils/userActivityTracking'

export default defineComponent({
  name: 'AgencyHome',
  components: {
    AgencyCode,
    AgencyInfo,
    AlertMessages,
    Contacts,
    EmailNotifications,
  },
  setup() {
    const dataSet = reactive({
      agencyGroups: {},
      agency: {},
      contacts: {},
      comprater: {},
      emailNotifications: {},
    })
    const loading = ref(false)
    const agentIsHomeOffice = ref(false)

    function loadDataSet() {
      agentIsHomeOffice.value = store.getters[UserSessionGetters.IsHomeOffice]

      return Promise.all([
        store.dispatch(AgencyActions.GetAgencyFromSession).then(() => store.dispatch(AgencyCodeActions.GetAgencyGroup)),
        store.dispatch(CompraterActions.GetCompraterFromSession),
        store.dispatch(AgencyContactPreferencesEmailsActions.GetAgencyContactPreferencesEmailsFromSession),
        store.dispatch(AgencyContactPreferencesEmailsActions.ClearEmailListFromSession),
        store.dispatch(ContactActions.GetContactsFromSession, {
          itemsPerPage: 50,
          pagination: 1,
          sortMethod: 1,
          contactType: 2,
        }),
      ])
    }

    Register(
      loadDataSet().then(() => {
        dataSet.agencyGroups = store.getters[AgencyCodeGetters.AgencyGroups]
        dataSet.agency = store.getters[AgencyGetters.Agency]
        dataSet.emailNotifications = store.getters[AgencyContactPreferencesEmailsGetters.EmailNotifications]
        dataSet.contacts = store.getters[ContactGetters.Contacts]
        dataSet.comprater = store.getters[CompraterGetters.Comprater]
        loading.value = true
        userActivityTimerWorker.startUserTimerWorker()
      })
    ).then(() => trackUserActivity())

    return {
      loading,
      agentIsHomeOffice,
      dataSet,
    }
  },
})
</script>
