/**
 * The image interface.
 */
 export interface ImageInterface {
    path: string;
    alt?: string;
    displayOrder?: number;
    width?: number;
    height?: number;
  }
  
  /**
   * The image model.
   * Implements @see ImageInterface.
   */
  export class Image implements ImageInterface {
    path: string;
    alt?: string;
    displayOrder?: number;
    width?: number;
    height?: number;
  
    /**
     * Creates a new instance of @see Image.
     * @constructor
     * @param {Object} options the options object.
     */
    constructor (options?: {
      path: string;
      alt?: string;
      displayOrder?: number;
      width?: number;
      height?: number;
    }) {
      this.path = options?.path ?? ''
      this.alt = options?.alt ?? ''
      this.displayOrder = options?.displayOrder
      this.width = options?.width
      this.height = options?.height
    }
  }
  
  /**
   * The image state interface.
   */
  export interface ImageStoreInterface {
    images: Array<ImageInterface>;
  }
  