import { ActionTree } from 'vuex'

import { RootState } from '@/store/types'
import { AlertMessagesMutations } from './mutations'
import { AlertMessage, AlertMessageInterface, AlertMessagesInterface, AlertMessageSource, AlertMessageType } from './types'

export class AlertMessagesActions {
  /** Adds a single message to the alerts component */
  public static AddMessage = 'alertMessages/addMessage'

  /** Adds a single info message to the alerts component */
  public static AddInfoMessage = 'alertMessages/addInfoMessage'

  /** Adds a single error message to the alerts component */
  public static AddErrorMessage = 'alertMessages/addErrorMessage'

  /** Adds messages to the alerts component. */
  public static AddMessages = 'alertMessages/addMessages'

  /** Clears the messages from the alerts component. */
  public static ClearMessages = 'alertMessages/clearMessages'

  /** Clears the messages of a single source from the alerts component. */
  public static ClearMessagesBySource = 'alertMessages/clearMessagesBySource'
}

export const actions: ActionTree<AlertMessagesInterface, RootState> = {
  addMessage ({ commit }, payload: { message: AlertMessageInterface }): void {
    commit(AlertMessagesMutations.ADD_MESSAGE, payload.message)
  },
  addInfoMessage ({ commit }, payload: { messageText: string; source?: AlertMessageSource }) {
    commit(
      AlertMessagesMutations.ADD_MESSAGE,
      new AlertMessage({
        text: payload.messageText,
        type: AlertMessageType.Info,
        source: payload.source ?? AlertMessageSource.General
      }))
  },
  addErrorMessage ({ commit }, payload: { messageText: string; source?: AlertMessageSource }): void {
    commit(
      AlertMessagesMutations.ADD_MESSAGE,
      new AlertMessage({
        text: payload.messageText,
        type: AlertMessageType.Error,
        source: payload.source ?? AlertMessageSource.General
      }))
  },
  addMessages ({ commit }, payload: { messages: Array<AlertMessageInterface> }): void {
    commit(AlertMessagesMutations.ADD_MESSAGES, payload.messages)
  },
  clearMessages ({ commit }) {
    commit(AlertMessagesMutations.CLEAR_MESSAGES)
  },
  clearMessagesBySource ({ commit }, payload: { source: AlertMessageSource }) {
    commit(AlertMessagesMutations.CLEAR_MESSAGESBYSOURCE, payload)
  }
}

export default actions
