import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { ReportsCalloutsInterface } from './types'

/**
 * Reports callouts getters including TitleStatus
 */
export class ReportsCalloutsGetters {
  public static CabIsDown = 'reportsCallouts/getCabIsDown'
  public static FirmographicsCompanyName = 'reportsCallouts/getFirmographicsCompanyName'
  public static FirmographicsNaicCodes = 'reportsCallouts/getFirmographicsNaicCodes'
  public static FirmographicsSicCodes = 'reportsCallouts/getFirmographicsSicCodes'
  public static FirmographicsTransactionId = 'reportsCallouts/getFirmographicsTransactionId'
  public static TitleStatus = 'reportsCallouts/getTitleStatus'
}

/**
 * exporting getTitleStatus getter.
 */
export const getters: GetterTree<ReportsCalloutsInterface, RootState> = {
  getTitleStatus(state: ReportsCalloutsInterface) {
    return state.titleStatus
  },

  getFirmographicsCompanyName(state: ReportsCalloutsInterface) {
    return state.firmographics.companyName
  },

  getFirmographicsSicCodes(state: ReportsCalloutsInterface) {
    return state.firmographics.sicCodes
  },

  getFirmographicsNaicCodes(state: ReportsCalloutsInterface) {
    return state.firmographics.naicCodes
  },

  getFirmographicsTransactionId(state: ReportsCalloutsInterface) {
    return state.firmographics.transactionId
  },

  getCabIsDown(state: ReportsCalloutsInterface) {
    return state.cabIsDown
  },
}

export default getters
