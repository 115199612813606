import { MutationTree } from 'vuex'
import { NavigationInformationInterface, NavigationItemWithChildrenInterface } from './types'

export class NavigationMutations {
  public static SET_MAIN_NAVIGATION_ITEMS = 'SET_MAIN_NAVIGATION_ITEMS'
}

export const mutations: MutationTree<NavigationInformationInterface> = {
  SET_MAIN_NAVIGATION_ITEMS(state: NavigationInformationInterface, items: Array<NavigationItemWithChildrenInterface>) {
    state.mainNavigationItems = items
  }
}

export default mutations
