import useApis from '@/api'
import { ApiResponse } from '@/models/api'
import store from '@/store'
import { Address, AddressInterface, ClientInterface } from '@/store/modules/client/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { FirmographicsInterface } from '../reports/firmographics/types'
import { ReportsCalloutsMutations } from './mutations'
import {
  FirmographicsApiResponse,
  FirmographicsCalloutActionResult,
  OrderCabApiResponse,
  OrderCabCalloutActionResult,
  ReportTransactionApiResponse,
  ReportTransactionCalloutActionResult,
  ReportsCalloutsInterface,
  ValidateAddressApiResponse,
  ValidateAddressCalloutActionResult,
} from './types'

/**
 * The reports callouts actions.
 */
export class ReportsCalloutsActions {
  public static GetReportTransaction = 'reportsCallouts/getReportTransaction'
  public static LoadFirmographics = 'reportsCallouts/loadFirmographics'
  public static OrderCAB = 'reportsCallouts/orderCab'
  public static OrderFirmographics = 'reportsCallouts/orderFirmographics'
  public static SetCabIsDown = 'reportsCallouts/setCabIsDown'
  public static SetFirmographicsInfo = 'reportsCallouts/setFirmographicsInfo'
  public static ValidateAddress = 'reportsCallouts/validateAddress'
}

const { standard: api } = useApis()

/**
 * The reports callouts action tree.
 */
export const actions: ActionTree<ReportsCalloutsInterface, RootState> = {
  async orderCab(context, payload: { client: ClientInterface; shouldReorder: string }) {
    const reorderOption: string = payload.shouldReorder ? 'reorder' : ''
    return await api
      .post<ApiResponse<OrderCabApiResponse>>('reports/standard/central_analysis_bureau/trucking_risk/order', {
        AccountHolderId: payload.client.accountHolderId,
        PolicyId: 0,
        QuoteId: 0,
        RequestCharacteristics: { CABVersion: 'V2', OrderProcessOption: reorderOption },
        AgentId: store.getters[UserSessionGetters.AgentId],
        DOT: payload?.client?.clientDetail.usdotNumber ?? 0,
      })
      .then<OrderCabCalloutActionResult>((response) => {
        // MaxDOT will be returned if data for the USDOT# in the request isn't found.
        // Technically, it's a 'failure' (considered a NoHit) and isSuccessful will be undefined.
        if (response.data.value?.maxDot) {
          return {
            isSuccessful: false,
            maxDot: response.data.value?.maxDot ?? '0',
            messages: response.data.value?.errorMessages ?? new Array<string>(),
          } as OrderCabCalloutActionResult
        }
        return {
          isSuccessful: response.data.isSuccessful,
          legalName: response.data.value?.legalName ?? '',
          operationClassification: response.data.value?.operationClassification,
          nameDba: response.data.value?.nameDba ?? '',
          businessAddress: response.data.value?.businessAddress ?? '',
          businessCity: response.data.value?.businessCity ?? '',
          businessState: response.data.value?.businessState ?? '',
          businessZip: response.data.value?.businessZip ?? '',
          businessZipPlusFour: response.data.value?.businessZipPlusFour ?? '',
          messages: response.data.value?.messages ?? new Array<string>(),
        } as OrderCabCalloutActionResult
      })
      .catch((reason) => {
        console.error('reports-callouts :: orderCab :: failed:', reason)
        return {
          isSuccessful: false,
          messages: new Array<string>(),
        }
      })
  },
  async orderFirmographics(context, payload: ClientInterface) {
    return await api
      .post<ApiResponse<FirmographicsApiResponse>>('reports/standard/firmographics/order', {
        AccountHolderId: payload.accountHolderId,
        AgentId: store.getters[UserSessionGetters.AgentId],
        QuoteId: store.getters[UserSessionGetters.QuoteId] ?? 0,
        PolicyId: store.getters[UserSessionGetters.PolicyId] ?? 0,
        ForceReorder: false,
        BusinessName:
          payload.clientDetail.businessEntityType === 'Individual'
            ? `${payload.person.firstName} ${payload.person.lastName}`
            : payload.business.businessName,
        DBA: payload.clientDetail.doingBusinessAs,
        StreetAddress: payload.businessAddress.streetAddress,
        City: payload.businessAddress.city,
        State: payload.businessAddress.state,
        ZipCode: payload.businessAddress.zipCode,
      })
      .then<FirmographicsCalloutActionResult>((response) => {
        return {
          isSuccessful: response.data.isSuccessful,
          companyName: response.data.value?.companyName ?? '',
          startYear: response.data.value?.startYear ?? 0,
          sicCodes: response.data.value?.sicCodes,
          naicCodes: response.data.value?.naicCodes,
          fein: response.data.value?.fein ?? '',
          numEmployees: response.data.value?.numEmployees ?? 0,
          messages: response.data.value?.messages ?? new Array<string>(),
        } as FirmographicsCalloutActionResult
      })
      .catch((reason) => {
        console.error('reports-callouts :: orderFirmographics :: failed:', reason)
        return {
          isSuccessful: false,
          messages: new Array<string>(),
        }
      })
  },
  async loadFirmographics(context, transactionId: string) {
    const agentId: string = store.getters[UserSessionGetters.AgentId]
    return await api
      .get<ApiResponse<FirmographicsApiResponse>>(`reports/standard/firmographics/${transactionId}?agentId=${agentId}`)
      .then<FirmographicsCalloutActionResult>((response) => {
        return {
          isSuccessful: response.data.isSuccessful,
          companyName: response.data.value?.companyName ?? '',
          startYear: response.data.value?.startYear ?? 0,
          sicCodes: response.data.value?.sicCodes,
          naicCodes: response.data.value?.naicCodes,
          fein: response.data.value?.fein ?? '',
          numEmployees: response.data.value?.numEmployees ?? 0,
          messages: response.data.value?.messages ?? new Array<string>(),
        } as FirmographicsCalloutActionResult
      })
      .catch((reason) => {
        console.error('reports-callouts :: loadFirmographics :: failed:', reason)
        return {
          isSuccessful: false,
          messages: new Array<string>(),
        }
      })
  },
  async getReportTransaction(context, payload) {
    return await api
      .post<ApiResponse<ReportTransactionApiResponse>>(`reports/standard/vendor_service/ordered`, {
        AccountHolderId: payload.accountHolderId,
        ServiceName: payload.serviceName,
      })
      .then<ReportTransactionCalloutActionResult>((response) => {
        return {
          isSuccessful: response.data.isSuccessful,
          transactionId: response.data.value?.transactionId,
          orderInPast30Days: response.data.value?.orderInPast30Days,
          orderInPast60Days: response.data.value?.orderInPast60Days,
          orderInPast90Days: response.data.value?.orderInPast90Days,
          messages: response.data.value?.messages ?? new Array<string>(),
        } as ReportTransactionCalloutActionResult
      })
      .catch((reason) => {
        console.warn('reports-callouts :: getReportTransaction :: failed', reason)
      })
  },
  async validateAddress(context, payload: AddressInterface[]) {
    return await api
      .post<ApiResponse<ValidateAddressApiResponse>>('reports/standard/address/validation_by_address', {
        address: payload[0]?.streetAddress ?? '',
        addressLine2: payload[0]?.secondaryAddress ?? '',
        city: payload[0]?.city ?? '',
        state: payload[0]?.state ?? '',
        zipCodeFirst5: payload[0]?.zipCode ?? '',
        zipCodePlus4: payload[0]?.zipPlusFour ?? '',
      })
      .then<ValidateAddressCalloutActionResult>((response) => {
        return {
          isSuccessful: response.data.isSuccessful,
          shouldShowAddressValidation: response.data.value?.actionRequired ?? false,
          suggestedAddress: response.data.value?.standardizedAddress
            ? new Address({
                addressType: '',
                streetAddress: response.data.value?.standardizedAddress ?? '',
                secondaryAddress: response.data.value?.standardizedAddress2 ?? '',
                city: response.data.value?.standardizedCity ?? '',
                state: response.data.value?.standardizedState ?? '',
                zipCode: response.data.value?.standardizedZipCode ?? '',
                zipPlusFour: response.data.value?.standardizedZipCodePlus4 ?? '',
                country: 'US',
              })
            : undefined,
          addressValidationMessages: response.data.value?.messages ?? new Array<string>(),
        }
      })
      .catch<ValidateAddressCalloutActionResult>((reason) => {
        console.error('reports-callouts :: validateAddress :: failed:', reason)
        return {
          isSuccessful: false,
          shouldShowAddressValidation: false,
          addressValidationMessages: new Array<string>(),
        }
      })
  },
  setFirmographicsInfo(context, payload: FirmographicsInterface) {
    context.commit(ReportsCalloutsMutations.SET_FIRMOGRAPHICS_INFO, payload)
  },

  setCabIsDown(context, payload: boolean) {
    context.commit(ReportsCalloutsMutations.SET_CAB_IS_DOWN, payload)
  },
}

export default actions
