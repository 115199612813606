import useApis from '@/api'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { CompraterActions } from '../comprater/actions'
import { SaveCompraterRequest } from '../comprater/types'
import { AgencyMutations } from './mutations'
import { Agency, AgencyInformationRequest, AgencyInterface } from './types'
export class AgencyActions {
  /**
   * gets the agency information using the agent id in session
   */
  public static GetAgencyFromSession = 'agency/getAgencyFromSession'
  /**
   * gets the agency information using the agent id passed in
   */
  public static GetAgencyById = 'agency/getAgencyById'
  /**
   * updates the agency information passed in
   */
  public static UpdateAgency = 'agency/updateAgency'
}

const { standard: api } = useApis()

export const actions: ActionTree<AgencyInterface, RootState> = {
  async getAgencyFromSession({ commit }) {
    try {
      const agentId = store.getters[UserSessionGetters.AgentId]
      const baseUrl = 'agent_portal/producers/v2/'
      const response = await api.get(`${baseUrl}agency/${agentId}`)
      const agency: Agency = new Agency(response.data.value)
      commit(AgencyMutations.SET_AGENCY, agency)
    } catch (reason) {
      console.error('agencyhome :: getAgencyFromSession :: failed:', reason)
      return false
    }
  },
  async getAgencyById({ commit }, payload: { agentId: number }) {
    try {
      const baseUrl = 'agent_portal/producers/v2/'
      const response = await api.get(`${baseUrl}agency/${payload.agentId}`)
      const agency: Agency = new Agency(response.data.value)
      commit(AgencyMutations.SET_AGENCY, agency)
    } catch (reason) {
      console.error('agencyhome :: getAgencyById :: failed:', reason)
      return null
    }
  },
  async updateAgency(
    _context,
    payload: { agencyInformation: AgencyInformationRequest; compraterInformation: SaveCompraterRequest }
  ) {
    try {
      const agencyResponse = (await api.put('agent_portal/producers/agency_information', payload.agencyInformation)).data.isSuccessful

      if (payload.compraterInformation.pickListId > 0) {
        return agencyResponse && await store.dispatch(CompraterActions.SaveComprater, payload.compraterInformation)
      }

      return agencyResponse
    } catch (reason) {
      console.error('editAgency :: updateAgency :: failed:', reason)
      return null
    }
  },
}
