import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { LoginType, UserSessionInterface } from './types'

/**
 * The user session getters.
 */
export class UserSessionGetters {
  /** Gets the agent group. */
  public static AgentGroup = 'userSession/agentGroup'

  /** Gets the agent identifier. */
  public static AgentId = 'userSession/agentId'

  /** Gets the application token. */
  public static AppToken = 'userSession/appToken'

  /**
   * Gets the calculated login type.
   * Takes into account weird exceptions from PL like isMGA.
   */
  public static CalculatedLoginType = 'userSession/calculatedLoginType'

  /** Gets whether or not the user can view commission statements. */
  public static CanViewCommissionStatement = 'userSession/canViewCommissionStatement'

  /** Gets the email address. */
  public static EmailAddress = 'userSession/emailAddress'

  /** Gets the employee number. */
  public static EmployeeNum = 'userSession/employeeNum'

  /** Gets the environment. */
  public static Environment = 'userSession/environment'

  /** Gets the first name. */
  public static FirstName = 'userSession/firstName'

  /** Gets the generated from */
  public static GeneratedFrom = 'userSession/generatedFrom'

  /** Gets the home office id. */
  public static HomeOfficeId = 'userSession/homeOfficeId'

  /** Gets the initial state */
  public static InitialState = 'userSession/initialState'

  /** Gets whether or not the user is the agency master admin */
  public static IsAgencyAdmin = 'userSession/isAgencyAdmin'

  /** Gets whether or not the user is the agency primary login. */
  public static IsAgencyPrimaryLogin = 'userSession/isAgencyPrimaryLogin'

  /** Gets whether or not the user is an agent. */
  public static IsAgent = 'userSession/isAgent'

  public static IsDaStore = 'userSession/isDaStore'

  /** Gets the home office id. */
  public static IsHomeOffice = 'userSession/isHomeOffice'

  /** Gets whether or not the user is internal sales */
  public static IsInternalSales = 'userSession/isInternalSales'

  /** Gets whether or not the user is an MGA. */
  public static IsMGA = 'userSession/isMGA'

  /** Gets whether or not the user is a super user. */
  public static IsSuperUser = 'userSession/isSuperUser'

  /** Gets the last name. */
  public static LastName = 'userSession/lastName'

  /** Gets the login provider type */
  public static LoginProviderType = 'userSession/loginProviderType'

  /** Gets the login type. */
  public static LoginType = 'userSession/loginType'

  /** Gets the master account identifier. */
  public static MasterAccountId = 'userSession/masterAccountId'

  /** Gets the master user identifier. */
  public static MasterUserId = 'userSession/masterUserId'

  /** Gets the NIS user identifier */
  public static NisUserId = 'userSession/nisUserId'

  /** Gets the office account code. */
  public static OfficeAccountCode = 'userSession/officeAccountCode'

  /** Gets the url user originated from. */
  public static OriginUrl = 'userSession/originUrl'

  /** Gets the list of permissions. */
  public static Permissions = 'userSession/permissions'

  /** Gets the policy id. */
  public static PolicyId = 'userSession/policyId'

  /** Gets the princeton agent code. */
  public static PrincetonAgentCode = 'userSession/princetonAgentCode'

  /** Gets the product. */
  public static Product = 'userSession/product'

  /** Gets the quote id. */
  public static QuoteId = 'userSession/quoteId'

  /** Gets the role. */
  public static Role = 'userSession/role'

  /** Gets the product. */
  public static SubProduct = 'userSession/subProduct'

  /**
   * Gets the true login type.
   * Should not be used unless you know what you're doing.
   * Use the 'CalculatedLoginType' getter instead.
   * */
  public static TrueLoginType = 'userSession/trueLoginType'

  /** Gets the username. */
  public static Username = 'userSession/username'

  public static UserProductOptions = 'userSession/userProductOptions'

  public static UserStateOptions = 'userSession/userStateOptions'
}

/**
 * The user session getter tree.
 */
export const getters: GetterTree<UserSessionInterface, RootState> = {
  agentGroup: (state: UserSessionInterface) => {
    return state.agentGroup
  },
  agentId: (state: UserSessionInterface) => {
    return state.agentId
  },
  calculatedLoginType: (state: UserSessionInterface) => {
    if (state.isMGA) return LoginType.Agent
    return state.loginType
  },
  canViewCommissionStatement: (state: UserSessionInterface) => {
    return state.canViewCommissionStatement
  },
  emailAddress: (state: UserSessionInterface) => {
    return state.emailAddress
  },
  employeeNum: (state: UserSessionInterface) => {
    return state.employeeNum
  },
  environment: (state: UserSessionInterface) => {
    return state.environment
  },
  firstName: (state: UserSessionInterface) => {
    return state.firstName
  },
  generatedFrom: (state: UserSessionInterface) => {
    return state.generatedFrom
  },
  homeOfficeId: (state: UserSessionInterface) => {
    return state.homeOfficeId
  },
  initialState: (state: UserSessionInterface) => {
    return state.initialState
  },
  isAgencyAdmin: (state: UserSessionInterface) => {
    return state.isAgencyAdmin
  },
  isAgencyPrimaryLogin: (state: UserSessionInterface) => {
    return state.isAgencyPrimaryLogin
  },
  isAgent: (state: UserSessionInterface) => {
    return state.isAgent
  },
  isDaStore: (state: UserSessionInterface) => {
    return state.isDaStore
  },
  isHomeOffice: (state: UserSessionInterface) => {
    return state.isHomeOffice
  },
  isInternalSales: (state: UserSessionInterface) => {
    return state.isInternalSales
  },
  isMGA: (state: UserSessionInterface) => {
    return state.isMGA
  },
  isSuperUser: (state: UserSessionInterface) => {
    return state.isSuperUser
  },
  lastName: (state: UserSessionInterface) => {
    return state.lastName
  },
  loginProviderType: (state: UserSessionInterface) => {
    return state.loginProviderType
  },
  loginType: (state: UserSessionInterface) => {
    return state.loginType
  },
  masterAccountId: (state: UserSessionInterface) => {
    return state.masterAccountId
  },
  masterUserId: (state: UserSessionInterface) => {
    return state.masterUserId
  },
  nisUserId: (state: UserSessionInterface) => {
    return state.nisUserId
  },
  officeAccountCode: (state: UserSessionInterface) => {
    return state.officeAccountCode
  },
  originUrl: (state: UserSessionInterface) => {
    return state.originUrl
  },
  permissions: (state: UserSessionInterface) => {
    return state.permissions
  },
  policyId: (state: UserSessionInterface) => {
    return state.policyId
  },
  princetonAgentCode: (state: UserSessionInterface) => {
    return state.princetonAgentCode
  },
  product: (state: UserSessionInterface) => {
    return state.product
  },
  quoteId: (state: UserSessionInterface) => {
    return state.quoteId
  },
  role: (state: UserSessionInterface) => {
    return state.role
  },
  subProduct: (state: UserSessionInterface) => {
    return state.subProduct
  },
  trueLoginType: (state: UserSessionInterface) => {
    return state.loginType
  },
  username: (state: UserSessionInterface) => {
    return state.username
  },
  userProductOptions: (state: UserSessionInterface) => {
    return state.userProductOptions
  },
  userStateOptions: (state: UserSessionInterface) => {
    return state.userStateOptions
  },
}

export default getters
