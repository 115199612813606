import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { actions } from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import { NavigationInformationInterface } from './types'

export const navigation: Module<NavigationInformationInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default navigation
