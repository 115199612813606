import { GetterTree } from 'vuex'

import { RootState } from '@/store/types'

import { AlertMessageInterface, AlertMessagesInterface, AlertMessageSource } from './types'

export class AlertMessagesGetters {
  public static AlertMessages = 'alertMessages/getAlertMessages'
  public static AlertMessagesBySource = 'alertMessages/getAlertMessagesBySource'
  public static HasAny = 'alertMessages/hasAnyAlertMessages'
}

export const getters: GetterTree<AlertMessagesInterface, RootState> = {
  getAlertMessages (state: AlertMessagesInterface): Array<AlertMessageInterface> {
    return state.alertMessages
  },
  getAlertMessagesBySource: (state: AlertMessagesInterface) => (source: AlertMessageSource): Array<AlertMessageInterface> => {
    return state.alertMessages.filter(x => x.source === source)
  },
  hasAnyAlertMessages (state: AlertMessagesInterface): boolean {
    return !!state.alertMessages.length
  }
}

export default getters
