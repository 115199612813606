// eslint-disable-next-line @typescript-eslint/ban-types
const startWorker = (funcObj: Function) => {
  // Build a worker from an anonymous function body
  const blobURL = URL.createObjectURL(new Blob(['(', funcObj.toString(), ')()'], { type: 'application/javascript' }))

  const worker = new Worker(blobURL)

  // Won't be needing this anymore
  URL.revokeObjectURL(blobURL)

  return worker
}

export default startWorker
