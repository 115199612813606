<template>
  <v-container>
    <div v-if="!isReadOnly">
      <v-card-title>
        <span v-if="showHelp">Business Type Help</span>
        <span v-else>Business Search Information</span>
        <v-spacer />
        <span v-if="showCancelSave">
          <simple-button color="secondary" @click="() => clickCancel()" class="mx-2"> Cancel </simple-button>
          <simple-button color="primary" @click="() => clickSave()" class="mx-2"> Save </simple-button>
        </span>
        <span v-else>
          <simple-button color="secondary" @click="returnToClientEntry" class="mx-2">Previous</simple-button>
          <simple-button color="primary" @click="goNext" class="mx-2"> Continue </simple-button>
        </span>
      </v-card-title>

      <alert-messages />

      <v-card v-if="showSection1()" class="mx-3 my-5 pa-5">
        <business-name-selection :client="currentClient" :businessNames="businessNames" />
      </v-card>
    </div>

    <v-card class="mx-3 my-5 pa-5">
      <business-type-selection />
    </v-card>

    <v-card class="mx-3 my-5 pa-5">
      <business-type-list />
    </v-card>

    <div v-if="!isReadOnly">
      <v-card-actions v-if="showCancelSave" flat class="justify-end">
        <simple-button color="secondary" @click="() => clickCancel()" class="mx-2"> Cancel </simple-button>
        <v-spacer />
        <simple-button color="primary" @click="() => clickSave()" class="mx-2"> Save </simple-button>
      </v-card-actions>

      <v-card-actions v-else flat class="mb-5 justify-end">
        <simple-button color="secondary" @click="returnToClientEntry" class="mx-2">Previous</simple-button>
        <v-spacer />
        <simple-button color="primary" @click="goNext" class="mx-2">Continue</simple-button>
      </v-card-actions>
    </div>
  </v-container>
</template>

<script lang="ts">
import BusinessNameSelection from '@/components/client/BusinessNameSelection.vue'
import BusinessTypeList from '@/components/client/BusinessTypeList.vue'
import BusinessTypeSelection from '@/components/client/BusinessTypeSelection.vue'
import { selectAllCategories } from '@/components/client/composables/useToggleSelectedCategories'
import AlertMessages from '@/components/common/AlertMessages.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import enums from '@/constants/enumerations'
import store from '@/store'
import { AccountHolderSaveResult } from '@/store/modules/account-holder-callouts/types'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { BusinessSearchActions } from '@/store/modules/business-search/actions'
import { BusinessSearchGetters } from '@/store/modules/business-search/getters'
import { ClientActions } from '@/store/modules/client/actions'
import { ClientGetters } from '@/store/modules/client/getters'
import { ClientInterface } from '@/store/modules/client/types'
import { OccupationFilterActions } from '@/store/modules/occupation-filter/actions'
import { ReportsCalloutsGetters } from '@/store/modules/reports-callouts/getters'
import { NaicInterface, SicInterface } from '@/store/modules/reports/firmographics/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import {
  BridgeToNamedInsured,
  ClearFirmographicsData,
  RedirectToClientEntry,
  RedirectToClientView,
} from '@/utils/ClientViewShared'
import { EventBus, Events } from '@/utils/EventBus'
import Register from '@/utils/ProcessingMaskHelpers'
import { computed, defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'BusinessSearchInfo',
  components: {
    AlertMessages,
    SimpleButton,
    BusinessNameSelection,
    BusinessTypeList,
    BusinessTypeSelection,
  },
  setup() {
    const isReadOnly: boolean = store.getters[BusinessSearchGetters.ReadOnly]
    if (isReadOnly) {
      EventBus.emit(Events.hideLayout)
    }

    const currentClient = computed<ClientInterface>(() => {
      return store.getters[ClientGetters.NewClient]
    })

    const naicCodes =
      store.getters[ReportsCalloutsGetters.FirmographicsNaicCodes]?.map((code: NaicInterface) => code.naic) ?? []
    const sicCodes =
      store.getters[ReportsCalloutsGetters.FirmographicsSicCodes]?.map((code: SicInterface) => code.sic) ?? []

    const firmographicsHasCodes = computed<boolean>(() => {
      return naicCodes.length || sicCodes.length
    })
    const firmographicsNoHit = !store.getters[ReportsCalloutsGetters.FirmographicsCompanyName].length

    const businessNames = reactive({
      selectedName: store.getters[ClientGetters.SelectedBusinessName],
      systemMatchedName: store.getters[ReportsCalloutsGetters.FirmographicsCompanyName],
      userEnteredName:
        currentClient.value.clientDetail.businessEntityType === enums.entityType.Individual
          ? `${currentClient.value.person.firstName} ${currentClient.value.person.lastName}`
          : currentClient.value.business.businessName,
    })

    function showSection1(): boolean {
      return (
        currentClient.value.clientDetail.clientHasUsdotType !== enums.usdotSelectedOption.Yes &&
        businessNames.systemMatchedName !== '' &&
        businessNames.userEnteredName.toLowerCase() !== businessNames.systemMatchedName.toLowerCase()
      )
    }

    function useUnfilteredOccupations(): boolean {
      // TODO: THIS ALSO NEEDS TO DO THE LOOKUP ON CRADOCCUPATIONMAPPING TO SEE IF FG CODES EXIST IN OUR TABLE
      return (
        (currentClient.value.clientDetail?.clientHasUsdotType === enums.usdotSelectedOption.Yes &&
          currentClient.value.clientDetail.motorCarrierForHire) ||
        firmographicsNoHit ||
        !firmographicsHasCodes.value
      )
    }

    store.dispatch(BusinessSearchActions.SetShowHelp, useUnfilteredOccupations())
    // Set the list of occupations to the prefilled one if there is any
    // Show the entire list if there is no occupation assigned or in read-only window (after clicking "Need Help" from PL)
    if (!currentClient.value.clientDetail.occupation || isReadOnly) {
      store.dispatch(OccupationFilterActions.ResetModule)
    } else {
      store.dispatch(
        OccupationFilterActions.SetListBasedOnPrefilledOccupation,
        currentClient.value.clientDetail.occupation
      )
    }

    const showHelp = computed<boolean>(() => {
      return store.getters[BusinessSearchGetters.ShowHelp]
    })

    const showCancelSave = computed<boolean>(() => {
      return Boolean(store.getters[BusinessSearchGetters.ShowCancelSave])
    })

    async function saveClient(): Promise<boolean> {
      const result: AccountHolderSaveResult = await store.dispatch(ClientActions.SaveClient, currentClient.value)

      if (!result.isSuccessful) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'There was a problem saving client information.',
        })
        return false
      }

      return true
    }

    const updateBusinessName = () => {
      const nameToUse =
        businessNames.selectedName === 'systemMatched' ? businessNames.systemMatchedName : businessNames.userEnteredName
      store.dispatch(ClientActions.SetSelectedBusinessName, businessNames.selectedName)

      if (currentClient.value.clientDetail.businessEntityType === enums.entityType.Individual) {
        const fullName = nameToUse.split(' ')
        if (fullName.length) {
          currentClient.value.person.firstName = fullName[0] ?? ''
          currentClient.value.person.lastName = fullName[fullName.length - 1] ?? ''
        }
        currentClient.value.business.businessName = ''
      } else {
        currentClient.value.business.businessName = nameToUse
        currentClient.value.person.firstName = ''
        currentClient.value.person.lastName = ''
      }

      store.dispatch(ClientActions.SetNewClient, currentClient.value)
    }

    function validateInputs(): boolean {
      let isValid = true

      if (showSection1()) {
        if (businessNames.selectedName === '') {
          store.dispatch(AlertMessagesActions.AddErrorMessage, {
            messageText: 'Please select the correct business name in order to proceed.',
          })
          isValid = false
        }
        if (isValid) updateBusinessName()
      }

      if (!isReadOnly && !currentClient.value.clientDetail.occupation) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: "Please select the customer's business type in order to proceed.",
        })
        isValid = false
      }
      return isValid
    }

    function returnToClientEntry() {
      store.dispatch(AlertMessagesActions.ClearMessages)
      store.dispatch(ClientActions.SetSelectedBusinessName, '')
      RedirectToClientEntry()
    }

    /** Does all of the saves and checks required for continuing. */
    async function checkForNext(): Promise<boolean> {
      store.dispatch(AlertMessagesActions.ClearMessages)

      if (!validateInputs()) return false

      if (!(await saveClient())) return false

      return true
    }

    /** Checks everything and goes to the next page. */
    async function goNext() {
      if (!(await Register(checkForNext()))) {
        return false
      } else {
        const prefillType = enums.prefillType.CVSameState
        const payload = {
          state: store.getters[UserSessionGetters.InitialState],
          subproduct: store.getters[UserSessionGetters.SubProduct],
          generatedFrom: store.getters[UserSessionGetters.GeneratedFrom],
          isNewQuote: !(store.getters[UserSessionGetters.QuoteId] || store.getters[UserSessionGetters.PolicyId]),
        }
        if (
          !payload.isNewQuote &&
          !store.getters[ClientGetters.NewClient].isRewrite &&
          !store.getters[ClientGetters.NewClient].isCrossSell
        ) {
          ClearFirmographicsData()
          RedirectToClientView()
        } else {
          ClearFirmographicsData()
          BridgeToNamedInsured(prefillType, payload)
        }
      }

      return true
    }

    function clickCancel() {
      store.dispatch(AlertMessagesActions.ClearMessages)
      store.dispatch(ClientActions.SetOccupation, '')
      store.dispatch(BusinessSearchActions.SetShowHelp, false)
      store.dispatch(BusinessSearchActions.SetShowCancelSave, false)
      store.dispatch(OccupationFilterActions.ResetModule)
      return true
    }

    async function clickSave() {
      store.dispatch(AlertMessagesActions.ClearMessages)
      if (!currentClient.value.clientDetail.occupation) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: "Please select the customer's business type in order to proceed.",
        })
        return false
      }

      store.dispatch(BusinessSearchActions.SetShowHelp, false)
      store.dispatch(BusinessSearchActions.SetShowCancelSave, false)
      await store.dispatch(OccupationFilterActions.ResetModule)
      selectAllCategories(false)
      return true
    }

    return {
      businessNames,
      clickCancel,
      clickSave,
      currentClient,
      goNext,
      isReadOnly,
      returnToClientEntry,
      showCancelSave,
      showHelp,
      showSection1,
      useUnfilteredOccupations,
    }
  },
})
</script>
