import { MutationTree } from 'vuex'
import { CompraterInterface } from './types'

export class CompraterMutations {
    public static SET_COMPRATER = 'SET_COMPRATER'
}

export const mutations: MutationTree<CompraterInterface> = {
    SET_COMPRATER(state: CompraterInterface, comprater: CompraterInterface) {
      state.id = comprater.id
      state.data = comprater.data
    },
}

export default mutations