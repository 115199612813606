import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { BusinessSearchInterface } from './types'

export class BusinessSearchGetters {
  public static MCFHReadOnly = 'businessSearch/mcfhReadOnly'
  public static ShowCancelSave = 'businessSearch/showCancelSave'
  public static ReadOnly = 'businessSearch/readOnly'
  public static ShowHelp = 'businessSearch/showHelp'
}

export const getters: GetterTree<BusinessSearchInterface, RootState> = {
  mcfhReadOnly(state: BusinessSearchInterface): boolean {
    return state.mcfhReadOnly
  },
  showCancelSave(state: BusinessSearchInterface): boolean {
    return state.showCancelSave
  },
  readOnly(state: BusinessSearchInterface): boolean {
    return state.readOnly
  },
  showHelp(state: BusinessSearchInterface): boolean {
    return state.showHelp
  },
}

export default getters
