<template>
  <v-container>
    <alert-messages />
    <v-card class="ma-3 px-3 pb-2">
      <v-card-title>Search Instructions</v-card-title>
      <v-card-text>
        If no search results are returned or you do not find the correct client Account ID please select the "Add New
        Customer" button.
      </v-card-text>
    </v-card>
    <v-card class="ma-3 pb-2">
      <v-card-title>Client Search / Start a New Quote</v-card-title>
      <v-container>
        <v-card-text>
          <v-card class="px-5 py-2 mb-2" flat outlined>
            <app-text-field
              label="First Name"
              :maxlength="100"
              @blur="searchInput.person.firstName = ConvertToTitleCase(searchInput.person.firstName)"
              v-model="searchInput.person.firstName"
              for="first-name"
            />
            <app-text-field
              label="Last Name"
              :maxlength="100"
              isRequired
              @blur="searchInput.person.lastName = ConvertToTitleCase(searchInput.person.lastName)"
              v-model="searchInput.person.lastName"
              for="last-name"
            />
            <v-row class="ml-4 mb-5"> <h3>OR</h3> </v-row>
            <app-text-field
              label="Business Name"
              :maxlength="100"
              isRequired
              @blur="searchInput.business.businessName = ConvertToUpperCase(searchInput.business.businessName)"
              v-model="searchInput.business.businessName"
              for="business-name"
            />
          </v-card>
          <app-text-field class="px-5" :maxlength="5" label="Zip Code" isRequired v-model="addressToUse.zipCode" for="zip-code" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <simple-button color="primary" :disabled="!formIsValid" @click="performSearch" class="mx-2"
            >Search</simple-button
          >
          <v-spacer />
          <simple-button v-show="formIsValid && showResults" color="primary" @click="addCustomer" class="mx-2"
            >Add New Customer</simple-button
          >
        </v-card-actions>
      </v-container>
    </v-card>
    <client-search-results v-if="showResults" :clients="clientList" />
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from 'vue'
import router from '@/router'
import store from '@/store'

import SimpleButton from '@/components/common/input/SimpleButton.vue'
import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import AppTextField from '@/components/common/input/AppTextField.vue'
import ClientSearchResults from '@/components/client/ClientSearchResults.vue'
import { Client, AddressInterface } from '@/store/modules/client/types'
import { ClientActions } from '@/store/modules/client/actions'
import { ClientGetters } from '@/store/modules/client/getters'
import Register from '@/utils/ProcessingMaskHelpers'
import { ConvertToTitleCase, ConvertToUpperCase } from '@/utils/Formatter'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import enums from '@/constants/enumerations'
import { ClearFirmographicsData } from '@/utils/ClientViewShared'

export default defineComponent({
  name: 'ClientSearch',
  components: {
    SimpleButton,
    AlertMessages,
    AppTextField,
    ClientSearchResults,
  },
  setup() {
    ClearFirmographicsData()

    // User Input
    const searchInput = reactive(new Client())

    const addressToUse = computed<AddressInterface>(() => {
      return store.getters[UserSessionGetters.Product] === enums.product.CV
        ? searchInput.businessAddress
        : searchInput.residentialAddress
    })

    // Search
    const showResults = ref<boolean>(false)
    const clientList = computed(() => {
      return store.getters[ClientGetters.Clients]
    })

    async function performSearch() {
      store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })
      Register(store.dispatch(ClientActions.SearchClient, searchInput))
      showResults.value = true
    }

    // Add Customer
    const addCustomer = () => {
      const client: Client = store.getters[ClientGetters.NewClient]
      client.clientMode = enums.clientMode.NewClient
      searchInput.initialState = client.initialState
      searchInput.businessAddress.state = client.initialState
      searchInput.product = client.product
      searchInput.subProduct = client.subProduct
      Register(store.dispatch(ClientActions.SetNewClient, searchInput))
      Register(store.dispatch(ClientActions.SetSelectedBusinessName, ''))
      router.push({ name: 'Client Entry', query: router.currentRoute.query })
    }

    // UI Validation
    const formIsValid = computed<boolean>(() => {
      const isValid =
        (searchInput.person.lastName.length > 0 || searchInput.business.businessName.length > 0) &&
        /^([\s|A-Z|a-z|\\'|\\.|\\-]*)$/.test(searchInput.person.firstName) && /^([\s|A-Z|a-z|\\'|\\.|\\-]*)$/.test(searchInput.person.lastName) &&
        addressToUse.value.zipCode.length === 5 &&
        /^\d+$/.test(addressToUse.value.zipCode)

      if (!isValid) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        showResults.value = false
      }

      return isValid
    })

    return {
      addCustomer,
      addressToUse,
      clientList,
      ConvertToTitleCase,
      ConvertToUpperCase,
      enums,
      formIsValid,
      performSearch,
      showResults,
      searchInput,
    }
  },
})
</script>
