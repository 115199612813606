<template>
  <v-footer id="footer" class="text-center d-block">
    © {{ new Date().getFullYear() }} National General, an Allstate company. All rights reserved.
  </v-footer>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageFooter'
})
</script>

<style scoped lang="scss">
#footer {
  background-color: white;
}
</style>
