import { AddressInterface, Agency, AgencyInterface, Client, Policy, Quote } from '@/store/modules/client/types'
import { FormatCurrency, FormatDateForDatePicker } from '@/utils/Formatter'

export const PolicyPaymentMethodDisplay: Map<string, string> = new Map([
  ['D', 'Direct Bill'],
  ['AC', 'Auto Pay - Credit Card'],
  ['AS', 'Auto Pay - Checking/Savings'],
  ['PF', 'Premium Finance'],
  ['CP', 'Account Current Pay In Full'],
  ['CF', 'Account Current Premium Finance'],
])

export const ExternalSearchGridHeader = [
  { text: 'Account ID', value: 'accountHolderId' },
  { text: 'Named Insured', value: 'namedInsured' },
  { text: 'Address', value: 'address' },
  { text: 'Address2', value: 'address2' },
  { text: 'City', value: 'city' },
  { text: 'State', value: 'state' },
  { text: 'Zip Code', value: 'zipCode' },
]

export const InternalSearchGridHeader = [
  { text: 'Account ID', value: 'accountHolderId' },
  { text: 'Agent', value: 'princetonAgtCode' },
  { text: 'Named Insured', value: 'namedInsured' },
  { text: 'Address', value: 'address' },
  { text: 'Address2', value: 'address2' },
  { text: 'City', value: 'city' },
  { text: 'State', value: 'state' },
  { text: 'Zip Code', value: 'zipCode' },
]

export interface SearchFieldsInterface {
  accountHolderId: number
  agentId: number
  princetonAgtCode: string
  namedInsured: string
  address: string
  address2: string
  city: string
  state: string
  zipCode: string
}

export class SearchFields implements SearchFieldsInterface {
  accountHolderId: number
  agentId: number
  princetonAgtCode: string
  namedInsured: string
  address: string
  address2: string
  city: string
  state: string
  zipCode: string

  constructor(client: Client) {
    this.accountHolderId = client.accountHolderId ?? 0
    this.agentId = client.agentId ?? 0
    this.princetonAgtCode = client.agencyResult?.princetonAgtCode ?? ''
    this.namedInsured = !client.business?.businessName
      ? `${client.person.firstName} ${client.person.lastName}`
      : client.business.businessName

    const addressToUse: AddressInterface =
      client.businessAddress.addressType.length > 0 ? client.businessAddress : client.residentialAddress

    this.address = addressToUse.streetAddress ?? ''
    this.address2 = addressToUse.secondaryAddress ?? ''
    this.city = addressToUse.city ?? ''
    this.state = addressToUse.state ?? ''
    this.zipCode = addressToUse.zipCode ?? ''
  }
}

export const ExternalQuoteGridHeader = [
  { text: 'Quote Number', value: 'quoteId', width: '15%', align: 'center' },
  { text: 'State', value: 'govState', width: '8%', align: 'center' },
  { text: 'Product', value: 'subProductDisplayName', width: '18%', align: 'center' },
  { text: 'Effective Date', value: 'effectiveDate', width: '12%', align: 'center' },
  { text: 'Date Quoted', value: 'createdDate', width: '12%', align: 'center' },
  { text: 'Premium', value: 'premium', width: '12%', align: 'center' },
  { text: 'MVR Ordered', value: 'isMvrOrdered', width: '8%', align: 'center' },
  { text: 'Claims History Ordered', value: 'isClaimsHistoryOrdered', width: '15%', align: 'center' },
]

export const InternalQuoteGridHeader = [
  { text: 'Quote Number', value: 'quoteId', width: '15%', align: 'center' },
  { text: 'Agent', value: 'princetonAgtCode', width: '12%', align: 'center' },
  { text: 'State', value: 'govState', width: '5%', align: 'center' },
  { text: 'Product', value: 'subProductDisplayName', width: '15%', align: 'center' },
  { text: 'Effective Date', value: 'effectiveDate', width: '10%', align: 'center' },
  { text: 'Date Quoted', value: 'createdDate', width: '12%', align: 'center' },
  { text: 'Premium', value: 'premium', width: '10%', align: 'center' },
  { text: 'MVR Ordered', value: 'isMvrOrdered', width: '8%', align: 'center' },
  { text: 'Claims History Ordered', value: 'isClaimsHistoryOrdered', width: '13%', align: 'center' },
]

export interface QuoteFieldsInterface {
  quoteId: string
  agentId: number
  princetonAgtCode: string
  source: string
  product: string
  subproduct: string
  effectiveDate: string
  createdDate: string
  premium: string
  isMvrOrdered: string
  isClaimsHistoryOrdered: string
  govState: string
  subProductDisplayName: string
  agencyResult: AgencyInterface
}

export class QuoteFields implements QuoteFieldsInterface {
  quoteId: string
  agentId: number
  princetonAgtCode: string
  source: string
  product: string
  subproduct: string
  effectiveDate: string
  createdDate: string
  premium: string
  isMvrOrdered: string
  isClaimsHistoryOrdered: string
  govState: string
  subProductDisplayName: string
  agencyResult: AgencyInterface

  constructor(quote: Quote) {
    this.quoteId = quote.quoteId ?? ''
    this.agentId = quote.agentId ?? 0
    this.princetonAgtCode = quote.agencyResult.princetonAgtCode ?? ''
    this.source = quote.source ?? ''
    this.product = quote.product ?? ''
    this.subproduct = quote.subproduct ?? ''
    this.effectiveDate = quote.effectiveDate ? FormatDateForDatePicker(quote.effectiveDate) : ''
    this.createdDate = quote.createdDate ? FormatDateForDatePicker(quote.createdDate) : ''
    this.premium = quote.totalPremium ? FormatCurrency(quote.totalPremium) : 'Unrated'
    this.isMvrOrdered = quote.isMotorVehicleRecordOrdered ? 'Yes' : 'No'
    this.isClaimsHistoryOrdered = quote.isClaimsHistoryOrdered ? 'Yes' : 'No'
    this.govState = quote.govState ?? ''
    this.subProductDisplayName = quote.subProductDisplayName ?? ''
    this.agencyResult = quote.agencyResult ?? new Agency()
  }
}

export const PolicyGridHeader = [
  { text: 'Quote Number', value: 'quoteId', width: '10%', align: 'center' },
  { text: 'Source', value: 'source', width: '10%', align: 'center' },
  { text: 'Product', value: 'product', width: '10%', align: 'center' },
  { text: 'SubProduct', value: 'subProduct', width: '17%', align: 'center' },
  { text: 'Effective Date', value: 'effectiveDate', width: '10%', align: 'center' },
  { text: 'Date Quoted', value: 'createdDate', width: '10%', align: 'center' },
  { text: 'Premium', value: 'premium', width: '10%', align: 'center' },
  { text: 'MVR Ordered', value: 'isMvrOrdered', width: '10%', align: 'center' },
  { text: 'Claims History Ordered', value: 'isClaimsHistoryOrdered', width: '13%', align: 'center' },
]

export interface PolicyFieldsInterface {
  additionalProducts: Array<string>
  agencyResults: AgencyInterface
  canceledDate: string
  cost: string
  detailedPolicyStatus: string
  effectiveDate: string
  expirationDate: string
  nextAmountDue: string
  nextAmountDueDate: string
  paymentMethod: string
  policyId: number
  policyNumber: string
  policyStatus: string
  policySuffix: string
  policyTerm: string
  product: string
  state: string
  subProduct: string
  premium: string
}

export class PolicyFields implements PolicyFieldsInterface {
  additionalProducts: Array<string>
  agencyResults: AgencyInterface
  canceledDate: string
  detailedPolicyStatus: string
  cost: string
  effectiveDate: string
  expirationDate: string
  nextAmountDue: string
  nextAmountDueDate: string
  paymentMethod: string
  policyId: number
  policyNumber: string
  policyStatus: string
  policySuffix: string
  policyTerm: string
  product: string
  state: string
  subProduct: string
  premium: string

  constructor(policy: Policy) {
    this.additionalProducts = policy.additionalProducts ?? []
    this.agencyResults = policy.agencyResult ?? new Agency()
    this.canceledDate = policy.canceledDate ? FormatDateForDatePicker(policy.canceledDate) : ''
    this.cost = policy.cost ? FormatCurrency(policy.cost) : FormatCurrency(0)
    this.detailedPolicyStatus = policy.detailedPolicyStatus ?? ''
    this.effectiveDate = policy.effectiveDate ? FormatDateForDatePicker(policy.effectiveDate) : ''
    this.expirationDate = policy.expirationDate ? FormatDateForDatePicker(policy.expirationDate) : ''
    this.nextAmountDue = policy.nextAmountDue ? FormatCurrency(policy.nextAmountDue) : '$0.00'
    this.nextAmountDueDate =
      policy.nextAmountDue && policy.nextAmountDueDate
        ? FormatDateForDatePicker(policy.nextAmountDueDate)
        : 'No Payment Due'
    this.paymentMethod =
      policy.paymentMethod && PolicyPaymentMethodDisplay.has(policy.paymentMethod)
        ? (PolicyPaymentMethodDisplay.get(policy.paymentMethod) as string)
        : ''
    this.policyId = policy.policyId
    this.policyNumber = policy.policyNumber ?? ''
    this.policyStatus = policy.policyStatus ?? ''
    this.policySuffix = policy.policySuffix ?? ''
    this.policyTerm = policy.policyTerm ?? ''
    this.product = policy.product ?? ''
    this.state = policy.govState ?? ''
    this.subProduct = policy.subProduct ?? ''
    this.premium = policy.totalPremium ? FormatCurrency(policy.totalPremium) : FormatCurrency(0)
  }
}
