import { BusinessSearchInterface } from './types'

export const state: BusinessSearchInterface = {
  mcfhReadOnly: false,
  showCancelSave: false,
  readOnly: false,
  showHelp: false,
}

export default state
