/* eslint-disable no-console */
import { agencyFieldNames, contactFieldNames } from '@/constants/ASSPFieldNames'
import internalRoutes from '@/constants/internalRoutes'
import { ApiResponse } from '@/models/api'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty } from 'lodash'
import tokenService from './utils/tokenService'

/** The interceptor request interface. */
export interface InterceptorRequestInterface {
  authorizationOnFulfilledRequest: (request: AxiosRequestConfig) => Promise<AxiosRequestConfig<unknown>>
  onFulfilled: (request: AxiosRequestConfig) => AxiosRequestConfig<unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected: (error: any) => Record<string, any>
}

/** The interceptor response interface. */
export interface InterceptorResponseInterface {
  onFulfilled: (
    response: AxiosResponse<ApiResponse>
  ) => AxiosResponse<ApiResponse> | Promise<AxiosResponse<ApiResponse>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected: (error: any) => Record<string, any>
}

/** The interceptor interface. */
export interface InterceptorInterface {
  request: InterceptorRequestInterface
  response: InterceptorResponseInterface
}

/** The interceptor collection interface. */
export interface InterceptorCollectionInterface {
  default: InterceptorInterface
}

/** The interceptors. */
const interceptors: InterceptorCollectionInterface = {
  default: {
    request: {
      authorizationOnFulfilledRequest: async (request: AxiosRequestConfig) => {
        if (!request.url?.includes('agent_portal/authorization')) {
          if (!request?.headers) return request
          const token = await tokenService.getToken()
          request.headers.Authorization = `${token.type} ${token.value}`
        }

        // If IE 11 and a get method then override the local browser caching using a timestamp
        const overrideCache: boolean = window.navigator.userAgent.indexOf('Trident/') !== -1 && request.method === 'get'
        if (overrideCache) {
          request.params = { ...request.params, timestamp: new Date().getTime().toString() }
        }

        // Hide token information
        if (request.url !== 'agent_portal/authorization') {
          console.log(`${request.method?.toUpperCase()} ${request.url} :: started`, request)
        }

        return request
      },
      onFulfilled: (request: AxiosRequestConfig) => {
        if (!request?.headers) return request
        request.headers.key = process.env.VUE_APP_CLIENT_SECRET

        // Hide token information
        if (request.url !== 'agent_portal/authorization') {
          console.log(`${request.method?.toUpperCase()} ${request.url} :: started`, request)
        }

        return request
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onRejected: (error: any) => {
        // TODO: Implement the rest of this interceptor
        throw new Error(error)
      },
    },
    response: {
      onFulfilled: (response: AxiosResponse<ApiResponse>) => {
        // Convert the response body to camelCase since we cannot guarantee the format that an API gives us
        response = camelcaseKeys(response, {
          deep: true,
          stopPaths: [
            'data.value.descriptionByCategory', // Don't convert Driver Incident descriptions / categories
            'data.value.document', // Don't convert a Hot Storage document
            'data.value.overrides', // Don't convert Curator's attribute overrides
          ],
        })

        // Hide token information
        if (response.config.url !== 'agent_portal/authorization') {
          console.log(`${response.config.method?.toUpperCase()} ${response.config.url} :: complete`, response)
        }
        const responseErrors = Object.fromEntries(Object.entries(response.data)).errors ?? []

        if (
          response.status <= 200 &&
          !Object.prototype.hasOwnProperty.call(response.data, 'isSuccessful') &&
          isEmpty(responseErrors)
        ) {
          console.log(`${response.config.method?.toUpperCase()} ${response.config.url} :: successful`, response)
        } else if (
          response?.data &&
          (!Object.prototype.hasOwnProperty.call(response.data, 'isSuccessful') || !response.data.isSuccessful)
        ) {
          // ASSP
          // 1. Add Contact Email Modal
          // and 2. Send Registration Link Modal may return error messages with 200 code for validation message
          if (
            response.config.url?.includes('agent_portal/producers/contacts/agent/') &&
            response.config.url?.includes('/preferences_emails')
          ) {
            console.warn('No email preferences found.')
          } else if (
            !window.location.href.includes(internalRoutes.agentSelfServicePortalURLs.editEmailNotifications) &&
            !window.location.href.includes(internalRoutes.agentSelfServicePortalURLs.home) &&
            response.config.url !== 'lookup/cost_center'
          ) {
            throw new Error(response?.data?.messages?.join('\r\n'))
          }
        }

        // Processing Messages for ASSP
        if (window.location.href.includes(internalRoutes.agencySelfService)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data: any = response?.data
          if (!data) throw new Error('No successful response data received.')

          if (response.data.messages.length > 0) {
            // ASSP Add Contact Email Modal may return error messages with 200 code
            if (!response.data.isSuccessful) {
              const errorList = Array<string>()

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              Object.entries(data as Array<{ name: string; value: Array<string> }>).forEach(([k, v]) => {
                if (Array.isArray(v)) errorList.push(...v)
              })

              if (!errorList.length) {
                throw new Error('No error message received.')
              }

              if (
                !(
                  response.config.url?.includes('agent_portal/producers/contacts/agent/') &&
                  response.config.url?.includes('/preferences_emails')
                )
              ) {
                errorList.forEach((x: string) => {
                  store.dispatch(AlertMessagesActions.AddErrorMessage, {
                    messageText: x,
                    source: AlertMessageSource.ApiValidation,
                  })
                })
              }
            } else {
              const processingMsgList = Array<string>()
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              Object.entries(data as Array<{ name: string; value: Array<string> }>).forEach(([k, v]) => {
                if (Array.isArray(v)) processingMsgList.push(...v)
              })

              if (!processingMsgList.length) {
                throw new Error('No successful message received.')
              }
              processingMsgList.forEach((x: string) => {
                store.dispatch(AlertMessagesActions.AddInfoMessage, { messageText: x })
              })
            }
          }
        }

        return response
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onRejected: (error: AxiosError<unknown>) => {
        store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = error?.response?.data
        if (!data) throw new Error(error?.message)

        if (error?.response?.status === 400) {
          const errorList = Array<string>()

          const isEditAgency: boolean = window.location.href.includes(
            internalRoutes.agentSelfServicePortalURLs.editAgency
          )
          const isSaveContact: boolean =
            window.location.href.includes(internalRoutes.agentSelfServicePortalURLs.editContact) ||
            window.location.href.includes(internalRoutes.agentSelfServicePortalURLs.addContact)

          if (isEditAgency || isSaveContact) {
            let fieldNames
            if (isEditAgency) {
              fieldNames = agencyFieldNames
            } else {
              fieldNames = contactFieldNames
            }

            fieldNames.forEach((field) => {
              Object.entries(data as Array<{ name: string; value: Array<string> }>).forEach(([property, message]) => {
                if (Array.isArray(message)) {
                  if (field.toLowerCase() === property.toLowerCase()) {
                    errorList.push(...message)
                  }
                }
              })
            })
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(data as Array<{ name: string; value: Array<string> }>).forEach(([k, v]) => {
              if (Array.isArray(v)) errorList.push(...v)
            })
          }

          if (!errorList.length) {
            throw new Error(error?.message)
          }

          errorList.forEach((x: string) => {
            store.dispatch(AlertMessagesActions.AddErrorMessage, {
              messageText: x,
              source: AlertMessageSource.ApiValidation,
            })
          })
        } else if (error?.response?.status === 500) {
          let errorMessage = 'There was a problem with a service.'

          // Set general error message on the agency self service side
          if (window.location.href.includes(internalRoutes.agencySelfService)) {
            errorMessage =
              'We are unable to process your request due to a technical issue. Please resubmit your change at a later time.'
          }

          store.dispatch(AlertMessagesActions.AddErrorMessage, {
            messageText: errorMessage,
            source: AlertMessageSource.ApiValidation,
          })
          data.errors?.forEach((x: string) => {
            console.log(x)
          })
        }

        throw new Error(error?.message)
      },
    },
  },
}

export default function (): InterceptorCollectionInterface {
  return {
    default: interceptors.default,
  }
}
