<template>
  <app-dialog :value="value" max-width="40%">
    <template v-slot:header>
      <alert-messages :sources="['address-validation']" />
    </template>
    <template v-slot:title>
      <slot name="title"></slot>
    </template>
    <template v-slot:subtitle v-if="suggestedAddress">
      Our system has located a similar address to the one you have entered. Please verify your selection below and
      continue.
    </template>
    <template v-slot:subtitle v-else>
      We are sorry, we were unable to locate the address you previously entered. Please select from the options below to
      continue.
    </template>
    <template v-slot:content>
      <v-radio-group v-model="selection" mandatory>
        <v-row>
          <v-col :cols="5">
            <v-radio v-if="suggestedAddress" label="System-Matched address:" :value="AddressChoice.Suggested" />
            <v-radio v-else label="Return and update your address" :value="AddressChoice.ReturnAndEdit" />
          </v-col>
          <v-col :cols="7" v-if="suggestedAddress">{{ suggestedAddressString }}</v-col>
        </v-row>
        <v-row>
          <v-col :cols="5">
            <v-radio label="Keep the address you entered:" :value="AddressChoice.Current" />
          </v-col>
          <v-col :cols="7">{{ currentAddressString }}</v-col>
        </v-row>
      </v-radio-group>
    </template>
    <template v-slot:actions>
      <simple-button @click="exit" class="ma-3" color="primary">Continue</simple-button>
    </template>
  </app-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'

import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import store from '@/store'
import { AddressInterface } from '@/store/modules/client/types'
import AppDialog from '@/components/common/AppDialog.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import enums from '@/constants/enumerations'

export default defineComponent({
  name: 'AddressValidation',
  components: {
    AlertMessages,
    AppDialog,
    SimpleButton,
  },
  props: {
    currentAddress: {
      required: false,
      type: Object as PropType<AddressInterface>,
    },
    suggestedAddress: {
      required: false,
      type: Object as PropType<AddressInterface>,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selection = ref<number>()

    const currentAddressString = computed<string>(() => {
      return props.currentAddress
        ? new Array<string>(
            props.currentAddress.streetAddress,
            props.currentAddress.secondaryAddress,
            props.currentAddress.city,
            props.currentAddress.state,
            props.currentAddress.zipCode
          ).join(' ')
        : ''
    })

    const suggestedAddressString = computed<string>(() => {
      return props.suggestedAddress
        ? new Array<string | undefined>(
            props.suggestedAddress?.streetAddress,
            props.suggestedAddress?.secondaryAddress,
            props.suggestedAddress?.city,
            props.suggestedAddress?.state,
            props.suggestedAddress?.zipCode
          ).join(' ')
        : ''
    })

    enum AddressChoice {
      Suggested,
      Current,
      ReturnAndEdit,
    }

    /** Exits the dialog. */
    async function exit() {
      if (selection.value === AddressChoice.Suggested) {
        emit('select-suggested-address', props.suggestedAddress)
      }

      if (selection.value === AddressChoice.Current) {
        emit('select-current-address')
      }

      if (selection.value === AddressChoice.ReturnAndEdit) {
        store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.AddressValidation })
        emit('return-and-edit')
      }
    }

    return {
      AddressChoice,
      currentAddressString,
      enums,
      exit,
      selection,
      suggestedAddressString,
    }
  },
})
</script>
