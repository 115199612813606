<template>
  <div>
    <p>{{ agency.name }}</p>
    <p>{{ agency.mailingAddress.street }}</p>
    <p>
      {{ agency.mailingAddress.city }},
      {{ agency.mailingAddress.state }}
      {{ agency.mailingAddress.zip }}
    </p>
    <p>Phone: {{ agency.phone }}</p>
    <p>Fax: {{ agency.fax }}</p>
    <p>{{ agency.email }}</p>
  </div>
</template>

<script lang="ts">
import { AgencyInterface } from '@/store/modules/agency/types'
import { PropType } from 'vue'

export default {
  props: {
    agency: {
      type: Object as PropType<AgencyInterface>,
      required: true,
    },
  },
}
</script>

<style></style>
