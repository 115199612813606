<template>
  <v-row no-gutters v-if="showEmails(model.category)">
    <v-col class="col">
      <p class="bold black--text d-inline">{{ model.category }}</p>
      <app-tooltip class="px-1 d-inline" :persistOnClick="true">
        <slot> {{ model.helpText }} </slot>
      </app-tooltip>

      <v-row no-gutters>
        <v-col cols="2" no-gutters>
          <div><em>Primary:</em></div>
        </v-col>
        <v-col cols="6" no-gutters>
          <simple-auto-complete
            v-model="model.primary"
            :items="items"
            :totalNumberOfItems="totalNumberOfItems"
            :itemTextName="itemTextName"
            :itemValueName="itemValueName"
            @loadMore="loadMoreEmails"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="2" no-gutters>
          <div><em>Secondary:</em></div>
        </v-col>
        <v-col cols="6" no-gutters>
          <simple-auto-complete
            v-model="model.secondary"
            :items="items"
            :totalNumberOfItems="totalNumberOfItems"
            :itemTextName="itemTextName"
            :itemValueName="itemValueName"
            @loadMore="loadMoreEmails"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SimpleAutoComplete from '@/components/common/input/SimpleAutoComplete.vue'
import store from '@/store'
import { AgencyContactPreferencesEmailsActions } from '@/store/modules/agency-contact-preferences-emails/actions'
import { AgencyContactPreferencesEmails, Email } from '@/store/modules/agency-contact-preferences-emails/types'
import Register from '@/utils/ProcessingMaskHelpers'
import ShowAgencyEmailNotification from '@/utils/ShowAgencyEmailNotification'
import { computed, defineComponent, PropType } from 'vue'
import SimpleTooltip from './SimpleTooltip.vue'

export default defineComponent({
  name: 'AppEmailNotificationDropdowns',
  components: {
    'app-tooltip': SimpleTooltip,
    SimpleAutoComplete,
  },
  props: {
    emailListResult: {
      required: true,
      type: Array as PropType<Array<Email>>,
    },
    totalNumberOfEmails: {
      required: false,
      type: Number,
      default: 0,
    },
    value: {
      required: false,
      type: Object as PropType<AgencyContactPreferencesEmails>,
      default: () => new AgencyContactPreferencesEmails(),
    }
  },

  setup(props, context) {
    const model = computed<AgencyContactPreferencesEmails>({
      get: () => props.value ?? '',
      set: (newValue) => context.emit('input', newValue),
    })

    const items = computed<Array<Email>>(() => props.emailListResult)
    const totalNumberOfItems = computed<number>(() => props.totalNumberOfEmails)
    const itemTextName = 'contactEmail'
    const itemValueName = 'contactId'

    function loadMoreEmails(pagination: number) {
      Register(
        store.dispatch(AgencyContactPreferencesEmailsActions.GetEmailListFromSession, { pagination })
      )
    }

    function showEmails(category: string) {
      return ShowAgencyEmailNotification(category)
    }

    const attrs = computed(() => context.attrs)
    const listeners = computed(() => context.listeners)

    return {
      attrs,
      items,
      itemTextName,
      itemValueName,
      listeners,
      loadMoreEmails,
      model,
      showEmails,
      totalNumberOfItems
    }
  },
})
</script>

<style lang="scss" scoped>
:deep() {
  @import '@/styles/_inputField.scss';
}
</style>
