<template>
  <v-dialog v-model="dialog" persistent :max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <app-button
        color="secondary"
        class="mx-2 white--text"
        v-bind="attrs"
        v-on="on"
        v-on:click="ClearAlertMessages"
      >
        Cancel
      </app-button>
    </template>
    <v-card>
      <v-card-text class="pt-1"><alert-messages /></v-card-text>
      <v-card-title class="text-h5"> Are you sure you want to cancel? </v-card-title>
      <v-card-text>If you click cancel, your changes will not be saved.</v-card-text>
      <v-card-actions class="mx-auto">
        <v-spacer></v-spacer>
        <app-button class="primary" color="white" text v-on:click="returnToHomePage"> Yes </app-button>
        <v-spacer></v-spacer>
        <app-button class="secondary" color="white" text @click="closeAndGoBack"> No </app-button>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'

import AlertMessages from '@/components/common/AlertMessages.vue'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import store from '@/store'

import ClearAlertMessages from '@/utils/ClearAlertMessages'
import ReturnToHomePage from "@/utils/ReturnToHomePage"

export default defineComponent({
  name: 'AppModal',
  components: {
    'alert-messages': AlertMessages,
    'app-button': SimpleButton,
  },
  setup() {
    const dialog = ref(false)

    const closeAndGoBack = () => {
      dialog.value = false
      store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })
    }

    const returnToHomePage = () => ReturnToHomePage()

    return {
      ClearAlertMessages,
      closeAndGoBack,
      dialog,
      returnToHomePage
    }
  }
})
</script>

<style lang="scss" scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
