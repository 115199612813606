/**
 * The login type enum. Should match PL and GrandCentral exactly.
 */
export enum LoginType {
  Hr = 0,
  Agent = 1,
  Insured = 2,
  SelfFunded = 3,
  Claimant = 4,
  Uninitialized = 5,
}

export enum LoginProviderType {
  Uninitialized = 'Uninitialized',
  GrandCentral = 'GrandCentral',
  Nis = 'Nis',
  System = 'System',
  GrandCentralAgent = 'GrandCentralAgent',
  GrandCentralHr = 'GrandCentralHr',
  GrandCentralInsured = 'GrandCentralInsured',
  GrandCentralClaimant = 'GrandCentralClaimant',
  GrandCentralSelfFunded = 'GrandCentralSelfFunded',
}

export interface SubProductInterface {
  subProduct: string
  site: string
  uiDisplay: string
  product: string
  uiDisplayOrder: string
  packageSubProduct: string
  isAncillaryInd: boolean
}

export class SubProduct implements SubProductInterface {
  subProduct: string
  site: string
  uiDisplay: string
  product: string
  uiDisplayOrder: string
  packageSubProduct: string
  isAncillaryInd: boolean

  constructor(options?: {
    subProduct: string
    site: string
    uiDisplay: string
    product: string
    uiDisplayOrder: string
    packageSubProduct: string
    isAncillaryInd: boolean
  }) {
    this.subProduct = options?.subProduct ?? ''
    this.site = options?.site ?? ''
    this.uiDisplay = options?.uiDisplay ?? ''
    this.product = options?.product ?? ''
    this.uiDisplayOrder = options?.uiDisplayOrder ?? ''
    this.packageSubProduct = options?.packageSubProduct ?? ''
    this.isAncillaryInd = options?.isAncillaryInd ?? false
  }
}

export interface UserStateProductsInterface {
  agentId: number
  state: string
  site: string
  subProducts: Array<SubProductInterface>
}

export class UserStateProducts implements UserStateProductsInterface {
  agentId: number
  state: string
  site: string
  subProducts: Array<SubProductInterface>

  constructor(options?: { agentId: number; state: string; site: string; subProducts: Array<SubProductInterface> }) {
    this.agentId = options?.agentId ?? 0
    this.state = options?.state ?? ''
    this.site = options?.site ?? ''
    this.subProducts = options?.subProducts.map((subProduct: SubProductInterface) => new SubProduct(subProduct)) ?? []
  }
}

/**
 *  Interface containing user session information.
 */
export interface UserSessionInterface {
  agentGroup: number
  agentId: number
  canViewCommissionStatement: boolean
  emailAddress: string
  employeeNum: number
  environment: string
  firstName: string
  generatedFrom: string
  homeOfficeId: number
  initialState: string
  isAgencyAdmin: boolean | null
  isAgencyPrimaryLogin: boolean
  isAgent: boolean
  isDaStore: boolean
  isHomeOffice: boolean
  isInternalSales: boolean
  isMGA: boolean
  isSuperUser: boolean
  lastName: string
  loginProviderType: LoginProviderType
  loginType: LoginType
  masterAccountId: number
  masterUserId: string | null
  mode: string
  nisUserId: string
  officeAccountCode: number
  originUrl: string
  permissions: Array<string>
  policyId: number
  princetonAgentCode: string
  product: string
  quoteId: number
  role: string
  subProduct: string
  username: string
  userProductOptions: Array<SubProductInterface>
  userStateOptions: Array<string>
}

/**
 *  The user session information.
 */
export class UserSession implements UserSessionInterface {
  agentGroup: number
  agentId: number
  canViewCommissionStatement: boolean
  emailAddress: string
  employeeNum: number
  environment: string
  firstName: string
  generatedFrom: string
  homeOfficeId: number
  initialState: string
  isAgencyAdmin: boolean | null
  isAgencyPrimaryLogin: boolean
  isAgent: boolean
  isDaStore: boolean
  isHomeOffice: boolean
  isInternalSales: boolean
  isMGA: boolean
  isSuperUser: boolean
  lastName: string
  loginProviderType: LoginProviderType
  loginType: LoginType
  masterAccountId: number
  masterUserId: string | null
  mode: string
  nisUserId: string
  officeAccountCode: number
  originUrl: string
  permissions: Array<string>
  policyId: number
  princetonAgentCode: string
  product: string
  quoteId: number
  role: string
  subProduct: string
  username: string
  userProductOptions: Array<SubProductInterface>
  userStateOptions: Array<string>

  /**
   *  Initializes the user session information.
   */
  constructor(options?: {
    agentGroup: number
    agentId: number
    canViewCommissionStatement: boolean
    emailAddress: string
    employeeNum: number
    environment: string
    firstName: string
    generatedFrom: string
    homeOfficeId: number
    initialState: string
    isAgencyAdmin: boolean | null
    isAgencyPrimaryLogin: boolean
    isAgent: boolean
    isDaStore: boolean
    isHomeOffice: boolean
    isInternalSales: boolean
    isMGA: boolean
    isSuperUser: boolean
    lastName: string
    loginProviderType: LoginProviderType
    loginType: LoginType
    masterAccountId: number
    masterUserId: string | null
    mode: string
    nisUserId: string
    officeAccountCode: number
    originUrl: string
    permissions: Array<string>
    policyId: number
    princetonAgentCode: string
    product: string
    quoteId: number
    role: string
    subProduct: string
    username: string
    userProductOptions: Array<SubProductInterface>
    userStateOptions: Array<string>
  }) {
    this.agentGroup = options?.agentGroup ?? 0
    this.agentId = options?.agentId ?? 0
    this.canViewCommissionStatement = options?.canViewCommissionStatement ?? false
    this.emailAddress = options?.emailAddress ?? ''
    this.employeeNum = options?.employeeNum ?? 0
    this.environment = options?.environment ?? ''
    this.firstName = options?.firstName ?? ''
    this.generatedFrom = options?.generatedFrom ?? ''
    this.homeOfficeId = options?.homeOfficeId ?? 0
    this.initialState = options?.initialState ?? ''
    this.isAgencyAdmin = options?.isAgencyAdmin ?? null
    this.isAgencyPrimaryLogin = options?.isAgencyPrimaryLogin ?? false
    this.isAgent = options?.isAgent ?? true
    this.isDaStore = options?.isDaStore ?? false
    this.isHomeOffice = options?.isHomeOffice ?? false
    this.isInternalSales = options?.isInternalSales ?? true
    this.isMGA = options?.isMGA ?? false
    this.isSuperUser = options?.isSuperUser ?? false
    this.lastName = options?.lastName ?? ''
    this.loginProviderType = options?.loginProviderType ?? LoginProviderType.Uninitialized
    this.loginType = options?.loginType ?? LoginType.Uninitialized
    this.masterAccountId = options?.masterAccountId ?? 0
    this.masterUserId = options?.masterUserId ?? null
    this.mode = options?.mode ?? ''
    this.nisUserId = options?.nisUserId ?? ''
    this.officeAccountCode = options?.officeAccountCode ?? 0
    this.originUrl = options?.originUrl ?? ''
    this.permissions = options?.permissions ?? []
    this.policyId = options?.policyId ?? 0
    this.princetonAgentCode = options?.princetonAgentCode ?? ''
    this.product = options?.product ?? ''
    this.quoteId = options?.quoteId ?? 0
    this.role = options?.role ?? ''
    this.subProduct = options?.subProduct ?? ''
    this.username = options?.username ?? ''
    this.userProductOptions = options?.userProductOptions ?? []
    this.userStateOptions = options?.userStateOptions ?? []
  }
}
