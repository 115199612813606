import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { ClientListInterface } from './types'

export class ClientGetters {
  public static Clients = 'client/clients'
  public static NewClient = 'client/newClient'
  public static AccountHolderId = 'client/accountHolderId'
  public static InitialState = 'client/initialState'
  public static Product = 'client/product'
  public static SelectedBusinessName = 'client/selectedBusinessName'
  public static SubProduct = 'client/subProduct'
  public static DisablePrefilledFields = 'client/disablePrefilledFields'
}

export const getters: GetterTree<ClientListInterface, RootState> = {
  clients: (state: ClientListInterface) => state.clientList,
  newClient: (state: ClientListInterface) => state.newClient,
  accountHolderId: (state: ClientListInterface) => state.newClient.accountHolderId,
  initialState: (state: ClientListInterface) => state.newClient.initialState,
  product: (state: ClientListInterface) => state.newClient.product,
  selectedBusinessName: (state: ClientListInterface) => state.selectedBusinessName,
  subProduct: (state: ClientListInterface) => state.newClient.subProduct,
  disablePrefilledFields: (state: ClientListInterface) => state.disablePrefilledFields,
}

export default getters
