import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { NavigationInformationInterface, NavigationItemWithChildrenInterface } from '../navigation/types'

export class NavigationCalloutGetters {
  public static GetMainNavigationItems = 'navigationCallouts/getMainNavigationItems'
}

export const getters: GetterTree<NavigationInformationInterface, RootState> = {
  getMainNavigationItems: (state): Array<NavigationItemWithChildrenInterface> => state.mainNavigationItems
}

export default getters
