<template>
  <v-container>
    <router-view />
  </v-container>
</template>

<script lang="ts">
import store from '@/store'
import { NavigationActions } from '@/store/modules/navigation/actions'
import { EventBus, Events } from '@/utils/EventBus'
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  name: 'ClientSearchHome',
  components: {},
  setup() {
    EventBus.emit(Events.showLayout)

    onMounted(async () => {
      // Had to put this here instead of MainNavigation.vue due to a token-based race condition
      store.dispatch(NavigationActions.PopulateNavigation)
    })
    return {}
  },
})
</script>
