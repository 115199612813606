import enums from '@/constants/enumerations'
import internalRoutes from '@/constants/internalRoutes'
import ClientSearchHome from '@/views/client/ClientSearchHome.vue'
import BusinessSearchInfo from '@/views/client/sub-routes/BusinessSearchInfo.vue'
import ClientEntry from '@/views/client/sub-routes/ClientEntry.vue'
import ClientSearch from '@/views/client/sub-routes/ClientSearch.vue'
import ClientView from '@/views/client/sub-routes/ClientView.vue'
import { RouteConfig } from 'vue-router'

const ClientWorkflow: RouteConfig = {
  path: internalRoutes.client,
  redirect: `${internalRoutes.client}/search`,
  name: enums.pageName.ClientSearch,
  component: ClientSearchHome,
  children: [
    {
      path: 'entry',
      name: enums.pageName.ClientEntry,
      component: ClientEntry,
    },
    {
      path: 'search',
      component: ClientSearch,
    },
    {
      path: 'view',
      name: enums.pageName.ClientView,
      component: ClientView,
    },
    {
      path: 'business-search-info',
      name: enums.pageName.BusinessSearchInfo,
      component: BusinessSearchInfo,
    },
  ],
}

export default ClientWorkflow
