import useApis from '@/api'
import enumerations from '@/constants/enumerations'
import { PhoneNumber } from '@/models/phone-number'
import store from '@/store'
import { ClientActions } from '@/store/modules/client/actions'
import { RoutingActionResult } from '@/store/modules/gateway-callouts/types'
import { RootState } from '@/store/types'
import { htmlDecode } from '@/utils/Formatter'
import tokenService, { TokenInterface } from '@/utils/tokenService'
import { ActionTree } from 'vuex'
import { BusinessSearchActions } from '../business-search/actions'
import { Address, Phone } from '../client/types'
import { UserSessionApiResponse } from '../user-session-callouts/types'
import { UserSessionActions } from '../user-session/actions'
import { LoginProviderType, UserSession } from '../user-session/types'

/**
 * The gateway callout actions.
 */
export class GatewayCalloutActions {
  /** Loads the load initial access token */
  public static LoadAccessToken = 'gatewayCallouts/loadAccessToken'
  /** Loads the amt instance */
  public static LoadAmtInstance = 'gatewayCallouts/loadAmtInstance'
}

const { standard: api } = useApis()

/**
 * The gateway callouts action tree.
 */
export const actions: ActionTree<Record<string, never>, RootState> = {
  async loadAccessToken(_context, payload: { instanceId: string }): Promise<boolean> {
    try {
      const request = { id: payload.instanceId }

      const tokenResponse = await api.post('agent_portal/authorization', request, { withCredentials: true })

      const appToken: TokenInterface = {
        // Set the token "READY FOR" token refresh 15 minutes before it actually expires
        expiration: new Date(Date.now() + (tokenResponse.data.value.appTokenExpiresIn - 900) * 1000),
        type: tokenResponse.data.value.appTokenType,
        value: tokenResponse.data.value.appToken,
      }

      tokenService.loadInitialToken(appToken)

      return true
    } catch (reason) {
      console.error('gateway-callouts :: loadInitialToken :: failed:', reason)
      return false
    }
  },

  async loadAmtInstance(_context, payload: { instanceId: string }): Promise<RoutingActionResult | false> {
    try {
      const request = { id: payload.instanceId }

      const userSessionResponse = await api.post('agent_portal/grand_central/session_information', request)

      const userSession: UserSessionApiResponse = userSessionResponse.data.value

      const userInfo = new UserSession({
        agentGroup: userSession.agentGroup ?? 0,
        agentId: userSession.agentId ?? 0,
        canViewCommissionStatement: userSession.canViewCommissionStatement ?? false,
        emailAddress: userSession.emailAddress ?? '',
        employeeNum: userSession.employeeNum ?? 0,
        environment: userSession.environment ?? '',
        firstName: userSession.firstName ?? '',
        generatedFrom: userSession.generatedFrom ?? '',
        homeOfficeId: Number(userSession.homeOffice) ?? 0,
        initialState: userSession.state ?? '',
        isAgencyAdmin: userSession.isAgencyAdmin ?? null,
        isAgencyPrimaryLogin: userSession.isAgencyPrimaryLogin ?? false,
        isAgent: userSession.isAgent ?? false,
        isDaStore: userSession.isDaStore ?? false,
        isHomeOffice: Number(userSession.homeOffice) === userSession.agentId ?? false,
        isInternalSales: true,
        isMGA: userSession.isMGA ?? false,
        isSuperUser: userSession.isSuperUser ?? false,
        lastName: userSession.lastName ?? '',
        loginProviderType: userSession.loginProviderType ?? LoginProviderType.Uninitialized,
        loginType: Number(userSession.loginType) ?? 0,
        masterAccountId: userSession.masterAccountId ?? 0,
        masterUserId: userSession.masterUserId ?? null,
        nisUserId: userSession.nisUserId ?? '',
        mode: userSession.mode ?? '',
        officeAccountCode: userSession.officeAccountCode ?? 0,
        originUrl: userSession.originUrl ?? '',
        permissions: userSession.permissions ?? [],
        policyId: userSession.policyId ?? 0,
        princetonAgentCode: userSession.princetonAgentCode ?? '',
        product: userSession.product ?? '',
        quoteId: userSession.quoteId ?? 0,
        role: userSession.role ?? '',
        subProduct: userSession.subProduct ?? '',
        username: userSession.username ?? '[No Username]',
        userProductOptions: [],
        userStateOptions: [],
      })

      await store.dispatch(UserSessionActions.SetUserInformation, { userInfo })

      if (userSession.mode === enumerations.appMode.ClientSearch) {
        if (
          userSession.entryPoint === enumerations.entryPoint.ClientEntryRewrite ||
          userSession.entryPoint === enumerations.entryPoint.ClientEntryCrossSell
        ) {
          let hasUSDot: string =
            !Number.isNaN(userSession.clientUsdotNumber) && Number(userSession.clientUsdotNumber) > 0
              ? enumerations.usdotSelectedOption.Yes
              : enumerations.usdotSelectedOption.NotRequired
          let businessEntity = userSession.clientBusinessEntity
          if (userSession.entryPoint === enumerations.entryPoint.ClientEntryCrossSell) {
            businessEntity = enumerations.entityType.Individual
            hasUSDot = enumerations.usdotSelectedOption.None
          }
          const tempUsdotNum = Number.parseInt(userSession.clientUsdotNumber ?? '')
          store.dispatch(ClientActions.SetNewClient, {
            accountHolderId: userSession.accountHolderId,
            initialState: userSession.state,
            product: userSession.product,
            subProduct: userSession.subProduct,
            agentId: userSession.agentId,
            person: {
              firstName: userSession.clientFirstName ?? '',
              lastName: userSession.clientLastName ?? '',
              birthDate: '',
            },
            business: {
              businessName: htmlDecode(userSession.clientBusinessName ?? ''),
            },
            businessAddress: new Address({
              addressType: enumerations.addressType.Business,
              city: userSession.clientCity ?? '',
              secondaryAddress: userSession.clientAddress2 ?? '',
              state: userSession.clientState ?? '',
              streetAddress: userSession.clientAddress1 ?? '',
              zipCode: userSession.clientZip?.substring(0, 5) ?? '',
              country: '',
              zipPlusFour: '',
            }),
            addresses: [
              new Address({
                addressType: enumerations.addressType.Business,
                city: userSession.clientCity ?? '',
                secondaryAddress: userSession.clientAddress2 ?? '',
                state: userSession.clientState ?? '',
                streetAddress: userSession.clientAddress1 ?? '',
                zipCode: userSession.clientZip ?? '',
                country: '',
                zipPlusFour: '',
              }),
            ],
            phoneNumbers: [
              new Phone({
                phoneNumber: userSession.clientPhoneNumber ?? '',
                phoneType: userSession.clientPhoneType ?? '',
                countryCode: '',
                extension: '',
                priority: 0,
                splitPhoneNumber: new PhoneNumber(''),
              }),
            ],
            emailAddresses: [
              {
                emailAddress: userSession.clientEmail ?? '',
                emailType: enumerations.emailType.Work,
                priority: 0,
              },
            ],
            clientDetail: {
              businessEntityType: businessEntity,
              doingBusinessAs: userSession.clientDoingBusinessAs ?? '',
              clientHasUsdotType: hasUSDot,
              motorCarrierForHire: userSession.motorCarrierForHire,
              usdotNumber: isNaN(tempUsdotNum) ? null : tempUsdotNum,
              occupation: '',
            },
            workEmailAddress: {
              emailAddress: userSession.clientEmail ?? '',
              emailType: enumerations.emailType.Work,
              priority: 0,
            },
          })
        } else {
          store.dispatch(ClientActions.SetNewClient, {
            accountHolderId: userSession.accountHolderId,
            initialState: userSession.state,
            product: userSession.product,
            subProduct: userSession.subProduct,
          })
        }

        if (userSession.entryPoint === enumerations.entryPoint.NeedHelp)
          store.dispatch(BusinessSearchActions.SetMCFHReadOnly, userSession.motorCarrierForHire)
      }

      return {
        mode: userSession.mode ?? '',
        entryPoint: userSession.entryPoint ?? '',
      } as RoutingActionResult
    } catch (reason) {
      console.error('gateway-callouts :: loadAmtInstance :: failed:', reason)
      return false
    }
  },
}

export default actions
