<template>
  <v-tooltip open-on-click top :open-on-hover="openOnHover" v-model="showTooltip">
    <template v-slot:activator="{ on, $attrs }">
      <span v-bind="$attrs" v-on="{ ...on, ...$listeners }" @click="togglePersist()">
        <slot name="activator">
          <v-icon class="material-icons mdi-18px" v-bind="$attrs" v-on="on">{{ materialIcon }}</v-icon>
        </slot>
      </span>
    </template>
    <div class="tooltip">
      <v-icon v-if="showClose" class="material-icons get-click" align="right" @click="manualClose()">
        mdi-close-box
      </v-icon>
      <slot></slot>
    </div>
  </v-tooltip>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'SimpleTooltip',
  props: {
    materialIcon: {
      required: false,
      type: String,
      default: 'mdi-help-circle',
    },
    persistOnClick: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const openOnHover = ref(true)
    const showTooltip = ref(false)

    const showClose = computed(() => {
      return !openOnHover.value && props.persistOnClick
    })

    function togglePersist() {
      if (props.persistOnClick) {
        openOnHover.value = !openOnHover.value
      }
    }

    function manualClose() {
      openOnHover.value = true
      showTooltip.value = false
    }

    return {
      manualClose,
      openOnHover,
      showClose,
      togglePersist,
      showTooltip,
    }
  },
})
</script>
<style scoped lang="scss">
.v-tooltip__content {
  background: rgba(204, 204, 204, 1) none repeat scroll 0% 0%;
  border: 5px solid rgb(102, 102, 102);
  opacity: 1 !important;
  color: #000;
  text-decoration: none;
  padding: 2px;
  pointer-events: initial;
}

.get-click {
  z-index: 2000;
}
</style>
