import { RootState } from '@/store/types'
import { Module } from 'vuex'
import actions from './actions'

export const systemTransferCallouts: Module<Record<string, never>, RootState> = {
  namespaced: true,
  actions,
}

export default systemTransferCallouts
