import Axios from 'axios'
import dayjs from 'dayjs'

import { ApiResponse } from '@/models/api'
import startWorker from './startWorker'
import tokenTimer from './tokenTimer'

/**
 * The token interface.
 */
export interface TokenInterface {
  expiration: Date
  type: string
  value: string
}

/**
 * The token model.
 */
export class Token implements TokenInterface {
  expiration: Date
  type: string
  value: string

  /**
   * Creates a new instance of the token model.
   */
  constructor(options: { expiration: Date; type: string; value: string }) {
    this.expiration = options?.expiration
    this.type = options?.type
    this.value = options?.value
  }
}

/** The api response type of the get app token interface. */
export interface GetAppTokenApiResponse {
  appToken?: string
  appTokenExpiresIn?: number
  appTokenType: string
}

class TokenService {
  private token: TokenInterface | undefined
  private tokenTimerWorker: Worker | undefined
  private attempts = 0

  async getToken(): Promise<TokenInterface> {
    if (this.token && dayjs().isBefore(dayjs(this.token.expiration))) return this.token

    await this.refreshToken()

    if (!this.token) {
      this.logOut()
      throw Error('There was an error getting the token')
    }

    return this.token
  }

  loadInitialToken(appToken: TokenInterface) {
    this.token = appToken
    this.startTokenTimerWorker()
  }

  private async logOut() {
    await Axios.create({baseURL: process.env.VUE_APP_GATEWAY_URL})
      .post('agent_portal/authorization/logout',  {}, { withCredentials: true })

    const transferUrl = `${process.env.VUE_APP_PL_URL}/Logout.aspx`
    window.open(transferUrl, '_self')
  }

  private async refreshToken() {
    try {
      return await Axios.create({ baseURL: process.env.VUE_APP_GATEWAY_URL })
        .post<ApiResponse<GetAppTokenApiResponse>>('agent_portal/user_session', {}, { withCredentials: true })
        .then((response) => {
          if (!response?.data?.value?.appToken) {
            throw new Error(response?.data?.messages?.join('\n'))
          }

          this.token = this.transformToken(response.data.value)
          this.stopTokenTimerWorker()
          this.startTokenTimerWorker()
          this.attempts = 0
        })
    } catch (reason) {
      this.attempts += 1
      if (this.attempts < 3) {
        await this.refreshToken()
      } else {
        this.logOut()
      }
      console.error('POST tokenService :: refreshToken :: failed', reason)
    }
  }

  private transformToken(tokenResponse: GetAppTokenApiResponse): TokenInterface {
    const value = tokenResponse.appToken ?? ''
    // Set the token "READY FOR" token refresh 15 minutes before it actually expires
    const expiration = new Date(Date.now() + ((tokenResponse.appTokenExpiresIn ?? 0) - 900) * 1000)
    const type = tokenResponse.appTokenType ?? ''
    return { value, expiration, type }
  }

  private startTokenTimerWorker() {
    if (window.Worker) {
      if (this.tokenTimerWorker === undefined) {
        this.tokenTimerWorker = startWorker(tokenTimer)

        this.tokenTimerWorker.onmessage = async () => {
          await this.getToken()
        }
      } else {
        console.error('There is already a token timer running.')
      }
    } else {
      console.error('Web worker is not supported on this browser.')
    }
  }

  private stopTokenTimerWorker() {
    if (this.tokenTimerWorker !== undefined) {
      this.tokenTimerWorker.terminate()
      this.tokenTimerWorker = undefined
    } else {
      console.error('No web worker for the token timer has started yet.')
    }
  }
}

const tokenService = new TokenService()

export default tokenService
