import { MutationTree } from 'vuex'

import { AlertMessagesInterface, AlertMessageInterface, AlertMessageSource } from './types'

export class AlertMessagesMutations {
  public static ADD_MESSAGE = 'ADD_MESSAGE'
  public static ADD_MESSAGES = 'ADD_MESSAGES'
  public static CLEAR_MESSAGES = 'CLEAR_MESSAGES'
  public static CLEAR_MESSAGESBYSOURCE = 'CLEAR_MESSAGESBYSOURCE'
}

export const mutations: MutationTree<AlertMessagesInterface> = {
  ADD_MESSAGE (state: AlertMessagesInterface, message: AlertMessageInterface) {
    state.alertMessages.push(message)
  },
  ADD_MESSAGES (state: AlertMessagesInterface, messages: Array<AlertMessageInterface>) {
    state.alertMessages.push(...messages)
  },
  CLEAR_MESSAGES (state: AlertMessagesInterface) {
    state.alertMessages = []
  },
  CLEAR_MESSAGESBYSOURCE (state: AlertMessagesInterface, payload: { source: AlertMessageSource }) {
    state.alertMessages = state.alertMessages.filter(x => x.source !== payload.source)
  }
}

export default mutations
