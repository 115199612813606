import { MutationTree } from "vuex";
import { OccupationInterface, SubCategory } from "../occupation/types";
import { OccupationFilterInterface } from "./types";


export class OccupationFilterMutations {
    public static SET_FILTERED_OCCUPATION_LIST = 'SET_FILTERED_OCCUPATION_LIST'
    public static SET_SEARCH_STRING = 'SET_SEARCH_STRING'
    public static SET_SELECTED_OCCUPATION_CATEGORIES = 'SET_SELECTED_OCCUPATION_CATEGORIES'
    public static SET_SELECTED_SUBCATEGORIES = 'SET_SELECTED_SUBCATEGORIES'
}

export const mutations: MutationTree<OccupationFilterInterface> = {
    SET_FILTERED_OCCUPATION_LIST(state: OccupationFilterInterface, occupations: Array<OccupationInterface>) {
        state.filteredOccupationList = occupations
    },
    SET_SEARCH_STRING(state: OccupationFilterInterface, searchString: string) {
        state.searchString = searchString
    },
    SET_SELECTED_OCCUPATION_CATEGORIES(state: OccupationFilterInterface, categories: Array<OccupationInterface>) {
        state.selectedOccupationCategories = categories
    },
    SET_SELECTED_SUBCATEGORIES(state: OccupationFilterInterface, subCategories: Array<SubCategory>) {
        state.selectedSubCategories = subCategories
    },
}

export default mutations