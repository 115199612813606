import { RouteConfig } from 'vue-router'
import internalRoutes from '@/constants/internalRoutes'

import AgencySelfService from '@/views/agency-self-service/AgencySelfService.vue'
import AgencyHome from '@/views/agency-self-service/sub-routes/AgencyHome.vue'
import EditAgency from '@/views/agency-self-service/sub-routes/EditAgency.vue'
import SaveContact from '@/views/agency-self-service/sub-routes/SaveContact.vue'
import EditEmailNotifications from '@/views/agency-self-service/sub-routes/EditEmailNotifications.vue'

const AgencyWorkflow: RouteConfig = {
    path: internalRoutes.agencySelfService,
    redirect: internalRoutes.agencySelfService + '/home',
    name: 'Agency Self Service',
    component: AgencySelfService,
    children: [
        {
            name: 'Home',
            path: 'home',
            meta: { title: '' },
            component: AgencyHome
        },
        {
            name: 'EditAgency',
            path: 'edit-agency',
            meta: { title: 'Edit Agency' },
            component: EditAgency
        },
        {
            name: 'EditContact',
            path: 'edit-contact',
            meta: { title: 'Edit Contact' },
            component: SaveContact
        },
        {
            name: 'AddContact',
            path: 'add-contact',
            meta: { title: 'Add Contact' },
            component: SaveContact
        },
        {
            name: 'EditEmailNotifications',
            path: 'edit-email-notifications',
            meta: { title: 'Edit Email Notifications' },
            component: EditEmailNotifications
        },
    ]
}

export default AgencyWorkflow
