<template>
  <v-container fluid>
    <v-layout wrap justify-space-around>
      <v-flex v-for="policy in policyDataToShow" :key="policy.policyId">
        <v-card outlined class="pa-3">
          <v-row class="pl-10 pr-5 py-5">
            <v-col cols="3">
              <v-row class="pt-1 font-weight-bold" v-if="!isAgent">
                <div class="pr-2">Agent: {{ policy.agencyResults.princetonAgtCode }}</div>
                <simple-tooltip>
                  <agent :agency="policy.agencyResults"></agent>
                </simple-tooltip>
              </v-row>
              <v-row>
                <simple-button plain link class="font-weight-bold" @click="goToPolicySummary(policy)">
                  {{ policy.policyNumber }} - {{ policy.policySuffix }}
                </simple-button>
              </v-row>
              <v-row>{{ policy.effectiveDate }} - {{ policy.expirationDate }} </v-row>
              <v-row>
                <span class="font-weight-bold">{{ policy.state }}</span>
              </v-row>
              <v-row class="ml-5 mt-n1">
                <img
                  :src="imagePath(policy.product)"
                  :alt="imageAlt(policy.product)"
                  :width="imageWidth(policy.product)"
                />
              </v-row>
            </v-col>
            <v-col cols="7" class="pt-1">
              <v-row no-gutters>
                <v-col class="col-border-bottom py-1" cols="6">
                  <span :class="getStatusClass(policy.policyStatus)">{{ policy.detailedPolicyStatus }}</span>
                </v-col>
                <v-col class="col-border-bottom py-1" cols="5" align="right">
                  <span :class="getCanceledDateClass(policy.canceledDate)">{{ policy.canceledDate }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="col-border-bottom font-weight-bold py-1" cols="6"><span>Next Amount Due:</span></v-col>
                <v-col class="col-border-bottom py-1" cols="5" align="right">
                  <span>{{ policy.nextAmountDue }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="col-border-bottom font-weight-bold py-1" cols="6"><span>Amount Due On:</span></v-col>
                <v-col class="col-border-bottom py-1" cols="5" align="right">
                  <span>{{ policy.nextAmountDueDate }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="col-border-bottom font-weight-bold py-1" cols="6"><span>Pay Method:</span></v-col>
                <v-col class="col-border-bottom py-1" cols="5" align="right">
                  <span>{{ policy.paymentMethod }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="font-weight-bold py-1"><span>Total Premium:</span></v-col>
                <v-col cols="5" class="py-1" align="right">
                  <span>{{ policy.cost }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="policy.additionalProducts.length">
                <v-col class="col-border-top font-weight-bold py-1" cols="6">
                  <span>Additional Products:</span>
                </v-col>
                <v-col class="col-border-top py-1" cols="5" align="right">
                  <span>{{ policy.additionalProducts.join(', ') }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <v-row dense>
                <simple-button link plain class="font-weight-bold" @click="goToPolicySummary(policy)">
                  View Policy
                </simple-button>
              </v-row>
              <v-row dense>
                <simple-button link plain class="font-weight-bold" @click="goToPolicyEndorsement(policy)">
                  Endorse Policy
                </simple-button>
              </v-row>
              <v-row dense>
                <simple-button link plain class="font-weight-bold" @click="goToPolicyMakeAPayment(policy)">
                  Make Payment
                </simple-button>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
      <simple-pagination
        :itemsPerPage="itemsPerPage"
        :numberOfRecords="policyData.length"
        :pageCount="pageCount"
        @input="updateItemsPerPage"
        @updatePage="updatePage"
      >
      </simple-pagination>
    </v-layout>
  </v-container>
</template>

<style lang="scss" scoped>
.col-border-bottom {
  border-bottom-width: 0.25px;
  border-bottom-style: solid;
  border-bottom-color: #e5e5e5;
}
.col-border-top {
  border-top-width: 0.25px;
  border-top-style: solid;
  border-top-color: #e5e5e5;
}
</style>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import enums from '@/constants/enumerations'
import store from '@/store'
import Register from '@/utils/ProcessingMaskHelpers'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import SimpleTooltip from '@/components/common/input/SimpleTooltip.vue'
import SimplePagination from '@/components/common/input/SimplePagination.vue'
import Agent from '@/components/common/Agent.vue'
import { ClientInterface, Policy, PolicyInterface } from '@/store/modules/client/types'
import { FormatCurrency } from '@/utils/Formatter'
import { PolicyGridHeader, PolicyFields } from '@/models/data-tables/clients'
import { CVIcon, PPAIcon, MCIcon, RVIcon } from '@/models/policy-icons'
import { SystemTransferCalloutActions } from '@/store/modules/system-transfer-callouts/actions'
import { PredefinedPath, SystemDomain } from '@/store/modules/system-transfer-callouts/types'
import { ClientGetters } from '@/store/modules/client/getters'
import { UserSessionGetters } from '@/store/modules/user-session/getters'

export default defineComponent({
  name: 'ClientViewPolicies',
  components: {
    SimpleButton,
    SimpleTooltip,
    Agent,
    SimplePagination,
  },
  props: {
    policies: {
      type: Array as PropType<Array<PolicyInterface>>,
      required: true,
    },
  },
  setup(props) {
    const headers = PolicyGridHeader
    const policyData = computed(() => {
      return props.policies.map((policy: Policy) => {
        return new PolicyFields(policy)
      })
    })

    const itemsPerPage = ref<number>(15)
    const page = ref<number>(1)

    function updatePage(currentPage: number) {
      page.value = currentPage
    }
    const pageCount = computed(() => {
      return Math.ceil(policyData.value.length / itemsPerPage.value)
    })
    const policyDataToShow = computed(() => {
      const endNum = page.value * itemsPerPage.value
      const startNum = endNum - itemsPerPage.value
      return policyData.value.slice(startNum, endNum)
    })

    function updateItemsPerPage(currentNumber: number) {
      itemsPerPage.value = currentNumber
    }

    const imageInfo = (product: string) => {
      if (product === enums.product.CV) return CVIcon
      if (product === enums.product.PPA) return PPAIcon
      if (product === enums.product.MC) return MCIcon
      if (product === enums.product.RV) return RVIcon

      return null
    }

    const imagePath = (product: string) => {
      const image = imageInfo(product)
      return image ? image.path : ''
    }

    const imageAlt = (product: string) => {
      const image = imageInfo(product)
      return image ? image.alt : ''
    }
    const imageWidth = (product: string) => {
      const image = imageInfo(product)
      return image ? image.width : 'auto'
    }

    function getStatusClass(status: string) {
      return status !== 'ActiveInForce' ? 'font-weight-bold red--text' : 'font-weight-bold'
    }

    function getCanceledDateClass(date: string) {
      const today = new Date().setHours(0, 0, 0, 0)
      const canceledDate = new Date(date).setHours(0, 0, 0, 0)
      return date !== '' && canceledDate <= today ? 'red--text' : ''
    }

    const isAgent = store.getters[UserSessionGetters.IsAgent]
    const currentAgentId = computed<ClientInterface>(() => store.getters[ClientGetters.NewClient]).value.agentId

    async function goToPolicyEndorsement(item: Policy) {
      const result: boolean = await Register(
        store.dispatch(SystemTransferCalloutActions.Bridge, {
          destination: PredefinedPath.PLPolicyEndorsement,
          domain: SystemDomain.PL,
          policyId: item.policyId,
          state: item.state,
          subproduct: item.product,
        })
      )

      return result
    }

    async function goToPolicyMakeAPayment(item: Policy) {
      const result: boolean = await Register(
        store.dispatch(SystemTransferCalloutActions.Bridge, {
          destination: PredefinedPath.PLPolicyMakeAPayment,
          domain: SystemDomain.PL,
          policyId: item.policyId,
          state: item.state,
          subproduct: item.product,
        })
      )

      return result
    }

    async function goToPolicySummary(item: Policy) {
      const result: boolean = await Register(
        store.dispatch(SystemTransferCalloutActions.Bridge, {
          destination: PredefinedPath.PLPolicySummary,
          domain: SystemDomain.PL,
          policyId: item.policyId,
          state: item.state,
          subproduct: item.product,
        })
      )

      return result
    }

    return {
      currentAgentId,
      FormatCurrency,
      getCanceledDateClass,
      getStatusClass,
      goToPolicyEndorsement,
      goToPolicyMakeAPayment,
      goToPolicySummary,
      headers,
      imageAlt,
      imagePath,
      imageWidth,
      isAgent,
      policyData,
      policyDataToShow,
      itemsPerPage,
      page,
      pageCount,
      updateItemsPerPage,
      updatePage,
    }
  },
})
</script>
