import { MutationTree } from 'vuex'
import { ImageInterface, ImageStoreInterface } from './types'

/**
 * The image mutations class.
 */
export class ImageMutations {
  public static SET_IMAGES = 'SET_IMAGES'
}

/**
 * The image mutation tree.
 */
export const mutations: MutationTree<ImageStoreInterface> = {
  SET_IMAGES (state: ImageStoreInterface, images: Array<ImageInterface>) {
    state.images = images
  }
}

export default mutations
