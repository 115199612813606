import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { BusinessSearchMutations } from './mutations'
import { BusinessSearchInterface } from './types'

export class BusinessSearchActions {
  public static SetMCFHReadOnly = 'businessSearch/setMCFHReadOnly'
  public static SetShowCancelSave = 'businessSearch/setShowCancelSave'
  public static SetReadOnly = 'businessSearch/setReadOnly'
  public static SetShowHelp = 'businessSearch/setShowHelp'
}

export const actions: ActionTree<BusinessSearchInterface, RootState> = {
  setMCFHReadOnly(context, mcfh: boolean) {
    context.commit(BusinessSearchMutations.SET_MCFH_READ_ONLY, mcfh)
  },
  setShowCancelSave(context, showCancelSave: boolean) {
    context.commit(BusinessSearchMutations.SET_SHOW_CANCEL_SAVE, showCancelSave)
  },
  setReadOnly(context, readOnly: boolean) {
    context.commit(BusinessSearchMutations.SET_READ_ONLY, readOnly)
  },
  setShowHelp(context, showHelp: boolean) {
    context.commit(BusinessSearchMutations.SET_SHOW_HELP, showHelp)
  },
}
