<template>
  <v-app id="app" class="d-flex ma-auto">
    <ProcessingMask />
    <AlertBanner class="sticky" v-if="$route.fullPath.includes(internalRoutes.agencySelfService)" />
    <v-main id="page-container" :class="{ 'normal': !hideLayout, 'minimal': hideLayout }">
      <template v-if="!hideLayout">
        <PageHeader />
        <MainNavigation :items="navItems" />
      </template>
      <div class="page">
        <router-view />
      </div>
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script lang="ts">
import store from '@/store'
import { computed, defineComponent, ref } from 'vue'

import ProcessingMask from '@/components/common/processing-mask.vue'
import PageFooter from '@/components/layout/PageFooter.vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import MainNavigation from '@/components/layout/navigation/MainNavigation.vue'
import { EventBus, Events } from '@/utils/EventBus'

import AlertBanner from '@/components/common/AlertBanner.vue'
import { AlertBannerGetters } from '@/store/modules/admin-alert/getters'
import { AlertBannerInterface } from '@/store/modules/admin-alert/types'

import internalRoutes from '@/constants/internalRoutes'
import { NavigationGetters } from './store/modules/navigation/getters'

export default defineComponent({
  name: 'App',
  components: {
    ProcessingMask,
    PageHeader,
    PageFooter,
    MainNavigation,
    AlertBanner,
  },
  setup() {
    const hideLayout = ref<boolean>(false)
    const navItems = computed(() => store.getters[NavigationGetters.GetMainNavigationItems])

    EventBus.on(Events.hideLayout, () => {
      hideLayout.value = true
    })
    EventBus.on(Events.showLayout, () => {
      hideLayout.value = false
    })

    const alertBanner = computed<AlertBannerInterface>(() => store.getters[AlertBannerGetters.AlertBanner])

    return {
      hideLayout,
      alertBanner,
      internalRoutes,
      navItems
    }
  },
})
</script>

<style lang="scss">
#app {
  margin: 0 auto;
}

#page-container.normal {
  background-color: white;
  width: $ngic-container-size-normal;
  margin: auto;
  margin-top: 20px;
}

#page-container.minimal {
  margin: auto;
  flex: none;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
