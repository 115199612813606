<template>
  <v-container>
    <h4>{{ gridTitle }}</h4>
    <v-data-table
      dense
      class="v-card v-sheet ngic-color-override my-3"
      :class="itemsPerPage > 15 ? 'scrollable' : ''"
      :headers="headers"
      :items="occupationsToDisplay"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:[`item.occupation`]="{ item }">
        <simple-button plain link left @click="() => useSetOccupation(item)" :disabled="item.unacceptable">
          {{ item.occupation }}
        </simple-button>
      </template>
    </v-data-table>
    <simple-pagination
      :itemsPerPage="itemsPerPage"
      :numberOfRecords="occupationsToDisplay.length"
      :pageCount="pageCount"
      @input="updateItemsPerPage"
      @updatePage="updatePage"
    >
    </simple-pagination>
  </v-container>
</template>

<script lang="ts">
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import SimplePagination from '@/components/common/input/SimplePagination.vue'
import verbiage from '@/constants/verbiage'
import store from '@/store'
import { BusinessSearchGetters } from '@/store/modules/business-search/getters'
import { OccupationFilterGetters } from '@/store/modules/occupation-filter/getters'
import { OccupationGridHeader, OccupationInterface } from '@/store/modules/occupation/types'
import { computed, defineComponent, ref } from 'vue'
import useSetOccupation from './composables/useSetOccupation'

export default defineComponent({
  name: 'BusinessTypeList',
  components: { SimpleButton, SimplePagination },
  setup() {
    const headers = OccupationGridHeader
    const sectionText = verbiage.bsiText
    const itemsPerPage = ref<number>(15)
    const page = ref<number>(1)
    const pageCount = ref<number>(0)

    const showHelp = computed<boolean>(() => {
      return store.getters[BusinessSearchGetters.ShowHelp]
    })

    const isReadOnly = Boolean(store.getters[BusinessSearchGetters.ReadOnly])

    const gridTitle = computed<string>(() => {
      const section5Title = isReadOnly ? '' : sectionText.section5Title
      return showHelp.value ? section5Title : sectionText.section3Title
    })

    const occupationsToDisplay = computed<Array<OccupationInterface>>(() => {
      return store.getters[OccupationFilterGetters.Occupations] ?? []
    })

    function updateItemsPerPage(currentNumber: number) {
      itemsPerPage.value = currentNumber
    }

    function updatePage(currentPage: number) {
      page.value = currentPage
    }

    return {
      gridTitle,
      headers,
      occupationsToDisplay,
      useSetOccupation,
      page,
      itemsPerPage,
      pageCount,
      updateItemsPerPage,
      updatePage,
    }
  },
})
</script>
