import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { actions } from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import { AgencyGroupListInterface } from './type'

export const agencyCode: Module<AgencyGroupListInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default agencyCode
