import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { EmailNotificationsInterface } from './types'

export class AgencyContactPreferencesEmailsGetters {
  public static EmailNotifications = 'emailNotifications/emailNotifications'
}

export const getters: GetterTree<EmailNotificationsInterface, RootState> = {
  emailNotifications: (state: EmailNotificationsInterface) => state,
}

export default getters
