import { Phone } from '@/models/phone-number'
import { MutationTree } from 'vuex'
import { AgencyInterface } from './types'

export class AgencyMutations {
  public static SET_AGENCY = 'SET_AGENCY'
}

export const mutations: MutationTree<AgencyInterface> = {
  SET_AGENCY(state: AgencyInterface, agency: AgencyInterface) {
    state.id = agency.id
    state.name = agency.name
    state.akaName = agency.akaName
    state.mailingAddress.street = agency.mailingAddress.street
    state.mailingAddress.city = agency.mailingAddress.city
    state.mailingAddress.state = agency.mailingAddress.state
    state.mailingAddress.zip = agency.mailingAddress.zip
    state.streetAddress.street = agency.streetAddress.street
    state.streetAddress.city = agency.streetAddress.city
    state.streetAddress.state = agency.streetAddress.state
    state.streetAddress.zip = agency.streetAddress.zip
    state.phone = new Phone(agency.phone.formattedPhoneNumber)
    state.fax = new Phone(agency.fax.formattedPhoneNumber)
    state.email = agency.email
    state.website = agency.website
    state.princetonAgtCode = agency.princetonAgtCode
    state.hours = agency.hours
    state.status = agency.status
    state.indicator = agency.indicator
    state.migrationStatus = agency.migrationStatus
    state.singleSignOnProviderInformation = agency.singleSignOnProviderInformation
    state.isSingleSignOn = agency.singleSignOnProviderInformation.length > 0
  },
}

export default mutations
