export default {
  agencySelfService: '/agent-self-service',
  client: '/client',
  clientView: '/client-view',
  agentSelfServicePortalPageNames: {
    home: 'home',
    editContact: 'Edit Contact',
    addContact: 'Add Contact',
    editAgency: 'Edit Agency',
    editEmailNotifications: 'Edit Email Notifications',
  },
  agentSelfServicePortalURLs: {
    home: 'home',
    editContact: 'edit-contact',
    addContact: 'add-contact',
    editAgency: 'edit-agency',
    editEmailNotifications: 'edit-email-notifications',
  },
}
