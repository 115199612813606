import useApis from '@/api'
import enums from '@/constants/enumerations'
import store from '@/store'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { Occupation, OccupationInterface, SubCategory } from '../occupation/types'
import { UserSessionGetters } from '../user-session/getters'
import { LoginType } from '../user-session/types'
import { OccupationMutations } from './mutations'
import { CradOccupationMapping, OccupationSearchRequest, OccupationSearchResponseInterface } from './types'

const services = enums.occupationCategories.services

export class OccupationSearchActions {
  public static SearchOccupations = 'occupationSearch/searchOccupations'
}

function getCategory(occupation: CradOccupationMapping, sicCodes: Array<string>, naicCodes: Array<string>) {
  if (!sicCodes.length && !naicCodes.length) return occupation.businessCategory

  const filters: Array<string | undefined> = sicCodes
    .map((x) => x.substring(0, 2))
    .filter((value, index, self) => index === self.findIndex((t) => t === value))

  return filters.includes(occupation.sic.substring(0, 2)) ? occupation.sicCategoryDesc : occupation.naicsCategoryDesc
}

function getSubCategories(
  isService: boolean,
  results: Array<CradOccupationMapping>,
  hasSicCodes: boolean,
  hasNaicCodes: boolean
): Array<SubCategory> {
  if (!isService || hasSicCodes || hasNaicCodes) return []

  const list = results
    .filter((value: CradOccupationMapping) => value.businessCategory === services)
    .map((x: CradOccupationMapping) => x.businessType)
    .filter((v: string, i: number, s: Array<string>) => i === s.findIndex((t) => t === v) ?? [])
    .sort((x: string, y: string) => (x.toLowerCase() > y.toLowerCase() ? 1 : -1))

  return list.map((x) => new SubCategory({ name: x, checked: true }))
}

function getSubCategory(isService: boolean, subCategory: string, hasSicCodes: boolean, hasNaicCodes: boolean) {
  if (!isService || hasSicCodes || hasNaicCodes) return null
  return new SubCategory({
    name: subCategory,
    checked: true,
  })
}

const { standard: api } = useApis()

export const actions: ActionTree<OccupationSearchResponseInterface, RootState> = {
  async searchOccupations(
    { commit },
    payload: { naicFilters: Array<string>; sicFilters: Array<string>; motorCarrier: boolean; state: string; product: string; }
  ) {
    try {
      const request: OccupationSearchRequest = {
        naicFilterCodes: payload.naicFilters,
        sicFilterCodes: payload.sicFilters,
        motorCarrier: payload.motorCarrier,
        state: payload.state,
        product: payload.product
      }
      const response = await api.post('lookup/occupation/search', request)
      let occupations: Array<OccupationInterface> = []
      if (response.data?.value?.occupationSearchResults) {
        occupations = response.data.value.occupationSearchResults.map(
          (item: CradOccupationMapping) =>
            new Occupation({
              category: getCategory(item, payload.sicFilters, payload.naicFilters),
              checked: true,
              description: item.businessTypeDefinitionDesc,
              naic: item.naic.toString(),
              occupation: item.occupation,
              rnOnly: item.displayInternalOnly,
              sic: item.sic,
              subCategoryList: getSubCategories(
                item.businessCategory === services,
                response.data.value.occupationSearchResults,
                payload.sicFilters.length > 0,
                payload.naicFilters.length > 0
              ),
              subCategory: getSubCategory(
                item.businessCategory === services,
                item.businessType,
                payload.sicFilters.length > 0,
                payload.naicFilters.length > 0
              ),
              unacceptable: false,
            })
        )
      }
      commit(
        OccupationMutations.SET_OCCUPATIONS,
        occupations.filter((item) =>
          parseInt(store.getters[UserSessionGetters.CalculatedLoginType]) === LoginType.Hr ? true : !item.rnOnly
        )
      )
      return true
    } catch (reason) {
      // eslint-disable-next-line no-console
      console.error('occupation-search :: SearchOccupations :: failed:', reason)
      return false
    }
  },
}
