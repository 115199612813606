import { SelectOption } from '@/models/select-option'
import { Firmographics } from '@/store/modules/reports/firmographics/types'
import { ReportsCalloutsInterface } from './types'

/**
 * State for Reports section of vuex
 */
export const state: ReportsCalloutsInterface = {
  cabIsDown: false,
  firmographics: new Firmographics(),
  titleStatus: new Array<SelectOption<string>>(),
}

export default state
