var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.hidden)?_c('v-btn',_vm._g(_vm._b({staticClass:"ngic-button rounded-0",class:{
    'text-decoration-underline': _vm.link && _vm.isHovering,
    'primary--text pa-0 ma-0 text-capitalize link-style wrap-link': _vm.link,
    'text-style': _vm.text,
    'justify-end': _vm.right,
    'justify-start': _vm.left,
  },attrs:{"color":_vm.color,"disabled":_vm.disabled,"text":_vm.text || _vm.link},on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false}}},'v-btn',_vm.attrs,false),_vm.listeners),[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }