import enums from '@/constants/enumerations'
import store from '@/store'
import { BusinessSearchGetters } from '@/store/modules/business-search/getters'
import { OccupationFilterGetters } from '@/store/modules/occupation-filter/getters'
import { OccupationSearchGetters } from '@/store/modules/occupation-search/getters'
import { Occupation, OccupationInterface, SubCategory } from '@/store/modules/occupation/types'

const services = enums.occupationCategories.services

/**
 *
 * @returns An array of selected categories
 */
function getSelectedCategories(): Array<string> {
  const categoryList: Array<OccupationInterface> = store.getters[OccupationFilterGetters.SelectedCategories] ?? []
  const subCategoryList: Array<SubCategory> = store.getters[OccupationFilterGetters.SelectedSubCategories] ?? []

  return categoryList
    .filter((item) => item.checked)
    .map((item) => item.category)
    .concat(subCategoryList.filter((item) => item.checked).map((item) => item.name))
}

/**
 *
 * @param occupations
 * @param categories
 * @param useSubCategories
 * @returns An array of occupations filtered by category
 */
function filterByCategories(
  occupations: Array<OccupationInterface>,
  categories: Array<string>,
  useSubCategories: boolean
): Array<OccupationInterface> {
  return occupations
    .sort((x, y) => (x.occupation.toLowerCase() > y.occupation.toLowerCase() ? 1 : -1))
    .filter((item) => {
      return useSubCategories && item.category === services
        ? categories.includes(item.subCategory?.name ?? '')
        : categories.includes(item.category)
    })
}

const noAvailableOccupations: Array<OccupationInterface> = [
  new Occupation({
    category: '',
    checked: false,
    description: '',
    naic: '',
    occupation: 'No available occupations returned',
    rnOnly: false,
    sic: '',
    subCategoryList: [],
    subCategory: null,
    unacceptable: true,
  }),
]

function filterBySearchString(
  occupations: Array<OccupationInterface>,
  searchString: string
): Array<OccupationInterface> {
  if (!searchString || searchString.length <= 2) return occupations

  const filteredList = occupations.filter((item) => {
    if (
      item.occupation.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
      item.sic.includes(searchString) ||
      item.naic.includes(searchString)
    ) {
      return item
    }
    return null
  })

  return filteredList.length > 0 ? filteredList : noAvailableOccupations
}

/**
 *
 * @returns A filtered list of occupations to display
 */
export default function (): Array<OccupationInterface> {
  const useSubCategories = store.getters[BusinessSearchGetters.ShowHelp] ?? false
  const searchString = store.getters[OccupationFilterGetters.SearchString] ?? ''
  const allOccupations = store.getters[OccupationSearchGetters.OccupationResults] ?? []

  return filterBySearchString(
    filterByCategories(allOccupations, getSelectedCategories(), useSubCategories),
    searchString
  )
}
