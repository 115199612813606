import useApis from '@/api'
import store from '@/store'
import useComparer from '@/utils/useComparer'
import { AlertMessagesActions } from '../alert-messages/actions'
import { AlertMessageSource } from '../alert-messages/types'
import { UserSessionGetters } from '../user-session/getters'

const { equalsIgnoreCase } = useComparer()

const { standard: api } = useApis()

export async function zipValidateCallout(payload: { state: string; zipCode: string }): Promise<boolean> {
  try {
    store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.Lookup })
    const govState = store.getters[UserSessionGetters.InitialState]
    if (equalsIgnoreCase(govState, payload.state)) {
      const request = { state: payload.state, zipcode: payload.zipCode }
      const response = await api.post('lookup/zip_code/validate', request)

      if (!response.data?.isSuccessful || !response.data?.value) {
        console.error('zip-validation :: zipValidateCallout :: failed:: unable to retrieve data in response')
        return false
      }

      if (!response.data.value.isValidZipCode) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'Zip Code must belong to the selected state.',
          source: AlertMessageSource.Lookup,
        })
        return false
      }
    } else {
      const response = await api.get(`lookup/valid_state/${payload.zipCode}`)

      if (!response.data?.isSuccessful || !response.data?.value) {
        console.error('zip-validation :: zipValidateCallout :: failed:: unable to retrieve data in response')
        return false
      }

      if (!equalsIgnoreCase(payload.state, response.data.value.validStateForZipCode)) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'Zip Code must belong to the selected state.',
          source: AlertMessageSource.Lookup,
        })
        return false
      }
    }

    return true
  } catch (reason) {
    console.error('zip-validation :: zipValidateCallout :: failed:', reason)
    return false
  }
}
