<template>
  <div>
    <div class="d-flex blue-and-white pa-4" v-if="$route.path.includes(internalRoutes.agencySelfService)">
      <router-link :to="internalRoutes.agencySelfService">
        <span class="text-h5 my-auto blue-and-white">User Admin</span>
      </router-link>
      <span class="text-h5 mx-5" v-if="!(pageTitle === '')">|</span>
      <span class="text-h7 mt-2">{{ pageTitle }}</span>
    </div>
    <nav v-else class="navigation_main">
      <ul class="nav_list parent">
        <li v-for="item in items" class="nav_list-item" :key="item.name">
          <div class="item-wrapper">
            <span @click="Register(action(item))">{{ item.text }}</span>
          </div>
          <ul class="nav_list child" v-if="!isEmpty(item.children)">
            <li v-for="childitem in item.children" class="nav_list-item" :key="childitem.name">
              <div class="item-wrapper">
                <span @click="Register(action(childitem))">{{ childitem.text }}</span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router/composables'

import { NavigationItemType } from '@/components/layout/navigation/navigationItem'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { SystemTransferCalloutActions } from '@/store/modules/system-transfer-callouts/actions'
import { PredefinedPath } from '@/store/modules/system-transfer-callouts/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'

import internalRoutes from '@/constants/internalRoutes'
import { NavigationItemWithChildrenInterface } from '@/store/modules/navigation/types'
import Register from '@/utils/ProcessingMaskHelpers'
import useComparer from '@/utils/useComparer'

export default defineComponent({
  name: 'MainNavigation',
  props: {
    items: {
      required: false,
      type: Array as PropType<Array<NavigationItemWithChildrenInterface>>,
      default: () => new Array<NavigationItemWithChildrenInterface>()
    }
  },
  setup() {
    const route = useRoute()
    const { isEmpty } = useComparer()
    const plDomain = process.env.VUE_APP_PL_URL
    const employeeNumber = store.getters[UserSessionGetters.EmployeeNum]
    const employeeNumberQuery = '{$employeeNumber}'

    async function replaceQueryParams(path: string): Promise<string> {
      return path.includes(employeeNumberQuery) ? path.replace(employeeNumberQuery, employeeNumber.toString()) : path;
    }

    async function action(item: NavigationItemType): Promise<boolean> {
      if (item.disabled) return false
      if (item.external) {
        if (item.path === '') {
          store.dispatch(AlertMessagesActions.AddErrorMessage, {
            messageText: 'Could not complete request. Not implemented yet.',
          })
          return false
        }
        const path = await replaceQueryParams(item.path)
        window.open(path, item.newWindow ? '_blank' : '_self')
        return true
      }
      if (plDomain === undefined) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'Could not complete request. The domain was not found.',
        })
        return false
      }

      const result: boolean = await store.dispatch(SystemTransferCalloutActions.Navigate, {
        destination: PredefinedPath.PLQuoteMain,
        domain: plDomain,
        path: item.path,
        shouldOpenNewWindow: item.newWindow,
        text: item.text,
      })

      return result
    }

    const pageTitle = computed(() => {
      return route.meta?.title
    })

    return {
      action,
      internalRoutes,
      isEmpty,
      pageTitle,
      Register
      }
  },
})
</script>

<style lang="scss" scoped>
$itemwidth: 150;
$itemheight: 36;
$navprimary: rgb(102, 102, 102);
$navsecondary: rgb(219, 219, 219);

nav.navigation_main {
  width: 100%;
  background: #373737;
  z-index: 1;
  height: $itemheight * 1px;
  overflow: hidden;

  .nav_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #373737;
    height: auto;
    width: 100%;
    padding: 0;
    z-index: 1;

    .nav_list-item {
      padding: 0;
      list-style: none;
      display: inline-block;
      width: $itemwidth * 1px;
      height: $itemheight * 1px;

      .item-wrapper {
        width: auto;
        font: normal normal 700 14px Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        text-align: center;

        span {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
          width: auto;
          display: block;
          padding: 10px;
          color: #fff;
          height: auto;
          outline: none;

          &:hover {
            outline: none;
            background: #ccc;
            color: #363636;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    &.parent {
      height: $itemheight * 1px;
      margin: 0 auto;
      > .nav_list-item > .nav_list.child {
        display: block;
        opacity: 0;
        height: 0;
        width: 0;
        overflow: hidden;
        transition: all 0s 0.5s;
      }
      > .nav_list-item:hover > .nav_list.child {
        opacity: 1;
        height: auto;
        width: $itemwidth + 30px;
      }
    }

    &.child {
      padding-inline-start: 0;
      position: absolute;
      width: $itemwidth + 30px;

      .nav_list-item .item-wrapper {
        font-weight: 400;
        font-size: 12px;
        text-transform: none;
        text-align: left;

        span {
          padding: 11px 10px;
          width: $itemwidth + 30px;

          &hover {
            background: rgb(128, 128, 128);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>