export default {
  editContact: [
    'In the last 5 years, have you filed for or been discharged from any bankruptcy?',
    'Have you ever ben the subject of an administrative proceeding regarding ny professional or occupational license that resulted in disciplinary action outside of continuing education compliance?',
    'Has your insurance license ever been suspended, revoked or surrendered by any regulatory agency? Have you ever been fined, penalized, sanctioned or subject to any other disciplinary action by a state or federal regulatory agency? Are you currently under investigation as a result of your activities in the business of insurance, securities, banking, investment banking or real estate?',
    'Have you ever had an insurance producer contract or any other business relationship with an insurance carrier severed for any alleged misconduct?',
    'Have you ever been convicted of, plead guilty or no contest to, or are your currently charged with or under investigation for any misdemeanor involving dishonesty or breach of trust or any felony?',
    "Are you currently the subject of any complaint, investigation or proceeding that could result in a 'yes' answer to any of these questions?",
  ],
  toolTipText: {
    activity: 'Receive automated reports for cancellations, non-renewals, past due payments and more.',
    billing: 'Receive a daily report for policies which are eligible for reinstatement.',
    claims: 'Receive an alert when a new claim is reported.',
    report: 'Receive a daily report for renewals that have not been paid.',
  },
  addEmailNotification: `Please check "Primary" or "Secondary" and we'll send the selected reports to the email address you add.`,
  sendRegistrationLink: `Please choose which option you'd like to send the registration link to (mobile phone or email) to begin setup of the individual's login.`,
  resendRegistrationLink: `Please choose which option you'd like to resend the registration link to (mobile phone or email) to begin setup of the individual's login.`,
  ssoSendRegistrationLink: `Please enter the contact's User ID associated with identity provider login and an email address to link their existing account with natgenagency.com for Single Sign-On (<b>SSO</b>).`,
  resendMessage: `A Registration link was sent to `,
  textConsent: `<p>By clicking "Send", you are confirming that the person associated with this phone number has consented to receiving text messages from National General Insurance on behalf of itself or its affiliates and trusted partners that may be delivered at any hour of the day, including between 6PM and 11AM Eastern Time. Data and Message rates may apply.</p>`,
  nisRegistrationAdditionalAgencyGrid1: `Please select or deselect which agency locations you'd like this contact to have login access to. We pre-selected the locations where we found this contact.`,
  nisRegistrationAdditionalAgencyGrid1b: `Highlighted agencies denote that the user already has login access to that location.`,
  nisRegistrationAdditionalAgencyGrid2: `While we did not find this contact at any of the locations below, you can give them login access by checking the boxes for the appropriate agency location(s).`,
  nisRegistrationAdditionalAgencyGrid2b: `Please review and verify contact information for all agencies in order to avoid duplicate contacts.`,
  nisRegistrationExpirationMessage: 'Registration link will expire in 15 days.',
  processTimeInfo: {
    editContact:
      "Changes to an appointed contact's name (first, middle, last) and delete contact submissions require review and may take up to 5 business days before visible in the system.",
    editOwnerContact:
      "Contact your sales representative to delete the owner or to change the owner's name or date of birth.",
    editAgency:
      'Contact your sales representative to make changes to the "Agency Name" or "Agency AKA Name."',
  },
  bsiText: {
    needHelpReadOnlyTitle: 'Business Type Dictionary',
    needHelpReadOnlyText:
      'It is important to select the correct business type for your customer.  Please use the business type dictionary below to review the descriptions to ensure the proper classification is selected.',
    section2CategoryTitle: 'Industry Classification Category',
    section2Text:
      "Please select the Business Type that describes the customer's operations by clicking the appropriate hyperlink or by entering the business type in the selection field. We have provided business descriptions below. You may filter that list with the Industry Category checkboxes or by utilizing the Business Type selection field to search/filter the descriptions.",
    section2Title:
      'We have identified the following Industry Classifications from SIC and NAICS as being associated with this customer:',
    section3Title: 'At least one Industry Category must be selected above.',
    section4CategoryTitle: 'Business Category',
    section4Title:
      "Please select the Business Type that describes the customer's operations by clicking the appropriate hyperlink or by entering the business type in the selection field. We have provided business descriptions below. You may filter that list with the Business Category checkboxes or by utilizing the Search field to filter the descriptions.",
    section5Title: 'At least one Business Category must be selected above.',
  },
  deleteMultipleContacts: "You're choosing to remove these contacts from your agency. Click Yes to continue.",
  deleteSingleContact: "Are you sure you want to delete this contact?",
  emailNotificationExplanation: "Receive valuable reports via email that will help you grow and manage your business. Click the Edit button to select which email address will receive the reports.",
  disabledForInactivity: "Login disabled for inactivity",
  disabledManually: "Login disabled"
}
