<template>
  <v-container>
    <v-card-text>
      <app-text-field label="First Name" isRequired v-model="userInput.person.firstName" for="first-name"/>
      <app-text-field label="Last Name" isRequired v-model="userInput.person.lastName" for="last-name"/>
      <app-text-field label="Address" isRequired for="street-address"/>
      <app-text-field label="Address 2" for="street-address-2"/>
      <app-text-field label="City" isRequired for="city"/>
      <app-select label="State" :items="stateOptions" isRequired for="state"/>
      <app-text-field label="Zip Code" isRequired v-model="userInput.addresses.zipCode" for="zip-code"/>
      <app-text-field label="Phone" showColumn for="phone">
        <template v-slot:rightColumn>
          <simple-select v-model="phoneTypeDefault" :items="phoneTypes" />
        </template>
      </app-text-field>
      <app-text-field label="Email" for="email"/>
    </v-card-text>
    <v-card-actions>
      <app-button color="primary" @click="returnToSearch" class="mx-2">Return to Client Search</app-button>
      <v-spacer />
      <app-button color="primary" class="mx-2">Continue</app-button>
    </v-card-actions>
  </v-container>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue'
import { CommonSelectOptions, SelectOption } from '@/models/select-option'
import router from '@/router'
import store from '@/store'
import enums from '@/constants/enumerations'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import AppSelect from '@/components/common/input/AppSelect.vue'
import AppTextField from '@/components/common/input/AppTextField.vue'
import SimpleSelect from '@/components/common/input/SimpleSelect.vue'
import { ClientGetters } from '@/store/modules/client/getters'
import { ClientInterface } from '@/store/modules/client/types'

export default defineComponent({
  name: 'BaseClientEntry',
  components: {
    'app-button': SimpleButton,
    'app-select': AppSelect,
    'app-text-field': AppTextField,
    'simple-select': SimpleSelect,
  },
  setup() {
    const userInput = computed<Array<ClientInterface>>(() => {
      return store.getters[ClientGetters.NewClient]
    })
    const phoneTypeDefault = 'Cell'

    const phoneTypes = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetPhoneTypes()
    })
    const returnToSearch = () => {
      router.push({ name: enums.pageName.ClientSearch })
    }
    const selectYesNoOptions = computed<SelectOption<boolean | undefined>[]>(() => {
      return CommonSelectOptions.GetSelectYesNoOptions()
    })
    const stateOptions = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetStateOptions()
    })
    const submit = () => {
      // eslint-disable-next-line no-console
      console.log('submit')
    }

    return {
      phoneTypeDefault,
      phoneTypes,
      returnToSearch,
      selectYesNoOptions,
      stateOptions,
      submit,
      userInput,
    }
  },
})
</script>
