import useApis from '@/api'
import enums from '@/constants/enumerations'
import internalRoutes from '@/constants/internalRoutes'
import { ApiResponse } from '@/models/api'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import dayjs from 'dayjs'
import { ActionTree } from 'vuex'
import { ClientGetters } from '../client/getters'
import { PredefinedPath, 
  SystemDomain, 
  AmtSystemTransferRequestInterface, 
  DataStoreSystemTransferRequestInterface,
  DataStoreExpirationDays } from './types'
import configKeys from '@/constants/configKeys'
import { isConfigEnabled } from '@/components/common/composables/isConfigEnabled'

/**
 * The system transfer callout actions.
 */
export class SystemTransferCalloutActions {
  /** bridges to different system. */
  public static Bridge = 'systemTransferCallouts/bridge'

  /** Navigates to the provided main menu item. */
  public static Navigate = 'systemTransferCallouts/navigate'

  /** Logs out the user. */
  public static LogoutUser = 'systemTransferCallouts/logoutUser'

  /* Deletes cookies that keep the user logged in */
  public static DeleteCookies = 'systemTransferCallouts/deleteCookies'
}

const { standard: api } = useApis()

/**
 * The system transfer callouts action tree.
 */
export const actions: ActionTree<Record<string, never>, RootState> = {
  bridge(
    context,
    payload: {
      destination: PredefinedPath
      domain: SystemDomain
      generatedFrom: string
      policyId: number
      prefillType: string
      quoteId: string
      state: string
      subproduct: string
    }
  ): Promise<boolean> {
    if (!payload.domain) {
      return Promise.reject(new Error('System Domain must not be empty'))
    }

    const requestData = [
      { name: 'AccountHolderId', value: `${store.getters[ClientGetters.AccountHolderId]}` },
      { name: 'AgentGroup', value: `${store.getters[UserSessionGetters.AgentGroup]}` },
      { name: 'AgentId', value: `${store.getters[UserSessionGetters.AgentId]}` },
      { name: 'EmployeeNum', value: `${store.getters[UserSessionGetters.EmployeeNum]}` },
      { name: 'LoginSource', value: 'PL' },
      { name: 'LoginProviderType', value: `${store.getters[UserSessionGetters.LoginProviderType]}` },
      { name: 'LoginType', value: `${store.getters[UserSessionGetters.TrueLoginType]}` },
      { name: 'Mode', value: 'Bridge' },
      { name: 'State', value: payload.state ?? `${store.getters[ClientGetters.InitialState]}` },
      { name: 'SubProduct', value: payload.subproduct ?? `${store.getters[ClientGetters.SubProduct]}` },
    ]

    if (payload.policyId) requestData.push({ name: 'PolicyId', value: payload.policyId.toString() })
    if (payload.quoteId) requestData.push({ name: 'QuoteId', value: payload.quoteId.toString() })
    requestData.push({ name: 'PrefillType', value: payload.prefillType ?? enums.prefillType.None })
    if (payload.generatedFrom) requestData.push({ name: 'GeneratedFrom', value: payload.generatedFrom })
    const isDaStore = store.getters[UserSessionGetters.IsDaStore]
    if (isDaStore) requestData.push({ name: 'IsDaStore', value: isDaStore })
    const isCrossSell = store.getters[ClientGetters.NewClient].isCrossSell
    if (isCrossSell) {
      requestData.push({ name: 'EffectiveDate', value: new Date().toJSON().slice(0, 10) })
      requestData.push({ name: 'GovState', value: payload.state ?? `${store.getters[ClientGetters.InitialState]}` })
    }
    const masterUserId = store.getters[UserSessionGetters.MasterUserId]
    if (masterUserId) requestData.push({ name: 'MasterUserId', value: masterUserId },)
    const isAgencyAdmin = store.getters[UserSessionGetters.IsAgencyAdmin]
    if (isAgencyAdmin) requestData.push({ name: 'MasterAgencyAdmin', value: isAgencyAdmin})

    const useDataStore = isConfigEnabled(configKeys.ADO519303_AgentPortal_Use_DataStore)
    if (useDataStore) requestData.push({ name: 'Destination', value: payload.destination})

    // One of these two request objects will be passed into the api.post call, and the other will not be used.
    // When the "Use DataStore" config is removed, the AMT request and route will be removed.
    const amtRequest: AmtSystemTransferRequestInterface = {
      data: requestData,
      destinationSystem: payload.destination,
      generationDate: new Date(),
      generationSystem: 'AgentPortal',
      user: store.getters[UserSessionGetters.Username],
      version: '1',
    }

    const dataStoreRequest: DataStoreSystemTransferRequestInterface = {
      data: requestData,
      expirationDate: dayjs().add(DataStoreExpirationDays, 'day').toDate(), 
      systemName: 'AgentPortal',
      systemUserId: store.getters[UserSessionGetters.Username]
    }

    return api
    .post<ApiResponse<string>>(
      useDataStore ?  '/data_store/system_transfer_object' : 'bridge/system_transfer', 
      useDataStore ? dataStoreRequest : amtRequest)
    .then<boolean>((response) => {
      const transferUrl = `${payload.domain}/PLGateway.aspx?InstanceID=${response.data.value}&BridgeSource=AgentPortal&IsUsingDataStore=${useDataStore}`
      window.open(transferUrl, '_self')
      return true
    })
    .catch<false>((reason) => {
      console.error('system-transfer-callouts :: bridge :: failed:', reason)
      return false
    })
  },
  
  navigate(
    _context,
    payload: {
      destination: string
      domain: SystemDomain
      path: string
      shouldOpenNewWindow: boolean
      text: string
    }
  ) {
    const requestData = [
      { name: 'AgentGroup', value: `${store.getters[UserSessionGetters.AgentGroup]}`, },
      { name: 'AgentId', value: `${store.getters[UserSessionGetters.AgentId]}`, },
      { name: 'EmployeeNum', value: `${store.getters[UserSessionGetters.EmployeeNum]}`, },
      { name: 'LoginProviderType', value: `${store.getters[UserSessionGetters.LoginProviderType]}`, },
      { name: 'LoginType', value: `${store.getters[UserSessionGetters.TrueLoginType]}`, },
      { name: 'Mode', value: 'Bridge', },
      { name: 'Path', value: payload.path, },
    ]
    const masterUserId = store.getters[UserSessionGetters.MasterUserId]
    if (masterUserId) requestData.push({ name: 'MasterUserId', value: masterUserId },)
    const isAgencyAdmin = store.getters[UserSessionGetters.IsAgencyAdmin]
    if (isAgencyAdmin) requestData.push({ name: 'MasterAgencyAdmin', value: isAgencyAdmin})

    const useDataStore = isConfigEnabled(configKeys.ADO519303_AgentPortal_Use_DataStore)

    // One of these two request objects will be passed into the api.post call, and the other will not be used.
    // When the "Use DataStore" config is removed, the AMT request and route will be removed.
    const amtRequest: AmtSystemTransferRequestInterface = {
      data: requestData,
      destinationSystem: 'PL',
      generationDate: new Date(dayjs().format()),
      generationSystem: 'AgentPortal',
      user: store.getters[UserSessionGetters.Username],
      version: '1',
    }

    const dataStoreRequest: DataStoreSystemTransferRequestInterface = {
      data: requestData,
      expirationDate: dayjs().add(DataStoreExpirationDays, 'day').toDate(), 
      systemName: 'AgentPortal',
      systemUserId: store.getters[UserSessionGetters.Username]
    }

    return api
      .post<ApiResponse>(
        useDataStore ?  '/data_store/system_transfer_object' : 'bridge/system_transfer', 
        useDataStore ? dataStoreRequest : amtRequest)
      .then<boolean>((response) => {
        const transferUrl = `${payload.domain}/PLGateway.aspx?InstanceID=${response.data.value}&BridgeSource=AgentPortal&IsUsingDataStore=${useDataStore}`
        window.open(transferUrl, payload.shouldOpenNewWindow ? '_blank' : '_self')

        return true
      })
      .catch<false>((reason) => {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: `Could not complete transfer to ${payload.text}: ${reason}`,
        })

        return false
      })
  },
  logoutUser() {
    // TODO: We need to add logic for clearing AgentPortal specific cookies on logout. NG currently does this on user logouts. -DRoss 2023.07.28

    if (window.location.href.includes(internalRoutes.agencySelfService)) {
      // ASSP is always opened in a pop-out window.
      window.close()
    }

    // If user is not in ASSP, bridge back to PL
    store.dispatch(SystemTransferCalloutActions.Bridge, {
      destination: PredefinedPath.Logout,
      domain: SystemDomain.PL,
    })
  },
  async deleteCookies () {
    await api.post('agent_portal/authorization/logout', {}, { withCredentials: true })
  },
}

export default actions
