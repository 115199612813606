<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    :items-per-page="5"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-skip-previous',
      lastIcon: 'mdi-skip-next',
      prevIcon: 'mdi-menu-left',
      nextIcon: 'mdi-menu-right',
      'items-per-page-text': 'Rows',
      'items-per-page-options': [5, 10, 25, 50, -1],
    }"
    show-select
    class="elevation-0 black--text dataTable"
    @click:row="handleClick"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <v-theme-provider dark>
        <v-simple-checkbox
          class="py-1"
          v-if="props.indeterminate"
          v-ripple
          v-bind="props"
          :value="props.indeterminate"
          v-on="on"
        />
        <v-simple-checkbox
          class="py-1"
          v-if="!props.indeterminate"
          v-ripple
          v-bind="props"
          v-on="on"
        />
      </v-theme-provider>
    </template>
    <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
      <v-simple-checkbox
        class="py-1"
        color="primary"
        @input="select($event)"
        :value="shouldBeChecked(item, isSelected)"
        :disabled="shouldDisable(item)"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { AgencyLocationFields, AgencyLocationGridHeader } from '@/models/data-tables/agency-contacts'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'LocationSelectionGrid',
  props: {
    itemKey: {
      required: true,
      type: [String, Number],
      default: ''
    },
    items: {
      required: true,
      type: Array as PropType<Array<AgencyLocationFields>>,
      default: () => []
    },
    value: {
      required: false,
      type: Array as PropType<Array<AgencyLocationFields>>,
      default: () => []
    },
    disableAssociated: {
      required: false,
      type: Boolean
    }
  },
  setup(props, context) {
    const selected = computed<Array<AgencyLocationFields>>({
      get: () => props.value,
      set: (newValue) => context.emit('input', newValue),
    })

    const singleSelect = false
    const headers = AgencyLocationGridHeader

    function handleClick(value: Array<AgencyLocationFields>) {
      selected.value = value
    }

    function shouldDisable(item: AgencyLocationFields): boolean {
      if (props.disableAssociated) {
        return item.isAssociatedWithCurrentContact
      }
      return item.agencyCode === store.getters[UserSessionGetters.PrincetonAgentCode]
    }

    function shouldBeChecked(item: AgencyLocationFields, isSelected: boolean): boolean {
      if (!item.isAssociatedWithCurrentContact) {
        // If the item is not associated, the selected value should be used
        return isSelected
      }

      if (item.agencyCode !== store.getters[UserSessionGetters.PrincetonAgentCode] ) {
        if (props.disableAssociated) {
          // If the item is disabled, it should always be checked
          return props.disableAssociated
        }
        return isSelected
      }

      // This case should be the logged in contact
      return true
    }

    return {
      handleClick,
      headers,
      selected,
      shouldBeChecked,
      shouldDisable,
      singleSelect
    }
  },
})
</script>

<style lang="scss" scoped>
</style>
