// Must match the order of the fields displayed in the edit/add contact page
export const contactFieldNames: Array<string> = [
    "firstName",
    "lastName",
    "suffix",
    "fullName",
    "mailingAddress.street",
    "mailingAddress.city",
    "mailingAddress.state",
    "mailingAddress.zip",
    "physicalAddress.street",
    "physicalAddress.city",
    "physicalAddress.state",
    "physicalAddress.zip",
    "dob",
    "email",
    "workPhone",
    "cellPhone",
    "ssn.id",
    "type",
    "appointed",
    "terminationEffectiveDate"
  ]

// Must match the order of the field displayed in eidt agency page
export const agencyFieldNames: Array<string> = [
    "name",
    "mailingAddress.street",
    "mailingAddress.city",
    "mailingAddress.state",
    "mailingAddress.zip",
    "streetAddress.street",
    "streetAddress.city",
    "streetAddress.state",
    "streetAddress.zip",
    "phone",
    "fax",
    "email",
    "website",
    "hours",
    "Hours.Value.OpenTime.Year",
    "Hours.Value.CloseTime.Year",
  ]
