import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { CompraterInterface } from './types'

export class CompraterGetters {
  public static Comprater = 'comprater/comprater'
}

export const getters: GetterTree<CompraterInterface, RootState> = {
  comprater: (state: CompraterInterface) => state,
}

export default getters
