import { Phone } from '@/models/phone-number'
import { MutationTree } from 'vuex'
import { Contact, ContactListInformationInterface, ContactListInterface } from './types'

export class ContactsMutations {
  public static SET_CONTACTS = 'SET_CONTACTS'
  public static FILTER_CONTACT = 'FILTER_CONTACT'
  public static NEW_CONTACT = 'NEW_CONTACT'
}

export const mutations: MutationTree<ContactListInformationInterface> = {
  SET_CONTACTS(state: ContactListInformationInterface, contactListInformation: ContactListInformationInterface) {
    state.totalNumberOfContacts = contactListInformation.totalNumberOfContacts
    state.contactList = contactListInformation.contactList.map(contact => {
      const cell = String(contact.cellPhone)
      const work = String(contact.workPhone)

      return {
        id: contact.id,
        agentId: contact.agentId,
        name: contact.name,
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
        appointed: contact.appointed,
        suffix: contact.suffix,
        mailingAddress: {
          street: contact.mailingAddress.street,
          city: contact.mailingAddress.city,
          state: contact.mailingAddress.state,
          zip: contact.mailingAddress.zip,
        },
        physicalAddress: {
          street: contact.physicalAddress.street,
          city: contact.physicalAddress.city,
          state: contact.physicalAddress.state,
          zip: contact.physicalAddress.zip,
        },
        dob: contact.dob,
        email: contact.email,
        workPhone: new Phone(work),
        cellPhone: new Phone(cell),
        ssn: contact.ssn,
        type: contact.type,
        hasActiveLicense: contact.hasActiveLicense,
        userIdStatus: contact.userIdStatus,
        registrationRequestMedium: contact.registrationRequestMedium,
        role: contact.role,
        nisUserId: contact.nisUserId,
        enabled: contact.enabled,
        lastStatusChangedBy: contact.lastStatusChangedBy,
        lastStatusChangeDate: contact.lastStatusChangeDate,
      }
    })
  },

  NEW_CONTACT(state: ContactListInterface) {
    state.contactList = [new Contact()]
  },

  FILTER_CONTACT(state: ContactListInterface, contactId: number) {
    state.contactList = state.contactList.filter((contact) => {
      return contact.id === contactId
    })
  },
}

export default mutations
