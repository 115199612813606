<template>
  <v-container>
    <h4>We have identified a different business name based upon the address provided:</h4>
    <v-radio-group v-model="currentBusinessNames.selectedName" class="ma-0">
      <v-row class="pt-0">
        <v-col cols="10"></v-col>
        <v-col cols="2" class="font-weight-bold">Select</v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="4" class="py-1 pl-0">System matched Business:</v-col>
        <v-col cols="6" class="py-1 text-right">{{ businessNames.systemMatchedName }}</v-col>
        <v-col cols="2" class="py-1"><v-radio :value="'systemMatched'" class="pl-5" /></v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="4" class="py-1 pl-0">Business you entered:</v-col>
        <v-col cols="6" class="py-1 text-right">{{ businessNames.userEnteredName }}</v-col>
        <v-col cols="2" class="py-1"><v-radio :value="'userEntered'" class="pl-5" /></v-col>
      </v-row>
    </v-radio-group>
    <h4>
      If the System matched Business is correct, please make sure the business name aligns with your Entity selection
      shown below:
    </h4>
    <app-select
      label="Entity:"
      class="pl-3 pt-3"
      :items="entities"
      isRequired
      v-model="currentClient.clientDetail.businessEntityType"
    >
      <template v-slot:tooltip>
        Please select the entity type that best describes the customer:
        <ul>
          <li>
            If the customer is a natural born person and does not operate their business under a corporation, LLC, or
            any other legal entity, select <strong>Individual</strong>.
          </li>
          <li>
            If the business is set up as a corporation, LLC, or any other legal entity except a partnership, select
            <strong>Corporation</strong>. (Note. Even if all vehicles are owned and titled in a personal name the risk
            should be rated as a Corporation)
          </li>
          <li>
            If the customer operates as any kind of partnership including general partnerships, or limited partnerships,
            select <strong>Partnership</strong>.
          </li>
        </ul>
      </template>
    </app-select>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import AppSelect from '@/components/common/input/AppSelect.vue'
import { BusinessNameInterface, ClientInterface } from '@/store/modules/client/types'
import { CommonSelectOptions } from '@/models/select-option'

export default defineComponent({
  name: 'BusinessNameSelection',
  components: { AppSelect },
  props: {
    client: {
      type: Object as PropType<ClientInterface>,
      required: true,
    },
    businessNames: {
      type: Object as PropType<BusinessNameInterface>,
      required: true,
    },
  },
  setup(props) {

    const currentBusinessNames = computed<BusinessNameInterface>(() => {
      return props.businessNames
    })

    const currentClient = computed<ClientInterface>(() => {
      return props.client
    })

    const entities = computed(() => {
      return CommonSelectOptions.GetEntities()
    })

    return {
      currentBusinessNames,
      currentClient,
      entities,
    }
  },
})
</script>
