import { render, staticRenderFns } from "./Gateway.vue?vue&type=template&id=051b52d2&scoped=true"
import script from "./Gateway.vue?vue&type=script&lang=ts"
export * from "./Gateway.vue?vue&type=script&lang=ts"
import style0 from "./Gateway.vue?vue&type=style&index=0&id=051b52d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051b52d2",
  null
  
)

export default component.exports