import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'
import { RootState } from '../../types'
import { Module } from 'vuex'
import { ReportsCalloutsInterface } from './types'

/**
 * reports callouts
 */
export const reportsCallouts: Module<ReportsCalloutsInterface, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default reportsCallouts
