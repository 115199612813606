<template>
  <div>
    <v-row v-for="(adminAlert, index) in adminAlerts" :key="index" class="banner-message ma-auto" align="center">
      <div class="d-flex px-16 ma-auto alertBanner" v-if="adminAlerts!=[]">
        <v-col class="px-16 py-5" :cols="10">
          <b id="alertMessage">{{ adminAlert.bannerMessage }}</b>
        </v-col>
        <v-col class="ma-auto" :cols="2">
          <simple-button class="dismiss" color="white" @click="dismissAdminAlert(adminAlert)">Dismiss</simple-button>
        </v-col>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import store from '@/store'

import { AdminAlertInterface } from '@/store/modules/admin-alert/types'
import { AlertBannerActions } from '@/store/modules/admin-alert/actions'
import { AlertBannerGetters } from '@/store/modules/admin-alert/getters'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import { UserSessionGetters } from '@/store/modules/user-session/getters'

export default defineComponent({
  name: 'AlertBanner',

  components: {
    SimpleButton,
  },

  setup() {
    const princetonCode = computed<string>(()=>store.getters[UserSessionGetters.PrincetonAgentCode])
    store.dispatch(AlertBannerActions.GetAdminAlertsByPrincetonCode, { princetonCode: princetonCode.value })
    const adminAlerts = computed<Array<AdminAlertInterface>>(() => store.getters[AlertBannerGetters.AlertBanner])
    const dismissAdminAlert = (adminAlert: AdminAlertInterface) => {
      store.dispatch(AlertBannerActions.DismissAdminAlert, { alertDismissed: adminAlert })
      }

    return {
      adminAlerts,
      dismissAdminAlert,
    }
  },
})
</script>

<style lang="scss">
.alertBanner {
  width: $ngic-app-max-width;
}
</style>
