import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'

export interface ComparerInterface {
  /** @returns true if `a` is equivalent to `b`, ignoring case */
  equalsIgnoreCase: (a: string | undefined, b: string | undefined) => boolean

  /**
   * @returns true if `source` includes `searchString`, ignoring case.
   * @param source If `source` is an array, the output is whether the `source` array contains an element that is exact case-insensitive match for `searchString`.
                   If `source` is a string, the output is whether `searchString` is a case-insensitive substring of `source`.
   */
  includesIgnoreCase: (source: string[] | string | undefined, searchString: string | undefined) => boolean

  isEmpty: (source: unknown) => boolean

  /** @returns true if the date is the minimum date or undefined, false otherwise. */
  isMinDate: (date: Date | Dayjs | string | undefined) => boolean

  isNullOrWhitespace: (source: string | undefined) => boolean

  /** Returns true if the given string is the word "true", ignoring case */
  isTrue: (boolString: string | undefined) => boolean

  includesSubStringIgnoreCase: (source: string, subString: string) => boolean
}

// First, define equalsIgnoreCase, which will be the core of most of the other string comparison methods

/** @returns true if `a` is equivalent to `b`, ignoring case */
function equalsIgnoreCase(a: string | undefined, b: string | undefined) {
  if (!a || !b) return a === b // Technically, we should return true if they are both undefined or if they are both empty strings
  return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
}

// Next, define the other string comparison methods

/**
 * @returns true if `source` includes `searchString`, ignoring case.
 * @param source If `source` is an array, the output is whether the `source` array contains an element that is exact case-insensitive match for `searchString`.
                 If `source` is a string, the output is whether `searchString` is a case-insensitive substring of `source`.
 */
function includesIgnoreCase(source: string[] | string | undefined, searchString: string | undefined) {
  if (!source || !searchString) return false

  // JavaScript does not have a case-insensitive version of String.includes, so we must use toLowerCase in this specific scenario.
  if (typeof source === 'string') return source.toLowerCase().includes(searchString.toLowerCase())

  // else, source is an array of strings
  return source.some((x) => equalsIgnoreCase(x, searchString))
}

/** @returns true if the date is the minimum date or undefined, false otherwise. */
function isMinDate(date: Date | Dayjs | string | undefined) {
  if (typeof date === 'undefined') return true
  if (typeof date === 'string' || date instanceof Date) return dayjs(date).isSame('0001-01-01', 'year')
  // By process of elimination, we know date is a Dayjs instance
  return date.isSame('0001-01-01', 'year')
}

/** @returns true if the string is undefined, null, empty, or all whitespace. */
function isNullOrWhitespace(source: string | undefined): boolean {
  return !source?.trim()
}

/**
 * This may seem redundant but it will allow us to more easily
 * decouple our code from lodash in the future if necessary.
 * This lodash method is incredibly useful, especially for our commonly used pseudo-dictionaries.
 */
function isEmpty(source: unknown): boolean {
  return _.isEmpty(source)
}

/** @returns true if the given string is the word "true", ignoring case */
const isTrue = (boolString: string | undefined) => equalsIgnoreCase(boolString, 'true')

/** @returns true if the substring can be found within the source, ignoring cases */
function includesSubStringIgnoreCase(source: string | undefined, subString: string | undefined): boolean {
  if (!source || !subString) return false
  return source.toLowerCase().indexOf(subString.toLowerCase()) > -1
}

/**
 * Returns an instance of ComparerInterface, which can be used to transform values into a variety of formats
 * @see ComparerInterface
 */
export default function (): ComparerInterface {
  return {
    equalsIgnoreCase,
    includesIgnoreCase,
    isEmpty,
    isMinDate,
    isNullOrWhitespace,
    isTrue,
    includesSubStringIgnoreCase,
  }
}
