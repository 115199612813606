import mitt from 'mitt'

export const EventBus = mitt()

export class Events {
  public static hideLayout = 'hide-layout'
  public static showLayout = 'show-layout'
  public static updateContactListGridInformation = 'update-contact-list-grid-information'
  public static updateTotalNumberOfContacts = 'update-total-number-of-contacts'
  public static closeModalAndStay = 'close-modal-and-stay'
  public static showModalConfirm = 'show-modal-confirm'
  public static disableModalConfirm = 'disable-modal-confirm'
  public static showEditContactMessage = 'show-edit-contact-message'
  public static refreshContacts = 'refresh-contacts'

}
