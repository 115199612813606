import store from "@/store"
import { ClientActions } from "@/store/modules/client/actions"
import { OccupationFilterActions } from "@/store/modules/occupation-filter/actions"
import { OccupationInterface } from "@/store/modules/occupation/types"

const noAvailableOccupations = 'No available occupations returned'

export default function (newOccupation: OccupationInterface): void {
    if (newOccupation.occupation === noAvailableOccupations) return

    store.dispatch(ClientActions.SetOccupation, newOccupation.occupation ? newOccupation.occupation : '')

    // Set the list of occupations to the selected one if selected from the search box or from the list
    // Or reset when 'x' (clear) is clicked
    if (!newOccupation.occupation) {
        store.dispatch(OccupationFilterActions.ResetModule)
    }
    else {
        store.dispatch(OccupationFilterActions.SetBusinessTypeAfterSelection, newOccupation)
    }

}