/** The Alert Banner interface */
export interface AdminAlertInterface {
  alertId: number;
  alertName: string;
  bannerMessage: string;
  rowEffective: string;
  location: string;
  alertAction: string;
  princetonCode: string;
}

/** The Alert Message model */
export class AdminAlert implements AdminAlertInterface {
  alertId: number;
  alertName: string;
  bannerMessage: string;
  rowEffective: string;
  location: string;
  alertAction: string;
  princetonCode: string;

  /** Creates a new instance of an Alert Message */
  constructor(options?: {
    alertId: number;
    alertName: string;
    bannerMessage: string;
    rowEffective: string;
    location: string;
    alertAction: string;
    princetonCode: string;
  }) {
    this.alertId = options?. alertId ?? 0
    this.alertName = options?.alertName ?? ''
    this.bannerMessage = options?. bannerMessage?? ''
    this.rowEffective = options?.rowEffective ?? ''
    this.location = options?.location ?? ''
    this.alertAction = options?.alertAction ?? ''
    this.princetonCode = options?.princetonCode ?? ''
  }
}

/** The Alert Banner state */
export interface AlertBannerInterface {
  adminAlerts: Array<AdminAlert>;
}
