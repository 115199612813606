import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AgencyGroupListInterface } from './type'

export class AgencyCodeGetters {
  public static AgencyGroups = 'agencyCode/agencyGroups'
}

export const getters: GetterTree<AgencyGroupListInterface, RootState> = {
  agencyGroups: (state: AgencyGroupListInterface) => state,
}

export default getters
