<template>
  <v-container>
    <alert-messages v-show="!refFields.showAddressValidation" />
    <v-card-text>
      <app-select
        label="Entity"
        :items="entities"
        @input="checkEntityType"
        isRequired
        v-model="userInput.clientDetail.businessEntityType"
      >
        <template v-slot:tooltip>
          Please select the entity type that best describes the customer:
          <ul>
            <li>
              If the customer is a natural born person and does not operate their business under a corporation, LLC, or
              any other legal entity, select <strong>Individual</strong>.
            </li>
            <li>
              If the business is set up as a corporation, LLC, or any other legal entity except a partnership, select
              <strong>Corporation</strong>. (Note. Even if all vehicles are owned and titled in a personal name the risk
              should be rated as a Corporation)
            </li>
            <li>
              If the customer operates as any kind of partnership including general partnerships, or limited
              partnerships, select <strong>Partnership</strong>.
            </li>
          </ul>
        </template>
      </app-select>
      <app-radio
        label="Does Insured have a USDOT#?"
        :options="usdotOptions"
        @input="selectUSDOTActions"
        isRequired
        v-model="userInput.clientDetail.clientHasUsdotType"
        for="does-insured-have-usdot"
      >
        <template v-slot:tooltip>
          Does the insured have a USDOT#?
          <div>
            <table class="tooltip-table">
              <tr>
                <td>Yes</td>
                <td>Select this option if the customer has a USDOT#.</td>
              </tr>
              <tr>
                <td>No, but plan to obtain</td>
                <td>
                  Select this option if the customer is in the process of obtaining or has recently requested a USDOT#.
                </td>
              </tr>
              <tr>
                <td>No, not required</td>
                <td>Select this option if the customer is not required to have a USDOT#.</td>
              </tr>
            </table>
          </div>
          Please search for the business in <a :href="refFields.saferUrl" target="_blank">SAFER</a> if you need
          assistance.
        </template>
        <template v-slot:footer>
          Please search for the business in <a :href="refFields.saferUrl" target="_blank">SAFER</a> if you need
          assistance
        </template>
      </app-radio>
      <app-text-field
        label="USDOT#"
        :hidden="!showUsdot"
        showColumn
        v-model="userInput.clientDetail.usdotNumber"
        for="usdot"
      >
        <template v-slot:rightColumn>
          <simple-button class="mx-2" color="primary" @click="orderCABReport(false)" v-model="isPossibleUsdot">
            Verify USDOT#
          </simple-button>
        </template>
      </app-text-field>
      <v-card v-show="showUsdotInfo" class="my-4 px-4">
        <v-row no-gutters class="align-center">
          <v-col cols="6">
            <v-card-subtitle class="font-weight-black">USDOT# Registered To:</v-card-subtitle>
            <div class="mx-4"><span v-html="refFields.registeredAddress" /></div>
            <v-card-text>
              <v-radio-group
                row
                dense
                label="Is this information correct?"
                @change="updateUSDOTSelections"
                v-model="userInput.clientDetail.isUsdotVerified"
              >
                <v-radio label="Yes" :value="true" @click="prefillAndLockdown" />
                <v-radio label="No" :value="false" />
              </v-radio-group>
            </v-card-text>
          </v-col>
          <v-col cols="6" class="pr-4">
            <v-card v-show="refFields.showNotVerifiedText" outlined class="my-4 pa-2">
              USDOT # was not a match. Please update USDOT information. For verification, you can look up the USDOT
              number by utilizing <a :href="refFields.saferUrl" target="_blank">SAFER</a>. For assistance please call
              customer service at 1-877-468-3466. If you continue the quote without a USDOT number, please note the rate
              may change once the information is verified.
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <app-select
        :hidden="!showAdditionalFields"
        label="Motor Carrier/For Hire"
        :items="selectYesNoOptions"
        :disabled="disablePrefilledFields"
        isRequired
        v-model="userInput.clientDetail.motorCarrierForHire"
        for="motor-carrier-or-for-hire"
      >
        <template v-slot:tooltip>
          Please select "Yes" if this customer is a Motor Carrier or is a For-Hire business type which does any
          transporting of goods or passengers for compensation.
        </template>
      </app-select>
      <app-text-field
        :hidden="!showBusinessName"
        label="Business Name"
        :disabled="disablePrefilledFields"
        isRequired
        @blur="userInput.business.businessName = ConvertToUpperCase(userInput.business.businessName)"
        v-model="userInput.business.businessName"
        for="business-name"
      />
      <app-text-field
        :hidden="!showPersonName"
        label="First Name"
        isRequired
        @blur="userInput.person.firstName = ConvertToTitleCase(userInput.person.firstName)"
        v-model="userInput.person.firstName"
        for="first-name"
      />
      <app-text-field
        :hidden="!showPersonName"
        label="Last Name"
        isRequired
        @blur="userInput.person.lastName = ConvertToTitleCase(userInput.person.lastName)"
        v-model="userInput.person.lastName"
        for="last-name"
      />
      <app-text-field
        :hidden="!showAdditionalFields"
        label="DBA"
        @blur="userInput.clientDetail.doingBusinessAs = ConvertToUpperCase(userInput.clientDetail.doingBusinessAs)"
        v-model="userInput.clientDetail.doingBusinessAs"
        for="dba"
      >
        <template v-slot:tooltip>
          A DBA is an operating name only and has no rights or privileges under an insurance policy. If the insured
          operates their business under any legal company name such as a corporation or LLC, the entity type selection
          should be Corporation with the company name, not the DBA, listed as the Business Name.
        </template>
      </app-text-field>
      <app-text-field
        :hidden="!showAdditionalFields"
        label="Business Address"
        @keydown="StreetAddressRestrictor"
        isRequired
        @blur="userInput.businessAddress.streetAddress = FormatAddress(userInput.businessAddress.streetAddress)"
        v-model="userInput.businessAddress.streetAddress"
        for="business-address"
      />
      <app-text-field
        :hidden="!showAdditionalFields"
        label="Business Address 2"
        @blur="userInput.businessAddress.secondaryAddress = FormatAddress(userInput.businessAddress.secondaryAddress)"
        v-model="userInput.businessAddress.secondaryAddress"
        for="business-address-2"
      />
      <app-text-field
        :hidden="!showAdditionalFields"
        label="City"
        @keydown="CityRestrictor"
        isRequired
        @blur="userInput.businessAddress.city = ConvertToTitleCase(userInput.businessAddress.city)"
        v-model="userInput.businessAddress.city"
        for="city"
      />
      <app-select
        :hidden="!showAdditionalFields"
        label="State"
        :items="stateOptions"
        isRequired
        v-model="userInput.businessAddress.state"
        for="state"
      />
      <app-text-field
        :hidden="!showAdditionalFields"
        :maxlength="5"
        @keydown="IntegersOnly"
        label="Zip Code"
        isRequired
        v-model="userInput.businessAddress.zipCode"
        for="zip-code"
      />
      <phone-field
        :hidden="!showAdditionalFields"
        label="Phone"
        showColumn
        v-model="userInput.phoneNumbers[0]"
        ref="phoneNumberEntry"
        for="phone"
        splitPhone
      >
        <template v-slot:rightColumn>
          <simple-select :items="phoneTypes" v-model="userInput.phoneNumbers[0].phoneType" />
        </template>
      </phone-field>
      <app-text-field
        :hidden="!showAdditionalFields"
        label="Email"
        v-model="userInput.workEmailAddress.emailAddress"
        @keydown.space="(event) => event.preventDefault()"
        for="email"
      />
    </v-card-text>
    <v-card-actions>
      <simple-button color="secondary" @click="returnToSearch" class="mx-2">Return to Client Search</simple-button>
      <v-spacer />
      <simple-button color="primary" @click="goNext" class="mx-2" v-if="showAdditionalFields">Continue</simple-button>
    </v-card-actions>
    <address-validation
      :value="refFields.showAddressValidation"
      :currentAddress="userInput.businessAddress"
      :suggestedAddress="suggestedAddress"
      @return-and-edit="closeAndGoBack"
      @select-current-address="closeAndContinue"
      @select-suggested-address="closeAndContinueWithSave($event)"
    >
      <template v-slot:title>Current Address</template>
    </address-validation>
  </v-container>
</template>

<script lang="ts">
import AddressValidation from '@/components/common/AddressValidation.vue'
import AlertMessages from '@/components/common/AlertMessages.vue'
import AppRadio from '@/components/common/input/AppRadio.vue'
import AppSelect from '@/components/common/input/AppSelect.vue'
import AppTextField from '@/components/common/input/AppTextField.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import SimpleSelect from '@/components/common/input/SimpleSelect.vue'
import PhoneField from '@/components/common/input/PhoneField.vue'
import enums from '@/constants/enumerations'
import externalRoutes from '@/constants/externalRoutes'
import { CommonSelectOptions, SelectOption } from '@/models/select-option'
import router from '@/router'
import store from '@/store'
import { AccountHolderSaveResult } from '@/store/modules/account-holder-callouts/types'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessage, AlertMessageSource, AlertMessageType } from '@/store/modules/alert-messages/types'
import { ClientActions } from '@/store/modules/client/actions'
import { ClientGetters } from '@/store/modules/client/getters'
import { AddressInterface, Client, ClientInterface } from '@/store/modules/client/types'
import { ReportsCalloutsActions } from '@/store/modules/reports-callouts/actions'
import { ReportsCalloutsGetters } from '@/store/modules/reports-callouts/getters'
import {
  FirmographicsCalloutActionResult,
  OrderCabCalloutActionResult,
  ValidateAddressCalloutActionResult,
} from '@/store/modules/reports-callouts/types'
import { zipValidateCallout } from '@/store/modules/zip-validation/actions'
import {
  BridgeToNamedInsured,
  ClientSetupCheck,
  GetExistingFirmographicsData,
  GetTransaction,
  RedirectToBusinessSearchInfo,
} from '@/utils/ClientViewShared'
import { ConvertToTitleCase, ConvertToUpperCase, FormatAddress } from '@/utils/Formatter'
import { IntegersOnly, CityRestrictor, StreetAddressRestrictor } from '@/utils/InputFilters'
import Register from '@/utils/ProcessingMaskHelpers'
import { computed, defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  name: 'CVClientEntry',
  components: {
    SimpleButton,
    AppRadio,
    AppSelect,
    AppTextField,
    SimpleSelect,
    PhoneField,
    AlertMessages,
    AddressValidation,
  },
  setup() {
    const additional60DaysOfUsdotNumbers = 17500 // 'Magic Number' defined by the business

    const refFields = reactive({
      addressValidated: false,
      entityIsBusiness: false,
      entityIsPerson: false,
      entitySelected: false,
      firmographicsHasCodes: false,
      firmographicsNoHit: false,
      hasUSDOT: false,
      registeredAddress: '',
      requiredUSDOTSelectionsMade: false,
      saferUrl: externalRoutes.safer,
      showAddressValidation: false,
      showInfoVerification: false,
      showNotVerifiedText: false,
      skipAccountHolderSave: false,
    })

    const disablePrefilledFields = computed<boolean>(() => {
      return store.getters[ClientGetters.DisablePrefilledFields]
    })
    const cabInformation = reactive<ClientInterface>(new Client())
    const isPossibleUsdot = ref<boolean | undefined>()
    const suggestedAddress = ref<AddressInterface | undefined>()

    const entities = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetEntities()
    })

    function hasDigitsOnly(stringToCheck: string) {
      return /^\d+$/.test(stringToCheck)
    }

    function isMotorCarrierForHire(classifications: Array<string>) {
      const forHireClassifications = ['Authorized For-Hire', 'Exempt For-Hire', 'Migrant', 'U. S. Mail']
      if (classifications.some((x) => forHireClassifications.indexOf(x) !== -1)) return true
      return false
    }

    const isValidUsdotNumber = (usdotNumber: string) => {
      return usdotNumber.length >= 4 && usdotNumber.length <= 14 && hasDigitsOnly(usdotNumber)
    }

    const phoneTypes = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetPhoneTypes()
    })

    const returnToSearch = () => {
      store.dispatch(AlertMessagesActions.ClearMessages)
      router.push({ name: enums.pageName.ClientSearch, query: router.currentRoute.query })
    }

    const selectYesNoOptions = computed<SelectOption<boolean | undefined>[]>(() => {
      return CommonSelectOptions.GetSelectYesNoOptions()
    })

    const stateOptions = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetStateOptions()
    })

    const usdotOptions = computed<SelectOption<string>[]>(() => {
      return CommonSelectOptions.GetUSDOTOptions()
    })

    function setNoUsdotMessage() {
      store.dispatch(AlertMessagesActions.AddInfoMessage, {
        messageText:
          "Please provide the customer's information below. Note the rate may change once the USDOT# is obtained.",
        source: AlertMessageSource.USDOTInfo,
      })
    }

    function setNotRequiredUsdotMessage() {
      store.dispatch(AlertMessagesActions.AddInfoMessage, {
        messageText: "Please provide the customer's information below.",
        source: AlertMessageSource.USDOTInfo,
      })
    }

    function setUsdotNotFoundMessage() {
      store.dispatch(AlertMessagesActions.AddInfoMessage, {
        messageText: `USDOT # was not a match. Please update USDOT information. For verification, you can look up the USDOT number by utilizing <strong><a href=${externalRoutes.safer} target="_blank">SAFER</a></strong>. For assistance please call customer service at 1-877-468-3466. If you continue the quote without a USDOT number, please note the rate may change once the information is verified.`,
        source: AlertMessageSource.USDOTInfo,
      })
    }

    function setPossibleUsdotMessage() {
      store.dispatch(AlertMessagesActions.AddInfoMessage, {
        messageText:
          "The provided USDOT# did not return any information. Please provide the customer's information below. Note the rate may change once the insured's USDOT# information is verified.",
        source: AlertMessageSource.USDOTInfo,
      })
    }

    function setCABDownOrErrorMessage() {
      store.dispatch(AlertMessagesActions.AddInfoMessage, {
        messageText: `The provided USDOT# cannot be verified at this time. Please provide the customer's information below. Note the rate may change once the insured's USDOT# information is verified.`,
        source: AlertMessageSource.USDOTInfo,
      })
    }

    async function setRefFieldsFromClientData(client: ClientInterface) {
      refFields.entitySelected = client.clientDetail.businessEntityType !== ''
      refFields.hasUSDOT = client.clientDetail.clientHasUsdotType === enums.usdotSelectedOption.Yes
      refFields.requiredUSDOTSelectionsMade =
        (refFields.hasUSDOT && (!!client.clientDetail.isUsdotVerified || !!client.clientDetail.isPossibleUsdot)) ||
        (!refFields.hasUSDOT &&
          client.clientDetail.clientHasUsdotType !== '' &&
          client.clientDetail.clientHasUsdotType !== 'None')

      if (refFields.entitySelected && refFields.requiredUSDOTSelectionsMade) {
        refFields.entityIsPerson = client.clientDetail.businessEntityType === enums.entityType.Individual
        refFields.entityIsBusiness = client.clientDetail.businessEntityType !== enums.entityType.Individual
      }
    }

    const userInput = computed<ClientInterface>(() => {
      const clientToLoad: ClientInterface = store.getters[ClientGetters.NewClient]
      setRefFieldsFromClientData(clientToLoad)
      if (Object.values(clientToLoad.businessAddress).every((x) => x === '')) {
        clientToLoad.businessAddress = clientToLoad.residentialAddress
      }
      return clientToLoad
    })

    const showAdditionalFields = computed<boolean>(() => {
      return (refFields.entitySelected && refFields.requiredUSDOTSelectionsMade) || !!isPossibleUsdot.value
    })

    const showBusinessName = computed<boolean>(() => {
      return refFields.entityIsBusiness && (refFields.requiredUSDOTSelectionsMade || !!isPossibleUsdot.value)
    })

    const showPersonName = computed<boolean>(() => {
      return refFields.entityIsPerson && (refFields.requiredUSDOTSelectionsMade || !!isPossibleUsdot.value)
    })

    const showUsdot = computed<boolean>(() => {
      return refFields.entitySelected && refFields.hasUSDOT
    })

    const showUsdotInfo = computed<boolean>(() => {
      return refFields.hasUSDOT && refFields.showInfoVerification
    })

    function hasPossibleUsdot(currentMaxDot: number, usdotEntered: number) {
      return usdotEntered > currentMaxDot && usdotEntered <= currentMaxDot + additional60DaysOfUsdotNumbers
    }

    const infoVerified = computed<boolean | undefined>(() => {
      return userInput.value.clientDetail.isUsdotVerified
    })

    function upperCaseDba() {
      userInput.value.clientDetail.doingBusinessAs = userInput.value.clientDetail.doingBusinessAs
        ? ConvertToTitleCase(userInput.value.clientDetail.doingBusinessAs)
        : ''
    }

    function updateUSDOTSelections() {
      if (!refFields.hasUSDOT) {
        store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.USDOTValidation })
      }
      refFields.requiredUSDOTSelectionsMade =
        (refFields.hasUSDOT &&
          (!!userInput.value.clientDetail.isUsdotVerified || !!userInput.value.clientDetail.isPossibleUsdot)) ||
        !refFields.hasUSDOT
      refFields.showNotVerifiedText =
        typeof userInput.value.clientDetail.isUsdotVerified !== 'undefined' &&
        !userInput.value.clientDetail.isUsdotVerified
    }

    function checkEntityType(selectedOption: string) {
      delete userInput.value.clientDetail.isUsdotVerified
      store.dispatch(ClientActions.DisablePrefilledFields, false)
      if (refFields.hasUSDOT) refFields.requiredUSDOTSelectionsMade = false // resetting for display reasons
      refFields.showInfoVerification = false

      switch (selectedOption) {
        case enums.entityType.Individual:
          refFields.entitySelected = true
          refFields.entityIsBusiness = false
          refFields.entityIsPerson = true
          break
        // eslint-disable-next-line no-fallthrough
        case enums.entityType.Corporation:
        case enums.entityType.Partnership:
          refFields.entitySelected = true
          refFields.entityIsBusiness = true
          refFields.entityIsPerson = false
          break
        default:
          refFields.entitySelected = false
          refFields.entityIsBusiness = false
          refFields.entityIsPerson = false
      }
    }

    const selectUSDOTActions = (selectedOption: string) => {
      refFields.hasUSDOT = selectedOption === enums.usdotSelectedOption.Yes
      store.dispatch(ClientActions.DisablePrefilledFields, false)

      store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.USDOTInfo })

      delete userInput.value.clientDetail.isUsdotVerified
      delete userInput.value.clientDetail.isPossibleUsdot

      if (selectedOption === enums.usdotSelectedOption.Yes) {
        refFields.showInfoVerification = false
        store.dispatch(ClientActions.DisablePrefilledFields, true)
        isPossibleUsdot.value = undefined
      } else if (selectedOption === enums.usdotSelectedOption.No) {
        setNoUsdotMessage()
      } else if (selectedOption === enums.usdotSelectedOption.NotRequired) {
        setNotRequiredUsdotMessage()
      }
      updateUSDOTSelections()
    }

    function clearPrefilledInformation() {
      delete userInput.value.clientDetail.motorCarrierForHire
      userInput.value.person.firstName = ''
      userInput.value.person.lastName = ''
      userInput.value.business.businessName = ''
      userInput.value.clientDetail.doingBusinessAs = ''
      userInput.value.businessAddress.streetAddress = ''
      userInput.value.businessAddress.city = ''
      userInput.value.businessAddress.state = ''
      userInput.value.businessAddress.zipCode = ''
    }

    const hasUSDOTAndMCFH = computed<boolean>(() => {
      return refFields.hasUSDOT && userInput.value.clientDetail.motorCarrierForHire === true
    })

    function splitLegalName(legalName: string, entity: string) {
      if (entity === enums.entityType.Individual) {
        cabInformation.business.businessName = ''
        const fullName = ConvertToTitleCase(legalName).split(' ')
        if (fullName.length) {
          cabInformation.person.firstName = fullName[0] ?? ''
          cabInformation.person.lastName = fullName[fullName.length - 1] ?? ''
        }
      } else {
        cabInformation.person.firstName = ''
        cabInformation.person.lastName = ''
        cabInformation.business.businessName = legalName
      }
    }

    function setCabInformation(result: OrderCabCalloutActionResult) {
      cabInformation.clientDetail.businessEntityType = userInput.value.clientDetail.businessEntityType
      cabInformation.clientDetail.clientHasUsdotType = userInput.value.clientDetail.clientHasUsdotType
      cabInformation.businessAddress.streetAddress = result.businessAddress ?? ''
      cabInformation.businessAddress.city = result.businessCity ?? ''
      cabInformation.businessAddress.state = result.businessState ?? ''
      cabInformation.businessAddress.zipCode = result.businessZip ?? ''
      cabInformation.businessAddress.zipPlusFour = result.businessZipPlusFour ?? ''
      splitLegalName(result.legalName ?? '', userInput.value.clientDetail.businessEntityType)
      cabInformation.clientDetail.doingBusinessAs = result.nameDba ?? ''
      cabInformation.clientDetail.motorCarrierForHire = isMotorCarrierForHire(result.operationClassification ?? [])
    }

    async function orderCABReport(shouldReorder: boolean): Promise<boolean> {
      let reportResults = true
      store.dispatch(ReportsCalloutsActions.SetCabIsDown, false)
      isPossibleUsdot.value = undefined

      if (!shouldReorder) {
        store.dispatch(AlertMessagesActions.ClearMessages)
        delete userInput.value.clientDetail.isPossibleUsdot
        delete userInput.value.clientDetail.isUsdotVerified
        refFields.requiredUSDOTSelectionsMade = false
        refFields.showInfoVerification = false
        store.dispatch(ClientActions.DisablePrefilledFields, false)
        refFields.skipAccountHolderSave = false
        updateUSDOTSelections()
      }
      if (!userInput.value.clientDetail.usdotNumber) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'Please provide USDOT# and then click Verify USDOT# in order to proceed.',
          source: AlertMessageSource.USDOTValidation,
        })
      } else if (!isValidUsdotNumber(userInput.value.clientDetail.usdotNumber.toString())) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'Please enter a valid USDOT#.',
          source: AlertMessageSource.USDOTValidation,
        })
      } else {
        const result: OrderCabCalloutActionResult = await Register(
          store.dispatch(ReportsCalloutsActions.OrderCAB, { client: userInput.value, shouldReorder })
        )

        let showPossibleUsdot = isPossibleUsdot.value ?? false
        if (!result.isSuccessful) {
          if (!shouldReorder) clearPrefilledInformation()
          if (result.maxDot) {
            const currentMaxDot = parseInt(result.maxDot)
            showPossibleUsdot = hasPossibleUsdot(currentMaxDot, userInput.value.clientDetail.usdotNumber)
            if (showPossibleUsdot) {
              setPossibleUsdotMessage()
              reportResults = true
            } else {
              setUsdotNotFoundMessage()
              reportResults = false
            }
          } else {
            setCABDownOrErrorMessage()
            store.dispatch(ReportsCalloutsActions.SetCabIsDown, true)
            // We want to open up fields so they can be populated by user
            showPossibleUsdot = true
            reportResults = false
          }
        } else if (!shouldReorder) {
          const fullZip = result.businessZipPlusFour?.length
            ? `${result.businessZip} - ${result.businessZipPlusFour}`
            : result.businessZip
          refFields.registeredAddress = `${result.legalName}<br />${result.businessAddress}<br />${result.businessCity}, ${result.businessState} ${fullZip}`
          setCabInformation(result)
          refFields.showInfoVerification = true
        }
        isPossibleUsdot.value = showPossibleUsdot
        userInput.value.clientDetail.isPossibleUsdot = showPossibleUsdot
      }

      return reportResults
    }

    async function updateStore() {
      // Default types for business specific fields
      userInput.value.businessAddress.addressType = enums.addressType.Business
      userInput.value.workEmailAddress.emailType = enums.emailType.Work

      // Clean up name fields
      if (userInput.value.clientDetail.businessEntityType === enums.entityType.Individual) {
        userInput.value.business.businessName = ''
        if (!userInput.value.person.firstName) userInput.value.person.firstName = ''
        if (!userInput.value.person.lastName) userInput.value.person.lastName = ''
      } else {
        userInput.value.person.firstName = ''
        userInput.value.person.lastName = ''
        if (!userInput.value.business.businessName) userInput.value.business.businessName = ''
      }

      // Save all UserInput values to store
      await store.dispatch(ClientActions.SetNewClient, userInput.value)

      // Add business specific fields to their respective larger lists
      await store.dispatch(ClientActions.UpdateAddress, userInput.value.businessAddress)
      await store.dispatch(ClientActions.UpdateEmailAddress, userInput.value.workEmailAddress)
    }

    async function saveClient(): Promise<boolean> {
      await updateStore()
      const updatedClient: ClientInterface = store.getters[ClientGetters.NewClient]
      const result: AccountHolderSaveResult = await store.dispatch(ClientActions.SaveClient, updatedClient)

      if (!result.isSuccessful) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'There was a problem saving client information.',
        })
        return false
      }
      return true
    }

    function prefillAndLockdown() {
      userInput.value.clientDetail.motorCarrierForHire = cabInformation.clientDetail.motorCarrierForHire
      userInput.value.person.firstName = cabInformation.person.firstName
      userInput.value.person.lastName = cabInformation.person.lastName
      userInput.value.business.businessName = cabInformation.business.businessName
      userInput.value.clientDetail.doingBusinessAs = cabInformation.clientDetail.doingBusinessAs
      userInput.value.businessAddress.streetAddress = cabInformation.businessAddress.streetAddress
      userInput.value.businessAddress.city = cabInformation.businessAddress.city
      userInput.value.businessAddress.state = cabInformation.businessAddress.state
      userInput.value.businessAddress.zipCode = cabInformation.businessAddress.zipCode

      store.dispatch(ClientActions.DisablePrefilledFields, true)
    }

    async function validateZipCode(): Promise<boolean> {
      return await zipValidateCallout({
        state: userInput.value.businessAddress.state,
        zipCode: userInput.value.businessAddress.zipCode,
      })
    }

    async function validateAddress(): Promise<boolean> {
      const result: ValidateAddressCalloutActionResult = await Register(
        store.dispatch(ReportsCalloutsActions.ValidateAddress, userInput.value.addresses)
      )
      if (!result.isSuccessful) {
        store.dispatch(AlertMessagesActions.AddErrorMessage, {
          messageText: 'There was a problem validating the address.',
          source: AlertMessageSource.AddressValidation,
        })
        return false
      }

      // If no action is required, we don't want to send them to the address validation screen.
      if (!result.shouldShowAddressValidation) return true

      if (result.shouldShowAddressValidation && !result.suggestedAddress) {
        const messages = result.addressValidationMessages.map(
          (x) =>
            new AlertMessage({ text: x, type: AlertMessageType.Error, source: AlertMessageSource.AddressValidation })
        )
        store.dispatch(AlertMessagesActions.AddMessages, { messages })
      }

      // Otherwise, capture the suggestion. Let address validation handle whether or not there was actually a suggestion.
      suggestedAddress.value = result.suggestedAddress

      // If the address needs to be validated, display the modal.
      refFields.showAddressValidation = true

      return false
    }

    // TODO: There needs to be a callout/lookup on PLVendorService for Firmographics with AccountHolderID
    async function isHistoryHitWithAccountHolderId(accountHolderId: number) {
      let transactionId: string = store.getters[ReportsCalloutsGetters.FirmographicsTransactionId]
      if (!transactionId) {
        const transactionIdFromServices = (await GetTransaction(accountHolderId, 'Firmographics'))?.transactionId
        if (transactionIdFromServices) {
          GetExistingFirmographicsData(accountHolderId)
          transactionId = store.getters[ReportsCalloutsGetters.FirmographicsTransactionId]
        }
      }
      return transactionId?.length > 0 && store.getters[ReportsCalloutsGetters.FirmographicsCompanyName].length > 0
    }

    async function orderFirmographics(): Promise<boolean> {
      const result: FirmographicsCalloutActionResult = await Register(
        store.dispatch(ReportsCalloutsActions.OrderFirmographics, userInput.value)
      )

      if (!result.isSuccessful) {
        BridgeToNamedInsured()
      }

      // TODO: This should also probably do the check on CRADOccupationMapping???
      if (!result.companyName?.length) {
        refFields.firmographicsNoHit = true
      } else if (result.sicCodes?.length || result.naicCodes?.length) {
        refFields.firmographicsHasCodes = true
      }

      const firmographicsTranId =
        (await GetTransaction(userInput.value.accountHolderId, 'Firmographics'))?.transactionId ?? ''

      store.dispatch(ReportsCalloutsActions.SetFirmographicsInfo, {
        transactionId: firmographicsTranId,
        companyName: result.companyName,
        sicCodes: result.sicCodes,
        naicCodes: result.naicCodes,
      })

      return true
    }

    // Does all of the saves and checks required for continuing.
    async function checkForNext(): Promise<boolean> {
      store.dispatch(AlertMessagesActions.ClearMessages)

      // Save for AccountHolderID
      if (!refFields.skipAccountHolderSave) {
        if (!(await saveClient())) return false
      }

      // Always validate zipCode is in state
      if (!(await validateZipCode())) return false

      if (!(await ClientSetupCheck(userInput.value, true))) {
        // Skip Address Verification & Firmographics if there's a history hit for the AccountHolder
        if (!(await isHistoryHitWithAccountHolderId(userInput.value.accountHolderId)) && !hasUSDOTAndMCFH.value) {
          if ((!refFields.addressValidated && !(await validateAddress())) || !(await orderFirmographics())) return false
        }
      }

      // Reorder CAB if necessary to log with AccountHolderId
      if (refFields.hasUSDOT) {
        const cabTransactionId = (await GetTransaction(userInput.value.accountHolderId, 'TruckingRisk'))?.transactionId
        if (!cabTransactionId && !store.getters[ReportsCalloutsGetters.CabIsDown] && !(await orderCABReport(true)))
          return false
      }

      return true
    }

    // Checks everything and goes to the next page.
    async function goNext(): Promise<boolean> {
      if (!(await Register(checkForNext()))) {
        return false
      } else {
        if (!(await ClientSetupCheck(userInput.value, true))) {
          return false
        }

        store.dispatch(AlertMessagesActions.ClearMessages)
        RedirectToBusinessSearchInfo()

        return true
      }
    }

    // Tries to go to the next page.
    async function closeAndContinue(): Promise<boolean> {
      refFields.addressValidated = true
      refFields.skipAccountHolderSave = true

      return await Register(goNext())
    }

    // Updates the address and tries to go to the next page.
    async function closeAndContinueWithSave(suggestedAddress: AddressInterface): Promise<boolean> {
      refFields.addressValidated = true
      refFields.skipAccountHolderSave = false
      suggestedAddress.addressType = 'Business'

      await store.dispatch(ClientActions.UpdateAddress, suggestedAddress)

      const clientToUse = store.getters[ClientGetters.NewClient]
      clientToUse.businessAddress = suggestedAddress

      await store.dispatch(ClientActions.SetNewClient, clientToUse)

      if (!(await goNext())) return false
      return true
    }

    // Goes back to allow edit of address.
    function closeAndGoBack() {
      refFields.showAddressValidation = false
      refFields.addressValidated = false
      refFields.skipAccountHolderSave = false

      return false
    }

    return {
      additional60DaysOfUsdotNumbers,
      cabInformation,
      CityRestrictor,
      checkEntityType,
      clearPrefilledInformation,
      closeAndContinue,
      closeAndContinueWithSave,
      closeAndGoBack,
      ConvertToTitleCase,
      ConvertToUpperCase,
      disablePrefilledFields,
      entities,
      FormatAddress,
      goNext,
      hasUSDOTAndMCFH,
      infoVerified,
      IntegersOnly,
      isPossibleUsdot,
      isValidUsdotNumber,
      orderCABReport,
      phoneTypes,
      prefillAndLockdown,
      refFields,
      returnToSearch,
      saveClient,
      selectUSDOTActions,
      selectYesNoOptions,
      showAdditionalFields,
      showBusinessName,
      showPersonName,
      showUsdot,
      showUsdotInfo,
      stateOptions,
      StreetAddressRestrictor,
      suggestedAddress,
      updateUSDOTSelections,
      upperCaseDba,
      usdotOptions,
      userInput,
    }
  },
})
</script>
