export interface SicInterface {
  sic?: string;
  description?: string;
}

export class SicCode implements SicInterface {
  sic?: string;
  description?: string;

  constructor (options?: {
    sic?: string;
    description?: string;
  }) {
    this.sic = options?.sic ?? ''
    this.description = options?.description ?? ''
  }
}

export interface NaicInterface {
  naic?: string;
  description?: string;
}

export class NaicCode implements NaicInterface {
  naic?: string;
  description?: string;

  constructor (options?: {
    naic?: string;
    description?: string;
  }) {
    this.naic = options?.naic ?? ''
    this.description = options?.description ?? ''
  }
}

export interface FirmographicsInterface {
  transactionId: string;
  companyName: string;
  sicCodes?: Array<SicInterface>;
  naicCodes?: Array<NaicInterface>;
}

export class Firmographics implements FirmographicsInterface {
  transactionId: string;
  companyName: string;
  sicCodes?: Array<SicInterface>;
  naicCodes?: Array<NaicInterface>;

  constructor (options?: {
    transactionId: string;
    companyName: string;
    sicCodes: Array<SicInterface>;
    naicCodes: Array<NaicInterface>;
  }) {
    this.transactionId = options?.transactionId ?? ''
    this.companyName = options?.companyName ?? ''
    this.sicCodes = options?.sicCodes?.map((code: SicInterface) => new SicCode(code)) ?? []
    this.naicCodes = options?.naicCodes?.map((code: NaicInterface) => new NaicCode(code)) ?? []
  }
}
