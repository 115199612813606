import internalRoutes from '@/constants/internalRoutes'
import AgencyWorkflow from '@/router/agency-self-service'
import ClientWorkflow from '@/router/client'
import store from '@/store'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import Gateway from '@/views/Gateway.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      redirect: '/Gateway',
    },
    {
      path: '/Gateway',
      name: 'Gateway',
      component: Gateway,
    },
    AgencyWorkflow,
    ClientWorkflow,
  ],
  scrollBehavior() {
    // eslint-disable-next-line no-unused-expressions
    document.getElementById('app')?.scrollIntoView()
  },
})

router.beforeEach((to, _from, next) => {
  const agentId = store.getters[UserSessionGetters.AgentId]
  if (agentId || to.path === '/Gateway') next()
  else next({ name: 'Gateway' })
})

router.afterEach((_to, from) => {
  if (from.fullPath.includes(internalRoutes.agencySelfService))
    store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })
})

export default router
