<template>
  <div class="py-12">
    <div class="d-flex justify-space-between">
      <div class="formTitle d-flex align-end">
        <h2>Agency Contacts</h2>
      </div>

      <div class="grid-actions">
        <app-modal
          name="Delete Contacts"
          title="Delete Contacts"
          :maxWidth="maxModalWidth"
          confirmButtonLabel="Yes"
          rejectButtonLabel="No"
          :deactivateOnReject="false"
          activatorButtonColor="tertiary"
          :disabled="isMultipleDeleteDisabled"
          @clearAlertMessages="ClearAlertMessages"
          @confirm="multipleDelete"
        >
          <v-card-text class="px-6">{{ deleteContactsConfirmationMessage }}</v-card-text>
        </app-modal>

        <router-link class="edit-btn pl-4" :to="{ name: 'AddContact' }">
          <simple-button color="primary">Add Contact</simple-button>
        </router-link>
      </div>
    </div>
    <div class="mx-auto contact-list">
      <v-tabs left>
        <v-tab v-for="filterName in tabList" :key="filterName" @click="updateTabName(filterName)">{{
          filterName
        }}</v-tab>
        <v-tab-item v-for="filterName in tabList" :key="filterName">
          <v-data-table
            v-model="selected"
            class="elevation-1 black--text dataTable fixedTable"
            :headers="headers"
            :single-select="false"
            show-select
            :items="contactData(filterName)"
            :items-per-page="currentItemsPerPage"
            :server-items-length="totalNumberOfContacts"
            :page="currentPagination"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-skip-previous',
              lastIcon: 'mdi-skip-next',
              prevIcon: 'mdi-menu-left',
              nextIcon: 'mdi-menu-right',
              'items-per-page-text': 'Rows',
              'items-per-page-options': itemsPerPage,
            }"
            :sort-by="currentSortColumn"
            :sort-desc="currentSortDesc"
            @update:page="updatePagination"
            @update:items-per-page="updateItemPerPage"
            @update:sort-by="updateSortColumn"
            @update:sort-desc="updateSortDesc"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon class="black--text" v-on:click="goToEditContact(item)">{{ iconOptions.pencil }}</v-icon>
            </template>

            <!-- NIS config -->
            // eslint-disable-next-line vue/valid-v-slot
            <template #[`item.userIdStatus`]="{ item }" v-if="enableNisFeatures">
              <app-modal
                v-if="statusIsRegisterOrResend(item.userIdStatus)"
                :name="item.userIdStatus"
                :title="getTitle(item)"
                :maxWidth="maxModalWidth"
                :isAppButton="false"
                :normalButtonStyle="updateUserIdStyle(item.userIdStatus)"
                confirmButtonLabel="Send"
                rejectButtonLabel="Cancel"
                :deactivateOnReject="false"
                @clearAlertMessages="ClearAlertMessages"
                @confirm="sendRegistrationRequest"
              >
                <send-registration-link-section
                  :contact="item"
                  :message="setRegistrationMessage(item.userIdStatus)"
                  :resendMessage="setResendMessage(item.registrationRequestMedium)"
                  :isResendLink="statusIsResend(item.userIdStatus)"
                  :isSSO="currentAgency.isSingleSignOn"
                  @input="newRegisterUserInformation"
                />
              </app-modal>
              <p v-else class="black--text">{{ item.userIdStatus }}</p>
            </template>

            <template #[`item.loginStatus`]="{ item }">
              <v-tooltip :disabled="item.enabled" right max-width="125px">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.loginStatus }}</span>
                </template>
                <span >{{ item.disabledMessage }}</span>
              </v-tooltip>
            </template>

            <template #[`item.enabled`]="{ item }">
              <simple-button
                class="reactivate-button"
                :hidden="item.enabled || item.nisUserId === EmptyGuid"
                @click="reactivateNisUser(item.nisUserId)">
                Reactivate
              </simple-button>
            </template>

            <template v-slot:[`header.data-table-select`]="{}">Delete</template>
            <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="disableSelection(item)"
                :disabled="disableSelection(item)"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router'
import store from '@/store'
import { computed, defineComponent, PropType, ref } from 'vue'

import { AgencyGetters } from '@/store/modules/agency/getters'
import { Agency, MigrationStatus } from '@/store/modules/agency/types'
import { ContactActions } from '@/store/modules/contacts/actions'
import {
  Contact,
  ContactListInformationInterface,
  NisAgencyInformation,
  RegisterUserInformation,
} from '@/store/modules/contacts/types'

import SendRegistrationLink from '@/components/agency-self-service/modals/ModalSendRegistrationLinkSection.vue'
import SimpleButton from '@/components/common/input/SimpleButton.vue'
import AppModal from '@/components/common/modals/AppModal.vue'

import enumerations from '@/constants/enumerations'
import IconOptions from '@/constants/icon-options'
import verbiage from '@/constants/verbiage'
import {
  ContactListFields,
  ContactListFieldsInterface,
  ContactListGridHeader,
  TabHeader,
} from '@/models/data-tables/agency-contacts'
import { AlertMessagesActions } from '@/store/modules/alert-messages/actions'
import { AlertMessageSource } from '@/store/modules/alert-messages/types'
import ClearAlertMessages from '@/utils/ClearAlertMessages'
import DeleteMultipleContacts from '@/utils/ContactServices'
import { EventBus, Events } from '@/utils/EventBus'
import Register from '@/utils/ProcessingMaskHelpers'
import { EmptyGuid } from '@/constants/Strings'

export default defineComponent({
  name: 'Contacts',
  components: {
    SimpleButton,
    AppModal,
    'send-registration-link-section': SendRegistrationLink,
  },
  props: {
    contacts: {
      type: Object as PropType<ContactListInformationInterface>,
      required: true,
    },
  },
  setup(props) {
    const iconOptions = IconOptions
    const currentAgency: Agency = store.getters[AgencyGetters.Agency]

    // Set Enable NIS features to true when Agency is partially or fully migrated
    const enableNisFeatures = currentAgency.migrationStatus !== MigrationStatus.NotMigrated

    const headers = computed(() =>
      // Show the user ID column when NIS is enabled
      enableNisFeatures ? ContactListGridHeader : ContactListGridHeader.filter((header) => header.text !== 'User ID')
    )
    const tabList = TabHeader
    const validAppointedStatus = ['active', 'non-appointed', 'nonappointed']
    const managerAndOwnerType = ['Owner', 'Manager']

    const totalNumberOfContacts = ref<number>(props.contacts.totalNumberOfContacts)
    const currentPagination = ref<number>(1)
    const currentItemsPerPage = ref<number>(50)
    const currentSortMethod = ref<number>(1)
    const currentSortColumn = ref<string>('firstName')
    const currentSortDesc = ref<boolean>(false)
    const currentTab = ref<number>(2)
    const deleteContactsConfirmationMessage = verbiage.deleteMultipleContacts
    const itemsPerPage = totalNumberOfContacts.value < 500 ? [50, 100, 200, 400, -1] : [50, 100, 200, 400, 500]

    // Total number of the contacts is not able to update automatically even when STATE is updated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EventBus.on(Events.updateTotalNumberOfContacts, (totalNumber: any) => {
      totalNumberOfContacts.value = totalNumber
      currentPagination.value = 1
    })

    const goToEditContact = (contact: Contact) => {
      store.dispatch(ContactActions.FilterContact, contact.id)
      router.push({ name: 'EditContact', params: { agentId: contact.id.toString() } })
    }

    const contactData = (typeFilter: string) =>
      props.contacts.contactList
        .filter((contact) => {
          if (validAppointedStatus.includes(contact.appointed.toLowerCase())) {
            if (typeFilter === tabList.managerAndOwner) return managerAndOwnerType.includes(contact.type)
            return contact.type === typeFilter
          }
          return null
        })
        .map((contact: Contact) => {
          return new ContactListFields(contact)
        })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getSortNumberByValue = (sortMethodEnum: any, sortMethodValue: any) => {
      return Object.keys(sortMethodEnum).find((key) => sortMethodEnum[key].toString() === sortMethodValue.toString())
    }

    const getContactsFromServer = () => {
      Register(
        store
          .dispatch(ContactActions.GetContactsFromSession, {
            itemsPerPage: currentItemsPerPage.value,
            pagination: currentPagination.value,
            sortMethod: currentSortMethod.value,
            contactType: currentTab.value,
          })
          .then((response) => {
            if (response) {
              EventBus.emit(Events.refreshContacts)
            }
          })
      )
    }

    // Emit contact list pagination information to AgencyCode.vue (the dropdown to control contact list based on the Princeton Code selection)
    const emitContactListGridInformation = () => {
      EventBus.emit(Events.updateContactListGridInformation, {
        itemsPerPage: currentItemsPerPage.value,
        sortMethod: currentSortMethod.value,
        contactType: currentTab.value,
      })
    }

    const updatePagination = (pagination: number) => {
      currentPagination.value = pagination
      getContactsFromServer()
    }

    const updateItemPerPage = (itemNumber: number) => {
      currentItemsPerPage.value = itemNumber

      if (itemNumber === -1) {
        currentPagination.value = 1
      }

      emitContactListGridInformation()
      getContactsFromServer()
    }

    const updateSortColumn = (sortColumn: string) => {
      if (currentSortColumn.value !== sortColumn) {
        currentSortDesc.value = false
        if (sortColumn === undefined || JSON.parse(JSON.stringify(sortColumn)).length === 0) {
          currentSortColumn.value = sortColumn
          return
          // When sortColumn is passed in as undefined => Resetting sort column to First Name
          // Cannot set the currentSortColumn value to First Name because doing so will cause
          //  1. Current sort column to be update in the v-data-table component,
          //  2. updateSortColumn method to be called again
          //  3. To trigger GET contacts callout for the second time
          // Resetting sort column must be handled in updateSortDesc method
        }
      }

      currentSortDesc.value = false
      currentSortMethod.value = Number(
        getSortNumberByValue(enumerations.sortMethodEnum, [sortColumn, currentSortDesc.value])
      )

      if (currentSortMethod.value) {
        emitContactListGridInformation()
        getContactsFromServer()
      }

      currentSortColumn.value = sortColumn
    }

    // Refresh the contact list data wih new sort desc value when
    //    1. Sort column is not change and sort ascending (click on the sort arrow for the second time in the same sort column)
    //      a. When clicked on the sort arrow second time, updateSortDesc is called because sort desc is updated
    //      b. sort desc is update to true from the click
    //        * Sort desc will only be true when the arrow is clicked twice on the same column
    //    Or
    //    2. Sort column is undefined and sort ascending (resting the sort method to "First Name Ascending")
    //    sortDesc will be returned as an observer object sometimes
    const needToUpdateSortDesc = (sortDesc: boolean) =>
      sortDesc ||
      ((currentSortColumn.value === undefined || JSON.parse(JSON.stringify(currentSortColumn.value)).length === 0) &&
        !sortDesc)

    const updateSortDesc = (sortDesc: boolean) => {
      if (needToUpdateSortDesc(sortDesc)) {
        if (sortDesc) {
          currentSortDesc.value = sortDesc
          currentSortMethod.value = Number(
            getSortNumberByValue(enumerations.sortMethodEnum, [currentSortColumn.value, currentSortDesc.value])
          )
        } else {
          currentSortMethod.value = 1
        }

        emitContactListGridInformation()
        getContactsFromServer()
      }
    }

    function updateTabName(tabName: string) {
      currentTab.value = Number(getSortNumberByValue(enumerations.contactTypeEnum, tabName))
      currentPagination.value = 1

      return Register(
        Promise.all([
          store.dispatch(ContactActions.GetContactsFromSession, {
            itemsPerPage: currentItemsPerPage.value,
            pagination: currentPagination.value,
            sortMethod: currentSortMethod.value,
            contactType: currentTab.value,
          }),
        ]).then((response) => {
          emitContactListGridInformation()
          if (response[0]) {
            totalNumberOfContacts.value = props.contacts.totalNumberOfContacts
          } else {
            totalNumberOfContacts.value = 0
          }
        })
      )
    }
    function updateUserIdStyle(userIdStatus: string) {
      return enumerations.userIdColumnStyle[userIdStatus as keyof typeof enumerations.userIdColumnStyle]
    }

    function statusIsRegisterOrResend(userIdStatus: string) {
      return (
        userIdStatus === enumerations.userIdColumnOptions.Register ||
        userIdStatus === enumerations.userIdColumnOptions.Resend
      )
    }

    function statusIsResend(userIdStatus: string) {
      return userIdStatus === enumerations.userIdColumnOptions.Resend
    }

    const maxModalWidth: number | string = enumerations.modalWidths.Max
    const selected = ref<Array<ContactListFields>>([])
    const isMultipleDeleteDisabled = computed(() => selected.value.length === 0)
    const disableSelection = (contact: ContactListFields) => contact.type.toLowerCase() === 'owner'
    const checkBoxLabels = computed(() =>
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      selected.value
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map((contact) => {
          return {
            contactId: contact.id,
            contactName: `${contact.firstName} ${contact.lastName}`,
          }
        })
    )

    const multipleDelete = () => {
      DeleteMultipleContacts(selected.value).then((response) => {
        if (response) {
          // Emit close modal event on homepage when returning successful
          EventBus.emit(Events.closeModalAndStay)
          emitContactListGridInformation()
          getContactsFromServer()
          selected.value = []
        }
      })
    }

    const registerUserInformation = ref<RegisterUserInformation>({
      currentContactId: 0,
      firstName: '',
      lastName: '',
      dob: '',
      registrationEmail: '',
      registrationMobilePhone: '',
      isUpdate: false,
      nisLoginAgencies: [],
      identityProviderInformation: null,
      singleSignOnUsername: '',
    })

    function newRegisterUserInformation(modalValue: RegisterUserInformation) {
      registerUserInformation.value.currentContactId = modalValue.currentContactId
      registerUserInformation.value.firstName = modalValue.firstName
      registerUserInformation.value.lastName = modalValue.lastName
      registerUserInformation.value.dob = modalValue.dob
      registerUserInformation.value.registrationEmail =
        modalValue.registrationEmail === '' ? null : modalValue.registrationEmail
      registerUserInformation.value.registrationMobilePhone = modalValue.registrationMobilePhone
        ? modalValue.registrationMobilePhone.replaceAll('-', '')
        : null
      registerUserInformation.value.isUpdate = modalValue.isUpdate
      registerUserInformation.value.nisLoginAgencies = modalValue.nisLoginAgencies
      registerUserInformation.value.identityProviderInformation = currentAgency.singleSignOnProviderInformation[0]
      registerUserInformation.value.singleSignOnUsername = modalValue.singleSignOnUsername
    }

    const sendRegistrationRequest = () => {
      store.dispatch(AlertMessagesActions.ClearMessagesBySource, { source: AlertMessageSource.ApiValidation })

      if (
        registerUserInformation.value.firstName === '' ||
        registerUserInformation.value.lastName === '' ||
        registerUserInformation.value.dob === ''
      ) {
        // Show edit contact message with a redirect link when
        //    1. first name
        //    or 2. last name
        //    or 3. DOB information is incomplete
        EventBus.emit(Events.showEditContactMessage)
      } else {
        // TODO: Revisit the necessity of this block when logged in user is no longer disabled in Associated Location grid
        // Add Info object for current agency the NIS Login Agencies list, if one does not exist.
        if (
          registerUserInformation.value.nisLoginAgencies.filter((agencyInfo) => agencyInfo.agtId === currentAgency.id)
            .length === 0
        ) {
          const currentAgencyNisInfo: NisAgencyInformation = {
            agtId: currentAgency.id,
            agencyName: currentAgency.name,
            agencyPrincetonCode: currentAgency.princetonAgtCode,
            agencyStreetAddress:
              currentAgency.streetAddress.street +
              ', ' +
              currentAgency.streetAddress.city +
              +', ' +
              currentAgency.streetAddress.state +
              ', ' +
              currentAgency.streetAddress.zip,
            associatedContactId: registerUserInformation.value.currentContactId,
          }
          registerUserInformation.value.nisLoginAgencies.push(currentAgencyNisInfo)
        }

        Register(store.dispatch(ContactActions.RegisterContact, registerUserInformation.value)).then((response) => {
          if (response) {
            // Emit close modal event on homepage when returning successful
            EventBus.emit(Events.closeModalAndStay)
            emitContactListGridInformation()
            getContactsFromServer()
          }
        })
      }
    }

    function setRegistrationMessage(userIdStatus: string) {
      if (currentAgency.isSingleSignOn) {
        return verbiage.ssoSendRegistrationLink.replace(
          'identity provider',
          currentAgency.singleSignOnProviderInformation[0].providerName
        )
      }
      if (userIdStatus === enumerations.userIdColumnOptions.Resend) {
        return verbiage.resendRegistrationLink
      } else {
        return verbiage.sendRegistrationLink
      }
    }

    function setResendMessage(registrationRequestMedium: string) {
      return `${verbiage.resendMessage} ${registrationRequestMedium}.`
    }

    function getTitle(item: ContactListFieldsInterface) {
      return `Register User - ${item.firstName} ${item.lastName}`
    }

function reactivateNisUser(userId: string) {
  Register(store.dispatch(ContactActions.EnableNisAccount, userId)).then((response) =>{
    if (response) {
      getContactsFromServer()
    }
  })
}

    return {
      checkBoxLabels,
      ClearAlertMessages,
      contactData,
      currentItemsPerPage,
      currentPagination,
      currentSortColumn,
      currentSortDesc,
      disableSelection,
      enableNisFeatures,
      goToEditContact,
      headers,
      iconOptions,
      isMultipleDeleteDisabled,
      maxModalWidth,
      multipleDelete,
      newRegisterUserInformation,
      selected,
      sendRegistrationRequest,
      tabList,
      totalNumberOfContacts,
      updateSortDesc,
      updateItemPerPage,
      updatePagination,
      updateSortColumn,
      updateTabName,
      updateUserIdStyle,
      reactivateNisUser,
      statusIsRegisterOrResend,
      setRegistrationMessage,
      statusIsResend,
      setResendMessage,
      deleteContactsConfirmationMessage,
      currentAgency,
      getTitle,
      itemsPerPage,
      EmptyGuid
    }
  },
})
</script>

<style lang="scss" scoped>
a {
  padding: 5px 0;
}

h2 {
  padding-bottom: 0;
}

.ngic-button {
  width: 125px;
}

.theme--light.v-icon {
  color: black;
}

.reactivate-button {
  max-height: 24px;
  max-width: 95px;
  margin-left: -2.75em;
}

</style>
