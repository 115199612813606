export const SystemDomain = {
  PL: process.env.VUE_APP_PL_URL,
}

export type SystemDomain = typeof SystemDomain[keyof typeof SystemDomain]

export enum PredefinedPath {
  Logout = 'Logout',
  PLPolicyEndorsement = 'policy - pl endorsements',
  PLPolicyMakeAPayment = 'policy - make payment',
  PLPolicySummary = 'policy - pl policy summary',
  PLQuoteMain = 'pl-quotemain',
  PLQuoteNamedInsured = 'Quote - pl create new quote',
  PLQuoteSummary = 'quote - quotesummary',
  PLCrossSell = 'pl-crosssell',
}

export interface DataEntityInterface {
  name: string
  value: string
}

export const DataStoreExpirationDays = 90

export interface AmtSystemTransferRequestInterface {
  data: Array<DataEntityInterface>
  destinationSystem: string
  generationDate: Date
  generationSystem: string
  user: string
  version: string
}

export interface DataStoreSystemTransferRequestInterface {
  data: Array<DataEntityInterface>
  expirationDate: Date
  systemName: string
  systemUserId: string
}
