<template>
  <div>
    <img
      v-for="(logo, index) in logos"
      :key="index"
      :src="logo.path"
      :width="logo.width ? logo.width : 'auto'"
      :height="logo.height ? logo.height : 'auto'"
      :alt="logo.alt"
    />
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue'
import { sortBy } from 'lodash'

import store from '@/store'
import { ImageInterface } from '@/store/modules/image/types'
import { ImageGetters } from '@/store/modules/image/getters'

export default defineComponent({
  name: 'LogosComponent',
  setup() {
    /**
     * Retrieves and returns all necessary logos via lookup.
     */
    const logos = computed<Array<ImageInterface>>(() => {
      const unsortedLogos: Array<ImageInterface> = store.getters[ImageGetters.Images]
      return sortBy(unsortedLogos, ['displayOrder'])
    })

    return {
      logos,
    }
  },
})
</script>
