import useApis from '@/api'
import store from '@/store'
import { UserSessionGetters } from '@/store/modules/user-session/getters'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { CompraterMutations } from './mutations'
import { CompraterInterface, SaveCompraterRequest } from './types'

export class CompraterActions {
  /**
   * gets the comprater information using the agent id in session
   */
  public static GetCompraterFromSession = 'comprater/getCompraterFromSession'
  /**
   * gets the comprater information using the agent id passed in
   */
  public static GetCompraterById = 'comprater/getCompraterById'
  /**
   * saves the comp rater information passed in
   */
  public static SaveComprater = 'comprater/saveComprater'
}

const { standard: api } = useApis()

export const actions: ActionTree<CompraterInterface, RootState> = {
  async getCompraterFromSession({ commit }) {
    try {
      const agentId = store.getters[UserSessionGetters.AgentId]
      const response = await api.get('agent_portal/crm/picklist/' + agentId)
      const comprater: CompraterInterface = response.data.value
      if (comprater) {
        commit(CompraterMutations.SET_COMPRATER, comprater)
      }
    } catch (reason) {
      console.error('agencyhome :: getCompraterFromSession :: failed:', reason)
      return false
    }
  },
  async getCompraterById({ commit }, payload: { agentId: number }) {
    try {
      const response = await api.get('agent_portal/crm/picklist/' + payload.agentId)
      const comprater: CompraterInterface = response.data.value
      commit(CompraterMutations.SET_COMPRATER, comprater)
      return comprater
    } catch (reason) {
      console.error('agencyhome :: getCompraterById :: failed:', reason)
      return null
    }
  },
  async saveComprater(_context, request: SaveCompraterRequest) {
    try {
      const response = await api.put('agent_portal/crm/comprater', request)
      return response.data.isSuccessful
    } catch (reason) {
      console.error('editAgency :: saveComprater :: failed:', reason)
      return null
    }
  },
}
