<template>
  <v-dialog :value="value" :max-width="maxWidth" :persistent="persistent" @input="emit('input', $event)">
    <v-card>
      <div class="pt-1" v-if="$slots.header">
        <slot name="header" />
      </div>
      <v-card-title class="pa-5">
        <slot name="title" />
      </v-card-title>
      <v-card-subtitle class="px-5" v-if="$slots.subtitle">
        <slot name="subtitle" />
      </v-card-subtitle>
      <v-card-text class="px-5" v-if="$slots.content">
        <slot name="content" />
      </v-card-text>
      <v-card-actions class="pa-5 justify-space-between flex-row-reverse">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppDialog',
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxWidth: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      emit,
    }
  },
})
</script>
