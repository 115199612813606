import { Module } from 'vuex'

import { RootState } from '@/store/types'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import { AlertMessagesInterface } from './types'

export const alertMessages: Module<AlertMessagesInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default alertMessages
