import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { OccupationSearchResponseInterface } from './types'

export class OccupationSearchGetters {
  public static OccupationResults = 'occupationSearch/occupations'
}

export const getters: GetterTree<OccupationSearchResponseInterface, RootState> = {
  occupations(state: OccupationSearchResponseInterface) {
    return state.results
  }
}

export default getters
