import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ContactListInterface } from './types'

export class ContactGetters {
  public static Contacts = 'contacts/contacts'
}

export const getters: GetterTree<ContactListInterface, RootState> = {
  contacts: (state: ContactListInterface) => state
}

export default getters
