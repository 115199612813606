import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { actions } from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import { OccupationSearchResponseInterface } from './types'

export const occupations: Module<OccupationSearchResponseInterface, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default occupations
