import { Phone } from '@/models/phone-number'
import { EmptyGuid } from '@/constants/Strings'
import { ProviderProfile} from "@/models/provider-profile"

export interface SocialSecurityNumberInterface {
  id: string
  lastFour: string
}

export class SocialSecurityNumber implements SocialSecurityNumberInterface {
  id: string
  lastFour: string

  constructor(options?: { id: string; lastFour: string }) {
    this.id = options?.id ?? ''
    this.lastFour = options?.lastFour ?? ''
  }
}

export interface AddressInterface {
  street: string
  city: string
  state: string
  zip: string
}

export class Address implements AddressInterface {
  street: string
  city: string
  state: string
  zip: string

  constructor(options?: { street: string; city: string; state: string; zip: string }) {
    this.street = options?.street ?? ''
    this.city = options?.city ?? ''
    this.state = options?.state ?? ''
    this.zip = options?.zip ?? ''
  }
}

export enum AgentContactRole {
  NonAdmin = 0,
  Admin = 1,
  AdminNoCommission = 2
}

export interface ContactInterface {
  id: number
  agentId: number
  name: string
  firstName: string
  middleName: string
  lastName: string
  lastStatusChangeDate: string
  lastStatusChangedBy: string
  suffix: string
  mailingAddress: AddressInterface
  physicalAddress: AddressInterface
  dob: string
  email: string
  workPhone: Phone
  cellPhone: Phone
  ssn: SocialSecurityNumberInterface
  type: string
  appointed: string
  hasActiveLicense: boolean
  userIdStatus: string
  registrationRequestMedium: string
  role: AgentContactRole
  nisUserId: string
  enabled: boolean
}

export interface ContactListInformationInterface {
  totalNumberOfContacts: number
  contactList: Array<ContactInterface>
}

export interface ContactListInterface {
  contactList: Array<ContactInterface>
}

export class Contact implements ContactInterface {
  id: number
  agentId: number
  name: string
  firstName: string
  middleName: string
  lastName: string
  lastStatusChangeDate: string
  lastStatusChangedBy: string
  suffix: string
  mailingAddress: AddressInterface
  physicalAddress: AddressInterface
  dob: string
  email: string
  workPhone: Phone
  cellPhone: Phone
  ssn: SocialSecurityNumberInterface
  type: string
  appointed: string
  hasActiveLicense: boolean
  userIdStatus: string
  registrationRequestMedium: string
  role: AgentContactRole
  nisUserId: string
  enabled: boolean

  constructor(options?: {
    id: number
    agentId: number
    name: string
    firstName: string
    middleName: string
    lastName: string
    lastStatusChangeDate: string
    lastStatusChangedBy: string
    suffix: string
    mailingAddress: AddressInterface
    physicalAddress: AddressInterface
    dob: string
    email: string
    workPhone: Phone
    cellPhone: Phone
    ssn: SocialSecurityNumberInterface
    type: string
    appointed: string
    hasActiveLicense: boolean
    userIdStatus: string
    registrationRequestMedium: string
    role: AgentContactRole
    nisUserId: string
    enabled: boolean
  }) {
    this.id = options?.id ?? 0
    this.agentId = options?.agentId ?? 0
    this.name = options?.name ?? ''
    this.firstName = options?.firstName ?? ''
    this.middleName = options?.middleName ?? ''
    this.lastName = options?.lastName ?? ''
    this.lastStatusChangeDate = options?.lastStatusChangeDate ?? ''
    this.lastStatusChangedBy = options?.lastStatusChangedBy ?? ''
    this.suffix = options?.suffix ?? ''
    this.mailingAddress = options?.mailingAddress ?? new Address()
    this.physicalAddress = options?.physicalAddress ?? new Address()
    this.dob = options?.dob ?? ''
    this.email = options?.email ?? ''
    this.workPhone = options?.workPhone ?? new Phone('')
    this.cellPhone = options?.cellPhone ?? new Phone('')
    this.ssn = options?.ssn ?? new SocialSecurityNumber()
    this.type = options?.type ?? ''
    this.appointed = options?.appointed ?? ''
    this.hasActiveLicense = options?.hasActiveLicense ?? false
    this.userIdStatus = options?.userIdStatus ?? ''
    this.registrationRequestMedium = options?.registrationRequestMedium ?? ''
    this.role = options?.role ?? AgentContactRole.NonAdmin
    this.nisUserId = options?.nisUserId ?? EmptyGuid
    this.enabled = options?.enabled ?? false
  }
}

export class ContactListByBatch implements ContactListInformationInterface {
  totalNumberOfContacts: number
  contactList: Array<ContactInterface>

  constructor(options?: { totalNumberOfContacts: number; contactList: Array<ContactInterface> }) {
    this.totalNumberOfContacts = options?.totalNumberOfContacts ?? 0
    this.contactList = options?.contactList ?? []
  }
}

export interface ContactInformationRequest {
  additionalAgencyRoles: {[key: number]: AgentContactRole}
  agencyName: string
  agencyStatus: string
  agentId: number
  appointed: string
  cellPhone: string
  dob: string
  email: string
  firstName: string
  id: number
  isUpdate: boolean
  lastName: string
  mailingAddress: AddressInterface
  middleName: string
  nisUserId: string
  originalAppointedStatus: boolean
  physicalAddress: AddressInterface
  princetonAgentCode: string
  role: AgentContactRole
  ssn: SocialSecurityNumberInterface
  suffix: string
  type: string
  workPhone: string
}

export interface ContactRemovalInformation {
  contactId: number
  contactFirstName: string
  contactMiddleName: string
  contactLastName: string
  terminationEffectiveDate: string
  removeFromAllLocations: boolean
  hasActiveLicense: boolean
}
export interface LicenseTerminationRequest {
  removedContacts: Array<ContactRemovalInformation>
  agentId: number
  princetonAgentCode: string
  agencyName: string
}

export interface NisAgencyInformation {
  agtId: number
  agencyName: string
  agencyPrincetonCode: string
  agencyStreetAddress: string
  associatedContactId: number
}

export interface SingleSignOnInformationInterface {
  username: string
  providerInformation: ProviderProfile
}

export interface RegisterUserInformation {
  currentContactId: number
  firstName: string
  lastName: string
  dob: string
  registrationEmail: string | null
  registrationMobilePhone: string | null
  isUpdate: boolean
  nisLoginAgencies: Array<NisAgencyInformation>
  identityProviderInformation: ProviderProfile | null
  singleSignOnUsername: string | null
}

export interface RegisterUserRequestInterface {
  currentContactId: number
  registrationEmail: string | null
  registrationMobilePhone: string | null
  isUpdate: boolean
  nisLoginAgencies: Array<NisAgencyInformation>
}

export class RegisterUserRequest implements RegisterUserRequestInterface {
  contactFirstName: string
  contactLastName: string
  currentContactId: number
  registrationEmail: string | null
  registrationMobilePhone: string | null
  isUpdate: boolean
  nisLoginAgencies: Array<NisAgencyInformation>
  identityProviderInformation: ProviderProfile | null
  singleSignOnUsername: string | null

  constructor(options?: RegisterUserInformation) {
    this.contactFirstName = options?.firstName ?? ''
    this.contactLastName = options?.lastName ?? ''
    this.currentContactId = options?.currentContactId ?? 0
    this.registrationEmail = options?.registrationEmail ?? null
    this.registrationMobilePhone = options?.registrationMobilePhone ?? null
    this.isUpdate = options?.isUpdate ?? false
    this.nisLoginAgencies = options?.nisLoginAgencies ?? []
    this.identityProviderInformation = options?.identityProviderInformation ?? null
    this.singleSignOnUsername = options?.singleSignOnUsername ?? null
  }

}