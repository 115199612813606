import { ImageInterface } from '@/store/modules/image/types'

export const CVIcon: ImageInterface = {
  path: require('@/assets/icons/Cross-sell_Icons(rev)_CommercialVehicle.svg'),
  alt: 'Commercial Vehicle icon',
  width: 80,
}
export const PPAIcon: ImageInterface = {
  path: require('@/assets/icons/Cross-sell_Icons(rev)_PersonalAuto.svg'),
  alt: 'Personal Auto icon',
  width: 80,
}
export const MCIcon: ImageInterface = {
  path: require('@/assets/icons/Cross-sell_Icons(rev)_Motorcycle.svg'),
  alt: 'Motorcycle icon',
  width: 80,
}
export const RVIcon: ImageInterface = {
  path: require('@/assets/icons/Cross-sell_Icons(rev)_RecreationalVehicle.svg'),
  alt: 'Recreational Vehicle icon',
  width: 80,
}
