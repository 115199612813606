import store from '@/store'
import { OccupationFilterActions } from '@/store/modules/occupation-filter/actions'
import { OccupationFilterGetters } from '@/store/modules/occupation-filter/getters'
import { OccupationInterface } from '@/store/modules/occupation/types'
import { computed } from 'vue'

export function selectAllSubCategories(value: boolean): void {
  const subCategoriesToDisplay = computed<Array<OccupationInterface>>(() => {
    return store.getters[OccupationFilterGetters.SelectedSubCategories]
  })

  subCategoriesToDisplay.value.forEach((item) => {
    item.checked = value
  })

  store.dispatch(OccupationFilterActions.UpdateSelectedSubCategories, subCategoriesToDisplay.value)
  store.dispatch(OccupationFilterActions.FilterOccupations)
}

export function selectAllCategories(value: boolean): void {
  const categoriesToDisplay = computed<Array<OccupationInterface>>(() => {
    return store.getters[OccupationFilterGetters.SelectedCategories]
  })

  categoriesToDisplay.value.forEach((item) => {
    item.checked = value
  })

  store.dispatch(OccupationFilterActions.UpdateSelectedCategories, categoriesToDisplay.value)

  selectAllSubCategories(value)
}
