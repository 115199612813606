import { LoginProviderType, LoginType, UserSessionInterface } from './types'

/** The user session state. */
export const state: UserSessionInterface = {
  agentGroup: 0,
  agentId: 0,
  canViewCommissionStatement: false,
  emailAddress: '',
  employeeNum: 0,
  environment: '',
  firstName: '',
  generatedFrom: '',
  homeOfficeId: 0,
  initialState: '',
  isAgencyAdmin: null,
  isAgencyPrimaryLogin: false,
  isAgent: true,
  isHomeOffice: false,
  isDaStore: false,
  isInternalSales: true,
  isMGA: false,
  isSuperUser: false,
  lastName: '',
  loginProviderType: LoginProviderType.Uninitialized,
  loginType: LoginType.Uninitialized,
  masterAccountId: 0,
  masterUserId: null,
  mode: '',
  nisUserId: '',
  officeAccountCode: 0,
  originUrl: '',
  permissions: [],
  policyId: 0,
  princetonAgentCode: '',
  product: '',
  quoteId: 0,
  role: '',
  subProduct: '',
  username: '[No Username]',
  userProductOptions: [],
  userStateOptions: [],
}

export default state
